export const secureLocalStorage = {
  //To change the custom secure key, Please add `REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY` to .env and change the value
  hashKey: process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY || '__cognitive-engine__',

  // Method to encode data using XOR encryption
  encrypt(data: string) {
    let encoded = ''
    for (let i = 0; i < data.length; i++) {
      const charCode = data.charCodeAt(i) ^ this.hashKey.charCodeAt(i % this.hashKey.length)
      encoded += String.fromCharCode(charCode)
    }
    return encoded
  },

  // Method to decode data using XOR encryption
  decrypt(encodedData: string) {
    let decoded = ''
    for (let i = 0; i < encodedData.length; i++) {
      const charCode = encodedData.charCodeAt(i) ^ this.hashKey.charCodeAt(i % this.hashKey.length)
      decoded += String.fromCharCode(charCode)
    }
    return decoded
  },
}
