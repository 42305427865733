import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { RangeSliderProps } from './types'

export default function RangeSlider({
  sliderStyle,
  title,
  className,
  minValue,
  maxValue,
  sliderValue,
  handleSliderChange,
}: RangeSliderProps) {
  return (
    <Box sx={sliderStyle}>
      <div className="flex flex-row items-center gap-x-5 ">
        <div className="whitespace-nowrap">{title}</div>
        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          className={className}
          min={minValue}
          max={maxValue}
        />
      </div>
    </Box>
  )
}
