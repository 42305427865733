import { IconProps } from './types'

export const MODEL_TRAINING_TEST_ID = 'MODEL_TRAINING_TEST_ID'

export const ModelTrainingIcon = ({ testId = MODEL_TRAINING_TEST_ID, className }: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width={444} height={300} fill="none">
      <g clipPath="url(#a)">
        <rect width={21.514} height={14.72} x={364.02} y={51.161} fill="#131313" rx={6} />
        <g filter="url(#b)">
          <path fill="#7A7A7A" d="m259.52 20.16 40.703 23.5-79.5 137.699-40.703-23.5z" />
        </g>
      </g>
      <path
        fill="#EAEDFB"
        fillOpacity={0.08}
        d="M376.687 285.614s68.15-30.346 66.568-91.566c-1.583-61.22-66.166-89.692-110.026-51.901-43.847 37.804-72.22 6.099-94.942-25.844-22.721-31.943-73.388-72.25-139.88-53.624C28.76 82.184 7.32 152.811 14.228 199.946c4.961 33.855 23.085 62.554 47.778 84.612.804.717 1.62 1.421 2.462 2.125l312.218-1.069Z"
      />
      <g clipPath="url(#c)">
        <path
          fill="#fff"
          d="M390.414 56.757c-.205 0-.424-.029-.628-.014a20.373 20.373 0 0 0-2.5.029c-1.87.131-3.697.511-5.451 1.125l-1.71-2.981-7.132 4.136.088.146 1.651 2.82a21.71 21.71 0 0 0-5.261 6.884l-3.318-.877-2.119 7.95 3.318.878a21.741 21.741 0 0 0 1.14 8.593l-2.982 1.71 4.136 7.132 2.967-1.724a21.7 21.7 0 0 0 6.884 5.261l-.877 3.318 7.95 2.119.892-3.332c2.967.219 5.875-.19 8.579-1.14l1.71 2.981 7.132-4.136-1.725-2.967a21.455 21.455 0 0 0 5.247-6.883l3.332.891 2.119-7.95-3.332-.892a21.14 21.14 0 0 0-1.14-8.579l2.835-1.637.147-.087-2.485-4.282-1.651-2.836-2.821 1.652-.146.087s-.088-.102-.132-.16a21.25 21.25 0 0 0-6.752-5.101l.892-3.332-7.951-2.12-.891 3.333-.015-.015Zm11.794 25.123c-1.958 7.337-9.485 11.692-16.821 9.748-7.337-1.958-11.692-9.485-9.749-16.821 1.959-7.337 9.485-11.692 16.822-9.749 7.337 1.959 11.692 9.485 9.748 16.822Z"
          opacity={0.1}
        />
      </g>
      <g clipPath="url(#d)">
        <path
          fill="#fff"
          d="M422.84 104.383c-.07 0-.145-.01-.214-.005a7.151 7.151 0 0 0-2.711.394l-.583-1.016-2.431 1.41.03.049.563.962a7.402 7.402 0 0 0-1.794 2.347l-1.131-.299-.722 2.71 1.131.299a7.415 7.415 0 0 0 .388 2.93l-1.016.583 1.41 2.431 1.011-.588a7.417 7.417 0 0 0 2.347 1.794l-.299 1.131 2.71.722.304-1.136a7.217 7.217 0 0 0 2.925-.388l.583 1.016 2.431-1.41-.588-1.011a7.302 7.302 0 0 0 1.789-2.347l1.136.304.722-2.711-1.135-.304a7.215 7.215 0 0 0-.389-2.924l.966-.558.05-.03-.847-1.46-.563-.967-.961.563-.05.03s-.03-.034-.045-.054a7.232 7.232 0 0 0-2.302-1.739l.304-1.136-2.71-.723-.304 1.136-.005-.005Zm4.021 8.565a4.69 4.69 0 0 1-5.735 3.323 4.689 4.689 0 0 1-3.323-5.734 4.69 4.69 0 0 1 5.734-3.324 4.692 4.692 0 0 1 3.324 5.735Z"
          opacity={0.1}
        />
      </g>
      <g clipPath="url(#e)" opacity={0.1}>
        <path
          fill="#BFBBBB"
          d="m68.985 8 2.394 6.24c1.47 3.845 4.505 6.862 8.35 8.35l6.24 2.395-6.24 2.394c-3.845 1.47-6.86 4.505-8.35 8.35l-2.394 6.24-2.394-6.24c-1.47-3.845-4.506-6.86-8.351-8.35L52 24.985l6.24-2.394c3.845-1.47 6.861-4.506 8.35-8.351L68.986 8Z"
        />
      </g>
      <g clipPath="url(#f)" opacity={0.1}>
        <path
          fill="#BFBBBB"
          d="m427.492 35 1.197 3.12a7.265 7.265 0 0 0 4.176 4.175l3.12 1.197-3.12 1.198a7.263 7.263 0 0 0-4.176 4.175l-1.197 3.12-1.197-3.12a7.26 7.26 0 0 0-4.175-4.175L419 43.492l3.12-1.197a7.259 7.259 0 0 0 4.175-4.175l1.197-3.12Z"
        />
      </g>
      <g clipPath="url(#g)" opacity={0.1}>
        <path
          fill="#BFBBBB"
          d="m8.492 82 1.197 3.12a7.262 7.262 0 0 0 4.176 4.175l3.12 1.197-3.12 1.197a7.262 7.262 0 0 0-4.176 4.176l-1.197 3.12-1.197-3.12A7.262 7.262 0 0 0 3.12 91.69L0 90.492l3.12-1.197a7.262 7.262 0 0 0 4.175-4.175L8.492 82Z"
        />
      </g>
      <path
        fill="#BFBBBB"
        fillOpacity={0.4}
        d="M268.004 137.558v-34.28c0-.756-.066-1.523.042-2.26.295-1.963 1.467-3.111 3.45-3.244 1.851-.127 3.426 1.299 3.66 3.262.09.743.042 1.505.042 2.26v34.262h38.224c3.841 0 4.965 1.124 4.971 4.954.006 5.431.03 10.857 0 16.288-.085 12.724-9.412 22.161-22.057 22.173-38.098.036-76.196.036-114.287 0-12.651-.012-22.021-9.461-22.027-22.191-.03-37.476-.012-74.952-.012-112.428v-2.907h-3.714c-9.226 0-18.451.007-27.671 0-3.377 0-4.597-1.232-4.603-4.64-.012-5.654-.042-11.31 0-16.964.114-12.295 9.484-21.786 21.726-21.804 35.922-.055 71.844-.049 107.766 0 11.329.018 20.374 8.337 21.6 19.598.054.526.072 1.052.072 1.583-.006 2.447-1.412 4.079-3.528 4.115-2.031.036-3.413-1.444-3.666-3.776-.15-1.414-.294-2.864-.715-4.211-1.911-6.193-7.38-10.084-14.172-10.084-28.048-.018-56.091-.006-84.14-.006h-7.128c2.801 3.589 4.634 7.238 5.12 11.431.181 1.565.247 3.154.247 4.73.012 45.09 0 90.184.018 135.272 0 8.598 6.172 15.038 14.346 15.056 7.825.018 14.256-6.181 14.412-14.059.114-5.806.018-11.612.036-17.418.006-3.522 1.208-4.706 4.76-4.706h67.228v-.006Zm-70.191 36.026c.697.103.914.157 1.13.157 32.689 0 65.371.03 98.06-.024 7.182-.012 13.27-5.256 13.973-12.5.517-5.353.102-10.797.102-16.307H203.324c-.781 9.746 2.188 20.071-5.511 28.668v.006ZM131.264 36.053h28.71c0-5.275.216-10.398-.048-15.503-.385-7.443-6.762-13.25-14.202-13.292-7.459-.042-13.931 5.631-14.394 13.074-.325 5.166-.06 10.374-.06 15.715l-.006.006Z"
      />
      <path
        fill="#BFBBBB"
        fillOpacity={0.4}
        d="M199.975 79.329v45.327c0 3.811-1.169 4.932-5.124 4.938-4.266.006-8.538.024-12.803-.006-3.035-.024-4.409-1.33-4.409-4.267-.013-30.667-.013-61.335 0-92.002 0-2.937 1.368-4.231 4.415-4.25 4.421-.03 8.842-.023 13.27 0 3.339.013 4.645 1.26 4.645 4.484.012 15.258 0 30.517 0 45.776h.006Zm-4.651 42.977V33.319l-13.27.012-.006 91.325h12.803l.473-2.35ZM227.894 97.18v27.665c0 3.539-1.169 4.74-4.617 4.746-4.127.012-8.254.024-12.375 0-2.899-.018-4.21-1.316-4.21-4.247-.017-18.744-.017-37.488 0-56.232 0-2.956 1.288-4.265 4.169-4.29 4.197-.03 8.395-.023 12.599 0 3.188.013 4.434 1.256 4.44 4.47.017 9.3 0 18.594 0 27.894l-.006-.006Zm-3.394 27.665L224 70.5h-13.098v54.345H224.5ZM256.947 115.77c0 3.272.029 6.538-.012 9.809-.036 2.696-1.346 3.976-4.107 4a808.37 808.37 0 0 1-13.029 0c-2.732-.024-4.037-1.328-4.048-4.052-.03-6.613-.03-13.221 0-19.834.011-2.702 1.339-3.971 4.107-3.994a808.37 808.37 0 0 1 13.029 0c2.732.023 4.018 1.322 4.048 4.052.035 3.341.006 6.683.006 10.025l.006-.006ZM253.5 125v-19.249H239V125h14.5ZM231.954 14.542c2.212 0 4.431-.046 6.643.013 2.284.058 3.786 1.585 3.834 3.781.053 2.275-1.503 3.945-3.887 3.97-4.502.053-9.004.053-13.506 0-2.289-.025-3.78-1.591-3.833-3.788-.053-2.293 1.485-3.911 3.881-3.963 2.289-.052 4.573-.013 6.862-.013h.006ZM256.949 18.556c-.056 2.123-1.641 3.86-3.451 3.787-1.827-.072-3.305-1.9-3.243-4.03.056-2.135 1.624-3.852 3.451-3.786 1.827.065 3.305 1.9 3.249 4.023l-.006.006Z"
      />
      <g fill="#BFBBBB" clipPath="url(#h)" opacity={0.4}>
        <path d="m303.723 96.35-4.871-4.88c-8.845 5.642-18.431 7.247-28.624 4.227-7.905-2.342-14.142-7.075-18.656-13.975-9.158-14.005-6.818-32.489 5.473-43.822 12.02-11.085 30.676-11.887 43.608-2.008 13.386 10.227 18.231 29.987 7.803 45.937 1.577 1.56 3.144 3.109 5.007 4.955.12-.201.289-.653.597-.977.941-.992 1.379-.981 2.338-.026 4.11 4.083 8.215 8.172 12.322 12.258 2.815 2.801 5.664 5.568 8.434 8.414 5.171 5.313 2.987 14.114-4.016 16.116-3.712 1.06-7.016.16-9.771-2.561-4.746-4.689-9.466-9.406-14.196-14.114a5639.55 5639.55 0 0 1-6.297-6.27c-1.165-1.16-1.17-1.468-.005-2.62.252-.25.568-.438.854-.652v-.002Zm8.179-33.274c.008-17.584-14.225-31.849-31.812-31.888-17.634-.04-31.927 14.257-31.877 31.89.049 17.638 14.251 31.86 31.827 31.872 17.514.01 31.857-14.335 31.865-31.872l-.003-.003Zm-7.084 35.307s.021.063.063.105c6.802 6.772 13.578 13.57 20.425 20.293 2.015 1.979 4.548 2.446 7.243 1.64 5.421-1.624 7.026-8.402 2.932-12.516-6.542-6.572-13.136-13.093-19.709-19.633-.172-.172-.38-.308-.469-.379l-10.488 10.488.003.002Zm2.541-14.022-6.145 6.07 3.891 3.9 6.192-6.07c-1.41-1.396-2.856-2.83-3.938-3.897v-.003Z" />
        <path d="M280.111 37.164c14.306.104 25.864 11.795 25.742 26.037-.125 14.37-11.707 25.8-26.037 25.697-14.214-.101-25.721-11.816-25.596-26.057.125-14.23 11.773-25.779 25.893-25.677h-.002Zm-23.668 25.825c-.054 13.005 10.475 23.646 23.472 23.727 13.047.08 23.618-10.43 23.704-23.57.086-13.059-10.448-23.706-23.511-23.758-13.049-.055-23.61 10.477-23.665 23.601Z" />
        <path d="M263.837 70.446c-.268-.34-.732-.703-.899-1.17-2.028-5.744-1.355-11.197 1.923-16.312.592-.924 1.358-1.739 2.065-2.587.471-.567 1.066-.825 1.686-.277.657.582.459 1.217-.084 1.783-2.293 2.39-3.724 5.216-4.287 8.482-.482 2.789-.211 5.51.735 8.164.237.669.313 1.28-.396 1.708-.133.08-.308.091-.743.209ZM275.034 45.33c.386.366.889.622.949.956.067.386-.133 1.07-.43 1.23a18.558 18.558 0 0 1-3.31 1.394c-.331.101-1.014-.204-1.181-.515-.169-.313-.052-1.125.198-1.269 1.173-.668 2.437-1.182 3.774-1.801v.005Z" />
      </g>
      <defs>
        <clipPath id="a">
          <rect width={21.514} height={14.72} x={364.02} y={51.161} fill="#fff" rx={6} />
        </clipPath>
        <clipPath id="c">
          <path fill="#fff" d="M364.02 53.425h49.822v49.822H364.02z" />
        </clipPath>
        <clipPath id="d">
          <path fill="#fff" d="M413.842 103.247h16.985v16.985h-16.985z" />
        </clipPath>
        <clipPath id="e">
          <path fill="#fff" d="M52 8h33.97v33.97H52z" />
        </clipPath>
        <clipPath id="f">
          <path fill="#fff" d="M419 35h16.985v16.985H419z" />
        </clipPath>
        <clipPath id="g">
          <path fill="#fff" d="M0 82h16.985v16.985H0z" />
        </clipPath>
        <clipPath id="h">
          <path fill="#fff" d="M246 29h94v94h-94z" />
        </clipPath>
        <filter
          id="b"
          width={348.203}
          height={389.198}
          x={66.019}
          y={-93.839}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_107_557" stdDeviation={57} />
        </filter>
      </defs>
    </svg>
  </div>
)
