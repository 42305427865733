import { COLORS } from './colors'

const MuiCard = {
  styleOverrides: {
    root: {
      backgroundColor: COLORS.DARK94,
    },
  },
}

export default MuiCard
