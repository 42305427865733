import { IconProps } from './types'
import { COLORS } from '../../../themes/default/colors'

export const CHECK_ICON_TEST_ID = 'CHECK_ICON_TEST_ID'

export const Check = ({
  testId = CHECK_ICON_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  pathClasses,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill="none" d="M0 0h32v32H0z" />
      <path
        fill={color}
        className={pathClasses}
        path="evenodd"
        d="m30 7-1.41-1.41L11 23.17l-7.59-7.58L2 17l9 9L30 7z"
      />
    </svg>
  </div>
)
