import { LabelInput, Tooltip } from '../../../components'
import { InfoOutlined } from '../../../components/icons'
import { validationMessages } from '../../../consts'

interface IDefineSearchSpaceType {
  searchSpaceName: string
  onSearchSpaceNameChange: (value: string) => void
  isValidName: string
}

export default function DefineSearchSpace({
  searchSpaceName,
  onSearchSpaceNameChange,
  isValidName,
}: IDefineSearchSpaceType) {
  return (
    <div className="flex w-full flex-col gap-y-4 text-white">
      <div className="flex flex-col">
        <div className="flex flex-col font-RakutenSansBold text-2xl">Create Search Space</div>
        <div className="flex flex-row items-center ">
          Setup the search space
          {/* <Tooltip placement="right" title={''}>
            <InfoOutlined className="w-8 cursor-pointer px-2 pt-1" />
          </Tooltip> */}
        </div>
      </div>
      <div className="h-[1px] w-full bg-warm_grey"></div>
      <div className="flex flex-col">
        <div className="flex w-full flex-row whitespace-nowrap text-base ">
          Search space name <span className="ml-0.5 text-red-500">*</span>
        </div>
        <LabelInput
          error={isValidName !== ''}
          label="Unique name for your searchspace"
          placeholder="Type search space name"
          value={searchSpaceName}
          onChange={(e) => onSearchSpaceNameChange(e.target.value)}
          errorMessage={isValidName ? validationMessages.sourceName[isValidName] : ''}
        />
      </div>
    </div>
  )
}
