import { BadgeProps } from './types'

export default function Badge({ cue, label, className, cueClasses, labelClasses }: BadgeProps) {
  return (
    <div className={`flex h-7 items-center gap-1 rounded-full bg-warm600 p-2 ${className}`}>
      <div className={`flex whitespace-nowrap text-xs ${labelClasses}`}> {label}</div>
      <div
        className={`flex min-h-[theme('spacing[6]')]	w-full min-w-[theme('spacing[6]')] items-center justify-center rounded-full bg-dark2 p-1 text-xs ${cueClasses}`}
      >
        {cue}
      </div>
    </div>
  )
}
