import Plot from 'react-plotly.js'
import { COLORS } from '../../../../themes/default/colors'
import { ForecastChartProps } from './types'
import { ellipsizeString } from '../../../utils'

export const FORECAST_CHART_TEST_ID = 'FORECAST_CHART_TEST_ID'

const mapGraphData = (
  x: string[] | Date[],
  y: (string | number | null)[][],
  colors: string[],
  labels: string[],
  texts: string[]
): Plotly.Data[] =>
  y.map((data, index) => {
    let legendgroup = null
    if (index < 2) {
      legendgroup = 'thresholds'
    }
    return {
      name: labels[index],
      x: x,
      y: data,
      fill: 'none',
      line: {
        color: colors[index],
      },
      mode: index < 4 ? 'lines' : 'markers',
      marker: {
        color: colors[index],
        size: 10,
      },
      hoverinfo: 'none',
      text: texts[index],
      showlegend: !!index,
      ...(index < 2 ? { fill: index === 1 ? 'tonexty' : 'none', fillcolor: '#0089FF4D' } : {}),
      legendgroup: legendgroup,
    }
  })

export default function ForecastChart({
  testId = FORECAST_CHART_TEST_ID,
  xAxisData,
  yAxisData,
  colors,
  labels,
  containerBackgroundColor,
  plotBackgroundColor,
  fontColor,
  xAxisTitle,
  yAxisTitle,
  annotationTexts = [],
  onHover,
  scrollZoom,
  onClick,
  yTexts,
  children,
}: ForecastChartProps) {
  return (
    <div className="relative flex h-full w-full flex-col">
      {children}
      <Plot
        data-testid={testId}
        className="h-full w-full"
        onHover={onHover}
        onClick={onClick}
        data={mapGraphData(xAxisData, yAxisData, colors, labels, yTexts)}
        layout={{
          margin: { r: 35, l: 55, t: 50, b: 10 },
          autosize: true,
          paper_bgcolor: containerBackgroundColor,
          plot_bgcolor: plotBackgroundColor,
          font: {
            color: fontColor,
            size: 10,
          },
          xaxis: {
            title: xAxisTitle,
            showspikes: true,
            spikedash: 'solid',
            spikethickness: 0.5,
            spikecolor: 'white',
            titlefont: { size: 10 },
          },
          yaxis: {
            title: yAxisTitle,
            showgrid: true,
            rangemode: 'tozero',
            titlefont: { size: 10 },
          },
          // title: { text: title, font: { size: 15 }, xanchor: 'auto', yanchor: 'top' },
          annotations: [
            {
              xref: 'paper',
              yref: 'paper',
              x: -0.01,
              y: 1.1,
              xanchor: 'left',
              yanchor: 'bottom',
              text: ellipsizeString(annotationTexts[0], 20),
              showarrow: false,
              font: {
                size: 12,
              },
              hovertext: annotationTexts[0]?.match(/.{1,60}/g)?.join('<br>'),
            },
          ],
          legend: {
            yanchor: 'bottom',
            y: -0.35,
            xanchor: 'center',
            x: 0.4,
            orientation: 'h',
            font: { size: 10 },
          },
          modebar: {
            orientation: 'h',
            color: COLORS.PRIMARY,
            activecolor: 'white',
          },
          hoverlabel: { namelength: 20 },
          hovermode: 'x',
        }}
        config={{
          displaylogo: false,
          doubleClick: 'reset+autosize',
          watermark: false,
          scrollZoom,
          modeBarButtons: [['resetViews', 'zoomIn2d', 'zoomOut2d', 'pan2d', 'zoom2d']],
        }}
        useResizeHandler
      />
    </div>
  )
}
