/** This file contains all Login level routes */
import { RouteObject } from 'react-router-dom'
import Login from '../pages/login'
import { dashboardRoutes } from './dashboard-routes'
import Dashboard from '../pages/dashboard'
import {
  LOGIN_ROUTES,
  DASHBOARD_ROUTES,
  LOGIN_ROUTES_NAMES,
  DASHBOARD_ROUTES_NAMES,
} from './route-names'
import SignUp from '../pages/sign-up'
import OtpValidation from '../pages/otp-validation'
import AlertRules from '../pages/dashboard/alert-rules'
import { alertRoutes } from './alert-routes'
import AllSources from '../pages/dashboard/main-dashboard/all-sources'

export const loginRoutes: RouteObject[] = [
  {
    id: LOGIN_ROUTES_NAMES.LOGIN,
    index: true,
    path: LOGIN_ROUTES.LOGIN,
    element: <Login />,
  },
  {
    id: LOGIN_ROUTES_NAMES.SIGNUP,
    path: LOGIN_ROUTES.SIGNUP,
    element: <SignUp />,
  },
  {
    id: LOGIN_ROUTES_NAMES.VERIFY,
    path: LOGIN_ROUTES.VERIFY,
    element: <OtpValidation />,
  },
  {
    path: DASHBOARD_ROUTES.HOME,
    element: <Dashboard />,
    children: dashboardRoutes,
  },
  {
    id: DASHBOARD_ROUTES_NAMES.ALERTS_RULES,
    path: DASHBOARD_ROUTES.ALERTS_RULES,
    element: <AlertRules />,
  },
  {
    id: DASHBOARD_ROUTES_NAMES.ALL_SOURCES,
    path: DASHBOARD_ROUTES.ALL_SOURCES,
    element: <AllSources />,
  },
]
