import moment from 'moment'
import { GraphDateRangeType } from './types'
import { RelativeTimeType } from '../../components/graph-date-range/types'

export const getQueryRange = (graphDateRange: GraphDateRangeType) => {
  const defaultGraphDateRange = {
    rangeType: 'absolute',
    fromDate: moment().subtract(30, 'minutes').valueOf(),
    toDate: moment().valueOf(),
    showForecast: false,
    relativeTime: '30-minutes',
  }

  const { rangeType, fromDate, toDate, showForecast, relativeTime } =
    graphDateRange || defaultGraphDateRange

  const [amount, unit] = relativeTime.split('-')
  if (rangeType === 'absolute') {
    return { fromDate, toDate }
  } else if (showForecast) {
    const subAmount = Number(amount) >= 30 ? 30 : amount
    return {
      fromDate: moment().subtract(subAmount, 'minutes').valueOf(),
      toDate: moment()
        .add(amount, unit as RelativeTimeType)
        .valueOf(),
    }
  } else {
    return {
      fromDate: moment()
        .subtract(amount, unit as RelativeTimeType)
        .millisecond(0)
        .valueOf(),
      toDate: moment().millisecond(0).valueOf(),
    }
  }
}
