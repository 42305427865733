import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { MultivariateAnalysisInitialState } from './types'

export const reducerPath = 'multivariateAnalysis'

const initialState: MultivariateAnalysisInitialState = {
  occuranceRange: {
    fromDate: moment().subtract(120, 'minutes').valueOf(),
    toDate: moment().valueOf(),
  },
}

export const multivariateAnalysisSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeGraphDateRange: (prevState, { payload }) => {
      return {
        ...prevState,
        occuranceRange: payload,
      }
    },
  },
  extraReducers: (builder) => {},
})

export const { changeGraphDateRange } = multivariateAnalysisSlice.actions
