import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useAppSelector } from '../../../../../hooks'
import { RangeSlider, Tooltip } from '../../../../../components'
import {
  CorelationOrCausationType,
  initialCausalGraphState,
  selectedBreachStateSelector,
  useGetCausationsGraphQuery,
  useGetCorelationsQuery,
} from '../../../../../store/anomalies'
import MultivariateGraphCard from './multivariate-graph-card'
import { GraphAbsoluteRangeType } from '../../../../../components/graph-date-range/types'
import { SOURCES_ROUTES } from '../../../../../routes'
import { useSourceTabsContext } from '../../../../../providers'
import { SankeyDiagram } from '../../../../../components/charts'
import { useEffect, useState } from 'react'
import { COLORS } from '../../../../../../themes/default/colors'
import { BREACH_COLORS } from '../../../../../store/anomaly-detection'
import { sankeyDiagramLegend } from '../../../../../components/charts/sankey-diagram'
import { InfoOutlined, ThumbsUpOutlined } from '../../../../../components/icons'
import { AllCausalData, CausalArrayType, LabelType } from '../../../../../utils'
import _ from 'underscore'
import CausalAnomalyCard from './causal-anomaly-card'

export default function ViewMultivariateAnalysis() {
  const [nodeColor, setNodeColor] = useState<string[]>([])
  const { sourceId = '' } = useParams()
  const { setCurrentTabState, currentSourceTab, changeSourceMultivariateDateRange } =
    useSourceTabsContext()
  const selectedBreach = useAppSelector(selectedBreachStateSelector(sourceId as string))
  const { data: breaches = [] } = useGetCorelationsQuery({
    eventTime: moment(selectedBreach.eventTime).utc().valueOf(),
    queryId: selectedBreach.queryId,
  })

  const { data: causationData = initialCausalGraphState, isFetching: isFetchingCausalData } =
    useGetCausationsGraphQuery({
      sourceId: sourceId,
      eventTime: moment(selectedBreach.eventTime).utc().valueOf(),
    })

  const [sliderValue, setSliderValue] = useState<number[]>([0, 100])

  useEffect(() => {
    var nodeColor: string[] = []

    causationData.labelValue.map((a: LabelType) => {
      if (a.queryId == selectedBreach.queryId) {
        nodeColor.push(COLORS.BLUE100)
      } else if (!causationData.target.includes(a.queryId)) {
        nodeColor.push(COLORS.PRIMARY)
      } else if (
        causationData.source.includes(a.queryId) &&
        causationData.target.includes(a.queryId)
      ) {
        nodeColor.push(COLORS.LEMON_YELLOW)
      } else {
        nodeColor.push(BREACH_COLORS[2])
      }
    })
    setNodeColor(nodeColor)
  }, [causationData.labelValue, sliderValue])

  const [causalData, setCausalData] = useState<CausalArrayType>(initialCausalGraphState)

  useEffect(() => {
    setCausalData(causationData)
  }, [causationData])

  function handleSliderChange(event: Event, newValue?: number | number[]) {
    setSliderValue(newValue as number[])
  }

  useEffect(() => {
    setCausalData(graphData(causationData, sliderValue))
  }, [sliderValue])

  function graphData(causationData: CausalArrayType, sliderValue: number[]): AllCausalData {
    const sliderGraph: AllCausalData = {
      value: [],
      source: [],
      target: [],
      labelValue: [],
      nodeColor: [],
    }
    causationData.value.map((percentage: number, index) => {
      if (percentage >= sliderValue[0] && percentage <= sliderValue[1]) {
        sliderGraph.source.push(causationData.source[index])
        sliderGraph.target.push(causationData.target[index])
        sliderGraph.value.push(percentage)
      }
    })

    sliderGraph.labelValue = causationData.labelValue
    return sliderGraph
  }

  const handleBehaviourRouteState = (nextPath: string) => {
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: nextPath,
      sourceId,
      multivariateBehaviourDateRange: {
        showForecast: false,
        rangeType: 'absolute',
        relativeTime: '30-minutes',
        fromDate: moment(selectedBreach.eventTime).utc().subtract(30, 'minutes').valueOf(),
        toDate: moment(selectedBreach.eventTime).utc().add(30, 'minutes').valueOf(),
      },
    })
  }

  const selectedBreachGraphFromDate = moment(selectedBreach.eventTime)
    .subtract(30, 'minutes')
    .valueOf()
  const selectedBreachGraphToDate = moment(selectedBreach.eventTime).add(30, 'minutes').valueOf()

  return (
    <div className="flex h-[88vh] flex-col overflow-y-scroll">
      <div className="flex h-[10rem] flex-col px-5 py-3">
        <div className="pb-2 text-lg text-primary">Multivariate analysis</div>
        <CausalAnomalyCard severity={selectedBreach.severity} selectedBreach={selectedBreach} />
      </div>
      {!causationData.value.length &&
      !causationData.source.length &&
      !causationData.target.length &&
      !isFetchingCausalData ? (
        <div className="h-20 px-5 pt-3">
          <div className="flex h-20 flex-col justify-between bg-light_grey p-3">
            <span className="flex flex-row">Causal analysis</span>
            <span className="flex flex-row text-warm_grey">
              Causal source could not be detected for this anomaly
            </span>
          </div>
        </div>
      ) : (
        <div className="flex h-[34rem] w-full flex-col px-5 pt-3">
          <div>
            <SankeyDiagram
              title="Causal analysis"
              sources={causalData.source}
              targets={causalData.target}
              value={causalData.value}
              labelValue={causalData.labelValue}
              nodeColor={nodeColor}
              containerColor={COLORS.LIGHT_GREY}
              className="h-[32rem] w-full"
            />
          </div>

          <div className="flex h-20 w-full flex-row justify-between bg-light_grey">
            <div className="flex flex-row items-center gap-x-4 px-5">
              {sankeyDiagramLegend('Current anomaly', 'bg-blue-500')}
              {sankeyDiagramLegend('Causing anomaly', 'bg-orange-500')}
              {sankeyDiagramLegend('Intermediate anomaly', 'bg-yellow-200')}
              {sankeyDiagramLegend('Impacted anomaly', 'bg-red-600')}
            </div>
            <div className="flex flex-row items-center gap-x-3 px-6">
              <RangeSlider
                sliderStyle={{ width: 350 }}
                title={
                  <div className="flex flex-row items-center gap-x-2">
                    Contribution filter
                    <Tooltip
                      placement="top"
                      title="Use sliders to set minimum and maximum range of causal score percentage."
                    >
                      <InfoOutlined className="h-5 w-5 cursor-pointer" color={COLORS.WARM} />
                    </Tooltip>
                  </div>
                }
                minValue={Math.min(...causationData.value)}
                maxValue={Math.max(...causationData.value)}
                sliderValue={sliderValue}
                handleSliderChange={handleSliderChange}
              />

              {/* <span className="h-8 w-[1px] bg-white" />
            Feedback
            <ThumbsUpOutlined className="h-5 w-5 hover:scale-150 hover:cursor-pointer" />
            <ThumbsUpOutlined
              transform="rotate(180)"
              className="h-5 w-5 hover:scale-150 hover:cursor-pointer"
            /> */}
            </div>
          </div>
        </div>
      )}
      <div className="flex-1 px-5 pt-8">
        <div className={`flex w-full flex-col bg-light_grey px-3 `}>
          <div className="h-16 w-full py-3 text-lg">Correlated anomalies</div>
          <div className="grid grid-flow-row grid-cols-2 items-center gap-x-3 gap-y-2">
            <MultivariateGraphCard
              key={`${selectedBreach.queryId}_selected`}
              fromDate={selectedBreachGraphFromDate}
              toDate={selectedBreachGraphToDate}
              sourceId={sourceId}
              queryId={selectedBreach.queryId}
              applicationId={selectedBreach.applicationId}
              applicationName={selectedBreach.applicationName as string}
              metric={selectedBreach.metricName}
              onBehaviourClick={handleBehaviourRouteState}
              severity={selectedBreach.severity || 0}
            />
            {breaches.map((q: CorelationOrCausationType, index) => {
              const MultivariateGraphFromDate = moment(q.eventTime)
                .subtract(30, 'minutes')
                .valueOf()

              const MultivariateGraphToDate = moment(q.eventTime).add(30, 'minutes').valueOf()

              return (
                <MultivariateGraphCard
                  key={`${q.queryId}_${index}`}
                  fromDate={MultivariateGraphFromDate}
                  toDate={MultivariateGraphToDate}
                  sourceId={sourceId}
                  queryId={q.queryId as string}
                  applicationId={q.applicationId as string}
                  applicationName={q.applicationName as string}
                  metric={q.metricName as string}
                  onBehaviourClick={handleBehaviourRouteState}
                  severity={q.severity || 0}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
