import { COLORS } from './colors'

const MuiButton = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      fontSize: '0.7em',
      '&.Mui-disabled': {
        color: COLORS.WHITE,
        background: COLORS.WARM_GREY,
      },
    },
  },
}

export default MuiButton
