import Plot from 'react-plotly.js'
import { PieChartProps } from './types'
import Card from '../../card'
import { COLORS } from '../../../../themes/default/colors'

export const PIE_CHART_TEST_ID = 'PIE_CHART_TEST_ID'

export default function PieChart({
  testId = PIE_CHART_TEST_ID,
  data,
  labels,
  colors,
  fontColor,
  onHover,
  onClick,
  hoverData,
  heading,
  subheading,
  showViewAllButton,
  onViewAllClick,
  clickData,
  numSlices = 5,
}: PieChartProps) {
  const topNlabels = labels.slice(0, numSlices)
  // Truncate labels to 10 characters
  const truncatedLabels = topNlabels.map((label) =>
    label.length > 10 ? label.slice(0, 10) + '...' : label
  )
  const customData = clickData ? clickData : undefined
  // Handle click event on a slice
  const handleSliceClick = (event: any) => {
    if (onClick) {
      onClick(event.points[0]) // Passing clickData of the clicked slice
    }
  }

  return (
    <Card className="relative flex !w-1/3 flex-col rounded-md !p-4">
      <div className="mb-2 flex items-center justify-between">
        {/* Render the heading and subheading if provided */}
        <div>
          {heading && <h2 className="text-white">{heading}</h2>}
          {subheading && <p className="text-gray-600">{subheading}</p>}
        </div>
        {/* Conditionally render the "View All" button */}
        {showViewAllButton && (
          <button className="px-4 py-2 text-primary" onClick={onViewAllClick}>
            View All
          </button>
        )}
      </div>
      <Plot
        data-testid={testId}
        className="h-80 w-full"
        onHover={onHover}
        onClick={handleSliceClick}
        data={[
          {
            values: data,
            labels: truncatedLabels, // Use truncated labels
            type: 'pie',
            marker: { colors },
            textinfo: 'label+value',
            hoverinfo: 'text',
            text: hoverData, // Use hover data
            textposition: 'outside',
            hoverlabel: { align: 'left' },
            customdata: customData as any,
            automargin: true,
          },
        ]}
        layout={{
          margin: { t: 20, b: 20 },
          paper_bgcolor: COLORS.TRANSPARENT,
          plot_bgcolor: COLORS.TRANSPARENT,
          showlegend: false,
          font: { size: 12, color: fontColor },
          hoverlabel: {
            bgcolor: COLORS.HOVER_GRAY,
            font: { color: COLORS.WHITE },
            bordercolor: COLORS.HOVER_GRAY,
          },
        }}
        config={{
          displaylogo: false,
          doubleClick: 'reset+autosize',
          watermark: false,
          modeBarButtons: [[]],
        }}
        useResizeHandler
      />
    </Card>
  )
}
