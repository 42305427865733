import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const BELL_OUTLINE_TEST_ID = 'BELL_OUTLINE_TEST_ID'

export const BellOutlined = ({
  testId = BELL_OUTLINE_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  pathClasses,
  onClick,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        className={pathClasses}
        fill={color}
        d="M16 28a2 2 0 0 1-2-2h-2a4 4 0 0 0 8 0h-2a2 2 0 0 1-2 2ZM26 20v-8a10 10 0 0 0-20 0v8a2 2 0 0 1-2 2v2h24v-2a2 2 0 0 1-2-2ZM7.46 22A4 4 0 0 0 8 20v-8a8 8 0 0 1 16 0v8a4 4 0 0 0 .54 2Z"
      />
    </svg>
  </div>
)
