import { COLORS } from './colors'

const MuiPaper = {
  styleOverrides: {
    root: {
      height: '30px',
      width: '30px',
      backgroundColor: 'none',
      color: COLORS.WHITE,
      '.weekDayLabel': {
        color: 'red',
      },
      '&.Mui-disabled': {
        color: COLORS.WARM_GREY,
      },
    },
  },
}

export default MuiPaper
