import React, { useEffect, useState } from 'react'
import AlertsTable from './alerts-table'
import { SearchInput, Button, Toast, Autocomplete } from '../../../components'
import {
  ModalTypes,
  dashboardStateSelector,
  setCurrentModal,
  useGetSourcesByTenantIdQuery,
} from '../../../store/dashboard'
import { COLORS } from '../../../../themes/default/colors'
import { motion } from 'framer-motion'
import { POLLING_INTERVAL, pageTransitionAnimation } from '../../../consts'

import NoAlertsMessage from './no-alerts-message'
import SetAlertModal from './set-alert-modal'
import { resetState } from '../../../store/source'
import {
  changeAlertInputValues,
  resetState as resetAddAlertState,
  setAlertValues,
} from '../../../store/alert-rules/slice'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import DeleteAlertModal from './delete-alert-modal'
import {
  useAlertRuleStatusMutation,
  useDeleteAlertRuleMutation,
  useGetAllAlertsQuery,
} from '../../../store/alert-rule-dashboard/services'
import { useAddAlertMutation, useEditAlertMutation } from '../../../store/alert-rules/services'
import { addAlertSelector } from '../../../store/alert-rules/selectors'
import { OptionType } from '../../../types'
import { useGetApplicationsQuery } from '../../../store/anomaly-detection'
import { appDefault, handleClick, sourceDefault } from '../main-dashboard/consts'
import { Close } from '../../../components/icons'
import { ToastMessage, toastMessages } from './consts'
import AlertsMainLoader from './alerts-loaders/main-loader'
import { AlertRuleType } from '../../../store/alert-rule-dashboard/types'
import AlertsTableLoader from './alerts-loaders/table-loader'
import { alertAppSelector } from '../../../store/alert-rule-dashboard/selectors'
import { changeApplicationsState } from '../../../store/alert-rule-dashboard/slice'

export default function AlertRules() {
  const dispatch = useAppDispatch()
  const [showToast, setShowToast] = useState('')
  const [searchAlert, setSearchAlert] = useState('')
  const { currentModal } = useAppSelector(dashboardStateSelector)
  const [editRuleId, setEditRuleId] = useState('')
  const [deleteRuleId, setDeleteRuleId] = useState<string[]>([])
  const addAlertValues = useAppSelector(addAlertSelector)
  const applicationOptions = useAppSelector(alertAppSelector)
  const {
    appId,
    ruleName,
    message,
    queryIds,
    channels,
    anomalyPeriod,
    severityOption,
    anomalyCount,
    silencePeriod,
    values,
    isEdit,
    active,
  } = addAlertValues
  const [selectedSource, setSelectedSource] = useState<OptionType>(sourceDefault)
  const [selectedApplication, setSelectedApplication] = useState<OptionType>(appDefault)
  const [alertName, setAlertName] = useState<string[]>([])
  const [alertErrorMessage, setalertErrorMessage] = useState('')

  //RTK query for alert rules
  const {
    data: AlertRules = [],
    isLoading: isALertRulesLoading,
    refetch: getAllAlerts,
  } = useGetAllAlertsQuery({}, { pollingInterval: POLLING_INTERVAL })
  //RTK query to add alert
  const [addAlert, { isLoading: isSubmitting }] = useAddAlertMutation()

  //RTK query to delete alert rule
  const [deleteAlertRule] = useDeleteAlertRuleMutation()

  //RTK query for alert rule status
  const [alertStatus] = useAlertRuleStatusMutation()

  //RTK query to edit alert
  const [editAlert] = useEditAlertMutation()

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRows, setSelectedRows] = useState<AlertRuleType[]>([])
  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const [tableLoading, setTableLoading] = useState(false)

  const multipleDelete = (selectedRows: AlertRuleType[]) => {
    const keys = selectedRows?.map((value: AlertRuleType, index: number) => value.id!)
    const names = selectedRows?.map((value: AlertRuleType, index: number) => value.ruleName)
    handleOnDeleteClick(keys, names)
  }

  const { data: sources = [] } = useGetSourcesByTenantIdQuery(
    {},
    { pollingInterval: POLLING_INTERVAL }
  )

  const sourceOptions = [
    { label: 'All Sources', value: 'All' },
    ...sources.map((source) => ({ label: source.sourceName, value: source.id })),
  ]

  const { data: applications = [], isLoading: isAppLoading } = useGetApplicationsQuery(
    { sourceId: selectedSource.value.toString() },
    {
      skip: selectedSource.value === 'All',
    }
  )

  useEffect(() => {
    if (selectedSource.value !== 'All' && !isAppLoading) {
      const applicationOptions = [
        { label: 'All Applications', value: 'All' },
        ...applications.map((application) => ({
          label: application.appName,
          value: application.id,
        })),
      ]
      dispatch(changeApplicationsState(applicationOptions))
    }
  }, [selectedSource.value, isAppLoading])

  const handleOnDeleteClick = (alertId: string[], alertName: string[]) => {
    setAlertName(alertName)
    setDeleteRuleId(alertId)
    if (alertId?.length) {
      dispatch(setCurrentModal(ModalTypes.DELETE_ALERT))
    }
  }

  function resetDeleteState() {
    setSelectedRowKeys([])
    setSelectedRows([])
  }
  const handleOnDeleteRule = () => {
    setTableLoading(true)
    closeModal()
    deleteAlertRule(deleteRuleId).then((res: any) => {
      if (res.data && res?.data?.status === 'SUCCESS') {
        resetDeleteState()
        getAllAlerts().then(() => {
          setShowToast('deleteAlertSuccess')
          setTableLoading(false)
        })
      } else {
        setTableLoading(false)
      }
    })
  }

  const onEditAlertClick = (alertId: string) => {
    resetDeleteState()
    const selectAlert = AlertRules.find((a) => a.id == alertId)
    setEditRuleId(alertId)
    dispatch(setAlertValues(selectAlert)) // to update alertValues
    dispatch(changeAlertInputValues({ type: 'isEdit', value: true }))
    dispatch(setCurrentModal(ModalTypes.SET_ALERT))
  }

  const onSetAlertClick = () => {
    resetDeleteState()
    dispatch(setCurrentModal(ModalTypes.SET_ALERT))
  }

  const closeModal = () => {
    dispatch(resetState())
    dispatch(setCurrentModal(null))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchAlert(event.target.value)

  const handleOnToggle = (ruleId: string, status: boolean, alertName: string) => {
    setTableLoading(true)
    alertStatus({ ruleId, status: !status })
      .then((res: any) => {
        if (res.data.status === 'SUCCESS') {
          getAllAlerts().then(() => {
            setShowToast(!status ? 'alertEnableSuccess' : 'alertDisableSuccess')
            setTableLoading(false)
          })
        } else {
          setShowToast(
            `An error occurred while attempting to activate the alert for the ${alertName}`
          )
          setTableLoading(false)
        }
      })
      .finally(() => {
        closeModal()
      })
  }

  function onSourceChange(val: OptionType) {
    setSelectedApplication(appDefault)
    setSelectedSource(val)
    resetDeleteState()
  }

  function onApplicationChange(val: OptionType) {
    setSelectedApplication(val)
    resetDeleteState()
  }

  function filterAlerts() {
    let filteredAlerts = AlertRules.filter((alert) =>
      alert.ruleName.toLowerCase().includes(searchAlert.toLowerCase())
    )
    if (selectedSource.value !== 'All') {
      filteredAlerts = filteredAlerts.filter((alert) => alert.sourceId === selectedSource.value)
      if (selectedApplication.value !== 'All') {
        filteredAlerts = filteredAlerts.filter(
          (alert) => alert.applicationId === selectedApplication.value
        )
      }
    }
    return filteredAlerts
  }

  const onAddAlertClick = async () => {
    resetDeleteState()
    try {
      if (!isEdit) {
        addAlert({
          appId,
          ruleName,
          message,
          queryIds,
          type: 'email',
          period: Number(anomalyPeriod),
          threshold: Number(anomalyCount),
          channels,
          severityOption,
          silencePeriod: Number(silencePeriod),
          values,
        }).then((res: any) => {
          if (res?.data?.status === 'SUCCESS') {
            dispatch(setCurrentModal(null))
            dispatch(resetAddAlertState())
            getAllAlerts().then(() => setShowToast('newAlertSuccess'))
          } else {
            setShowToast('error')
            setalertErrorMessage(res?.error?.data?.message)
          }
        })
      } else {
        editAlert({
          id: editRuleId,
          ruleName,
          message,
          queryIds: queryIds,
          channels,
          period: Number(anomalyPeriod),
          threshold: Number(anomalyCount),
          severityOption,
          silencePeriod: Number(silencePeriod),
          values,
          active,
        }).then((res: any) => {
          if (res?.data?.status === 'SUCCESS') {
            dispatch(setCurrentModal(null))
            dispatch(resetAddAlertState())
            getAllAlerts().then(() => setShowToast('editAlertSuccess'))
          } else {
            setShowToast('error')
            setalertErrorMessage(res?.error?.data?.message)
          }
        })
      }
    } catch (e) {}
  }

  function hideToast() {
    setShowToast('')
  }
  const data = filterAlerts()

  useEffect(() => {
    getAllAlerts()
  }, [])
  return (
    <motion.div className="flex w-full flex-col" {...pageTransitionAnimation}>
      <Toast
        vertical="bottom"
        horizontal="center"
        open={showToast.length > 0}
        message={
          showToast.length > 0 && showToast !== 'error' ? (
            <ToastMessage state={toastMessages[showToast]} />
          ) : showToast === 'error' ? (
            alertErrorMessage
          ) : (
            ''
          )
        }
        onClose={hideToast}
        action={
          <Close className="w-8 p-2 pt-3 " onClick={() => setShowToast('')} color={COLORS.BLACK} />
        }
        autoHideDuration={4000}
      />
      {!isALertRulesLoading ? (
        <div>
          <div className="border-1 flex w-full flex-row items-center justify-between gap-3 px-5 pt-3">
            <div className="flex flex-row gap-4">
              <div className="flex items-center text-base font-semibold text-white">
                Alerts
                <div className="ml-1 flex h-3 w-7 items-center justify-center rounded-sm bg-beta font-RakutenSansBold text-[10px] text-black">
                  BETA
                </div>
              </div>

              <div className="flex items-center">
                <SearchInput
                  inputClassName="h-8 w-64"
                  inputPlaceholder="Search by Alert name"
                  value={searchAlert}
                  onChange={handleInputChange}
                  required
                  iconColor={COLORS.PRIMARY}
                />
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <Autocomplete
                iconColor={COLORS.PRIMARY}
                className="border-1 !w-40 border-primary bg-dark2 "
                inputClasses="text-xs border-primary !text-primary"
                value={sourceOptions.find((a: OptionType) => selectedSource.value === a.value)}
                placeholder="Source Name"
                options={sourceOptions}
                onChange={onSourceChange}
                optionClasses={'!w-auto !right-0'}
                onFocus={handleClick}
                maxChars={8}
                noDataWidth={'!w-40'}
              />
              <div className="w-40 border-primary">
                <Autocomplete
                  iconColor={selectedSource.value === 'All' ? COLORS.WARMGREY28 : COLORS.PRIMARY}
                  className="border-1 !w-30 border-primary bg-dark2"
                  inputClasses={`text-xs ${
                    selectedSource.value === 'All'
                      ? '!text-warm_grey border-warm_grey !focus:border-none'
                      : '!text-primary border-primary'
                  }`}
                  value={
                    selectedSource.value === 'All'
                      ? applicationOptions[0]
                      : applicationOptions.find(
                          (a: OptionType) => selectedApplication.value === a.value
                        )
                  }
                  placeholder="Application"
                  options={applicationOptions}
                  onChange={onApplicationChange}
                  disabled={selectedSource.value === 'All'}
                  optionClasses={'!w-auto !right-0'}
                  onFocus={handleClick}
                  maxChars={8}
                  noDataWidth={'!w-40'}
                />
              </div>
              <div className="flex flex-row gap-2">
                {selectedRowKeys.length > 0 && (
                  <Button
                    className="w-25 h-8 !bg-red-500"
                    disabled={false}
                    onClick={() => {
                      multipleDelete(selectedRows)
                    }}
                    buttonTextClass="text-white text-sm font-RakutenSansBold "
                  >
                    Delete
                  </Button>
                )}
                <Button
                  className="w-25 h-8"
                  variant="contained"
                  disabled={false}
                  onClick={() => onSetAlertClick()}
                  buttonTextClass="!text-black text-sm font-RakutenSansBold "
                >
                  Set Alert
                </Button>
              </div>
            </div>
          </div>
          <SetAlertModal
            show={currentModal === ModalTypes.SET_ALERT}
            onClose={closeModal}
            onSave={onAddAlertClick}
            addAlertValues={addAlertValues}
            isSubmittingForm={isSubmitting}
          />
          <DeleteAlertModal
            show={currentModal === ModalTypes.DELETE_ALERT && deleteRuleId.length > 0}
            onClose={closeModal}
            onDelete={handleOnDeleteRule}
            alertName={alertName}
          />

          <div className="px-5 pt-5">
            {!isALertRulesLoading &&
            (AlertRules.length === 0 || (searchAlert.length === 0 && data.length === 0)) ? (
              <NoAlertsMessage onClick={onSetAlertClick} />
            ) : tableLoading ? (
              <AlertsTableLoader />
            ) : (
              <AlertsTable
                alertRows={AlertRules && data}
                handleToggle={handleOnToggle}
                handleDelete={handleOnDeleteClick}
                handleEdit={onEditAlertClick}
                onSetAlert={onSetAlertClick}
                selectedRowKeys={selectedRowKeys}
                onSelect={onSelectChange}
                emptyText={
                  searchAlert ? (
                    <div className="flex h-[62vh] flex-col justify-center align-middle">
                      <p className="text-base text-white">No results found for {searchAlert}</p>
                      <p className="text-warm_grey">Check the spelling or try to remove filters</p>
                    </div>
                  ) : (
                    <NoAlertsMessage onClick={onSetAlertClick} />
                  )
                }
              />
            )}
          </div>
        </div>
      ) : (
        <AlertsMainLoader />
      )}
    </motion.div>
  )
}
