import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const CHEVRON_RIGHT_TEST_ID = 'CHEVRON_RIGHT_TEST_ID'

export const ChevronRight = ({
  testId = CHEVRON_RIGHT_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  pathClasses,
  transform,
  onClick,
}: IconProps) => {
  return (
    <div data-testid={testId} className={className} onClick={onClick}>
      <svg
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        transform={transform}
      >
        <path
          className={pathClasses}
          fill={color}
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  )
}
