import { ALERT_RULE_STATUS, GET_ALL_ALERTS } from '../../consts'
import { splitApi } from '../api'
import {
  AlertRuleType,
  AlertStatusRequestType,
  AlertStatusResponseType,
  DeleteAlertResponseType,
  GetAlertResponseType,
} from './types'

export const alertRulesApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getAllAlerts: build.query<AlertRuleType[], {}>({
      query: () => `${GET_ALL_ALERTS}`,
      transformResponse: (rawResult: GetAlertResponseType): AlertRuleType[] => {
        return rawResult.data
      },
    }),
    deleteAlertRule: build.mutation<DeleteAlertResponseType, string[]>({
      query: (alertIds: string[]) => ({
        url: `${GET_ALL_ALERTS}/multiple`,
        method: 'DELETE',
        body: alertIds,
      }),
      transformResponse: (result: DeleteAlertResponseType) => {
        return result
      },
    }),
    alertRuleStatus: build.mutation<AlertStatusResponseType, AlertStatusRequestType>({
      query: ({ ruleId, status }) => ({
        url: `${ALERT_RULE_STATUS}`,
        method: 'POST',
        body: { ruleId, status },
      }),
      transformResponse: (result: AlertStatusResponseType) => {
        return result
      },
    }),
  }),
})

export const { useGetAllAlertsQuery, useDeleteAlertRuleMutation, useAlertRuleStatusMutation } =
  alertRulesApi
