interface BreachSectionLoaderProps {
  numberOfItems: number
}

export const BREACH_SECTION_LOADER_TEST_ID = 'BREACH_SECTION_LOADER_TEST_ID'

export default function BreachSectionLoader({ numberOfItems }: BreachSectionLoaderProps) {
  const renderLoaderItems = () => {
    return Array.from({ length: numberOfItems }, (_, index) => (
      <div className="flex flex-col" key={index}>
        <div className="h-10 w-full bg-dark94" />
        <div className="flex h-52 w-full flex-col justify-around bg-dark p-4">
          <div className="h-6 w-40 rounded-md bg-dark94" />
          <div className="h-6 w-full rounded-md bg-dark94" />
          <div className="h-6 w-full rounded-md bg-dark94" />
        </div>
      </div>
    ))
  }

  return (
    <div
      className="flex h-full w-full animate-pulse flex-col gap-5"
      data-testid={BREACH_SECTION_LOADER_TEST_ID}
    >
      {renderLoaderItems()}
    </div>
  )
}
