import { AnyAction, Reducer, combineReducers, createAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { authSlice } from './auth'
import { addApplicationSlice } from './add-application'
import { dashboardSlice } from './dashboard'
import { alertSlice } from './alert'
import { sourceSlice } from './source'
import applicationSlice from './application/slice'
import { RootState } from '.'
import { webhookIntegrationSlice } from './webhook-integration'
import { anomaliesSlice } from './anomalies'
import { sourceTabSlice } from './source-tab'
import { multivariateAnalysisSlice } from './multivariate-analysis'
import { splitApi } from './api'
import anomalyDetectionSlice from './anomaly-detection/slice'
import { alertRuleSlice } from './alert-rules/slice'
import { alertRulesDashboardSlice } from './alert-rule-dashboard/slice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [alertSlice.name], // This will not be persisted
}

const combinedReducer = combineReducers({
  [anomalyDetectionSlice.name]: anomalyDetectionSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [addApplicationSlice.name]: addApplicationSlice.reducer,
  [dashboardSlice.name]: dashboardSlice.reducer,
  [alertSlice.name]: alertSlice.reducer,
  [sourceSlice.name]: sourceSlice.reducer,
  [applicationSlice.name]: applicationSlice.reducer,
  [webhookIntegrationSlice.name]: webhookIntegrationSlice.reducer,
  [anomaliesSlice.name]: anomaliesSlice.reducer,
  [sourceTabSlice.name]: sourceTabSlice.reducer,
  [multivariateAnalysisSlice.name]: multivariateAnalysisSlice.reducer,
  [splitApi.reducerPath]: splitApi.reducer,
  [alertRuleSlice.name]: alertRuleSlice.reducer,
  [alertRulesDashboardSlice.name]: alertRulesDashboardSlice.reducer,
})

export const resetAllState = createAction('root/resetAll')

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === resetAllState.type) {
    state = undefined
  }
  return combinedReducer(state, action)
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)
