import { IconProps } from './types'

export const ARROW_UP_TEST_ID = 'ARROW_UP_TEST_ID'

export const ArrowUp = ({
  testId = ARROW_UP_TEST_ID,
  className,
  color,
  strokeWidth = '0',
  strokeColor = color,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill={color}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    >
      <defs>
        <style></style>
      </defs>
      <polygon points="4 14 5.41 15.41 15 5.83 15 30 17 30 17 5.83 26.59 15.41 28 14 16 2 4 14" />
    </svg>
  </div>
)
