import moment from 'moment'
import { PredictionsWithOccurence } from '../../../../store/anomalies'
import { AnomalyChart, Button } from '../../../../components'
import {
  ANOMALY_GRAPH_LABELS,
  BREACH_COLORS,
  MetricTypeTitles,
} from '../../../../store/anomaly-detection'
import { COLORS } from '../../../../../themes/default/colors'
import { MetricTypesType } from '../../../../store/application'
import { NavLink } from 'react-router-dom'
import { appPaths } from '../../../../routes'
import { LinkIcon } from '../../../../components/icons'
import { handleNullValues } from '../../../../utils'

interface AnomalyGraphSectionProps {
  predictions: PredictionsWithOccurence
  onAcknowledge?: () => void
  isPredictionsLoading: boolean
  isAnomalyListLoading: boolean
  isAcknowledging?: boolean
  showHeader?: boolean
  containerColor?: string
  graphSectionClasses?: string
  grpahHeight?: number
  graphMarginBottom?: number
  graphPlotColor?: string
  queryId: string
  eventTime: string
  metricName?: string
  acknowledge?: boolean
  applicationName?: string
  metricType: string
  sourceId?: string
  applicationId?: string
  onApplicationLinkClick?: (path: string) => void
}

export default function AnomalyGraphSection({
  predictions,
  onAcknowledge,
  isAnomalyListLoading,
  isPredictionsLoading,
  isAcknowledging,
  showHeader = true,
  containerColor = COLORS.DARK94,
  graphSectionClasses,
  grpahHeight,
  graphMarginBottom,
  graphPlotColor = COLORS.DARK94,
  queryId,
  eventTime,
  metricName,
  acknowledge,
  applicationName,
  metricType,
  sourceId = '',
  applicationId = '',
  onApplicationLinkClick,
}: AnomalyGraphSectionProps) {
  const {
    eventTimes,
    upperThresholds,
    lowerThresholds,
    groundTruths,
    forecastValues,
    minorAnomalies,
    majorAnomalies,
    criticalAnomalies,
    firstTimetamp,
    lastTimetamp,
    peakValue,
  } = predictions

  const updatedUpperThresholds = handleNullValues(upperThresholds)
  const updatedLowerThresholds = handleNullValues(lowerThresholds)

  return (
    <div
      className={`flex w-full flex-col rounded-md ${graphSectionClasses}`}
      key={`${queryId}-${eventTime}`}
    >
      {showHeader && (
        <div className="flex w-full">
          <div className="flex w-4/6 flex-col p-4">
            {isAnomalyListLoading && (
              <>
                <div className="flex h-4 animate-pulse rounded-md bg-dark94"></div>
                <div className="mt-1 flex h-4 w-3/6 animate-pulse rounded-md bg-dark94"></div>
              </>
            )}
            {!isAnomalyListLoading && (
              <>
                <div className="flex text-base">
                  {metricName}{' '}
                  <NavLink
                    to={appPaths.multivariateBehaviourPage({ sourceId, applicationId })}
                    onClick={() =>
                      onApplicationLinkClick &&
                      onApplicationLinkClick(
                        appPaths.multivariateBehaviourPage({ sourceId, applicationId })
                      )
                    }
                  >
                    <LinkIcon className="ml-2 h-6 w-6 cursor-pointer" />
                  </NavLink>
                </div>
                <div className="font-RakutenSansLight text-xs">
                  {moment(eventTime).format('ddd, Do MMMM YYYY [at] HH:mm')}{' '}
                  <span className="pl-2">|</span> Applications: {applicationName}
                </div>
              </>
            )}
          </div>
          <div className="flex w-2/6 items-center justify-end px-4">
            <div className="flex h-16 w-28 items-center justify-center">
              {!isPredictionsLoading && !isAnomalyListLoading && (
                <Button
                  disabled={acknowledge === true || isAcknowledging}
                  styles={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onAcknowledge}
                >
                  Acknowledge
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {(isPredictionsLoading || isAnomalyListLoading) && (
        <div className="flex h-[250px] w-full animate-pulse rounded-md bg-dark94"></div>
      )}
      {!isPredictionsLoading && !isAnomalyListLoading && (
        <div className="flex w-full rounded-md">
          <AnomalyChart
            marginBottom={graphMarginBottom}
            height={grpahHeight}
            x={eventTimes}
            y={[
              updatedUpperThresholds,
              updatedLowerThresholds,
              groundTruths,
              forecastValues,
              minorAnomalies,
              majorAnomalies,
              criticalAnomalies,
            ]}
            colors={['#0089FF66', '#0089FF66', '#F59600', '#0089FF', ...BREACH_COLORS]}
            plotColor={graphPlotColor}
            containerColor={containerColor}
            fontColor={COLORS.WHITE}
            yTitle={MetricTypeTitles[metricType as MetricTypesType]}
            xTitle={'Time(mins)'}
            yTexts={ANOMALY_GRAPH_LABELS}
            colouredArea={{ x0: firstTimetamp, y0: 0, x1: lastTimetamp, y1: peakValue }}
          />
        </div>
      )}
    </div>
  )
}
