import { createSlice } from '@reduxjs/toolkit'
import { ApplicationStateType } from './types'
import { getQueries, validateQueryOfJobMetric } from './actions'

export const reducerPath = 'application'

const initialState: ApplicationStateType = {
  sourceId: '',
  sourceName: '',
  sourceType: '',
  sourceUrl: '',
  applicationId: '',
  applicationName: '',
  metricType: '',
  jobs: [],
  metrics: [],
  selectedJob: { label: '', value: '' },
  selectedMetric: { label: '', value: '' },
  currentQuery: '',
  queries: [],
  includeHistory: false,
  historyDays: 0,
  isFetching: false,
  isLoading: false,
}

const applicationSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeApplicationInputValues: (prevState, { payload }) => {
      let newState = {}

      if (
        (payload.type === 'selectedJob' || payload.type === 'selectedMetric') &&
        payload.value == null
      ) {
        newState = {
          [payload.type]: { label: '', value: '' },
        }
      }

      return {
        ...prevState,
        [payload.type]: payload.value,
        ...newState,
      }
    },
    changeCurrentQuery: (prevState, { payload }) => {
      return {
        ...prevState,
        currentQuery: payload,
      }
    },
    deleteQuery: (prevState, { payload }) => {
      return {
        ...prevState,
        queries: prevState.queries.map((q) => ({
          ...q,
          isDeleted: q.id === payload || q.requestId === payload ? true : q.isDeleted,
        })),
      }
    },
    resetApplicationState: (prevState) => {
      return {
        ...initialState,
        sourceId: prevState.sourceId,
        sourceUrl: prevState.sourceUrl,
        sourceName: prevState.sourceName,
        sourceType: prevState.sourceType,
      }
    },
    resetAppQueries: (prevState) => {
      return {
        ...prevState,
        metricType: '',
        jobs: [],
        metrics: [],
        selectedJob: { label: '', value: '' },
        selectedMetric: { label: '', value: '' },
        currentQuery: '',
      }
    },
    setApplicationSource: (prevState, { payload }) => {
      return {
        ...prevState,
        sourceId: payload.sourceId,
        sourceName: payload.sourceName,
        sourceUrl: payload.sourceUrl,
        sourceType: payload.sourceType,
      }
    },

    setEditApplication: (prevState, { payload }) => {
      return {
        ...prevState,
        applicationId: payload.applicationId,
        applicationName: payload.applicationName,
        includeHistory: payload.includeHistory,
        historyDays: payload.historyDays,
      }
    },
    changeUnitOfQuery: (prevState, { payload }) => {
      const queries = [...prevState.queries].map((q) =>
        q.id === payload.id || q.requestId === payload.id
          ? {
              ...q,
              unit: payload.unit,
            }
          : q
      )
      return {
        ...prevState,
        queries,
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(validateQueryOfJobMetric.pending, (state, { meta }) => {
        state.queries = [
          ...state.queries,
          {
            requestId: meta.requestId,
            queryString: state.currentQuery,
            metricType: state.metricType,
            unit: '',
            status: 'loading',
            isDeleted: false,
            hasUnit: false,
          },
        ]
        state.currentQuery = ''
      })
      .addCase(validateQueryOfJobMetric.fulfilled, (state, { payload, meta }) => {
        state.queries = state.queries.map((q) =>
          q.requestId === meta.requestId
            ? {
                ...q,
                status: payload.valid ? 'success' : 'no_data',
                unit:
                  payload.unit !== null && payload.unit !== '' ? payload.unit.toLowerCase() : '',
                hasUnit: payload.unit !== null && payload.unit !== '',
              }
            : q
        )
      })
      .addCase(validateQueryOfJobMetric.rejected, (state, { meta }) => {
        state.queries = state.queries.map((q) =>
          q.requestId === meta.requestId
            ? {
                ...q,
                status: 'error',
              }
            : q
        )
      })
      .addCase(getQueries.pending, (state) => {
        state.isFetching = true
      })
      .addCase(getQueries.fulfilled, (state, { payload }) => {
        state.isFetching = false
        state.queries = payload
      })
      .addCase(getQueries.rejected, (state) => {
        state.isFetching = false
      })
  },
})

export const {
  changeApplicationInputValues,
  changeCurrentQuery,
  deleteQuery,
  setApplicationSource,
  setEditApplication,
  resetApplicationState,
  changeUnitOfQuery,
  resetAppQueries,
} = applicationSlice.actions

export default applicationSlice
