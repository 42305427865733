import { BasicCard } from '../components'

export default function PrivacyNotice() {
  return (
    <BasicCard
      contentClasses="overflow-y-scroll text-warm text-justify normal-case select-none"
      containerClasses="flex relative flex-col w-6/12 py-5 mobile:w-9/12 tablet_p:w-9/12 tablet_p:h-[47rem] tablet_l:w-8/12 tablet_l:h-[40rem] laptop:w-6/12 laptop:h-[46rem] desktop:w-5/12 desktop:h-[47rem]"
    >
      <p>
        <strong>P</strong>
        <strong>RIVACY </strong>
        <strong>N</strong>
        <strong>OTICE</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <em>Last updated: 16</em>
        <em>th </em>
        <em>August 2022</em>
      </p>
      <p>
        <em>&nbsp;</em>
      </p>
      <p>
        Rakuten India Enterprise Private Limited (hereafter &ldquo;<strong>Rakuten India</strong>
        &rdquo;, &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo; and/or
        &ldquo;<strong>us</strong>&rdquo;). This privacy notice (&ldquo;<strong>Notice</strong>
        &rdquo;) describes how we collect, use, and disclose Personal Information (as defined below)
        about you when you use our website (&ldquo;<strong>Website</strong>&rdquo;) or cloud
        analytics services relating to the Rakuten SixthSense observability platform and test
        acceleration platform (&ldquo;<strong>Services</strong>&rdquo;).
      </p>
      <p>&nbsp;</p>
      <p>
        We may change this Notice from time to time. If we make changes, we will notify you by
        revising the date at the top of this statement and, in cases where there are material
        changes we will provide you with additional notice (such as adding a statement to our
        website homepage or sending you a notification) prior to the changes becoming effective. We
        encourage you to review the Notice whenever you access the Website, Services or otherwise
        interact with us to stay informed about our information practices and the choices available
        to you.
      </p>
      <p>&nbsp;</p>
      <p>
        Rakuten India and its group companies are part of the Rakuten Group, which includes the
        affiliates and subsidiaries of our parent company Rakuten Group, Inc., based in Tokyo,
        Japan. More information about Rakuten is available at{' '}
        <a href="http://global.rakuten.com/corp/about/company">
          http://global.rakuten.com/corp/about/company.
        </a>
      </p>
      <p>&nbsp;</p>
      <h2>NOTE: You must be at least 18 years old to access or use the Services.</h2>
      <p>
        <strong>
          <em>&nbsp;</em>
        </strong>
      </p>
      <p>
        <strong>
          <em>&nbsp;</em>
        </strong>
      </p>
      <p>
        <strong>
          <em>&nbsp;</em>
        </strong>
      </p>
      <p>
        <strong>Sections of this Notice</strong>
      </p>
      <p>
        <a href="#_TOC_250008">
          What This Notice
          Covers.......................................................................................................
          1
        </a>
      </p>
      <p>
        <a href="#_TOC_250007">
          The Information We
          Collect...................................................................................................
          1
        </a>
      </p>
      <p>
        <a href="#_TOC_250006">
          Use, Legal Basis of Use and Retention of Your
          Information........................................................ 2
        </a>
      </p>
      <p>
        <a href="#_TOC_250005">
          Disclosure of Information about
          You.......................................................................................
          3
        </a>
      </p>
      <p>
        <a href="#_TOC_250004">
          Your Rights and
          Choices........................................................................................................
          4
        </a>
      </p>
      <p>
        <a href="#_TOC_250003">
          Updating Your
          Information....................................................................................................
          5
        </a>
      </p>
      <p>
        <a href="#_TOC_250002">
          Security and International
          Transfers.........................................................................................
          5
        </a>
      </p>
      <p>
        <a href="#_TOC_250001">
          No Collection of Information from
          Children............................................................................. 6
        </a>
      </p>
      <p>
        <a href="#_TOC_250000">
          Contact
          Us...........................................................................................................................
          6
        </a>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250008"></a>What This Notice Covers
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        This Notice applies to your use of Website and Services operated by us. Please be aware that
        for certain other services rendered by other Rakuten Group companies, we or the other
        Rakuten Group companies may provide you with separate privacy notices applicable to those
        services.
      </p>
      <p>&nbsp;</p>
      <p>
        Please only use the Services after you have carefully read and understood this Notice, our
        Website and Services &lsquo;terms of use&rsquo; and other applicable terms. In accessing the
        Website or using the Services, you represent that you understand the contents of this Notice
        describing our collection, use, transfer and retention of your information herein.
      </p>
      <p>&nbsp;</p>
      <p>
        If you have any questions or inquiries regarding this Notice, please contact us using the
        information provided in the &lsquo;Contact Us&rsquo; section below.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250007"></a>The Information We Collect
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        For the purposes stated in the Section titled &ldquo;Use, Legal Basis of Use and Retention
        of Your Information&rdquo;, we collect information about you as described in this Section.
        This includes information that you give us
      </p>
      <p>&nbsp;</p>
      <p>
        directly, information about your use of the Services, and information received from third
        parties. All the personal information that we collect and use in accordance with this Notice
        is centrally managed and stored by Rakuten India.
      </p>
      <p>&nbsp;</p>
      <p>
        <em>Information that you give us</em>
      </p>
      <p>
        <em>&nbsp;</em>
      </p>
      <p>Identifiers are:</p>
      <ul>
        <li>Your full name</li>
        <li>Your login username and password</li>
        <li>Your business email address</li>
        <li>Your phone number</li>
        <li>Your company/ employer name</li>
        <li>Your region of operations/ business</li>
        <li>Your designation in your organisation/ company</li>
        <li>IP address</li>
        <li>Server log information</li>
        <li>Information related to products and services displayed and searched; and</li>
      </ul>
      <p>
        <em>Information we collect from other sources</em>
      </p>
      <p>
        <em>&nbsp;</em>
      </p>
      <p>
        We may obtain information about you from other sources such as our third-party marketing and
        outreach partners. We may combine this information with those listed above, or collected
        through your use of the Website or Services.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250006"></a>Use, Legal Basis of Use and Retention of Your Information
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        We use your personal information to provide you information regarding our Services,
        including demos, product details, security information, and other particulars regarding
        Services and their functionalities, and access to our Website.
      </p>
      <p>&nbsp;</p>
      <p>
        We will also user your personal information to advertise and market our Services in
        accordance with your interests and preferences, to conduct research and development, to
        respond to customer inquiries, and to prevent and mitigate fraudulent or illegal activities.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>Use of Your Information</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>Specifically, we may use your information for the purposes listed below:</p>
      <p>&nbsp;</p>
      <p>
        <em>To provide you with details regarding our Website and Services:</em>
      </p>
      <ul>
        <li>For the provision of personalized services, content, and information related to the</li>
        <li>
          For the provision of product information such as pricing, demos, security information, and
          other such
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <em>To provide you with advertisements, promotion and marketing content:</em>
      </p>
      <ul>
        <li>
          To display or send to you advertising content, such as direct mail or email newsletters,
          with your consent, or via methods otherwise permitted under applicable
        </li>
        <li>For the display, introduction, or advertisement of products and services offered by</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <em>To undertake service improvement, research and development:</em>
      </p>
      <ul>
        <li>
          To improve existing services and conduct R&amp;D for the development of new services or
          improvements to our
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <em>To respond appropriately to customer inquiries:</em>
      </p>
      <ul>
        <li>
          So that we may respond appropriately to customer inquiries, complaints, or requests in
          relation to our Services, advertisements, and
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <em>To prevent and mitigate fraudulent or illegal activities:</em>
      </p>
      <ul>
        <li>
          For the prevention, identification and investigation of scams, cyber-attacks, and other
          potentially illegal or fraudulent activities, or in relation to any other activities
          necessary to protect the legal rights of Rakuten India, the Rakuten Group and/or the
          rights of third
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>Legal Basis of the Use of Your Information</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        In the European Economic Area, we are obliged to have a legitimate reason (or legal basis)
        for each processing activity and in most cases, the basis on which we rely will be one of
        the following:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          to fulfil our contractual obligations to you, for example to provide Services to you in
          the event that you purchase them;
        </li>
        <li>
          to comply with our legal obligations, for example to provide you with necessary
          information to ensure the Services you purchase are in accordance with committed service
          levels; and
        </li>
        <li>
          to meet our legitimate interests, for example to understand how you use the Services and
          to improve them and to provide tailored marketing messages to you via certain
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Additionally, we may ask you to give us your consent to collect and use certain types of
        personal information, for example, when we would like to send you e-mail marketing messages
        and process Sensitive Information. If we ask for your consent to process your personal
        information, we will ensure that you may withdraw your consent at any time. In the event you
        withdraw your consent, certain aspects of the Services may not be available to you, and may
        impact your enjoyment of use of the Services.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>Retention of Your Information</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        Unless instructed by you, we will keep your personal information necessary to fulfil our
        contractual obligations for as long as we have a relationship with you, and for a period of
        three (3) years thereafter. We will only retain your personal information after this time if
        we are required to do so to comply with the applicable laws, or if there are outstanding
        claims or complaints that will reasonably require your personal information to be retained.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250005"></a>Disclosure of Information about You
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <em>Disclosure to our vendors</em>
      </p>
      <p>
        <em>&nbsp;</em>
      </p>
      <p>
        We provide your personal information to sellers and relevant service providers (each a
        &ldquo;Merchant&rdquo;), including those located outside of India, when you use the Website
        or Services. These include:
      </p>
      <ul>
        <li>customer relationship management services and forms integrated with our Website;</li>
        <li>hosting of our products on cloud services;</li>
        <li>service providers engaged to communicate</li>
      </ul>
      <p>
        <em>Disclosure to Other Third Parties</em>
      </p>
      <p>
        <em>&nbsp;</em>
      </p>
      <p>
        In addition to the disclosures described above, we may also disclose your personal
        information to certain trusted third party service providers, including those located
        outside of India, where necessary to carry out our business operations.
      </p>
      <p>&nbsp;</p>
      <p>
        Please be assured that we will only disclose your personal information in compliance with
        the applicable law, and with the appropriate management and supervision of the relevant
        third parties.
      </p>
      <p>&nbsp;</p>
      <p>
        On occasion, judicial or regulatory authorities may request access to or disclosure of
        information about you for law enforcement purposes. Such requests typically pertain to
        criminal investigations or other legal proceedings. Where they are necessary and legitimate,
        we will respond to such requests in accordance with our internal policies and applicable
        law.
      </p>
      <p>&nbsp;</p>
      <p>
        Additionally, in cases where it is necessary to prevent and mitigate fraudulent or illegal
        activities, or to protect life, body, or property, we may disclose your personal information
        to another Rakuten Group company or to a third party.
      </p>
      <p>&nbsp;</p>
      <p>
        Furthermore, we may share your personal information with any third party that purchases, or
        to which we transfer, all or substantially all of our assets and business. Should such a
        transfer occur, we will use reasonable efforts to try to ensure that the entity to which we
        transfer your personal information uses it in a manner that is consistent with this Notice.
      </p>
      <p>&nbsp;</p>
      <p>
        We may disclose any types your information in the section titled &ldquo;The Information We
        Collect&rdquo; to the minimum necessity in order to achieve the purpose of the disclosure.
      </p>
      <p>&nbsp;</p>
      <p>
        We will not use or disclose your information for purposes not set forth in this Notice
        without informing you and taking any necessary steps in advance as required under applicable
        law.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250004"></a>Your Rights and Choices
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <em>Your Rights</em>
      </p>
      <p>
        <em>&nbsp;</em>
      </p>
      <p>
        You may have certain rights regarding your personal information, subject to the applicable
        law. These may include the following rights to:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>access your personal information</strong>: you can request access the information
          about your personal information, such as the categories, the purposes of the processing or
          any other information required under the applicable law;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>rectify your personal information</strong>: you can rectify inaccurate personal
          information concerning you;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>erase your personal information</strong>: you can ask us to erase your personal
          information we have collected;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>restrict </strong>our use of your personal information;
        </li>
        <li>
          <strong>object to our use of your personal information: </strong>you can object to certain
          types of our processing of your personal information depending on the circumstances, such
          as profiling or any other processing required under the applicable law;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>data portability</strong>: receive your personal information in a usable
          electronic format and transmit it to an external party;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>learn </strong>more about the sources from which we collect information, the
          purposes for which we collect and share information, the information we hold, and the
          categories of parties with whom we share your information;
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>be free from discrimination</strong>: You can freely exercise these rights without
          fear of being denied goods or ; and
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>lodge a complaint</strong>: you can lodge a complaint with us at{' '}
          <a className="text-blue-800" href="mailto:ISPDprod-RS_support@rakuten.com">
            ISPDprod-RS_support@rakuten.com.
          </a>
        </li>
      </ul>
      <p>
        We do not &ldquo;sell&rdquo; your personal information to any third-parties outside of the
        scope of disclosure and transfer provided under this Notice.
      </p>
      <p>&nbsp;</p>
      <p>
        If you would like to ask about or exercise such rights, please contact us in accordance with
        the section titled &lsquo;Contact us&rsquo;. You may use an authorized agent to exercise
        your rights in accordance with the applicable law. In that case, please ensure that you give
        the written permission to the agent.
      </p>
      <p>&nbsp;</p>
      <p>
        Once we receive the request, we will verify your identity based on your personal information
        that we already have and that we may additionally require from you for the verification
        purpose.
      </p>
      <p>&nbsp;</p>
      <p>
        In the event your user account and personal information is provided to us by your employer,
        we will initiate your request/ inquiries with your employer for the purposes of
        verification. Once verified and instructed by your employer to address or carry out your
        request/ inquiry, we shall do so within the timeline provided under applicable law.
      </p>
      <p>&nbsp;</p>
      <p>
        Please note that we may also require additional information from you in order to respond
        your request appropriately.
      </p>
      <p>&nbsp;</p>
      <p>
        We encourage you to contact us to update or correct your information if it changes or if the
        personal information we hold about you is inaccurate.
      </p>
      <p>&nbsp;</p>
      <p>
        Please also note that your rights are not absolute, and there are some grounds where we may
        decline your request as permitted under the applicable law.
      </p>
      <p>&nbsp;</p>
      <p>
        <em>Your Choices</em>
      </p>
      <p>
        <em>&nbsp;</em>
      </p>
      <p>
        You may either access our Services or contact the sales department for access, updates to,
        or any changes to their personal information that they have provided by logging into the
        Services. You may also email us at{' '}
        <a className="text-blue-800" href="mailto:ISPDprod-RS_support@rakuten.com">
          ISPDprod-RS_support@rakuten.com{' '}
        </a>
        to exercise your choices and rights with respect to your personal information.
      </p>
      <p>&nbsp;</p>
      <p>
        In the event your user account and personal information is provided to us by your employer,
        we will initiate your request/ inquiries with your employer for the purposes of
        verification. Once verified and instructed by your employer to address or carry out your
        request/ inquiry, we shall do so within the timeline provided under applicable law.
      </p>
      <p>&nbsp;</p>
      <p>
        As a principle, you will have a choice whether to provide information to us. However, where
        you have a choice and decide not to provide us with certain information, you may not be able
        to make use or take advantage of all the features provided by the Services. You may also be
        unable to receive certain information that may otherwise benefit you, such as notifications
        of campaigns or promotions.
      </p>
      <p>&nbsp;</p>
      <p>
        You can change your preferences for receiving promotional email and your preferred contact
        methods at any time by following the relevant instructions on our websites.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250003"></a>Updating Your Information
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        We want to do all we can to keep your personal information accurately recorded in our
        system. To this end, we endeavour to provide you with easy access to your information.
        Please remember to update information about you that has become inaccurate or outdated. You
        may reach out to us as per the information provided under the section titled &lsquo;Contact
        us&rsquo;.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250002"></a>Security and International Transfers
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        We are committed to handing your personal information in accordance with high security
        standards and in compliance with applicable law. We do so in order to prevent unauthorized
        access or unauthorized disclosure of your information, to maintain the accuracy of such
        information and, when necessary, to ensure its proper destruction. When collecting or
        transferring your personal information, we employ data encryption technology, and restrict
        access only to those persons who require it to fulfil their job responsibilities. We conduct
        periodic reviews of our practices to ensure that our safeguards are properly implemented and
        remain state of the art.
      </p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250001"></a>No Collection of Information from Children
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        Our Services are not designed for or directed towards children or minors. We do not
        intentionally collect or process any personal information from any person below 18 years old
        (or any such age as applicable to establish adulthood in your jurisdiction). If you discover
        that anyone younger than 18 years (or the permitted age in your jurisdiction) has provided
        us with any personal information, please contact us promptly on{' '}
        <a className="text-blue-800" href="mailto:ISPDprod-RS_support@rakuten.com">
          ISPDprod-RS_support@rakuten.com.{' '}
        </a>
        We will aim to permanently delete all such personal information without undue delay.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>Changes to this Notice</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        From time to time, in order to comply with changes in applicable laws or for legitimate
        business purposes, we may make changes to this Notice. Any changes we make will be displayed
        on this page. Please check back from time to time to ensure that you have read the most
        recent version of this Notice.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>
        <a id="_TOC_250000"></a>Contact Us
      </h1>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        If you have any questions or concerns regarding the contents of this Notice or other aspects
        of how your personal information is handled, please contact your product &lsquo;point of
        contact&rsquo; or write to us at <u>ISPDprod-</u>{' '}
        <a className="text-blue-800" href="mailto:RS_support@rakuten.com">
          RS_support@rakuten.com.
        </a>
      </p>
      <p>&nbsp;</p>
      <p>
        Once you contact us, we will respond without undue delay to you. Please note that when
        contacting us, we may ask you for certain information in order to verify your identity and
        respond to your request appropriately.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <em>
          This Notice has been updated. Please see below the dates for all issued versions
          (including changes where applicable):
        </em>
      </p>
      <p>
        <em>&nbsp;</em>
      </p>
      <ul>
        <li>
          <em>13</em>
          <em>th </em>
          <em>April </em>
        </li>
      </ul>
      <p>
        <em>&nbsp;</em>
      </p>
      <ul>
        <li>
          <em>16</em>
          <em>th </em>
          <em>
            August 2022 (added details pertaining to not collecting information from minors, and
            information collected from customers when their employers procure Services from us).
          </em>
        </li>
      </ul>
      <p>
        <em>&nbsp;</em>
      </p>
      <p>
        <em>Please contact </em>
        <a href="mailto:ISPDprod-RS_support@rakuten.com">
          <em>ISPDprod-RS_support@rakuten.com</em>{' '}
        </a>
        <em>if you would like to view any earlier copies of this Notice.</em>
      </p>
    </BasicCard>
  )
}
