import { ValidationTypes } from './types'
import { validations } from './validations'

export * from './types'

export const validateField = (
  value: string | number | null,
  validationChecks: ValidationTypes[]
) => {
  try {
    validationChecks.forEach((curr) => {
      const [validation, check] = curr.split(':')
      if (validations[validation](value, check)) {
        throw new Error(validation)
      }
    })
    return ''
  } catch (e: any) {
    return e.message as string
  }
}

export const isFormValid = (
  fields: { [key: string]: string | number },
  validations: { [key: string]: ValidationTypes[] }
) => {
  return Object.keys(validations).reduce(
    (acc, curr) => acc && validateField(fields[curr], validations[curr]) === '',
    true
  )
}
