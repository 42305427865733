import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const FILE_ICON_TEST_ID = 'FILE_ICON_TEST_ID'

export const FileIcon = ({
  testId = FILE_ICON_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  pathClasses,
}: IconProps) => (
  <div className={className} data-testid={testId}>
    <svg viewBox="0 0 13 15" xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        className={pathClasses}
        color={color}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.59}
        d="M7.978 1.132V3.76c0 .356 0 .534.07.67.06.12.157.218.277.279.136.069.314.069.67.069h2.628m.172 1.265v4.588c0 1.068 0 1.603-.208 2.011-.183.36-.475.651-.834.834-.409.208-.943.208-2.012.208H4.67c-1.07 0-1.603 0-2.012-.208a1.909 1.909 0 0 1-.834-.834c-.208-.408-.208-.943-.208-2.011V4.014c0-1.068 0-1.603.208-2.011.183-.36.475-.651.834-.834.409-.208.943-.208 2.012-.208h2.043c.467 0 .7 0 .92.053.195.046.38.124.551.228.193.118.358.283.688.613L10.9 3.884c.33.33.495.495.614.687.104.171.181.357.228.552.053.22.053.453.053.92Z"
      />
    </svg>
  </div>
)
