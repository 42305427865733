import { createSlice } from '@reduxjs/toolkit'
import { DashboardStateType, SourceType, TrendType } from './types'
import { SortTypes } from './consts'
import moment from 'moment'
import { updateSource } from '../source'
import { addSource, initialCurrentSourceState } from '../add-application'
import { appDefault, sourceDefault } from '../../pages/dashboard/main-dashboard/consts'

export const reducerPath = 'dashboard'

export const trendInitialValue: TrendType = {
  level: 'source',
  trendType: 'week',
  data: [],
  change: 0,
  changeType: null,
  count: 0,
}

export const initialTrendsState = {
  applicationTrend: trendInitialValue,
  sourceTrend: trendInitialValue,
  anomalyTrend: trendInitialValue,
  metricTrend: trendInitialValue,
}

const initialState: DashboardStateType = {
  currentSource: {} as SourceType,
  sortType: SortTypes.ASC,
  sortBy: 'createdOn',
  trendFromDate: moment().subtract(7, 'days').valueOf(),
  trendToDate: moment().valueOf(),
  currentModal: null,
  dashboardDateRange: {
    showForecast: false,
    rangeType: 'relative',
    relativeTime: '30-minutes',
    fromDate: moment().subtract(30, 'minutes').valueOf(),
    toDate: moment().seconds(0).milliseconds(0).valueOf(),
  },
  dashboardRedirection: false,
  selectedApplication: appDefault,
  selectedSource: sourceDefault,
}

export const dashboardSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeCurrentSource: (prevState, { payload }) => {
      return {
        ...prevState,
        currentSource: payload,
      }
    },
    changeSortType: (prevState, { payload }) => {
      return {
        ...prevState,
        sortType: payload,
      }
    },
    changeSortBy: (prevState, { payload }) => {
      return {
        ...prevState,
        sortBy: payload,
      }
    },
    setCurrentModal: (prevState, { payload }) => {
      return {
        ...prevState,
        currentModal: payload,
      }
    },
    changeDashboardDateRange: (prevState, { payload }) => {
      return {
        ...prevState,
        dashboardDateRange: {
          ...prevState.dashboardDateRange,
          ...payload,
        },
      }
    },
    setDashboardRedirection: (prevState, { payload }) => {
      return {
        ...prevState,
        dashboardRedirection: payload,
      }
    },
    setSelectedApplication: (prevState, { payload }) => {
      return {
        ...prevState,
        selectedApplication: payload,
      }
    },
    setSelectedSource: (prevState, { payload }) => {
      return {
        ...prevState,
        selectedSource: payload,
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(updateSource.fulfilled, (state) => {
        state.currentModal = null
      })
      .addCase(addSource.fulfilled, (state, { payload, meta }) => {
        state.currentSource = {
          ...initialCurrentSourceState,
          id: payload.id,
          sourceName: meta.arg.sourceName,
        }
      })
  },
})
export const {
  changeCurrentSource,
  changeSortBy,
  changeSortType,
  setCurrentModal,
  changeDashboardDateRange,
  setDashboardRedirection,
  setSelectedApplication,
  setSelectedSource,
} = dashboardSlice.actions
