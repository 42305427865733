import { ModelTrainingIcon } from '../../../../../components/icons'

export default function ModelTrainingScreen() {
  return (
    <div className="relative grid h-full w-full grid-cols-3 place-items-center text-xl text-warm_grey">
      <div className="absolute top-48 flex flex-col justify-center text-center">
        <ModelTrainingIcon className="w-3/12" />
        <span> Cogntive AI is learning from the data,</span>
        <span> anomalies will appear once detected</span>
      </div>
    </div>
  )
}
