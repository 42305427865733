/** This file contains all App level routes */
import { RouteObject } from 'react-router-dom'
import App from '../../App'
import { loginRoutes } from './login-routes'
import ErrorPage from '../templates/error-page'
import ServerErrorPage from '../templates/server-error-page'

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: loginRoutes,
  },
  {
    path: '/error',
    element: <ServerErrorPage />,
  },
]
