import { removeTrailingSlash } from '../../utils'
import { AddSourceRequestType, HeaderType, UpdateSourceRequestType } from './types'

export const mapHeaderAsKeyValue = (arr: HeaderType[]) =>
  arr.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.value }), {})

export const mapKeyValueAsHeaderOptions = (headers: { [key: string]: string }): HeaderType[] =>
  Object.keys(headers).map((name) => ({ name: name, value: headers[name] }))

export const transformAddSourceRequest = (params: AddSourceRequestType) => ({
  sourceName: params.sourceName,
  sourceType: params.sourceType,
  url: removeTrailingSlash(params.url),
  httpMethod: params.httpMethod,
  isBasicAuth: params.isBasicAuth,
  basicAuthId: params.basicAuthId,
  basicAuthPassword: params.basicAuthPassword,
  isTlsClientAuth: params.isTlsClientAuth,
  isWithCaCert: params.isWithCaCert,
  headers: mapHeaderAsKeyValue(params.headers),
  queryInterval: params.queryInterval,
  scrapeSteps: params.scrapeSteps,
  queryTimeout: params.queryTimeout,
  prometheusPrefix: params.prometheusPrefix,
})

export const transformUpdateSourceRequest = (params: UpdateSourceRequestType) => ({
  id: params.sourceId,
  sourceName: params.sourceName,
  httpMethod: params.httpMethod,
  headers: mapHeaderAsKeyValue(params.headers),
  queryInterval: params.queryInterval,
  scrapeSteps: params.scrapeSteps,
  queryTimeout: params.queryTimeout,
})
