import { useEffect, useState } from 'react'
import { LabelInput } from '../../components'

export const HEADER_INPUT_TEST_ID = 'HEADER_INPUT_TEST_ID'

interface HeaderInputProps {
  name: string
  value: string
  onChange: (name: string, value: string) => void
  className?: string
}

export default function HeaderInput({ name, value, onChange, className }: HeaderInputProps) {
  const [headerName, setHeaderName] = useState(name)
  const [headerValue, setHeaderValue] = useState(value)

  useEffect(() => {
    onChange?.(headerName, headerValue)
  }, [headerName, headerValue])

  return (
    <div data-testid={HEADER_INPUT_TEST_ID} className="mt-2 flex flex-row">
      <div className="flex-1">
        <LabelInput
          label="Header"
          placeholder="Header name"
          value={headerName}
          onChange={(e) => setHeaderName(e.target.value)}
        />
      </div>
      <div className={`w-10 ${className}`}></div>
      <div className="flex-1">
        <LabelInput
          label="Value"
          placeholder="Header value"
          value={headerValue}
          onChange={(e) => setHeaderValue(e.target.value)}
        />
      </div>
    </div>
  )
}
