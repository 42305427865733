import { useEffect, useMemo, useState, FunctionComponent } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { motion, AnimatePresence } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  useGetAnomaliesQuery,
  useGetMetricsQuery,
  initialPredictions,
  changeMetricSelectedBreach,
  GroupedAnomaliesType,
  useGetAggregationsQuery,
  initialAggregationsState,
  selectedBreachStateSelector,
  useGetCorelationsQuery,
  changeDefDevSelectedBreach,
  changeAnomaliesDateRange,
} from '../../../../store/anomalies'
import { POLLING_INTERVAL, accordianTransition, pageTransitionAnimation } from '../../../../consts'
import { SOURCES_ROUTES, appPaths } from '../../../../routes'
import AnomaliesNoDataMessage from './loaders/anomalies-no-data-screen'
import ModelTrainingScreen from './loaders/model-training-screen'
import { AnomalyCard, CompareChart, GraphDateRange, Select } from '../../../../components'
import { MetricTypeTitles, SeverityTypes, getQueryRange } from '../../../../store/anomaly-detection'
import {
  ApplicationType,
  anomalyStateSelector,
  changeCurrentApplicationId,
  changeCurrentMetricType,
  useGetApplicationsQuery,
} from '../../../../store/anomaly-detection'
import AnomalyGraphSection from './anomaly-graph-section'
import { COLORS } from '../../../../../themes/default/colors'
import { MetricTypesType } from '../../../../store/application/types'
import { comparisonRangeTimestampsAggregator, getDeviationTimeFormat } from '../../../../utils'
import BreachTimeline from './breach-timeline-section'
import {
  GraphAbsoluteRangeType,
  GraphDaterangeType,
} from '../../../../components/graph-date-range/types'
import { useSourceTabsContext } from '../../../../providers'
import AnomalyCardLoader from '../../main-dashboard/loaders/anomaly-card'
import { anomaliesForecastOptions, graphDateTabs } from '../behaviour/consts'
import { OptionType } from '../../../../types'
import OptionsWrapper from '../behaviour/option-wrapper'
import { metricOptions } from '../../main-dashboard/consts'
import { dashboardStateSelector, setDashboardRedirection } from '../../../../store/dashboard'

export default function Anomalies() {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sourceId = '' } = useParams()
  const {
    setCurrentTabState,
    currentSourceTab,
    changeSourceAnomaliesDateRange,
    changeSourceMultivariateDateRange,
    changeSourceBehaviourDateRange,
  } = useSourceTabsContext()
  const { anomaliesDateRange } = currentSourceTab
  const selectedBreach = useAppSelector(selectedBreachStateSelector(sourceId))
  const [openAnomalyIndex, setOpenAnomalyIndex] = useState(0)
  const graphDateRange = anomaliesDateRange
  const { fromDate: currentFromDate, toDate: currentToDate } = getQueryRange(graphDateRange)
  const { currentApplicationId, metricType } = useAppSelector(anomalyStateSelector)
  const { dashboardRedirection } = useAppSelector(dashboardStateSelector)
  // RTK Query for Get Anomalies
  const { data: anomalies = [], isLoading: isAnomaliesLoading } = useGetAnomaliesQuery(
    {
      id: sourceId,
      level: 'source',
      fromDate: currentFromDate,
      toDate: currentToDate,
    },
    { pollingInterval: POLLING_INTERVAL }
  )
  useEffect(() => {
    if (!dashboardRedirection) {
      dispatch(changeCurrentApplicationId(''))
      dispatch(changeCurrentMetricType(''))
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setDashboardRedirection(false))
    }
  }, [])

  function filterArrayById(arrayOfObjects?: GroupedAnomaliesType[]) {
    if (currentApplicationId !== '' && currentApplicationId !== 'all') {
      if (metricType !== '' && metricType !== 'metricType') {
        let filteredArray = arrayOfObjects?.filter(
          (obj: GroupedAnomaliesType) => obj.applicationId === currentApplicationId
        )

        filteredArray = filteredArray?.filter(
          (obj: GroupedAnomaliesType) => obj.metricType === metricType
        )

        return filteredArray
      } else {
        return arrayOfObjects?.filter(
          (obj: GroupedAnomaliesType) => obj.applicationId === currentApplicationId
        )
      }
    } else {
      if (metricType !== '' && metricType !== 'metricType') {
        let filteredArray = arrayOfObjects?.filter(
          (obj: GroupedAnomaliesType) => obj.metricType === metricType
        )
        return filteredArray
      }
    }
    return anomalies
  }

  const { data: applications = [] } = useGetApplicationsQuery({ sourceId }, { skip: !sourceId })
  const applicationOptions = useMemo(() => {
    const options = applications.map((p: ApplicationType) => ({
      label: p.appName,
      value: p.id,
    }))
    options.unshift({ label: 'All', value: 'all' })
    return options
  }, [applications])

  const { data: corelations = [] } = useGetCorelationsQuery(
    {
      eventTime: moment(anomalies[openAnomalyIndex]?.lastUpdated).valueOf(),
      queryId: anomalies[openAnomalyIndex]?.queryId,
    },
    {
      skip: !anomalies[openAnomalyIndex]?.queryId || !anomalies[openAnomalyIndex]?.lastUpdated,
    }
  )

  // RTK Query for Get Metrics
  const { data: predictions = initialPredictions, isFetching: isMetricsFetching } =
    useGetMetricsQuery(
      {
        queryId: anomalies[openAnomalyIndex]?.queryId,
        applicationId: anomalies[openAnomalyIndex]?.applicationId,
        fromDate: anomalies[openAnomalyIndex]?.graphFromDate,
        toDate: anomalies[openAnomalyIndex]?.graphToDate,
      },
      {
        skip:
          !anomalies[openAnomalyIndex]?.queryId || anomalies[openAnomalyIndex].type !== 'metric',
      }
    )

  // RTK Query for Get Aggregations
  const { data: comparisons = initialAggregationsState, isLoading: isAggregationLoading } =
    useGetAggregationsQuery(
      {
        queryId: anomalies[openAnomalyIndex]?.queryId,
        fromDate: anomalies[openAnomalyIndex]?.graphFromDate,
        toDate: anomalies[openAnomalyIndex]?.graphToDate,
        aggregationLevel: anomalies[openAnomalyIndex]?.level,
        includeComparison: anomalies[openAnomalyIndex]?.type === 'deflection',
      },
      {
        skip:
          !anomalies[openAnomalyIndex]?.queryId || anomalies[openAnomalyIndex]?.type === 'metric',
      }
    )

  const minutesDifference = useMemo(
    () => moment().diff(moment(currentSourceTab?.details?.createdOn), 'minutes'),
    [currentSourceTab?.details?.createdOn]
  )

  const handleChevronClick = (index: number) => {
    if (openAnomalyIndex === index) {
      setOpenAnomalyIndex(-1)
    } else {
      setOpenAnomalyIndex(index)
      dispatch(
        changeMetricSelectedBreach({
          sourceId,
          queryId: anomalies[index]?.queryId,
          applicationId: anomalies[index].applicationId,
          applications: anomalies[index].applications,
          applicationName: anomalies[index].applications[0],
          metricType: anomalies[index].metricType,
          eventTime: anomalies[index].lastUpdated,
          severity: anomalies[index].severity,
        })
      )
    }
  }

  const handleViewMultivariateRouteState = ({
    fromDate,
    toDate,
    applicationId,
  }: GraphAbsoluteRangeType & { applicationId: string }) => {
    const nextPath = `${appPaths.multivariatePage({
      sourceId,
      applicationId: applicationId,
    })}`
    dispatch(
      changeMetricSelectedBreach({
        sourceId,
        queryId: anomalies[openAnomalyIndex]?.queryId,
        applicationId: anomalies[openAnomalyIndex].applicationId,
        applications: anomalies[openAnomalyIndex].applications,
        applicationName: anomalies[openAnomalyIndex].applications[0],
        metricType: anomalies[openAnomalyIndex].metricType,
        eventTime: anomalies[openAnomalyIndex].lastUpdated,
        severity: anomalies[openAnomalyIndex].severity,
        metricName: anomalies[openAnomalyIndex].metricName,
        groundTruth: anomalies[openAnomalyIndex].breaches[0].groundTruth,
        upperThreshold: anomalies[openAnomalyIndex].breaches[0].upperThreshold,
        lowerThreshold: anomalies[openAnomalyIndex].breaches[0].lowerThreshold,
      })
    )
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: nextPath,
      sourceId,
      multivariateDateRange: {
        fromDate: moment(selectedBreach.eventTime).utc().subtract(30, 'minutes').valueOf(),
        toDate: moment(selectedBreach.eventTime).utc().add(30, 'minutes').valueOf(),
      },
      multivariateBehaviourDateRange: {
        showForecast: false,
        rangeType: 'absolute',
        relativeTime: '30-minutes',
        fromDate: moment(selectedBreach.eventTime).utc().subtract(30, 'minutes').valueOf(),
        toDate: moment(selectedBreach.eventTime).utc().add(30, 'minutes').valueOf(),
      },
    })

    changeSourceMultivariateDateRange({
      sourceId,
      fromDate,
      toDate,
    })

    navigate(nextPath)
  }

  const onApplicationChange = (option: OptionType) => {
    if (currentApplicationId !== option.value) {
      dispatch(changeCurrentApplicationId(option.value))
      setCurrentTabState({ sourceId, behaviourAppId: option.value })
    }
  }
  const onMetricChange = (option: OptionType) => {
    if (metricType !== option.value) {
      dispatch(changeCurrentMetricType(option.value))
    }
  }
  const handleOnViewApplicationClick = ({
    fromDate,
    toDate,
    applicationId,
  }: GraphAbsoluteRangeType & { applicationId: string }) => {
    const nextPath = `${appPaths.appBehaviourPage({
      sourceId,
      applicationId: applicationId,
    })}`
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: nextPath,
      sourceId,
      multivariateDateRange: {
        fromDate,
        toDate,
      },
      multivariateBehaviourDateRange: {
        showForecast: false,
        rangeType: 'absolute',
        relativeTime: '30-minutes',
        fromDate,
        toDate,
      },
    })

    changeSourceBehaviourDateRange({
      sourceId,
      fromDate,
      toDate,
    })

    navigate(nextPath)
  }
  const handlemaximiseClick = (type: string, index: number) => {
    if (anomalies[index] !== undefined) {
      if (anomalies[index].breaches.length > 0) {
        const selected = anomalies[index].breaches[0]
        dispatch(
          changeMetricSelectedBreach({
            sourceId,
            queryId: anomalies[index]?.queryId,
            applications: anomalies[index].applications,
            metricType: anomalies[index].metricType,
            ...selected,
          })
        )
      }
      if (anomalies[index].defdevs.length > 0) {
        const selected = anomalies[index].defdevs[0]
        dispatch(
          changeDefDevSelectedBreach({
            sourceId,
            queryId: anomalies[index]?.queryId,
            applications: anomalies[index].applications,
            ...selected,
          })
        )
      }
      const path =
        type === 'metric'
          ? appPaths.anomaliesBreachesTab({ sourceId, queryId: anomalies[index].queryId })
          : appPaths.anomaliesDefDevTab({ sourceId, queryId: anomalies[index].queryId })
      setCurrentTabState({
        prevActiveTab: SOURCES_ROUTES.ANOMALIES,
        prevAnomalyPath: path,
        sourceId,
      })
      navigate(path)
      // if (type === 'metric') {
      //   navigate(
      //     `${appPaths.anomaliesBreachesTab({
      //       sourceId,
      //       queryId: anomalies[index].queryId,
      //     })}`
      //   )
      // } else {
      //   navigate(
      //     `${appPaths.anomaliesDefDevTab({
      //       sourceId,
      //       queryId: anomalies[index].queryId,
      //     })}`
      //   )
      // }
    }
  }

  const onGraphDateRange = (params: any) => {
    changeSourceAnomaliesDateRange({ sourceId, ...params })
  }

  const handleGraphDateTabChange = (value: string) => {
    onGraphDateRange({ rangeType: value })
  }

  const handleGraphDateRangeChange = (value: string | { fromDate: number; toDate: number }) => {
    const graphDateRangeValue =
      graphDateRange.rangeType === 'relative'
        ? { relativeTime: value }
        : (value as { [key: string]: number | string })
    onGraphDateRange(graphDateRangeValue)
    setOpenAnomalyIndex(0)
  }

  useEffect(() => {
    if (anomaliesDateRange === undefined) {
      setCurrentTabState({ sourceId, anomaliesDateRange: graphDateRange })
    } else {
      dispatch(changeAnomaliesDateRange(anomaliesDateRange))
    }
  }, [pathname])

  return (
    <motion.div
      className="flex h-screen w-full flex-col gap-5 overflow-y-hidden"
      {...pageTransitionAnimation}
    >
      <div className="flex h-[88vh] w-full flex-col overflow-y-scroll laptop:px-20 desktop:px-24">
        <div className="flex w-full flex-row items-center py-1">
          <div className="border-1 flex h-8 w-full flex-row items-center gap-5">
            <Select
              iconColor={COLORS.PRIMARY}
              className="border-1 !w-44 border-primary bg-dark2"
              buttonClasses="hover:opacity-70 !h-6 bg-dark2 shadow-none border-primary font-semibold !rounded-sm"
              selectedValueClasses={'text-xs !text-primary'}
              value={applicationOptions.find((a: OptionType) => a.value === currentApplicationId)}
              placeholder="Application"
              options={applicationOptions}
              onChange={onApplicationChange}
              OptionsWrapper={OptionsWrapper as FunctionComponent}
            />
            <div className="w-40 border-primary">
              <Select
                iconColor={COLORS.PRIMARY}
                className="border-1 !w-20 border-primary bg-dark2"
                buttonClasses="hover:opacity-70 !h-6 bg-dark2 shadow-none border-primary font-semibold !rounded-sm"
                selectedValueClasses={'text-xs !text-primary'}
                value={metricOptions.find((a: OptionType) => a.value === metricType)}
                placeholder="Metrics"
                options={metricOptions}
                onChange={onMetricChange}
                optionClasses={'!w-[140px]'}
              />
            </div>
          </div>
          <div className="flex w-80">
            <GraphDateRange
              tabs={graphDateTabs}
              rangeType={graphDateRange?.rangeType}
              options={anomaliesForecastOptions}
              infoText={''}
              fromDate={graphDateRange?.fromDate}
              toDate={graphDateRange?.toDate}
              relativeValue={graphDateRange?.relativeTime}
              onChange={handleGraphDateRangeChange}
              onTabChange={handleGraphDateTabChange}
              onShowForecastChange={() => null}
              showForecast={false}
              displayRelativeForecast={false}
              maxAllowedDays={1}
            />
          </div>
        </div>
        {filterArrayById(anomalies)?.length === 0 &&
          !isAnomaliesLoading &&
          (minutesDifference < 30 ? <ModelTrainingScreen /> : <AnomaliesNoDataMessage />)}
        {isAnomaliesLoading ? (
          <AnomalyCardLoader numberOfItems={3} />
        ) : (
          <>
            {filterArrayById(anomalies)?.map((a: GroupedAnomaliesType, index) => {
              const metrics =
                corelations.length > 0
                  ? corelations.map((c, i) => (
                      <span key={i} className="ml-1">
                        {c.metricName}{' '}
                        {i !== corelations.length - 1 && corelations.length !== 1 ? ',' : ''}
                      </span>
                    ))
                  : [<span key="n/a">N/A</span>]
              return (
                <div
                  className="grid grid-flow-row grid-cols-1 items-center laptop:py-6 desktop:py-8"
                  key={`${index}-${a.queryId}`}
                >
                  <div className="flex w-full flex-col justify-between">
                    <AnomalyCard
                      title={a.metricName}
                      eventTime={a.createdAt}
                      breachesCount={a.breachesCount}
                      deviationCount={a.defdevsCount}
                      application={a.applications}
                      lastUpdatedTime={a.lastUpdated}
                      status={a.status}
                      severityTypes={SeverityTypes}
                      severity={a.severity}
                      onMaximiseClick={() => handlemaximiseClick(a.type, index)}
                      onChevronClick={() => handleChevronClick(index)}
                      isOpen={index === openAnomalyIndex}
                      anomalyType={a.type}
                      trend={a.trend}
                      lastAnomaly={a.type === 'metric' ? a.breaches[0] : a.defdevs[0]}
                    />
                    <AnimatePresence mode="wait">
                      {index === openAnomalyIndex && (
                        <motion.div key={a.queryId} {...accordianTransition}>
                          <div className="flex h-[1px] w-[95%] self-center bg-warmgrey28" />
                          <div className="flex h-[17rem] w-full flex-col bg-charcolGrey p-2">
                            <BreachTimeline
                              breaches={a.timeline}
                              onViewMultivariateClick={() =>
                                handleViewMultivariateRouteState({
                                  applicationId: a.applicationId,
                                  fromDate: a.graphFromDate,
                                  toDate: a.graphToDate,
                                })
                              }
                              sourceId={sourceId}
                              isFetching={false}
                              metrics={metrics}
                              anomalyType={a.type}
                              onViewApplicationClick={() =>
                                handleOnViewApplicationClick({
                                  applicationId: a.applicationId,
                                  fromDate: a.graphFromDate,
                                  toDate: a.graphToDate,
                                })
                              }
                            />
                          </div>
                          <div className="flex h-[1px] w-[95%] self-center bg-warmgrey28" />
                          <div className="flex w-full flex-col bg-charcolGrey laptop:p-3 desktop:p-4">
                            <div className="text-md pb-2 pl-5 font-RakutenSansBold capitalize text-white">
                              {anomalies[openAnomalyIndex]?.metricName}
                            </div>

                            {anomalies[openAnomalyIndex].type === 'metric' && (
                              <div className="flex w-full">
                                <AnomalyGraphSection
                                  graphPlotColor={COLORS.CHARCOL_GREY}
                                  grpahHeight={250}
                                  containerColor={COLORS.CHARCOL_GREY}
                                  showHeader={false}
                                  predictions={predictions}
                                  isPredictionsLoading={isMetricsFetching}
                                  isAnomalyListLoading={isMetricsFetching}
                                  queryId={a.queryId}
                                  metricType={a.metricType}
                                  eventTime={a.lastUpdated}
                                />
                              </div>
                            )}

                            {anomalies[openAnomalyIndex].type !== 'metric' && (
                              <div className="flex w-full laptop:h-72 desktop:h-64">
                                <CompareChart
                                  yAxisData={comparisons.y}
                                  xlabels={comparisons.labels}
                                  legendSuffix={''}
                                  plotBackgroundColor={COLORS.CHARCOL_GREY}
                                  containerBackgroundColor={COLORS.CHARCOL_GREY}
                                  fontColor={COLORS.WHITE}
                                  yAxisTitle={
                                    MetricTypeTitles[
                                      anomalies[openAnomalyIndex].metricType as MetricTypesType
                                    ]
                                  }
                                  xAxisTitle={`Time in ${
                                    comparisonRangeTimestampsAggregator[
                                      anomalies[openAnomalyIndex]?.level
                                    ].reduceTimeBy
                                  }`}
                                  annotationTexts={['']}
                                  colouredArea={{
                                    x0: getDeviationTimeFormat(
                                      a.type,
                                      a.defdevs[a.defdevs.length - 1]?.level,
                                      a.defdevs[a.defdevs.length - 1]?.deviationStartTime
                                    ),
                                    x1: getDeviationTimeFormat(
                                      a.type,
                                      a.defdevs[a.defdevs.length - 1]?.level,
                                      a.defdevs[a.defdevs.length - 1]?.deviationEndTime
                                    ),
                                    y0: 0,
                                    y1:
                                      Math.max(
                                        ...(comparisons.y
                                          .flat()
                                          .filter((value) => value !== null) as number[])
                                      ) || 0,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              )
            })}
          </>
        )}
      </div>
    </motion.div>
  )
}
