import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const LOGOUT_ICON_TEST_ID = 'LOGOUT_ICON_TEST_ID'

export const LogoutIcon = ({
  testId = LOGOUT_ICON_TEST_ID,
  className,
  color = COLORS.PRIMARY,
}: IconProps) => {
  return (
    <div data-testid={testId} className={className}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.5556 20.4444L24 16M24 16L19.5556 11.5556M24 16H13.3333M13.3333 8H12.2667C10.7732 8 10.0265 8 9.45603 8.29065C8.95426 8.54631 8.54631 8.95426 8.29065 9.45603C8 10.0265 8 10.7732 8 12.2667V19.7333C8 21.2268 8 21.9735 8.29065 22.544C8.54631 23.0457 8.95426 23.4537 9.45603 23.7094C10.0265 24 10.7732 24 12.2667 24H13.3333"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}
