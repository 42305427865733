import { COLORS } from '../../../themes/default/colors'
import {
  BasicCard,
  Button,
  HelperText,
  LabelInput,
  Select,
  Spinner,
  Tooltip,
} from '../../components'
import {
  HttpMethods,
  changeHeaderValues,
  changeInputValues,
  headersSelector,
  httpInputsSelector,
  HeaderType,
  addSource,
} from '../../store/add-application'
import { useAppDispatch, useAppSelector } from '../../hooks'
import HeaderInput from '../../partials/add-edit-source-card/headers-input'
import { useEffect, useMemo } from 'react'
import { Close, InfoOutlined } from '../../components/icons'
import { DefineSourceCardProps } from './types'
import { isFormValid, onlyIntegerKeyDown, validateField } from '../../utils'
import { validationMessages } from '../../consts'
import { useTestConnectionMutation, sourceTypeSelector } from '../../store/source'
import { QIntervalTooltipList, QTimeoutTooltipList } from '../../consts/tooltips'

export const ADD_SOURCE_CARD_TEST_ID = 'ADD_SOURCE_CARD_TEST_ID'

export const httpOptions = Object.keys(HttpMethods).map((k) => ({ label: k, value: k }))

export default function AddSourceCard({ onClose, onSaveAction }: DefineSourceCardProps) {
  const dispatch = useAppDispatch()
  const httpInputs = useAppSelector(httpInputsSelector)
  const headers = useAppSelector(headersSelector)
  const sourceType = useAppSelector(sourceTypeSelector)
  const [submitSourceUrl, { data: connectionState = { status: null }, isLoading }] =
    useTestConnectionMutation()

  useEffect(() => {
    if (httpInputs.sourceId !== '') {
      onSaveAction()
    }
  }, [httpInputs.sourceId])

  const handleInputChange = (type: string, value: string, check?: boolean) => {
    if (check) {
      Number(value) >= 0 && dispatch(changeInputValues({ type, value }))
    } else {
      dispatch(changeInputValues({ type, value }))
    }
  }

  const handleHeaderInputChange = (index: number, type: string, value: string) => {
    dispatch(changeHeaderValues({ index, type, value }))
  }

  const handleTestConnection = async () => {
    // headers are not present in backend api
    await submitSourceUrl({
      url: httpInputs.httpUrl,
      sourceType: sourceType,
      prometheusPrefix: httpInputs.prometheusPrefix,
    })
  }

  const handleOnSaveClick = () => {
    dispatch(addSource(httpInputs))
  }

  const disableSave = useMemo(
    () =>
      connectionState.status !== 'SUCCESS' ||
      httpInputs.isLoading ||
      !isFormValid(
        {
          httpUrl: httpInputs.httpUrl,
          queryInterval: httpInputs.queryInterval,
          queryTimeout: httpInputs.queryTimeout,
        },
        {
          httpUrl: ['required', 'url'],
          queryInterval: ['required', 'min:15', 'max:86400'],
          queryTimeout: ['required', 'min:1', 'max:5'],
        }
      ),
    [
      connectionState.status,
      httpInputs.httpUrl,
      httpInputs.isLoading,
      httpInputs.queryInterval,
      httpInputs.queryTimeout,
    ]
  )

  const isValidUrl = validateField(httpInputs.httpUrl, ['required', 'url'])

  const isValidInterval = useMemo(
    () => validateField(httpInputs.queryInterval, ['required', 'min:60', 'max:86400']),
    [httpInputs.queryInterval]
  )

  const isValidTimeout = useMemo(
    () => validateField(httpInputs.queryTimeout, ['required', 'min:1', 'max:5']),
    [httpInputs.queryTimeout]
  )

  return (
    <BasicCard
      testId={ADD_SOURCE_CARD_TEST_ID}
      contentStyles={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}
      containerClasses="flex flex-col relative mobile:min-h-[55vh] mobile:w-10/12 mobile:h-[55vh] tablet_p:min-h-[40rem] tablet_p:w-9/12 tablet_p:h-[40rem] tablet_l:w-8/12 tablet_l:min-h-[40rem] tablet_l:h-[40rem] laptop:w-6/12 laptop:min-h-[42rem] laptop:h-[42rem] desktop:w-6/12 desktop:min-h-[42rem] desktop:h-[42rem]"
      contentClasses="flex flex-col !px-10 overflow-y-auto !pt-4"
      cardActions={
        <div className="flex w-full flex-col items-end !px-10 py-4">
          <div className="mb-6 !w-full bg-dark2 p-[1px] shadow-md" />
          <div>
            <Button
              testId={`${ADD_SOURCE_CARD_TEST_ID}_SAVE`}
              fullWidth
              variant="contained"
              disabled={disableSave}
              onClick={handleOnSaveClick}
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      {onClose !== undefined && !httpInputs.isLoading ? (
        <button
          data-testid={`${ADD_SOURCE_CARD_TEST_ID}_CLOSE`}
          className="absolute right-2.5 top-2.5 text-primary"
          onClick={onClose}
        >
          <Close className="w-10 p-2 pt-3 " color={COLORS.PRIMARY} />
        </button>
      ) : null}
      <div className="mt-2 flex-1 flex-col">
        <div className="text-xl text-white">{httpInputs.sourceName} via Prometheus</div>
        <HelperText text="Configure the source" />
      </div>
      <div className="mt-2 flex flex-col">
        <div className="text-l flex text-white">HTTP</div>
        <div className="mt-2 flex-1">
          <LabelInput
            testId={`${ADD_SOURCE_CARD_TEST_ID}_URL`}
            required
            label="Url"
            error={isValidUrl !== ''}
            placeholder="enter an accessible URL"
            value={httpInputs.httpUrl}
            onChange={(e) => handleInputChange('httpUrl', e.target.value)}
            errorMessage={isValidUrl ? validationMessages.httpUrl[isValidUrl] : ''}
          />
        </div>
      </div>
      <div className="mt-2 flex flex-col">
        <div className="text-l flex text-white">Method and Auth</div>
        <div className="mt-2 w-1/6 mobile:w-3/12 tablet_p:w-3/12 tablet_l:w-2/12 laptop:w-3/12 desktop:w-2/12">
          <Select
            testId={`${ADD_SOURCE_CARD_TEST_ID}_METHOD`}
            labelClasses="text-xs"
            label="HTTP Method"
            options={httpOptions}
            value={httpOptions.find((h) => h.value === httpInputs.httpMethod)}
            onChange={(option) => handleInputChange('httpMethod', option.value as string)}
          />
        </div>
        <div className="mt-2">
          {headers.map((header: HeaderType, index: number) => (
            <HeaderInput
              key={index}
              name={header.name}
              value={header.value}
              onChange={(name, value) => handleHeaderInputChange(index, name, value)}
            />
          ))}
        </div>
      </div>
      <div className="mt-5 flex flex-row">
        <div className="flex-2">
          <Button
            testId={`${ADD_SOURCE_CARD_TEST_ID}_TEST_BUTTON`}
            fullWidth
            variant="outlined"
            onClick={handleTestConnection}
            disabled={isValidUrl !== ''}
          >
            Test connection
          </Button>
        </div>
        <div className="flex flex-row items-center justify-between px-3">
          {isLoading && <Spinner className="h-6 w-6" />}

          {connectionState.status !== null && !isLoading && (
            <>
              <span
                className={`mx-2 rounded border px-2 text-base ${
                  connectionState.status === 'SUCCESS'
                    ? 'border-green-900 bg-green-300 text-green-900'
                    : 'border-red-900 bg-red-300 text-red-900'
                }`}
              >
                {connectionState.status}
              </span>
              {connectionState.status === 'SUCCESS' ? (
                <span className="text-primary"> Test connection passed </span>
              ) : (
                <span className="text-red-600"> Test connection failed </span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="my-5 w-5/6 self-center bg-primary p-[0.5px]" />
      <div className="flex flex-col">
        <div className="text-l flex text-white">Query config</div>
        <div className="mt-2 flex flex-row">
          <div className="flex-1">
            <LabelInput
              testId={`${ADD_SOURCE_CARD_TEST_ID}_QUERY_INTERVAL`}
              type="number"
              error={isValidInterval !== ''}
              onKeyDown={onlyIntegerKeyDown}
              label="Query interval (seconds)"
              toolTipMessage={<QIntervalTooltipList />}
              toolTipPlacement="top"
              placeholder="Interval in seconds"
              value={httpInputs.queryInterval}
              onChange={(e) => handleInputChange('queryInterval', e.target.value, true)}
              errorMessage={
                isValidInterval ? validationMessages.queryInterval[isValidInterval] : ''
              }
            />
          </div>
          <div className="w-10"></div>
          <div className="flex-1">
            <LabelInput
              testId={`${ADD_SOURCE_CARD_TEST_ID}_QUERY_TIMEOUT`}
              type="number"
              error={isValidTimeout !== ''}
              onKeyDown={onlyIntegerKeyDown}
              label="Query timeout (seconds)"
              toolTipMessage={<QTimeoutTooltipList />}
              toolTipPlacement="top"
              placeholder="time in seconds"
              value={httpInputs.queryTimeout}
              onChange={(e) => handleInputChange('queryTimeout', e.target.value, true)}
              errorMessage={isValidTimeout ? validationMessages.queryTimeout[isValidTimeout] : ''}
            />
          </div>
        </div>
      </div>
    </BasicCard>
  )
}
