import { MouseEventHandler } from 'react'
import { MenuDropdown, Switch } from '../../../../components'
import { Settings } from '../../../../components/icons'
import { MenuItem } from '../../../../components/menu-dropdown/types'

export const ANOMALY_SETTINGS_TEST_ID = 'ANOMALY_SETTINGS_TEST_ID'

interface AnomalyGraphSettingsProps {
  testId?: string
  settingsMenuItems?: MenuItem[]
  isZoomable: boolean
  onScrollZoomChange: MouseEventHandler | undefined
}
export default function AnomalyGraphSettings({
  testId = ANOMALY_SETTINGS_TEST_ID,
  isZoomable,
  onScrollZoomChange,
}: AnomalyGraphSettingsProps) {
  return (
    <div data-testid={testId}>
      <MenuDropdown
        transitionClasses={{ leave: 'dealy-75' }}
        itemsClasses="!mt-0.5 z-20"
        menuText={(open) => (
          <div
            className={`flex h-5 w-5 items-center justify-center rounded-md ${
              open ? 'bg-primary20' : ''
            }`}
          >
            <Settings className="h-5 w-5 fill-primary" />
          </div>
        )}
        menuItems={[
          {
            label: (
              <div className="flex items-center gap-3 rounded-md px-2 py-2">
                <span className="text-sm text-warm">scroll to zoom</span>
                <Switch checked={isZoomable} onClick={onScrollZoomChange} />
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}
