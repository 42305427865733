export default function GraphLoadingMessage() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-white">
      <div className="flex w-full items-center justify-center space-x-2">
        {[...Array(5)].map((_, k) => (
          <div
            key={k}
            className="inline-block h-4 w-4 animate-pulse rounded-full bg-primary"
            role="status"
          />
        ))}
      </div>
      <div className="mt-5 text-2xl">The query results will be available soon</div>
      <div className="text-base">Wait while we query your data and visualise here</div>
    </div>
  )
}
