import { TextAreaProps } from './types'
import { useState } from 'react'
import Tooltip from '../tooltip'
import { InfoOutlined } from '../icons'
import { COLORS } from '../../../themes/default/colors'

export default function TextArea({
  className,
  value,
  onChange,
  onKeyDown,
  placeholder,
  label,
  labelClases,
  required,
  infoText,
  entityValidation,
  error,
  errorMessage,
  maxLength,
}: TextAreaProps) {
  const [isFocused, setIsFocused] = useState(false)
  const [isTouched, setIsTouched] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = () => {
    setIsFocused(false)
    setIsTouched(true)
  }

  return (
    <>
      <label className={`flex flex-row pb-1 text-sm ${labelClases}`}>
        {label}
        {required && <span className="ml-0.5 text-red-500 ">*</span>}
        {infoText && (
          <Tooltip placement={'top-start'} title={<div className="text-xs">{infoText}</div>}>
            <InfoOutlined className="ml-1.5 mt-0.5 h-4 w-4" color={COLORS.PRIMARY} />
          </Tooltip>
        )}
      </label>
      <textarea
        placeholder={placeholder}
        className={`${className}
        ${error && entityValidation !== 'required' && ' !border-b-red-500'}
        ${error && !isFocused && isTouched && '!border-b-red-500'}
              h-16 w-full resize-none rounded-sm border border-dark2 bg-dark2 p-3 text-sm text-white placeholder:text-warm_grey focus:border-b-primary focus:outline-none focus:ring-primary
          `}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        maxLength={maxLength}
      />
      <div className="flex h-1 flex-row pb-3 text-xs text-red-500 ">
        {entityValidation !== 'required' ? errorMessage : !isFocused && isTouched && errorMessage}
      </div>
    </>
  )
}
