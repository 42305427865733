import moment from 'moment'
import List, { LIST_TEST_ID } from '../list'
import ListItem from '../list-item'
import { BreachListProps } from './types'
import { getBreachInfo } from '../../store/anomalies/utils'

export default function BreachList({ items, severityTypes, severityColors }: BreachListProps) {
  return (
    <List className="flex w-full flex-col bg-dark94 bg-opacity-50" data-testid={LIST_TEST_ID}>
      {items.map((item, i) => {
        const { direction, thresholdType, value } = getBreachInfo(
          item.groundTruth,
          item.upperThreshold,
          item.lowerThreshold
        )
        return (
          <ListItem
            className="pointer-events-none flex w-full flex-row items-center pl-0"
            key={`${item.queryId}-${item.applicationId}-${moment(item.eventTime).valueOf()}-${i}`}
          >
            <div
              className={`h-28 w-[0.150rem] ${severityColors[(item.severity as number) || 0]}`}
            />
            <div className="flex w-full flex-col pl-5">
              <div className="flex flex-col gap-2 pb-1">
                <div className="flex items-center gap-2">
                  <span className="font-RakutenSansBold text-white">
                    {severityTypes[Number(item.severity || 0)]}
                  </span>
                  {item.score ? (
                    <>
                      <span className="text-primary">{Math.round(Number(item.score))}%</span>
                      <span className="text-warm">Score</span>
                    </>
                  ) : (
                    ''
                  )}
                </div>

                <span className="text-base opacity-50">
                  {moment(item.eventTime).format('ddd, Do MMMM YYYY [at] HH:mm')}
                </span>
              </div>

              <div className="whitespace-normal text-sm text-white opacity-50">
                <span className="font-RakutenSansBold">{item.metricName}</span> of{' '}
                <span className="font-RakutenSansBold"> {item.applicationName}</span>{' '}
                {value ? (
                  <>
                    is {direction} to{' '}
                    <span className="font-RakutenSansBold">{item.groundTruth}</span> and breached{' '}
                    <span className="font-RakutenSansBold"> {thresholdType} threshold</span> of{' '}
                    <span className="font-RakutenSansBold">{value}</span>
                  </>
                ) : (
                  <>
                    <span className="ml-1">has</span>
                    <span className="ml-1 font-RakutenSansBold">no breaches</span>
                  </>
                )}
              </div>
            </div>
          </ListItem>
        )
      })}
    </List>
  )
}
