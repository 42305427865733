import { PropsWithChildren, RefObject } from 'react'
import { Dialog, Transition } from '@headlessui/react'

interface ITryMeModal extends PropsWithChildren {
  isOpen: boolean
  onClose: () => void
  initialFocus: RefObject<any>
}

export default function TryMeModal({ isOpen, onClose, children, initialFocus }: ITryMeModal) {
  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as="div"
    >
      <Dialog initialFocus={initialFocus} onClose={onClose}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 backdrop-blur-sm" aria-hidden="true" />
        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-hidden">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center">
            {/* The actual dialog panel  */}
            {children}
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
