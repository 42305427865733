export const MetricTypes = {
  load: 'load',
  error: 'error',
  response_time: 'response_time',
}

export const MetricUnitTypes = {
  GAUGE: 'gauge',
  COUNTER: 'counter',
}
