import { Fragment } from 'react'
import { Popover, Tab, Transition } from '@headlessui/react'
import { GraphAbsoluteRangeType, GraphDateRangeProps, RelativeTimeType } from './types'
import moment from 'moment'
import RelativeRangePanel from './relative-range-panel'
import { TimeOutlined } from '../icons'
import { AbsoluteRangePanel } from './absolute-range-panel'
import { COLORS } from '../../../themes/default/colors'

export const GRAPH_DATE_RANGE_TEST_ID = 'GRAPH_DATE_RANGE_TEST_ID'

const nextPastText = new Map([
  [true, ['next', 'from']],
  [false, ['past', 'until']],
])

const getRelativeButtonText = (amount: string, unit: RelativeTimeType, showForecast: boolean) => {
  return (
    <span className="italic text-warm">
      <span className="capitalize">{nextPastText.get(showForecast)?.[0]}</span>
      <span className="px-1.5 font-bold not-italic text-white">
        {amount} {unit}
      </span>
      from
      <span className="px-1.5 font-bold not-italic text-white">
        {showForecast ? 'now' : moment().subtract(amount, unit).format('HH:mm')}
      </span>
      until
      <span className="px-1.5 font-bold not-italic text-white">
        {showForecast ? moment().add(amount, unit).format('HH:mm') : 'now'}
      </span>
    </span>
  )
}

const getAbsoluteButtonText = (value: GraphAbsoluteRangeType, showForecast: boolean) => {
  return (
    <span className="italic text-warm">
      From
      <span className="px-1.5 font-semibold capitalize not-italic text-white">
        {moment(value.fromDate).format('MMM DD, HH:mm')}
      </span>
      to
      <span className="px-1.5 font-semibold capitalize not-italic text-white">
        {moment(value.toDate).format('MMM DD, HH:mm')}
      </span>
    </span>
  )
}

export default function GraphDateRange({
  testId = GRAPH_DATE_RANGE_TEST_ID,
  options,
  infoText,
  rangeType,
  onTabChange,
  relativeValue = '30-minutes',
  onShowForecastChange,
  showForecast,
  onChange,
  fromDate,
  toDate,
  className,
  tabs,
  displayRelativeForecast = true,
  maxAllowedDays,
}: GraphDateRangeProps) {
  const [timeAmount, timeUnit] = relativeValue.split('-')

  const buttonText =
    rangeType === 'relative'
      ? getRelativeButtonText(timeAmount, timeUnit as RelativeTimeType, showForecast)
      : getAbsoluteButtonText({ fromDate, toDate }, showForecast)

  return (
    <Popover as="div" className={`relative w-max ${className}`} data-testid={testId}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex h-8 cursor-pointer flex-row items-center rounded bg-[#3D3F43] p-3 text-xs !text-white hover:border-primary hover:text-opacity-70 focus:outline-none
                ${open ? '!border-primary' : 'text-opacity-70'}`}
          >
            <TimeOutlined className="mr-1 w-4" pathClasses="fill-primary" /> {buttonText}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-2"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`absolute top-2 z-[15] w-screen max-w-xl -translate-x-1/2 transform p-5 text-white ${
                rangeType === 'absolute' ? '-left-4' : '-left-8'
              }`}
            >
              <div className="flex w-full flex-col overflow-hidden rounded-md border border-white p-5 shadow-lg ring-1 ring-black ring-opacity-5 backdrop-blur-2xl">
                <Tab.Group
                  selectedIndex={tabs.findIndex((t) => t === rangeType)}
                  onChange={(index: number) => onTabChange(tabs[index])}
                >
                  <Tab.List className="flex flex-row space-x-10 border-b-2 border-warm_grey pb-2 text-lg">
                    {tabs.map((tab) => (
                      <Tab key={tab} as={Fragment}>
                        {({ selected }) => (
                          <button className={`capitalize ${selected ? 'text-primary' : ''}`}>
                            {tab}
                          </button>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {tabs.includes('relative') && (
                      <RelativeRangePanel
                        showForecast={showForecast}
                        timeAmount={timeAmount}
                        timeUnit={timeUnit}
                        nextPastText={nextPastText}
                        onShowForecastChange={onShowForecastChange}
                        onChange={onChange}
                        infoText={infoText}
                        options={options}
                        relativeValue={relativeValue}
                        displayForecast={displayRelativeForecast}
                      />
                    )}
                    {tabs.includes('absolute') && (
                      <AbsoluteRangePanel
                        fromDate={fromDate}
                        toDate={toDate}
                        onChange={onChange}
                        onApplyClick={() => close()}
                        maxAllowedDays={maxAllowedDays}
                      />
                    )}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
