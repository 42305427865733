import { Switch as HSwitch } from '@headlessui/react'
import { ISwitchProps } from './types'

export const SWITCH_TEST_ID = 'SWITCH_TEST_ID'

export default function Switch({
  testId = SWITCH_TEST_ID,
  checked,
  className,
  circleClassName,
  htmlFor,
  checkedClasses,
  ...props
}: ISwitchProps) {
  return (
    <HSwitch
      data-testid={testId}
      checked={checked}
      className={`${checked ? `bg-primary ${checkedClasses}` : 'bg-dark94'}
      relative inline-flex h-4 w-7 shrink-0 cursor-pointer rounded-full border-[1px] border-primary transition-colors duration-200 ease-in-out ${className}`}
      {...props}
    >
      <span className="sr-only">{htmlFor}</span>
      <span
        aria-hidden="true"
        className={`${checked ? 'translate-x-[13px]' : 'translate-x-[1.5px]'}
      pointer-events-none inline-block h-3 w-3 translate-y-[1px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out ${circleClassName}`}
      />
    </HSwitch>
  )
}
