import {
  ACKNOWLEDGE_ANOMALY_API,
  AGGREGATION_API,
  ANOMALY_LIST_API,
  BREACH_LIST_API,
  CAUSATIONS_GRAPH_API,
  CAUSATION_LIST_API,
  METRIC_API,
} from '../../consts'
import {
  AcknowledgeAnomalyRequestType,
  AcknowledgeAnomalyResponseType,
  CorelationListRequestType,
  CorelationResponseDataType,
  CorelationResponseType,
  GetAnomalylistApiRequestType,
  GetMetricsResponseType,
  PredictionsWithOccurence,
  GroupedAnomaliesType,
  GetAggragationsApiRequestType,
  GetAggragationsApiResponseType,
  CausationListRequestType,
  CausationListResponseDataType,
  CausationResponseType,
  CorelationOrCausationType,
  CausationGraphResponseType,
  CausationGraphRequestType,
  NodesType,
} from './types'
import { splitApi, tagTypes } from '../api'
import { GetMetricsApiRequestType } from '../anomaly-detection'
import { CausalArrayType, DeviationArrayType } from '../../utils'
import { initialPredictions } from './slice'
import {
  getAnomaliesOccurrence,
  transformAggregationData,
  transformAggregationForDeflection,
  transformCausalGraphData,
  transformUnivariateData,
} from '../../utils/chart'
import { transformGetAnomaliesResponse } from './utils'

export const anomaliesApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getAnomalies: build.query<GroupedAnomaliesType[], GetAnomalylistApiRequestType>({
      query: ({ level, id, toDate, fromDate }) =>
        `${ANOMALY_LIST_API}?level=${level}&id=${id}&fromDate=${fromDate}&toDate=${toDate}`,
      transformResponse: transformGetAnomaliesResponse,
      providesTags: (result = []) => [
        ...result.map(({ queryId: id }) => ({ type: 'A', id } as const)),
        { type: tagTypes.ANOMALIES, id: 'LIST' },
      ],
    }),
    getAnomaly: build.query<GroupedAnomaliesType[], GetAnomalylistApiRequestType>({
      query: ({ level, id, toDate, fromDate }) =>
        `${ANOMALY_LIST_API}?level=${level}&id=${id}&fromDate=${fromDate}&toDate=${toDate}`,
      transformResponse: transformGetAnomaliesResponse,
      providesTags: (result = []) => [
        ...result.map(({ queryId: id }) => ({ type: 'A', id } as const)),
        { type: tagTypes.ANOMALIES, id: 'LIST' },
      ],
    }),
    getCorelations: build.query<CorelationOrCausationType[], CorelationListRequestType>({
      query: ({ eventTime, queryId }) =>
        `${BREACH_LIST_API}?level=query&eventTime=${eventTime}&id=${queryId}`,
      transformResponse: (rawResult: CorelationResponseType): CorelationOrCausationType[] =>
        rawResult.data
          ?.filter((p) => !!p.correlatedAnomaly)
          .map((p: CorelationResponseDataType) => ({
            queryId: p.correlatedQueryId,
            applicationId: p.correlatedApplicationId,
            metricName: p.correlatedQueryName,
            applicationName: p.correlatedApplicationName,
            applications: [],
            eventTime: p.correlatedAnomaly?.eventTime,
            severity: p.correlatedAnomaly?.severity,
            score: p.percentage,
            groundTruth: p.correlatedAnomaly?.groundTruth,
            upperThreshold: p.correlatedAnomaly?.upperThreshold,
            lowerThreshold: p.correlatedAnomaly?.lowerThreshold,
            isAnomaly: p.correlatedAnomaly?.isAnomaly,
          })),
      providesTags: (result = []) => [
        ...result.map(({ queryId: id }) => ({ type: tagTypes.CORELATIONS, id } as const)),
        { type: tagTypes.CORELATIONS, id: 'LIST' },
      ],
    }),
    getMetrics: build.query<PredictionsWithOccurence, GetMetricsApiRequestType>({
      query: ({ applicationId, queryId, fromDate, toDate }) =>
        `${METRIC_API}?level=query&id=${queryId}&appId=${applicationId}&fromDate=${fromDate}&toDate=${toDate}`,
      transformResponse: (
        rawResult: GetMetricsResponseType,
        _,
        { fromDate, toDate }
      ): PredictionsWithOccurence => {
        if (rawResult.data.length > 0) {
          const metrics = rawResult.data[0]
          const queryId = metrics.queryId
          const metricType = metrics.metricType
          const predictions = transformUnivariateData(
            metrics.predictions,
            Number(fromDate),
            Number(toDate)
          )
          const occurences = getAnomaliesOccurrence(predictions)
          return { ...predictions, queryId, metricType, ...occurences }
        } else {
          return initialPredictions
        }
      },
      providesTags: (metric) => [
        { type: tagTypes.METRICS, id: metric?.queryId } as const,
        { type: tagTypes.METRICS, id: 'LIST' },
      ],
    }),
    acknowledgeAnomaly: build.mutation<boolean, AcknowledgeAnomalyRequestType>({
      query(body) {
        return {
          url: `${ACKNOWLEDGE_ANOMALY_API}`,
          method: 'POST',
          body,
        }
      },
      transformResponse: (result: AcknowledgeAnomalyResponseType) => !!result,
      invalidatesTags: [{ type: tagTypes.ANOMALIES, id: 'LIST' }],
    }),
    getAggregations: build.query<DeviationArrayType, GetAggragationsApiRequestType>({
      query: ({
        queryId,
        aggregationLevel,
        fromDate,
        toDate,
        includeComparison,
        includeAggregationEntities,
      }) =>
        `${AGGREGATION_API}?queryId=${queryId}&aggregationLevel=${aggregationLevel}&fromDate=${fromDate}&toDate=${toDate}&includeComparison=${includeComparison}&includeDataPoints=${
          includeAggregationEntities === undefined ? '' : includeAggregationEntities
        }`,
      transformResponse: (rawResult: GetAggragationsApiResponseType, _, args): DeviationArrayType =>
        rawResult.data.previous
          ? transformAggregationForDeflection(
              [rawResult.data.current, rawResult.data.previous],
              args.aggregationLevel,
              args.fromDate,
              args.toDate
            )
          : transformAggregationData(
              [rawResult.data.current],
              args.aggregationLevel,
              args.fromDate,
              args.toDate
            ),
      providesTags: () => [{ type: tagTypes.AGGREGATIONS, id: 'LIST' }],
    }),
    getCausations: build.query<CorelationOrCausationType[], CausationListRequestType>({
      query: ({ eventTime, queryId }) =>
        `${CAUSATION_LIST_API}?level=query&eventTime=${eventTime}&id=${queryId}`,
      transformResponse: (rawResult: CausationResponseType): CorelationOrCausationType[] =>
        rawResult.data
          ?.filter((p) => !!p.causingAnomaly)
          .map((p: CausationListResponseDataType) => ({
            queryId: p.causingQueryId,
            applicationId: p.causingApplicationId,
            metricName: p.causingQueryName,
            applicationName: p.causingApplicationName,
            applications: [],
            eventTime: p.eventTime,
            severity: p.causingAnomaly?.severity,
            score: p.percentage,
            groundTruth: p.causingAnomaly?.groundTruth,
            upperThreshold: p.causingAnomaly?.upperThreshold,
            lowerThreshold: p.causingAnomaly?.lowerThreshold,
            isAnomaly: p.causingAnomaly?.isAnomaly,
          })),
      providesTags: (result = []) => [
        ...result.map(({ queryId: id }) => ({ type: tagTypes.CAUSATIONS, id } as const)),
        { type: tagTypes.CAUSATIONS, id: 'LIST' },
      ],
    }),
    getCausationsGraph: build.query<CausalArrayType, CausationGraphRequestType>({
      query: ({ eventTime, sourceId }) =>
        `${CAUSATIONS_GRAPH_API}?eventTime=${eventTime}&sourceId=${sourceId}`,
      transformResponse: (rawResult: CausationGraphResponseType): CausalArrayType =>
        transformCausalGraphData(rawResult),
    }),
  }),
})

export const {
  useGetAnomaliesQuery,
  useLazyGetAnomalyQuery,
  useGetCorelationsQuery,
  useGetMetricsQuery,
  useAcknowledgeAnomalyMutation,
  useGetAggregationsQuery,
  useGetCausationsQuery,
  useGetCausationsGraphQuery,
} = anomaliesApi
