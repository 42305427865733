import { forwardRef, useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert'
import { AlertProps } from './types'
import { Slide } from '@mui/material'

export const ALERT_TEST_ID = 'ALERT_TEST_ID'

const AlertComponent = forwardRef<HTMLDivElement, MuiAlertProps & { testId?: string }>(
  function Alert(props, ref) {
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        {...props}
        data-testid={`${ALERT_TEST_ID}_ALERT`}
      />
    )
  }
)

export default function Alert({
  testId = ALERT_TEST_ID,
  show,
  className,
  message,
  severity,
  onClose,
}: AlertProps) {
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (message !== '') setOpen(true)
    else setOpen(false)
  }, [message])

  return (
    <Snackbar
      data-testid={testId}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={(props) => (
        <Slide data-testid={`${testId}_SLIDE`} {...props} direction="left" />
      )}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <AlertComponent
        onClick={handleClose}
        severity={severity}
        className={className}
        sx={{
          width: '100%',
          '& .MuiAlert-icon .MuiSvgIcon-root': { color: 'white' },
          '& .MuiAlert-action .MuiIconButton-root .MuiSvgIcon-root': { color: 'white' },
        }}
      >
        {message}
      </AlertComponent>
    </Snackbar>
  )
}
