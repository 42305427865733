import { useMemo } from 'react'
import { COLORS } from '../../../themes/default/colors'
import { BasicCard, Button, HelperText, LabelInput } from '../../components'
import { Close } from '../../components/icons'
import { PrometheusIcon } from '../../components/icons/prometheus-icon'
import { validationMessages } from '../../consts'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { changeInputValues, httpInputsSelector } from '../../store/add-application'
import { validateField } from '../../utils'
import { DefineSourceCardProps } from './types'

export const DEFINE_SOURCE_CARD_TEST_ID = 'DEFINE_SOURCE_CARD_TEST_ID'

export default function DefineSourceCard({ onClose, onProceedClick }: DefineSourceCardProps) {
  const dispatch = useAppDispatch()

  const httpInputs = useAppSelector(httpInputsSelector)

  const handleInputChange = (type: string, value: string) => {
    dispatch(changeInputValues({ type, value }))
  }

  const isValidName = useMemo(
    () => validateField(httpInputs.sourceName, ['required', 'entityName']),
    [httpInputs.sourceName]
  )

  return (
    <BasicCard
      testId={DEFINE_SOURCE_CARD_TEST_ID}
      containerClasses="flex relative flex-col w-5/12 px-5 py-5 mobile:w-6/12 mobile:h-[25rem] mobile:overflow-y-scroll tablet_p:w-7/12 tablet_l:w-5/12 laptop:w-5/12 desktop:w-4/12"
    >
      {onClose !== undefined && (
        <button className="absolute right-2.5 top-2.5 text-primary" onClick={onClose}>
          <Close className="w-10 p-2 pt-3 " color={COLORS.PRIMARY} />
        </button>
      )}
      <div className="text-xl font-bold text-white "> Start by adding data source</div>
      <HelperText text="Add a source from where a time series data can be retrieved" />

      <div className="my-5 h-px w-full bg-warm_grey" />
      <div className="mt-2">
        <LabelInput
          testId={`${DEFINE_SOURCE_CARD_TEST_ID}_NAME`}
          required
          error={isValidName !== ''}
          label="Data source name"
          placeholder="a_new_source"
          value={httpInputs.sourceName}
          onChange={(e) => handleInputChange('sourceName', e.target.value)}
          errorMessage={isValidName ? validationMessages.sourceName[isValidName] : ''}
        />
        <div className="mt-5 text-xs text-white">Choose source type</div>
        <div className="tablet_p:h-18 mt-3 flex h-20 flex-row items-center border-2 border-primary mobile:h-20 mobile:w-full tablet_p:w-6/12 tablet_l:w-6/12 laptop:w-5/12 desktop:w-5/12">
          <div className="flex w-2/6 justify-center">
            <PrometheusIcon className="w-10" />
          </div>
          <div className="flex w-4/6 flex-col">
            <div className="mb-2 text-sm italic text-warm_grey">Prometheus</div>
            <div className="mb-2 text-xs text-warm_grey">Time series database</div>
          </div>
        </div>
        <div className="mt-5 flex flex-row">
          <div className="w-2/6"></div>
          <Button
            testId={`${DEFINE_SOURCE_CARD_TEST_ID}_PROCEED`}
            variant="contained"
            size="large"
            className="h-6 w-2/6 sm:h-6 sm:w-3/6 md:h-6 md:w-4/6 lg:h-7 lg:w-3/6 "
            onClick={onProceedClick}
            disabled={isValidName !== ''}
          >
            Proceed
          </Button>
          <div className="w-2/6"></div>
        </div>
      </div>
    </BasicCard>
  )
}
