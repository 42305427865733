import { createSlice } from '@reduxjs/toolkit'
import { RegisterDetailsType, UserIntialState } from './types'
import { dashboardApi } from '../dashboard'
import { authApi } from './services'

export const reducerPath = 'login'

const initialRegisterDetails: RegisterDetailsType = {
  email: '',
  password: '',
  confirmPassword: '',
  orgName: '',
  designation: '',
}

const initialState: UserIntialState = {
  name: '',
  username: '',
  password: '',
  email: '',
  roles: [] as string[],
  tenantName: '',
  userId: '',
  isLoading: false,
  errorMessage: '',
  hasSources: false,
  orgName: '',
  registerDetails: initialRegisterDetails,
}

export const authSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeLoginInputValue: (prevState, { payload }) => {
      return {
        ...prevState,
        [payload.type]: payload.value,
      }
    },
    changeRegisterValue: (prevState, { payload }) => {
      return {
        ...prevState,
        registerDetails: {
          ...prevState.registerDetails,
          [payload.type]: payload.value,
        },
      }
    },
    resetRegisterValue: (prevState) => {
      return {
        ...prevState,
        registerDetails: initialRegisterDetails,
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.roles = payload.roles
        state.userId = payload.id
        state.email = payload.email
        state.isLoading = false
        state.tenantName = payload.tenantName
        state.orgName = payload.orgName
        state.name = payload.username
      })

      .addMatcher(
        dashboardApi.endpoints.getSourcesByTenantId.matchFulfilled,
        (state, { payload }) => {
          state.hasSources = payload.length > 0
        }
      )
  },
})

export const { changeLoginInputValue, changeRegisterValue, resetRegisterValue } = authSlice.actions
