import { GET_ALL_ALERTS, TEST_ALERT_NOTIFICATION } from '../../consts'
import { splitApi } from '../api'
import {
  AddAlertRequestType,
  AddAlertResponseType,
  EditAlertRequestType,
  EditAlertResponseType,
  TestAlertNotificationRequestType,
  TestAlertNotificationResponseType,
} from './types'

export const alertRulesApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    addAlert: build.mutation<AddAlertResponseType, AddAlertRequestType>({
      query(body) {
        return {
          url: `${GET_ALL_ALERTS}`,
          method: 'POST',
          body: body,
        }
      },
      transformResponse: (result: AddAlertResponseType) => result,
    }),
    testAlertNotification: build.mutation<
      TestAlertNotificationResponseType,
      TestAlertNotificationRequestType
    >({
      query(body) {
        return {
          url: `${TEST_ALERT_NOTIFICATION}`,
          method: 'POST',
          body: body,
        }
      },
      transformResponse: (result: TestAlertNotificationResponseType) => result,
    }),
    editAlert: build.mutation<EditAlertResponseType, EditAlertRequestType>({
      query(body) {
        return {
          url: `${GET_ALL_ALERTS}`,
          method: 'PUT',
          body: body,
        }
      },
      transformResponse: (result: EditAlertResponseType) => result,
    }),
  }),
})

export const { useAddAlertMutation, useTestAlertNotificationMutation, useEditAlertMutation } =
  alertRulesApi
