import { useEffect } from 'react'
import { BasicModal } from '../../../../components'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import AddEditApplicationCard from '../../../../partials/add-edit-application-card'
import {
  applicationSelector,
  changeApplicationInputValues,
  changeCurrentQuery,
  changeUnitOfQuery,
  deleteQuery,
  getQueries,
  validateQueryOfJobMetric,
  useGetSourceLabelsQuery,
  useGetSourceMetricValuesQuery,
  useEditPrometheusApplicationMutation,
} from '../../../../store/application'
import { setToastMessage } from '../../../../store/alert'
import { setShowEditApplicationModal } from '../../../../store/anomaly-detection'
import { mapQueriesForBody } from '../../../../store/application/utils'
import { resetAppQueries } from '../../../../store/application/slice'

interface EditSourceModalProps {
  show: boolean
  onClose: () => void
  onSaveSuccess?: () => void
}

export default function EditApplicationModal({
  show,
  onClose,
  onSaveSuccess = () => null,
}: EditSourceModalProps) {
  const dispatch = useAppDispatch()
  const application = useAppSelector(applicationSelector)
  const { data: sourcelabelOptions = [] } = useGetSourceLabelsQuery({
    sourceId: application.sourceId,
  })

  const { data: sourceValueOptions = [] } = useGetSourceMetricValuesQuery(
    {
      sourceId: application.sourceId,
      label: application.selectedJob.value,
    },
    { skip: !application.selectedJob.value }
  )

  const [editApplication, { isLoading: isSubmitting }] = useEditPrometheusApplicationMutation()

  useEffect(() => {
    if (application.applicationId) {
      dispatch(getQueries(application.applicationId))
    }
    return () => {}
  }, [application.applicationId])

  useEffect(() => {
    dispatch(
      changeCurrentQuery(
        `${application.selectedMetric.value}{job='${application.selectedJob.value}'}`
      )
    )
    return () => {}
  }, [application.selectedJob.value, application.selectedMetric.value, dispatch])

  const handleInputChange = (type: string, value: string | number | boolean) => {
    dispatch(changeApplicationInputValues({ type, value }))
  }

  const handleValidateQuery = () => {
    dispatch(
      validateQueryOfJobMetric({
        sourceId: application.sourceId,
        query: application.currentQuery,
        queryIndex: application.queries.length,
        metricType: application.metricType,
      })
    )
  }

  const handleDeleteQuery = (id: string) => {
    dispatch(deleteQuery(id))
  }

  const handleSave = () => {
    editApplication({
      id: application.applicationId,
      applicationName: application.applicationName,
      queries: mapQueriesForBody(application.queries),
    }).then((res: any) => {
      if (res.data) {
        if (res?.data?.status === 'SUCCESS') {
          onSaveSuccess()
          dispatch(setToastMessage({ severity: 'success', message: res.data.message }))
          dispatch(setShowEditApplicationModal(false))
          dispatch(resetAppQueries())
        } else {
          dispatch(setToastMessage({ severity: 'error', message: res.data.message }))
        }
      }
    })
  }

  const handleQueryUnitChange = (id: string, unit: string) => {
    dispatch(changeUnitOfQuery({ id, unit }))
  }

  return (
    <BasicModal isOpen={show}>
      <AddEditApplicationCard
        {...application}
        onSave={handleSave}
        onCancel={onClose}
        onAddQuery={handleValidateQuery}
        onChange={handleInputChange}
        onDeleteQuery={handleDeleteQuery}
        jobs={sourcelabelOptions}
        metrics={sourceValueOptions}
        onQueryUnitChange={handleQueryUnitChange}
        isLoading={isSubmitting}
      />
    </BasicModal>
  )
}
