import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  addPrometheusApplication,
  addPrometheusSource,
  validateQuery,
} from './services'
import { AddApplicationStateType } from './types'


export const validateQueryOfJobMetric = createAsyncThunk(
  'addApplication/validateQueryOfJobMetric',
  async ({
    sourceId,
    query,
    queryIndex,
    metricType,
  }: {
    sourceId: string
    query: string
    queryIndex: number
    metricType: string
  }) => {
    const response = await validateQuery(sourceId, query)
    return { value: query, queryIndex, metricType, ...response }
  }
)

export const addSource = createAsyncThunk(
  'addApplication/addSource',
  async (params: AddApplicationStateType) => {
    const response = await addPrometheusSource(params)
    return response
  }
)

export const addApplication = createAsyncThunk(
  'addApplication/addApplication',
  async (params: AddApplicationStateType) => {
    const response = await addPrometheusApplication(params)
    return response
  }
)
