import { COLORS } from './colors'

const MuiDataGrid = {
  styleOverrides: {
    root: {
      backgroundColor: COLORS.DARK,
      '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
        visibility: 'visible',
        width: 'auto',
      },
      '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
        opacity: 0.5,
      },
      '.MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '&.MuiDataGrid-root': {
        backgroundColor: '#292929',
        color: '#BFBFBF',
      },
      '.MuiDataGrid-cell--textLeft': {
        paddingLeft: '25px',
      },
      '.MuiDataGrid-columnHeaderTitleContainer': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      '.css-t89xny-MuiDataGrid-columnHeaderTitle': {
        fontWeight: '900',
      },
      '.MuiDataGrid-withBorderColor': {
        borderColor: '#595A59 !important',
      },
      '&.MuiDataGrid-withBorderColor': {
        border: 'none',
      },
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none !important ',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
      },
      '.MuiDataGrid-footerContainer': {
        display: 'none',
      },
      '&.MuiDataGrid-cell': {
        minHeight: '30px !important',
      },
    },
    row: {
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
}

export default MuiDataGrid
