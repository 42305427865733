import { Tab } from '@headlessui/react'
import Switch from '../switch'
import { RelativeRangePanelProps } from './types'

export default function RelativeRangePanel({
  showForecast,
  onShowForecastChange,
  options,
  onChange,
  relativeValue,
  infoText,
  nextPastText,
  timeAmount,
  timeUnit,
  displayForecast = true,
}: RelativeRangePanelProps) {
  return (
    <Tab.Panel className="flex w-full flex-col">
      {displayForecast && (
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <span className="mr-2">Show forecast</span>
            <Switch checked={showForecast} onChange={() => onShowForecastChange(!showForecast)} />
          </div>
          <div className="flex">
            Showing{' '}
            <span className="px-1 text-primary">
              {nextPastText.get(showForecast)?.[0]} {timeAmount} {timeUnit}
            </span>
            {nextPastText.get(showForecast)?.[1]} now
          </div>
        </div>
      )}
      {Object.keys(options).map((optionKey) => (
        <div key={optionKey} className="my-2 flex w-full flex-row items-center px-5">
          <div
            className={`w-24 text-lg ${
              !options[optionKey].forecastAvailable && showForecast ? 'text-warm_grey' : ''
            }`}
          >
            {optionKey}
          </div>
          <div className="flex h-10 w-full flex-grow items-center justify-around rounded-md border border-warm_grey">
            {options[optionKey].options.map((opt) =>
              opt?.disabled ? (
                <div className="text-warm_grey" key={opt.value}>
                  {opt.label}
                </div>
              ) : (
                <div
                  onClick={() =>
                    showForecast
                      ? options[optionKey].forecastAvailable && onChange?.(opt.value as string)
                      : onChange?.(opt.value as string)
                  }
                  key={opt.value}
                  className={`flex hover:cursor-pointer ${
                    relativeValue === opt.value ? 'text-primary' : 'text-white'
                  } ${
                    !options[optionKey].forecastAvailable && showForecast ? 'text-warm_grey' : ''
                  }`}
                >
                  {opt.label}
                </div>
              )
            )}
          </div>
        </div>
      ))}
      <div className="mt-2 px-5 text-warm_grey">{infoText}</div>
    </Tab.Panel>
  )
}
