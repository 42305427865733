import { generatePath } from 'react-router-dom'
import { ANOMALIES_ROUTES, DASHBOARD_ROUTES, SOURCES_ROUTES } from './route-names'

type Params = { [key: string]: string }

export const appPaths = {
  editPage: (params?: Params) =>
    generatePath(`/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.EDIT_SOURCE}`, params),

  searchSpace: (params?: Params) =>
    generatePath(`/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SEARCH_SPACE}`, params),

  multivariatePage: (params: Params) =>
    generatePath(
      `/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCE}/${SOURCES_ROUTES.VIEW_MULTIVARIATE}`,
      params
    ),
  anomaliesPage: (params?: Params) =>
    generatePath(
      `/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCE}/${SOURCES_ROUTES.ANOMALIES}`,
      params
    ),
  anomaliesViewPage: (params?: Params) =>
    generatePath(
      `/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCE}/${SOURCES_ROUTES.ANOMALIES_VIEW}`,
      params
    ),
  anomaliesBreachesTab: (params?: Params) =>
    generatePath(
      `/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCE}/${SOURCES_ROUTES.ANOMALIES_VIEW}/${ANOMALIES_ROUTES.BREACHES}`,
      params
    ),
  anomaliesDefDevTab: (params?: Params) =>
    generatePath(
      `/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCE}/${SOURCES_ROUTES.ANOMALIES_VIEW}/${ANOMALIES_ROUTES.DEF_DEV}`,
      params
    ),
  behaviourPage: (params: Params) =>
    generatePath(
      `/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCES}/${SOURCES_ROUTES.BEHAVIOUR}`,
      params
    ),
  appBehaviourPage: (params: Params) =>
    generatePath(
      `/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCE}/${SOURCES_ROUTES.APP_BEHAVIOUR}`,
      params
    ),
  multivariateBehaviourPage: (params: Params) =>
    generatePath(
      `/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCE}/${SOURCES_ROUTES.MULTIVARIATE_BEHAVIOUR}`,
      params
    ),
}
