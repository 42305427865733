export default function ServerErrorPage() {
  return (
    <div
      id="error-page"
      className="flex h-screen w-full flex-col items-center justify-center bg-primary text-white"
    >
      <h2 className="mb-2 text-[50px] font-bold leading-none sm:text-[80px] md:text-[100px]">
        500
      </h2>
      <h4 className="mb-3 text-[22px] font-semibold leading-tight">
        Sorry, an unexpected error has occurred.
      </h4>
      <p className="mb-8 text-lg">Internal Server Error</p>
    </div>
  )
}
