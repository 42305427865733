import BasicCard from '../basic-card'
import { TinyLineChart } from '../charts'
import { ChangeTypeColor, KpiCardProps } from './types'
import { numberFormatter } from '../../utils'
import { ArrowRight, Caret, ChevronDown, ChevronRight } from '../icons'
import { COLORS } from '../../../themes/default/colors'

export const KPI_CARD_TEST_ID = 'KPI_CARD_TEST_ID'

const changeTypeColor: ChangeTypeColor = {
  up: {
    graphColor: '#8fce00',
    textColor: 'text-green-500',
    arrowType: <Caret className="!w-4 self-center fill-green-500" />,
  },
  down: {
    graphColor: '#ED213A',
    textColor: 'text-red-500',
    arrowType: <Caret className="!w-4 self-center fill-red-500" transform="rotate(180)" />,
  },
  no_change: { graphColor: '#999999', textColor: '', arrowType: null },
}

export default function Kpicard({
  testId = KPI_CARD_TEST_ID,
  total,
  unit,
  title,
  percentage,
  changeType,
  chartData,
  backgroundColor,
  onClick,
}: KpiCardProps) {
  const changeTypeKey = Object.keys(changeTypeColor).includes(changeType as string)
    ? changeType
    : 'no_change'
  const change = changeTypeColor[changeTypeKey as string]
  return (
    <BasicCard
      testId={testId}
      contentClasses="flex w-full h-full flex-col !p-3 !pl-10 mobile:!pl-4 tablet_p:!pl-5 tablet_l:!pl-4 laptop:!pl-5 desktop:!pl-8"
      containerClasses={`group flex flex-row h-28 w-[22vw] rounded-lg ${
        onClick ? 'hover:bg-woodsmoke cursor-pointer' : ''
      }`}
      onClick={onClick}
    >
      <div className="flex w-full flex-row justify-between gap-1 text-sm text-white">
        {title}
        {onClick ? (
          <ChevronRight
            className="w-6 transform transition-transform duration-200 group-hover:translate-x-1"
            color={COLORS.PRIMARY}
          />
        ) : null}
      </div>
      <div className="laptop:h-22 flex h-28 w-full justify-between mobile:h-16 tablet_p:h-16 tablet_l:h-12 desktop:h-24">
        <div className="z-10 flex w-4/12 items-center gap-2 text-3xl font-bold text-white mobile:text-2xl tablet_p:text-3xl tablet_l:text-3xl laptop:text-3xl desktop:text-3xl">
          <div className="flex flex-row">
            {total}
            {unit}
          </div>

          <div className="mt-2 flex h-5 flex-row items-end whitespace-nowrap">
            {change.arrowType}
            <span className={`ml-1 text-sm font-semibold laptop:text-sm ${change.textColor}`}>
              {changeType !== null ? `${numberFormatter(Number(percentage), 2)} %` : '--'}
            </span>
          </div>
        </div>

        {/* <div className="flex w-6/12 items-center overflow-hidden">
          <TinyLineChart
            chartData={chartData}
            color={change.graphColor}
            backgroundColor={backgroundColor}
          />
        </div> */}
      </div>

      <span className="text-xs text-warm_grey">Compared to last week</span>
    </BasicCard>
  )
}
