import { IconProps } from './types'
import { COLORS } from '../../../themes/default/colors'

export const CHEVRON_DOWN_TEST_ID = 'CHEVRON_DOWN_TEST_ID'

export const ChevronDown = ({
  testId = CHEVRON_DOWN_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  onClick,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="m29 10.21-1.41-1.42L16 20.38 4.41 8.79 3 10.21l13 13 13-13z" />
    </svg>
  </div>
)
