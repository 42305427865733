import { RootState } from '..'
import { initialSelectedBreach, reducerPath } from './slice'
import { DefdevAnomalyType, MetricAnomalyType } from './types'

export const selectedBreachStateSelector =
  (sourceId: string) =>
  (state: RootState): MetricAnomalyType & { queryId: string; applications: string[] } =>
    state[reducerPath].selectedBreach.metric[sourceId] || initialSelectedBreach.metric

export const selectedDefDevStateSelector =
  (sourceId: string) =>
  (state: RootState): DefdevAnomalyType & { queryId: string } =>
    state[reducerPath].selectedBreach.defdev[sourceId] || initialSelectedBreach.defdev

export const anomaliesDateRangeSelector = (state: RootState) => state[reducerPath].dateRange
