import { Card } from '../../../../components'

interface DashboardCardLoaderProps {
  numberOfItems: number,
  heading : string
}

export default function DashboardCardLoader({ numberOfItems, heading }: DashboardCardLoaderProps) {
  const renderDashboardBars = () => {
    const randomHeight = () => `${Math.floor(Math.random() * 50) + 50}px` // Random height between 50px and 100px

    return (
      <div className="flex justify-center h-72">
        {[...Array(numberOfItems)].map((_, index) => (
          <div key={index} className="mx-1 flex items-end h-40">
            <div
              className="animate-pulse bg-gradient-to-b from-gray-700 to-gray-400 "
              style={{
                height: randomHeight(),
                width: '1.25rem',
                borderRadius: '3px',
                marginBottom: '4px',
                alignSelf: 'flex-end',
              }}
            ></div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Card className="relative flex flex-col justify-center rounded-md !w-1/3 !p-6">
      {heading && <h2 className="text-white">{heading}</h2>}
      {renderDashboardBars()}
    </Card>
  )
}
