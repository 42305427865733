import { SearchIcon } from '../icons'
import { SearchInputProps } from './types'

export const SEARCH_BAR_TEST_ID = 'SEARCH_BAR_TEST_ID'

export default function SearchInput({
  testId = SEARCH_BAR_TEST_ID,
  inputPlaceholder = 'Search',
  inputClassName,
  value,
  onChange,
  required,
  iconColor,
}: SearchInputProps) {
  return (
    <form data-testid={testId} className="flex items-center">
      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
          <SearchIcon className="h-9 w-9" color={iconColor} />
        </div>
        <input
          type="text"
          className={`block rounded-sm bg-dark p-2.5 pl-10 text-sm text-white focus:outline-none dark:text-white dark:placeholder-gray-400 ${inputClassName}`}
          placeholder={inputPlaceholder}
          required={required}
          value={value}
          onChange={onChange}
          maxLength={50}
        />
      </div>
    </form>
  )
}
