import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { MenuDropdownProps } from './types'
import Tooltip from '../tooltip'
import { Close } from '../icons'
import { ChevronUp } from '../icons'
import { COLORS } from '../../../themes/default/colors'
import Button from '../button'

export const MENU_DROPDOWN_TEST_ID = 'MENU_DROPDOWN_TEST_ID'

export default function MenuDropdown({
  testId = MENU_DROPDOWN_TEST_ID,
  menuText,
  menuItems,
  itemsClasses,
  menuTextClasses,
  transitionClasses = {
    enter: '',
    enterFrom: '',
    enterTo: '',
    leave: '',
    leaveFrom: '',
    leaveTo: '',
  },
}: MenuDropdownProps) {
  return (
    <Menu as="div" className="relative inline-block text-left" data-testid={testId}>
      {({ open }) => (
        <>
          <Menu.Button className={`flex justify-center ${menuTextClasses}`}>
            {menuText(open)}
            {menuItems[0].closeItem && (
              <ChevronUp
                color={COLORS.PRIMARY}
                className={`${open ? '' : 'rotate-180 transform'} ml-1 mt-1 w-3`}
                aria-hidden="true"
              />
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter={`transition duration-100 ease-out ${transitionClasses.enter}`}
            enterFrom={`transform scale-95 opacity-0 ${transitionClasses.enterFrom}`}
            enterTo={`transform scale-100 opacity-100 ${transitionClasses.enterTo}`}
            leave={`transition duration-75 ease-out ${transitionClasses.leave}`}
            leaveFrom={`transform scale-100 opacity-100 ${transitionClasses.leaveFrom}`}
            leaveTo={`transform scale-95 opacity-0 ${transitionClasses.leaveTo}`}
          >
            <Menu.Items
              className={`absolute right-0 z-20 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-sm bg-secondary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${itemsClasses}`}
            >
              <div className="p-1">
                {menuItems?.map((item, index) => (
                  <Menu.Item key={item.label as string}>
                    {typeof item.label === 'string' ? (
                      item.label.length > 20 ? (
                        <div className="flex flex-col">
                          <Tooltip placement="bottom" title={item.label}>
                            <div className="flex flex-row items-center justify-center py-2">
                              <Button
                                className="group block w-full items-center truncate rounded-md px-2 py-2 text-left text-sm text-white "
                                onClick={item.action}
                              >
                                {item.label}
                              </Button>
                              {item.closeItem && (
                                <Close
                                  className="mr-1 mt-1 w-4 py-2"
                                  pathClasses="fill-primary"
                                  onClick={item.closeItem}
                                />
                              )}
                            </div>
                          </Tooltip>
                          {menuItems.length - 1 !== index && (
                            <div className="h-[0.5px] w-full bg-white !p-0"></div>
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-col">
                          <div className="flex flex-row ">
                            <button
                              className="group block w-full items-center rounded-md px-2 py-2 text-left text-sm text-white"
                              onClick={item.action}
                            >
                              {item.label}
                            </button>
                            {item.closeItem && (
                              <Close
                                className="mr-1 mt-1 w-4 py-2"
                                pathClasses="fill-primary"
                                onClick={item.closeItem}
                              />
                            )}
                          </div>
                          {item.closeItem && menuItems.length - 1 !== index && (
                            <div className="h-[0.5px] w-full bg-white !p-0"></div>
                          )}
                        </div>
                      )
                    ) : (
                      item.label
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
