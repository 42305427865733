import {
  APPLICATION_API,
  FEEDBACK_API,
  COMPARE_API,
  METRIC_API,
  AGGREGATION_API,
} from '../../consts'
import {
  AggregationArrayType,
  ComparisonArrayType,
  PredictionArrayType,
  transformComparisonData,
  transformMetricAverageData,
  transformUnivariateData,
} from '../../utils'
import {
  GetAggragationsApiRequestType,
  GetAggragationsApiResponseType,
  GetMetricsResponseType,
  initialPredictions,
} from '../anomalies'
import { splitApi, tagTypes } from '../api'
import {
  ApplicationType,
  GetApplicationsRequestType,
  GetApplicationsResponseType,
  GetComparisonApiRequestType,
  GetComparisonApiResponseType,
  GetMetricsApiRequestType,
  SubmitFeedbackRequestType,
} from './types'

export const anomalyDetectionApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getForcast: build.query<PredictionArrayType, GetMetricsApiRequestType>({
      query: ({ applicationId, queryId, fromDate, toDate }) =>
        `${METRIC_API}?level=query&id=${queryId}&appId=${applicationId}&fromDate=${fromDate}&toDate=${toDate}`,
      transformResponse: (
        rawResult: GetMetricsResponseType,
        _,
        { fromDate, toDate }
      ): PredictionArrayType => {
        if (rawResult.data.length > 0) {
          return transformUnivariateData(
            rawResult.data[0].predictions,
            fromDate as number,
            toDate as number
          )
        } else {
          return initialPredictions
        }
      },
      providesTags: () => [{ type: tagTypes.FORECASTS, id: 'LIST' }],
    }),
    getComparisons: build.query<ComparisonArrayType, GetComparisonApiRequestType>({
      query: ({ queryId, aggregationLevel, number, entity }) =>
        `${COMPARE_API}?queryId=${queryId}&aggregationLevel=${aggregationLevel}&number=${number}&include=${entity}`,
      transformResponse: (rawResult: GetComparisonApiResponseType, _, args): ComparisonArrayType =>
        transformComparisonData(rawResult.data, args.number, args.aggregationLevel, args.entity),
      providesTags: () => [{ type: tagTypes.COMPARISONS, id: 'LIST' }],
    }),
    submitFeedback: build.mutation<boolean, SubmitFeedbackRequestType>({
      query({ queryId, eventTime, feedback }) {
        return {
          url: FEEDBACK_API,
          method: 'POST',
          body: { queryId, feedbackList: [{ eventTime, feedback }] },
        }
      },
      async onQueryStarted(
        { eventTime, feedback, feedbacks, feedbackColors, ...metricRequest },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          anomalyDetectionApi.util.updateQueryData('getForcast', metricRequest, (draft) => {
            Object.assign(draft, { feedbacks, feedbackColors })
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      transformResponse: (): boolean => true,
    }),
    getApplications: build.query<ApplicationType[], GetApplicationsRequestType>({
      query: ({ sourceId }) => `${APPLICATION_API}?sourceId=${sourceId}`,
      transformResponse: (applicationResponse: GetApplicationsResponseType): ApplicationType[] =>
        applicationResponse.applications
          ?.map((applications) => ({
            id: applications.id,
            sourceId: applications.sourceId,
            appName: applications.appName,
            appHealth: applications.appHealth,
            resName: applications.resName,
            resType: applications.resType,
            includeHistory: applications.includeHistory,
            historyDays: applications.historyDays,
            anomalyCount: applications.anomalyCount,
            updateDuration: applications.updateDuration,
          }))
          .sort(function (a: ApplicationType, b: ApplicationType) {
            let labelA = a.appName.toLowerCase()
            let labelB = b.appName.toLowerCase()
            return labelA.localeCompare(labelB, 'en', { numeric: true })
          }),
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: tagTypes.APPLICATIONS, id } as const)),
        { type: tagTypes.APPLICATIONS, id: 'LIST' },
      ],
    }),
    getMetricsAverage: build.query<AggregationArrayType, GetAggragationsApiRequestType>({
      query: ({
        queryId,
        aggregationLevel,
        fromDate,
        toDate,
        includeComparison,
        includeAggregationEntities,
      }) =>
        `${AGGREGATION_API}?queryId=${queryId}&aggregationLevel=${aggregationLevel}&fromDate=${fromDate}&toDate=${toDate}&includeComparison=${includeComparison}&includeDataPoints=${includeAggregationEntities}`,
      transformResponse: (
        rawResult: GetAggragationsApiResponseType,
        _,
        args
      ): AggregationArrayType =>
        transformMetricAverageData(rawResult.data.current, args.fromDate, args.toDate),
      providesTags: () => [{ type: tagTypes.AGGREGATIONS, id: 'LIST' }],
    }),
  }),
})

export const {
  useLazyGetForcastQuery,
  useLazyGetComparisonsQuery,
  useSubmitFeedbackMutation,
  useGetApplicationsQuery,
  useGetMetricsAverageQuery,
} = anomalyDetectionApi
