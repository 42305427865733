import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  // addPrometheusSource,
  updatePrometheusSource,
} from './services'
import { SourceStateType } from './types'

// export const addSource = createAsyncThunk('source/add', async (params: SourceStateType) => {
//   const response = await addPrometheusSource(params)
//   return response
// })

export const updateSource = createAsyncThunk('source/update', async (params: SourceStateType) => {
  const response = await updatePrometheusSource(params)
  return response
})
