import { APPLICATION_API, SOURCE_API, VALIDATE_QUERY } from '../../consts'
import { removeTrailingSlash } from '../../utils'
import axios from '../../utils/axios'
import { AddApplicationStateType } from './types'
import { mapHeaderAsKeyValue, mapQueriesForBody } from './utils'

export const validateQuery = (sourceId: string, query: string) => {
  return axios.post(VALIDATE_QUERY, { sourceId, query }).then((res) => res.data)
}

export const addPrometheusSource = (params: AddApplicationStateType) => {
  return axios
    .post(SOURCE_API, {
      sourceName: params.sourceName,
      sourceType: params.sourceType,
      url: removeTrailingSlash(params.httpUrl),
      httpMethod: params.httpMethod,
      basicAuth: params.basicAuth,
      basicAuthId: params.basicAuthId,
      basicAuthPassword: params.basicAuthPassword,
      tlsClientAuth: params.tlsClientAuth,
      withCaCert: params.withCaCert,
      headers: mapHeaderAsKeyValue(params.headers),
      queryInterval: params.queryInterval,
      scrapeSteps: params.scrapeSteps,
      queryTimeout: params.queryTimeout,
    })
    .then((res) => res.data)
}

export const addPrometheusApplication = (params: AddApplicationStateType) => {
  return axios
    .post(APPLICATION_API, {
      sourceId: params.sourceId,
      applicationName: params.applicationName,
      includeHistory: params.includeHistory,
      historyDays: params.historyDays,
      queries: mapQueriesForBody(params.queries),
    })
    .then((res) => res.data)
}
