import { COLORS } from '../default/colors'

export const antPagination = {
  colorPrimary: COLORS.PRIMARY,
  colorBgContainer: COLORS.DARK,
  borderRadius: 2,
  colorText: COLORS.WHITE,
  colorPrimaryHover: COLORS.PRIMARY,
  colorTextDisabled: COLORS.WHITE,
}
