import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import moment from 'moment'

import {
  BasicModal,
  SourceCard,
  Toast,
  KpiCard,
  Select,
  SearchInput,
  Tooltip,
  Breadcrumb,
} from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  SourceType,
  changeCurrentSource,
  dashboardStateSelector,
  changeSortType,
  changeSortBy,
  ModalTypes,
  TrendType,
  setCurrentModal,
  useGetSourcesByTenantIdQuery,
  useGetTrendsQuery,
  initialTrendsState,
  dashboardApi,
} from '../../../store/dashboard'
import { changeCurrentSourceId, setGraphFirstTimeLoading } from '../../../store/anomaly-detection'
import DefineSourceCard from '../../define-source/define-source-card'
import { COLORS } from '../../../../themes/default/colors'
import { sortingOptions } from './consts'
import { sortArrayByKeyIteratee } from '../../../utils'
import AddSourceCard from '../../add-source/add-source-card'
import AddApplicationCard from '../../add-application/add-application-card'
import { makeTrendobject } from '../../../utils/array'
import { resetState } from '../../../store/add-application'
import EditSourceModal from './edit-source-modal'
import { setApplicationSource } from '../../../store/application'
import { DASHBOARD_ROUTES, SOURCES_ROUTES, appPaths } from '../../../routes'
import SourceCardLoader from './loaders/source-card'
import KpiCardLoader from './loaders/kpi-card'
import { ChangeOrder, PlusFilled } from '../../../components/icons'
import { tagTypes } from '../../../store/api'
import { POLLING_INTERVAL, pageTransitionAnimation } from '../../../consts'
import { SourceTabType, useSourceTabsContext } from '../../../providers'
import { setSourceValues, useLazyGetSourceConnectionQuery } from '../../../store/source'

export const ALL_SOURCES_TEST_ID = 'ALL_SOURCES_TEST_ID'

export default function AllSources() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { trendFromDate, trendToDate, sortBy, sortType, currentSource, currentModal } =
    useAppSelector(dashboardStateSelector)
  const { sourceTabs, addSourceTab, setSourceTabValues } = useSourceTabsContext()
  const [searchValue, setSearchValue] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [getConnection] = useLazyGetSourceConnectionQuery()

  const { data: sources = [], isLoading: isSourceCardLoading } = useGetSourcesByTenantIdQuery(
    {},
    { pollingInterval: POLLING_INTERVAL }
  )
  const {
    data: { sourceTrend, applicationTrend, anomalyTrend, metricTrend } = initialTrendsState,
    isLoading: isKpiCardLoading,
  } = useGetTrendsQuery(
    {
      fromDate: trendFromDate,
      toDate: trendToDate,
    },
    { pollingInterval: POLLING_INTERVAL }
  )

  const sortedSources = useMemo(
    () => [...sources].sort(sortArrayByKeyIteratee(sortBy, sortType)),
    [sortBy, sources, sortType]
  )
  const breadcrumbs = [
    { routeName: 'Home', onClick: () => navigate(`/${DASHBOARD_ROUTES.HOME}`) },
    { routeName: 'All sources', onClick: () => null },
  ]

  useEffect(() => {
    if (!isSourceCardLoading) {
      if (sources && sources.length === 0) {
        handleAddSourceClicked()
      }
    }
  }, [sources, isSourceCardLoading])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value)

  const onSourceCardClick = (source: SourceType) => {
    if (source.applicationCount > 0) {
      dispatch(changeCurrentSource(source))
      dispatch(changeCurrentSourceId(source.id))
      dispatch(
        setApplicationSource({
          sourceId: source.id,
          sourceName: source.sourceName,
          sourceUrl: source.url,
          sourceType: source.sourceType,
        })
      )
      dispatch(setGraphFirstTimeLoading(true))
      const sourceTab = sourceTabs.find((s: SourceTabType) => s.id === source.id)
      if (!sourceTab) {
        addSourceTab({
          id: source.id,
          name: source.sourceName,
          prevAnomalyPath: `/dashboard/${DASHBOARD_ROUTES.SOURCES}/${source.id}/${SOURCES_ROUTES.ANOMALIES}`,
          prevBehaviourPath: `/dashboard/${DASHBOARD_ROUTES.SOURCES}/${source.id}/${SOURCES_ROUTES.BEHAVIOUR}`,
          prevActiveTab: SOURCES_ROUTES.ANOMALIES,
          details: source,
        })

        navigate(`/dashboard/${DASHBOARD_ROUTES.SOURCES}/${source.id}/${SOURCES_ROUTES.ANOMALIES}`)
      } else {
        navigate(
          sourceTab.prevActiveTab === SOURCES_ROUTES.ANOMALIES
            ? sourceTab.prevAnomalyPath
            : sourceTab.prevBehaviourPath
        )
      }
    } else {
      setShowToast(true)
      setTimeout(() => {
        setShowToast(false)
      }, 1000)
    }
  }

  const onDefinedSourceProceedClick = () => {
    dispatch(setCurrentModal(ModalTypes.ADD_SOURCE))
  }

  const onAddSourceAction = () => {
    dispatch(setCurrentModal(ModalTypes.ADD_APPLICATION))
  }

  const handleSourceCardPlusClick = (source: SourceType) => {
    dispatch(changeCurrentSource(source))
    dispatch(changeCurrentSourceId(source.id))
    dispatch(setCurrentModal(ModalTypes.ADD_APPLICATION))
  }

  const onEditSourceClick = (source: SourceType) => {
    dispatch(setSourceValues(source))
    getConnection(source.id)
    dispatch(setCurrentModal(ModalTypes.EDIT_SOURCE))
  }

  const onCreateSearchSpaceClick = (source: SourceType) => {
    const path = appPaths.searchSpace({
      sourceId: source.id,
    })
    addSourceTab({
      id: source.id,
      name: source.sourceName,
      prevAnomalyPath: path,
      prevBehaviourPath: path,
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      details: { ...source, sourceId: source.id, headers: [], queryTimeout: 0, queryInterval: 0 },
    })
    navigate(path)
  }

  const closeModal = () => {
    dispatch(resetState())
    dispatch(setCurrentModal(null))
    dispatch(dashboardApi.util.invalidateTags([tagTypes.SOURCES]))
  }

  const handleAddSourceClicked = () => {
    addSourceTab({
      id: 'add',
      name: 'New source',
      prevAnomalyPath: `/dashboard/${DASHBOARD_ROUTES.ADD_SOURCE}`,
      prevBehaviourPath: `/dashboard/${DASHBOARD_ROUTES.ADD_SOURCE}`,
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      details: {},
    })
    dispatch(resetState())

    navigate(`/dashboard/${DASHBOARD_ROUTES.ADD_SOURCE}`)
  }

  return (
    <motion.div
      data-testid={ALL_SOURCES_TEST_ID}
      className="flex h-[93vh] w-full flex-col overflow-x-hidden overflow-y-scroll px-8"
      {...pageTransitionAnimation}
    >
      <Breadcrumb items={breadcrumbs} containerClasses="py-4" />
      <div className="h-[0.5px] w-full bg-warm600" />
      {/* <Form method="post" ref={formRef} /> */}
      <Toast vertical="bottom" horizontal="center" open={showToast} message={'No applications'} />
      <BasicModal isOpen={currentModal === ModalTypes.DEFFINE_SOURCE}>
        <DefineSourceCard onClose={closeModal} onProceedClick={onDefinedSourceProceedClick} />
      </BasicModal>
      <BasicModal isOpen={currentModal === ModalTypes.ADD_SOURCE}>
        <AddSourceCard onClose={closeModal} onSaveAction={onAddSourceAction} />
      </BasicModal>
      <BasicModal isOpen={currentModal === ModalTypes.ADD_APPLICATION}>
        <AddApplicationCard
          onCancelClick={closeModal}
          onCloseClick={closeModal}
          onAddApplicationAction={closeModal}
          sourceUrl={currentSource.url}
        />
      </BasicModal>
      <EditSourceModal show={currentModal === ModalTypes.EDIT_SOURCE} onClose={closeModal} />
      <div className="flex h-20 w-full flex-row py-6 font-RakutenSans text-warm">
        <div className="flex w-11/12 items-center text-white">
          All Sources
          <div className="flex w-80 items-center pl-5">
            <SearchInput
              inputClassName="h-8 w-64"
              inputPlaceholder="Search source"
              value={searchValue}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="flex justify-end mobile:w-11/12 tablet_l:w-5/12 laptop:w-4/12 desktop:w-3/12">
          <div className="flex w-10 items-center">
            <Tooltip placement="top" title="Add source">
              <PlusFilled
                className="w-4 cursor-pointer"
                pathClasses="fill-primary"
                onClick={handleAddSourceClicked}
              />
            </Tooltip>
          </div>
          <div className="flex w-10 items-center">
            {sortType === 'ASC' ? (
              <div onClick={() => dispatch(changeSortType('DESC'))}>
                <ChangeOrder className="h-5 w-5 cursor-pointer bg-transparent" />
              </div>
            ) : (
              <div onClick={() => dispatch(changeSortType('ASC'))}>
                <ChangeOrder className="h-5 w-5 cursor-pointer bg-transparent" />
              </div>
            )}
          </div>
          <div className="flex w-1/6 items-center mobile:w-52 tablet_p:w-4/6">
            <Select
              value={sortingOptions.find((s) => s.value === sortBy)}
              options={sortingOptions}
              onChange={(option) => dispatch(changeSortBy(option.value))}
              buttonClasses="!h-9 !shadow-none"
              selectedValueClasses="!text-xs !whitespace-nowrap pl-12"
              showText={true}
              optionClasses="!text-xs"
              openClasses="!border-none"
              showTextValue="Sort by:"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-flow-row gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-4 mobile:grid-cols-2 tablet_p:grid-cols-2 tablet_l:grid-cols-3 laptop:grid-cols-4 desktop:grid-cols-4">
        {isSourceCardLoading ? (
          <SourceCardLoader numberOfItems={12} />
        ) : (
          sortedSources
            .filter(
              (source) =>
                (source.sourceName &&
                  source.sourceName.toLowerCase().includes(searchValue.toLowerCase())) ||
                searchValue === ''
            )
            .map((source) => (
              <SourceCard
                key={source.id}
                contentStyles={{ padding: '20px !important' }}
                containerClasses="w-full hover:drop-shadow-2xl cursor-pointer"
                sourceName={source.sourceName}
                sourceType={source.sourceType}
                applications={source.applicationCount || 0}
                metrics={source.metricsCount || 0}
                anomalies={source.anomalyCount || 0}
                sourceUpdates={`${source.updateDuration} secs`}
                createdOn={moment(source.createdOn).format('Do MMM YYYY HH:mm')}
                onClick={() => onSourceCardClick(source)}
                onPlusIconClick={() => handleSourceCardPlusClick(source)}
                menuItems={[
                  {
                    label: 'Edit source',
                    action: () => onEditSourceClick(source),
                  },
                  // {
                  //   label: 'Create Search Sapce',
                  //   action: () => onCreateSearchSpaceClick(source),  //Commented for future release
                  // },
                ]}
                isActive={source.isValid}
              />
            ))
        )}
      </div>
      <div className="h-14"></div>
    </motion.div>
  )
}
