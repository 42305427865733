import { classNames } from '../../utils'
import { ListItemProps } from './types'

export const LIST_ITEM_TEST_ID = 'LIST_ITEM_TEST_ID'

export default function ListItem({
  testId = LIST_ITEM_TEST_ID,
  className = '',
  children,
}: ListItemProps) {
  return (
    <li
      data-testid={testId}
      className={classNames(
        'flex justify-between p-3 text-white  hover:bg-primary hover:bg-opacity-50',
        className
      )}
    >
      {children}
    </li>
  )
}
