import { Fragment, useMemo, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { Check, ChevronUp, FileIcon } from '../icons'
import { AutocompleteProps } from './types'
import { classNames } from '../../utils'
import { OptionType } from '../../types'
import { COLORS } from '../../../themes/default/colors'
import Tooltip from '../tooltip'
import Checkbox from '../checkbox'

export const AUTOCOMPLETE_TEST_ID = 'AUTOCOMPLETE_TEST_ID'

export default function Autocomplete({
  testId = AUTOCOMPLETE_TEST_ID,
  id,
  options,
  className,
  labelClasses = '',
  label,
  helperText = '',
  value,
  onChange,
  disabled,
  maxChars,
  placeholder,
  inputClasses,
  buttonClasses,
  optionClasses,
  helperTextClasses,
  multipleSelect = false,
  selectedIconType = 'check',
  suffixChildren,
  optionsIconType,
  iconColor = COLORS.PRIMARY,
  selectAll = false,
  onMultiOptionClick,
  onFocus,
  noDataWidth,
  slectAlloptionName = '',
}: AutocompleteProps) {
  const [query, setQuery] = useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()))

  const handleAfterLeave = () => setQuery('')
  const isArray = Array.isArray(value)

  const isAllSelected = isArray ? options.length === value.length : false

  const handleSelectAll = useMemo(
    () => () => {
      if (isAllSelected) {
        onChange([])
      } else {
        onChange([...options])
      }
    },
    [isAllSelected, options]
  )
  let selectedVal = Array.isArray(value) ? value.map((v) => v.label) : value?.label

  return (
    <div className={`w-full ${className}`}>
      {label && (
        <div className={classNames('block', labelClasses)} html-for={id}>
          {label}
        </div>
      )}

      <Combobox
        value={value}
        onChange={onChange}
        data-testid={testId}
        multiple={multipleSelect as any}
        disabled={disabled}
      >
        <div className="relative z-10">
          <div className="relative w-full cursor-default overflow-hidden text-left shadow-sm sm:text-sm">
            {maxChars ? (
              <Tooltip title={selectedVal} placement="top-start">
                <Combobox.Input
                  placeholder={placeholder}
                  className={`h-8 w-full rounded-sm border border-dark2 bg-dark pl-2.5 pr-7 text-left text-base leading-5 text-white placeholder:text-base placeholder:text-warm_grey focus:border-primary focus:outline-none  ${inputClasses}`}
                  displayValue={(option: OptionType) => option.label}
                  onChange={(event) => setQuery(event.target.value)}
                  onFocus={onFocus}
                />
              </Tooltip>
            ) : (
              <Combobox.Input
                placeholder={placeholder}
                className={`h-8 w-full rounded-sm border border-dark2 bg-dark pl-2.5 pr-7 text-left text-base leading-5 text-white placeholder:text-base placeholder:text-warm_grey focus:border-primary focus:outline-none  ${inputClasses}`}
                displayValue={(option: OptionType) => option.label}
                onChange={(event) => setQuery(event.target.value)}
              />
            )}
            <Combobox.Button
              className={`absolute inset-y-0 right-0 flex items-center pr-2 ${buttonClasses}`}
            >
              {({ open }) => (
                <>
                  <ChevronUp
                    color={iconColor}
                    className={`${!open ? 'rotate-180 transform' : ''} w-4`}
                    aria-hidden="true"
                  />
                </>
              )}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={handleAfterLeave}
          >
            <Combobox.Options
              className={`absolute mt-1 max-h-60 w-full overflow-auto rounded-sm border border-primary bg-dark py-1 text-base shadow-sm ring-black ring-opacity-5 focus:outline-none sm:text-sm ${optionClasses}`}
            >
              {(options?.length === 0 || filteredOptions?.length === 0) && query !== '' && (
                <div
                  className={`relative cursor-default select-none py-2 pl-5 text-white ${noDataWidth}`}
                >
                  No data found
                </div>
              )}

              {selectAll && (
                <div
                  key={'_all_options_'}
                  className={`relative cursor-default select-none py-2 pl-10 pr-4`}
                  onClick={handleSelectAll}
                >
                  <div className="flex w-full flex-row items-center">
                    <div className={`absolute left-0 flex items-center pl-3 `}>
                      {selectedIconType === 'check' && (
                        <Check
                          className="w-5"
                          aria-hidden="true"
                          pathClasses={`${isAllSelected ? 'fill-black' : 'fill-primary'}`}
                        />
                      )}
                      {selectedIconType === 'checkbox' && (
                        <Checkbox checked={isAllSelected} className="w-4" />
                      )}
                    </div>
                    {optionsIconType === 'file' && (
                      <div className="w-14">
                        <div className="pl-2">
                          <div className="flex h-7 w-7 items-center justify-center rounded-full bg-primary">
                            <FileIcon className="w-4" />
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={`block truncate text-base text-white ${
                        isAllSelected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      Select All {slectAlloptionName}
                    </div>
                  </div>
                </div>
              )}

              {filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.label}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      selectAll && active
                        ? 'bg-primary20 !text-black'
                        : active
                        ? 'bg-primary !text-black'
                        : 'text-white'
                    }`
                  }
                  value={option}
                  onClick={onMultiOptionClick}
                >
                  {({ selected, active }) => (
                    <div className="flex w-full flex-row items-center">
                      <div
                        className={`absolute left-0 flex items-center pl-3 ${
                          active ? 'text-black' : 'text-teal-600'
                        }`}
                      >
                        {selected && selectedIconType === 'check' && (
                          <Check
                            className="w-5"
                            aria-hidden="true"
                            pathClasses={`${selected && active ? 'fill-black' : 'fill-primary'}`}
                          />
                        )}
                        {selectedIconType === 'checkbox' && (
                          <Checkbox checked={selected} className="w-4" />
                        )}
                      </div>
                      {optionsIconType === 'file' && (
                        <div className="w-14">
                          <div className="pl-2">
                            <div className="flex h-7 w-7 items-center justify-center rounded-full bg-primary">
                              <FileIcon className="w-4" />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        <Tooltip placement="right" title={option.label}>
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                          >
                            {option.label}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </Combobox.Option>
              ))}

              {suffixChildren}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {helperText !== '' && (
        <div className={`h-4 text-xs text-warm_grey ${helperTextClasses}`}>{helperText}</div>
      )}
    </div>
  )
}
