interface KpiCardLoaderProps {
  testId?: string
  numberOfItems?: number
}

export const KPI_CARD_LOADER_TEST_ID = 'KPI_CARD_LOADER_TEST_ID'

export default function KpiCardLoader({
  testId = KPI_CARD_LOADER_TEST_ID,
  numberOfItems,
}: KpiCardLoaderProps) {
  const kpiCard = () => {
    return [...Array(numberOfItems)].map((_, index) => (
      <div
        key={index}
        className="animate-pulse flex h-28 w-[15vw] flex-row items-center justify-between rounded-lg bg-dark p-4"
      >
        <div className="flex w-52 flex-col gap-5">
          <div className="h-9 rounded-md bg-seal_brown laptop:w-20 desktop:w-24" />
          <div className="flex flex-col gap-1">
            <div className="h-5 rounded-md bg-seal_brown laptop:w-36 desktop:w-48" />
            <div className="h-4 rounded-md bg-seal_brown laptop:w-36 desktop:w-48" />
          </div>
        </div>
        <div className="h-20 rounded-md bg-seal_brown laptop:w-24 desktop:w-32" />
      </div>
    ))
  }
  return (
    <div data-testid={testId} className="flex h-full w-full flex-col justify-around gap-3">
      {kpiCard()}
    </div>
  )
}
