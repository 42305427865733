import { CorelationOrCausationType, MetricAnomalyType } from '../../../../store/anomalies'
import { AnomalyList, Tabs } from '../../../../components'
import { SuccessIcon } from '../../../../components/icons'
import { Ref } from 'react'
import { severityColorClass } from '../behaviour/consts'

interface AnomalySectionProps {
  acknowledgedAnomalies: MetricAnomalyType[]
  unacknowledgedAnomalies: MetricAnomalyType[]
  onItemClick: (breach: MetricAnomalyType) => void
  selectedBreach?: CorelationOrCausationType
  listRef?: { [key: string]: Ref<HTMLDivElement> }
  queryId: string
  metricName: string
}

const AnomalyListTitle = ({ title }: { title: string }) => (
  <div className="flex w-full flex-row items-center whitespace-nowrap py-4 pl-3 text-sm text-warmgrey28">
    {title} <span className="ml-2 w-full border-[0.15px] border-white opacity-50"></span>
  </div>
)

export default function AnomalySection({
  acknowledgedAnomalies,
  unacknowledgedAnomalies,
  onItemClick,
  selectedBreach,
  listRef,
  queryId,
  metricName,
}: AnomalySectionProps) {
  return (
    <div className="flex h-full w-full flex-col">
      <Tabs
        tabs={[
          {
            title: 'All',
            component: (
              <>
                {unacknowledgedAnomalies.length === 0 ? (
                  <div className="flex h-52 flex-col items-center justify-center">
                    <div className="flex flex-col">
                      <SuccessIcon className="h-16 w-16" color="green" />
                    </div>
                    <div className="flex flex-col text-green-600">
                      All anomalies have been acknowledged
                    </div>
                  </div>
                ) : (
                  <>
                    <AnomalyList
                      selectedQueryId={selectedBreach?.id}
                      selectedEventTime={selectedBreach?.eventTime}
                      onItemClick={onItemClick}
                      items={unacknowledgedAnomalies}
                      listRef={listRef}
                      queryId={queryId}
                      metricName={metricName}
                      listClasses="p-4 gap-y-2"
                    />
                  </>
                )}
                {acknowledgedAnomalies.length !== 0 && (
                  <>
                    <AnomalyListTitle title="Acknowledged anomalies" />
                    <AnomalyList
                      selectedQueryId={selectedBreach?.id}
                      selectedEventTime={selectedBreach?.eventTime}
                      onItemClick={onItemClick}
                      items={acknowledgedAnomalies}
                      listRef={listRef}
                      queryId={queryId}
                      metricName={metricName}
                      listClasses="p-4 gap-y-2"
                    />
                  </>
                )}
              </>
            ),
          },
          {
            title: 'Acknowledged',
            tabPrefix: acknowledgedAnomalies.length,
            component: (
              <AnomalyList
                selectedQueryId={selectedBreach?.id}
                selectedEventTime={selectedBreach?.eventTime}
                onItemClick={onItemClick}
                items={acknowledgedAnomalies}
                listRef={listRef}
                queryId={queryId}
                metricName={metricName}
                severityColors={severityColorClass}
                listClasses="p-4 gap-y-2"
              />
            ),
          },
          {
            title: 'Unacknowledged',
            tabPrefix: unacknowledgedAnomalies.length,
            component: (
              <AnomalyList
                selectedQueryId={selectedBreach?.id}
                selectedEventTime={selectedBreach?.eventTime}
                onItemClick={onItemClick}
                items={unacknowledgedAnomalies}
                listRef={listRef}
                queryId={queryId}
                metricName={metricName}
                severityColors={severityColorClass}
                listClasses="p-4 gap-y-2"
              />
            ),
          },
        ]}
        panelClasses={'h-[70vh] overflow-y-scroll'}
        tabPrefixClasses={'text-primary'}
      />
    </div>
  )
}
