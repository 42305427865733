import { useEffect, useState } from 'react'
import moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { COLORS } from '../../../../../themes/default/colors'
import { Select } from '../../../../components'
import { ArrowLeft } from '../../../../components/icons'
import { OptionType } from '../../../../types'
import { compareIntervalOptions } from './consts'
import { AggregationLevels } from '../../../../store/anomaly-detection'
import { compareGraphLegendName } from '../../../../utils/chart'

export interface CompareLableType {
  fromTime: string
  toTime: string
}

export type CompareInfoType = Map<string, { x: string; y: string }>

export interface AnomalyComaprePanelProps {
  testId?: string
  entity?: string
  interval?: AggregationLevels
  labels: CompareLableType[]
  onIntervalChange: (value: OptionType) => void
  selectedInterval: OptionType
  onBackButtonPress: () => void
  durationNumber: number
  graphInfoSubject: BehaviorSubject<CompareInfoType>
}

const compareRangeLabel = {
  hour: 'HH:mm',
  day: 'Do HH:mm',
  week: 'DD MMM',
}

const initialGraphParametersState = (durationNumber: number, interval: AggregationLevels) =>
  new Map(
    [...Array(Number(durationNumber))].map((v, i) => [
      compareGraphLegendName(i, interval),
      { x: '-', y: '-' },
    ])
  )

export const COMPARE_PANEL_TEST_ID = 'COMPARE_PANEL_TEST_ID'

export default function AnomalyComparePanel({
  testId = COMPARE_PANEL_TEST_ID,
  entity,
  interval = AggregationLevels.hour,
  labels,
  selectedInterval,
  onIntervalChange,
  onBackButtonPress,
  durationNumber,
  graphInfoSubject,
}: AnomalyComaprePanelProps) {
  const [graphParameters, setGraphParameters] = useState(
    initialGraphParametersState(durationNumber, interval)
  )

  useEffect(() => {
    setGraphParameters(initialGraphParametersState(durationNumber, interval))
  }, [durationNumber, interval])

  useEffect(() => {
    const information$ = graphInfoSubject.subscribe((infoSection) => {
      setGraphParameters(infoSection)
    })

    return () => information$.unsubscribe()
  }, [graphInfoSubject])

  return (
    <div className="h-full w-full p-2 text-xs" data-testid={testId}>
      <div className="flex flex-row gap-1">
        <div className="flex items-center gap-2" onClick={onBackButtonPress}>
          <ArrowLeft className="w-5 hover:cursor-pointer hover:opacity-70" color={COLORS.PRIMARY} />
          <div className="text-sm capitalize text-white">Comparing {entity}</div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-2">
        <div className="w-5"></div>
        <span className="opacity-70">of previous</span>
        <div className="w-5/12">
          <Select
            isSectionalOptions
            options={compareIntervalOptions}
            value={selectedInterval}
            selectedIconType="radio"
            buttonClasses="border border-none bg-dark94 rounded-md"
            selectedValueClasses="!text-primary !text-sm"
            optionClasses="border border-none !text-xs"
            openClasses="bg-primary20"
            onChange={onIntervalChange}
            selectedIconClasses={'!w-3 !h-3'}
          />
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-6">
        {labels.map(({ fromTime, toTime }: CompareLableType, index: number) => {
          const label = compareGraphLegendName(index, interval)
          const info = graphParameters.get(label)
          return (
            <div className="flex flex-col" key={toTime}>
              <div className="flex flex-row items-center gap-1">
                <span className="font-RakutenSansSuperBold">{label}</span>
                <span className="opacity-70">
                  {moment(fromTime).format(compareRangeLabel[interval])} -{' '}
                  {moment(toTime).format(compareRangeLabel[interval])}
                </span>
              </div>

              <div className="flex flex-row gap-2">
                <span className="text-white">{info?.x || 'N/A'}</span> |{' '}
                <span className="text-primary">{info?.y || 'N/A'}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
