import { getRandomInteger } from '../../../../utils'

export default function AnomalyGraphLoader() {
  return (
    <div className="relative mx-5 flex h-72 w-full animate-pulse flex-row items-end justify-center overflow-hidden">
      <div className="absolute top-[50%] text-lg text-white">Fetching graph...</div>
      {[...Array(30)].map((_, i) => (
        <div
          key={i}
          style={{ height: `${getRandomInteger(100, 450)}px` }}
          className={`mx-3 min-h-[5px] w-5 bg-warm_grey opacity-20`}
        ></div>
      ))}
    </div>
  )
}
