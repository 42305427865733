import { Tab } from '@headlessui/react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import moment from 'moment'
import LabelInput from '../label-input'
import { HourGlassFilled } from '../icons'
import { AbsoluteCalendarProps, AbsoluteRangePanelProps } from './types'
import Switch from '../switch'
import Button from '../button'
import { useEffect, useMemo, useState } from 'react'
import { checkIfDateisCurrent, isValidRange, validateField } from '../../utils'
import { COLORS } from '../../../themes/default/colors'

export const ABSOLUTE_CALENDAR_TEST_ID = 'ABSOLUTE_CALENDAR_TEST_ID'

const AbsolutePanelInputStyle = {
  paddingRight: '0px',
  paddingLeft: '0px',
  backgroundColor: COLORS.DARK,
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: COLORS.WARM_GREY,
  },
}

export function AbsoluteCalendar({
  testId = ABSOLUTE_CALENDAR_TEST_ID,
  title,
  dateTitle,
  value,
  onChange,
  minimumDate,
  maxDate,
  disabled = false,
  subTimeTitle,
}: AbsoluteCalendarProps) {
  const isValidHourInterval = validateField(value.hour, ['required', 'min:0', 'max:24']) !== ''

  const isValidMinutedInterval =
    validateField(value.minutes, ['required', 'min:0', 'max:59']) !== ''

  const isValidSecondsInterval =
    validateField(value.minutes, ['required', 'min:0', 'max:59']) !== ''

  const selectedDate = useMemo(
    () =>
      moment({
        year: moment(value.date).year(),
        month: moment(value.date).month(),
        date: moment(value.date).date(),
        hours: value.hour,
        minutes: value.minutes,
        seconds: value.seconds,
      }).format('MMM DD, hh : mm : ss'),
    [value]
  )

  return (
    <div className="flex w-6/12 flex-col gap-y-2" data-testid={testId}>
      <div className="pl-0.5 text-sm">{title}</div>
      <DateCalendar
        onChange={(date: any) => onChange('date', moment(date).valueOf())}
        value={moment(value.date)}
        disabled={disabled}
        minDate={minimumDate ? moment(minimumDate) : null}
        maxDate={moment(maxDate)}
      />
      <div className="flex h-10 w-full flex-row items-center rounded border-[1px] border-warmgrey28 bg-dark p-2">
        <div className="flex flex-row">
          <HourGlassFilled className="m-2 w-4" />
        </div>
        <div className="flex flex-row gap-2 pt-4 text-sm">
          <LabelInput
            value={value.hour}
            disabled={disabled}
            error={isValidHourInterval}
            onChange={(e) => onChange('hour', Number(e.target.value), true)}
            type="number"
            placeholder="HH"
            sx={AbsolutePanelInputStyle}
          />
          <span className="pt-1">-</span>
          <LabelInput
            value={value.minutes}
            error={isValidMinutedInterval}
            disabled={disabled}
            onChange={(e) => onChange('minutes', Number(e.target.value), true)}
            type="number"
            placeholder="MM"
            sx={AbsolutePanelInputStyle}
          />
          <span className="pt-1">-</span>
          <LabelInput
            value={value.seconds}
            error={isValidSecondsInterval}
            disabled={disabled}
            onChange={(e) => onChange('seconds', Number(e.target.value), true)}
            type="number"
            placeholder="SS"
            sx={AbsolutePanelInputStyle}
          />
        </div>
      </div>
      <div className="w-full pl-0.5 text-sm italic text-warm">
        {dateTitle} {moment(value.date).format('MMM DD, hh : mm : ss')}{' '}
        <span className="text-xs">{subTimeTitle}</span>
      </div>
    </div>
  )
}

export const ABSOLUTE_PANEL_TEST_ID = 'ABSOLUTE_PANEL_TEST_ID'

export function AbsoluteRangePanel({
  testId = ABSOLUTE_PANEL_TEST_ID,
  fromDate,
  toDate,
  onChange,
  onApplyClick,
  maxAllowedDays = 6,
}: AbsoluteRangePanelProps) {
  const [fromDateTime, setFromDateTime] = useState({
    date: moment(fromDate).valueOf(),
    hour: moment(fromDate).hours(),
    minutes: moment(fromDate).minutes(),
    seconds: moment(fromDate).seconds(),
  })
  const [toDateTime, setToDateTime] = useState({
    date: moment(toDate).valueOf(),
    hour: moment(toDate).hours(),
    minutes: moment(toDate).minutes(),
    seconds: moment(toDate).seconds(),
  })
  const [isUntilNow, setUntilNow] = useState(checkIfDateisCurrent(toDate))
  const [untilNowDisabled, setUntilNowDisabled] = useState(false)

  function updateFromDateTime(name: string, value: number, check?: boolean) {
    setFromDateTime({
      ...fromDateTime,
      [name]: check ? (value >= 0 ? ('0' + String(value)).slice(-2) : '00') : value,
    })
  }

  useEffect(() => {
    const currentDate = moment().valueOf()
    const combinedFromDate = moment(fromDateTime.date)
      .hours(fromDateTime.hour)
      .minutes(fromDateTime.minutes)
      .seconds(fromDateTime.seconds)
    const combinedFromDateTime = combinedFromDate.valueOf()
    const maxAllowedDaysInMilliseconds = maxAllowedDays * 24 * 60 * 60 * 1000

    const newToDateTime = {
      date: moment(fromDateTime.date).add(maxAllowedDays, 'days').valueOf(),
      hour: fromDateTime.hour,
      minutes: fromDateTime.minutes,
      seconds: fromDateTime.seconds,
    }

    if (toDateTime.date < fromDateTime.date) {
      setToDateTime({ ...fromDateTime })
      if (toDateTime.hour < fromDateTime.hour) {
        setToDateTime({ ...fromDateTime, hour: fromDateTime.hour })
        if (toDateTime.minutes < fromDateTime.minutes) {
          setToDateTime({ ...fromDateTime, minutes: fromDateTime.minutes })
          if (toDateTime.seconds < fromDateTime.seconds) {
            setToDateTime({ ...fromDateTime, seconds: fromDateTime.seconds })
          }
        }
      }
    } else if (moment(fromDateTime.date).add(maxAllowedDays, 'days').isBefore(toDateTime.date)) {
      setToDateTime({ ...newToDateTime })
    }

    if (
      currentDate < fromDateTime.date ||
      currentDate > combinedFromDateTime + maxAllowedDaysInMilliseconds
    ) {
      setUntilNow(false)
      setUntilNowDisabled(true)
    } else {
      if (moment(toDate).date() == moment().date()) {
        if (fromDate >= currentDate - maxAllowedDaysInMilliseconds) {
          if (
            maxAllowedDaysInMilliseconds === 6 * 24 * 60 * 60 * 1000 &&
            moment(toDate).hour() == moment(currentDate).hour() &&
            moment(toDate).minute() == moment(currentDate).minute()
          ) {
            setUntilNow(true)
          } else {
            if (moment(toDate).valueOf() == moment().valueOf()) {
              setUntilNow(true)
            }
          }
        } else {
          setUntilNow(false)
        }
      }
      setUntilNowDisabled(false)
      if (isUntilNow || moment(toDate).date() == moment().date()) {
        setToDateTime({
          ...toDateTime,
          date: currentDate,
        })
      }
    }
  }, [fromDateTime])

  function updateToDateTime(name: string, value: number, check?: boolean) {
    setToDateTime({
      ...toDateTime,
      [name]: check ? (value >= 0 ? ('0' + String(value)).slice(-2) : '00') : value,
    })
  }

  const handleOnClick = () => {
    onChange({
      fromDate: moment({
        year: moment(fromDateTime.date).year(),
        month: moment(fromDateTime.date).month(),
        date: moment(fromDateTime.date).date(),
        hours: fromDateTime.hour,
        minutes: fromDateTime.minutes,
        seconds: fromDateTime.seconds,
      }).valueOf(),
      toDate: isUntilNow
        ? moment().valueOf()
        : moment({
            year: moment(toDateTime.date).year(),
            month: moment(toDateTime.date).month(),
            date: moment(toDateTime.date).date(),
            hours: toDateTime.hour,
            minutes: toDateTime.minutes,
            seconds: toDateTime.seconds,
          }).valueOf(),
    })
    onApplyClick()
  }

  const handleOnSwitchChange = () => {
    setUntilNow(!isUntilNow)
    if (!isUntilNow) {
      setToDateTime({
        date: moment().valueOf(),
        hour: moment().hours(),
        minutes: moment().minutes(),
        seconds: moment().seconds(),
      })
    }
  }

  const maxToDate = moment().isBefore(moment(fromDateTime.date).add(maxAllowedDays, 'days'))
    ? moment().valueOf()
    : moment(fromDateTime.date).add(maxAllowedDays, 'days').valueOf()

  return (
    <Tab.Panel data-testid={testId} className="flex w-full flex-col">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="flex w-full flex-row justify-around gap-3">
          <AbsoluteCalendar
            title="Custom date and Time"
            dateTitle="From"
            onChange={updateFromDateTime}
            value={fromDateTime}
            maxDate={moment().valueOf()}
          />
          <AbsoluteCalendar
            title="Showing only from the past"
            dateTitle="To"
            onChange={updateToDateTime}
            value={toDateTime}
            minimumDate={fromDateTime.date}
            disabled={isUntilNow}
            subTimeTitle={isUntilNow ? '(Current datetime)' : ''}
            maxDate={maxToDate}
          />
        </div>
        <div className="flex items-center justify-end text-warm">
          <div className="flex items-center text-sm">
            until now
            <Switch
              className="mx-2"
              checked={isUntilNow}
              onChange={handleOnSwitchChange}
              aria-disabled={untilNowDisabled}
            />
          </div>
          <Button
            variant="contained"
            className="w-24 !text-sm"
            onClick={handleOnClick}
            disabled={!isValidRange(fromDateTime, toDateTime, maxAllowedDays)}
          >
            Apply
          </Button>
        </div>
      </LocalizationProvider>
    </Tab.Panel>
  )
}
