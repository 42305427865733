import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { BasicCardProps } from './types'

export const BASIC_CARD_TEST_ID = 'BASIC_CARD_TEST_ID'

export default function BasicCard({
  testId = BASIC_CARD_TEST_ID,
  children,
  cardActions,
  containerClasses,
  contentClasses,
  contentStyles,
  onClick,
}: BasicCardProps) {
  return (
    <Card
      data-testid={testId}
      className={`w-full ${containerClasses}`}
      square={true}
      onClick={onClick}
    >
      <CardContent
        sx={{ '&:last-child': { paddingBottom: '16px' }, ...contentStyles }}
        className={contentClasses}
      >
        {children}
      </CardContent>
      {cardActions && <CardActions sx={{ padding: 0 }}>{cardActions}</CardActions>}
    </Card>
  )
}
