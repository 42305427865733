import { IconProps } from './types'

export const AlertIcon = ({ className, color = 'none', pathClasses }: IconProps) => (
  <div className={className}>
    <svg viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        className={pathClasses}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.5 4v2m0 2h.005M11.5 6a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
      />
    </svg>
  </div>
)
