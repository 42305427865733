import { RouteObject } from 'react-router-dom'
import { ANOMALIES_ROUTES, ANOMALIES_ROUTES_NAMES } from './route-names'
import BreachesTab from '../pages/dashboard/source/anomalies/anomalies-view/breaches-tab'
import DefDevTab from '../pages/dashboard/source/anomalies/anomalies-view/def-dev-tab'

export const anomaliesRoutes: RouteObject[] = [
  {
    id: ANOMALIES_ROUTES_NAMES.BREACHES,
    index: true,
    path: ANOMALIES_ROUTES.BREACHES,
    element: <BreachesTab />,
  },
  {
    id: ANOMALIES_ROUTES_NAMES.DEF_DEV,
    path: ANOMALIES_ROUTES.DEF_DEV,
    element: <DefDevTab />,
  },
]
