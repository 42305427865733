import { COLORS } from '../../../../../themes/default/colors'
import { Icon } from '../../../../components'

export default function NoDataMessage() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-white">
      <Icon.InfoOutlined className="h-20 w-20" color={COLORS.WHITE} />
      <div className="mt-5 text-2xl">There is no data present for the specified duration</div>
      <div className="text-base">Please select a proper duration to view the chart</div>
    </div>
  )
}
