import { Tab } from '@headlessui/react'
import { TabProps } from './types'
import Tooltip from '../tooltip'

export const TABS_COMPONENT_TEST_ID = 'TABS_COMPONENT_TEST_ID'

export default function Tabs({
  testId = TABS_COMPONENT_TEST_ID,
  tabs,
  tabClasses,
  tabPrefixClasses,
  panelClasses,
  maxTitleLength = 20,
}: TabProps) {
  return (
    <Tab.Group data-testid={testId} as="div">
      <Tab.List
        className={`flex w-full flex-row space-x-10 border-b-[1px] border-warm_grey px-4 text-sm ${tabClasses}`}
      >
        {tabs.map((tab) => (
          <Tab key={tab.title} as="div" className={` text-white focus:outline-none`}>
            {({ selected }) => (
              <button
                className={`py-3 capitalize ${
                  selected
                    ? 'items-center border-b-4 border-primary text-primary '
                    : 'text-warmgrey28'
                }`}
              >
                {tab.title.length > maxTitleLength ? (
                  <Tooltip
                    placement="top"
                    className="border-1 w-auto whitespace-normal"
                    title={tab.title}
                  >
                    <span className={`block truncate w-${maxTitleLength}`}>
                      <span className={tabPrefixClasses}>{tab.tabPrefix} </span>
                      {tab.title}
                    </span>
                  </Tooltip>
                ) : (
                  <span className="items-center whitespace-nowrap px-2">
                    <span className={tabPrefixClasses}>{tab.tabPrefix} </span>
                    {tab.title}
                  </span>
                )}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels as="div" className={panelClasses}>
        {tabs.map((tab) => (
          <Tab.Panel key={tab.title}>{tab.component}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  )
}
