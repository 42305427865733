import { useState, useEffect } from 'react'
export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: -1, y: -1 })
  useEffect(() => {
    const updateMousePosition = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY })
    }
    window.addEventListener('mousemove', updateMousePosition)
    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
    }
  }, [])
  return mousePosition
}
