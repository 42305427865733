export const validationMessages: { [key: string]: { [key in any]: string } } = {
  applicationName: {
    required: 'Application name is required',
    entityName: 'Enter a valid application name',
  },
  historyDays: {
    required: 'Min 1 day is required',
    min: 'Min 1 day is required',
    max: 'Max 10 days is allowed',
  },
  username: {
    required: 'Email is required',
    email: 'Enter a valid email',
  },
  password: {
    required: 'Password is required',
    password: 'Enter a valid password',
  },
  confirmPassword: {
    required: 'Confirm Password is required',
    match: 'Passwords do not match',
  },
  httpUrl: {
    required: 'Url is required',
    url: 'Enter a valid url',
  },
  queryInterval: {
    required: 'Min 60 seconds is required',
    min: ' Min 60 seconds is required',
    max: 'Max 86400 seconds is allowed',
  },
  queryTimeout: {
    required: 'Min 1 second is required',
    min: 'Min 1 second is required',
    max: 'Max 5 seconds is allowed',
  },
  sourceName: {
    required: 'Source name is required',
    entityName: 'Enter a valid source name',
  },
  orgName: {
    required: 'Organisation name is required',
    minChar: 'Min length should be 2',
  },
  designation: {
    required: 'Designation is required',
    minChar: 'Min length should be 2',
  },
  otpValidaton: {
    required: '6 digit Otp is required',
    length: 'Enter 6 digit OTP',
    match:
      'Incorrect OTP. Please check your email for the latest code or click on Resend OTP to request a new one',
  },
  PrometheusPrefix: {
    required: 'Prometheus prefix is required for Cortex source',
  },
  ruleName: {
    required: 'This field is mandatory',
    entityName: 'Enter a valid rule name',
  },
  silencePeriod: {
    required: 'This field is mandatory',
    min: 'Enter a valid silence period',
    max: 'Enter a valid silence period',
  },
  anomalyPeriod: {
    required: 'This field is mandatory',
    min: 'Enter a valid anomaly period',
    max: 'Enter a valid anomaly period',
    greaterThan: 'Enter a valid anomaly period',
  },
  anomalyCount: {
    required: 'This field is mandatory',
    min: ' Enter a valid anomaly count',
    max: ' Enter a valid anomaly count',
  },
  emails: {
    required: 'This field is mandatory',
    emails: 'Enter a valid email',
    arrayLength: 'Max limit reached for email addition',
  },
  message: {
    required: 'This field is mandatory',
    maxChar: 'Max 50 characters is allowed',
  },
}
