import { createSlice } from '@reduxjs/toolkit'
import { SourceStateType } from './types'
import { HttpMethods } from './consts'
import { updateSource } from './actions'
import { mapKeyValueAsHeaderOptions } from './utils'
import { isEmpty } from 'underscore'
import { sourceApi } from './services'

export const reducerPath = 'source'

const initialState: SourceStateType = {
  connectionId: '',
  sourceId: '',
  sourceName: '',
  sourceType: '',
  url: '',
  httpMethod: HttpMethods.GET,
  headers: [{ name: '', value: '' }],
  queryInterval: 60,
  queryTimeout: 1,
  scrapeSteps: 60,
  connectionState: { status: '', message: '' },
  isBasicAuth: false,
  basicAuthId: 'authid2',
  basicAuthPassword: 'authpass2',
  isTlsClientAuth: false,
  isWithCaCert: false,
  isLoading: false,
  isFetching: false,
  prometheusPrefix: 'prometheus',
}

const sourceSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeSourceValues: (prevState, { payload }) => {
      let newState = {}

      if (payload.type === 'url' || payload.type === 'httpMethod') {
        newState = {
          connectionState: initialState.connectionState,
        }
      }

      return {
        ...prevState,
        [payload.type]: payload.value,
        ...newState,
      }
    },
    changeSourceHeaders: (prevState, { payload }) => {
      const newHeaders = [...prevState.headers]

      newHeaders[payload.index] = {
        name: payload.type,
        value: payload.value,
      }

      if (
        (newHeaders[newHeaders.length - 1].name !== '' ||
          newHeaders[newHeaders.length - 1].value !== '') &&
        newHeaders.length < 5
      ) {
        newHeaders.push({ name: '', value: '' })
      }

      return {
        ...prevState,
        connectionState: initialState.connectionState,
        headers: newHeaders,
      }
    },
    changeSourceId: (prevState, { payload }) => ({
      ...prevState,
      sourceId: payload,
    }),
    changeSourceName: (prevState, { payload }) => ({
      ...prevState,
      sourceName: payload,
    }),
    changeSourceType: (prevState, { payload }) => ({
      ...prevState,
      sourceType: payload,
    }),
    resetState: () => {
      return initialState
    },
    setSourceValues: (prevState, { payload }) => {
      return {
        ...prevState,
        queryInterval: payload.updateDuration,
        sourceId: payload.id,
        ...payload,
      }
    },
    changeConnectionState: (prevState, { payload }) => {
      return {
        ...prevState,
        connectionState: payload,
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(updateSource.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateSource.fulfilled, (state) => {
        state.connectionId = initialState.connectionId
        state.sourceId = initialState.sourceId
        state.url = initialState.url
        state.httpMethod = initialState.httpMethod
        state.headers = initialState.headers
        state.queryInterval = initialState.queryInterval
        state.queryTimeout = initialState.queryTimeout
        state.scrapeSteps = initialState.scrapeSteps
        state.prometheusPrefix = initialState.prometheusPrefix
        state.isLoading = false
      })
      .addCase(updateSource.rejected, (state) => {
        state.isLoading = false
      })
      .addMatcher(sourceApi.endpoints.getSourceConnection.matchFulfilled, (state, { payload }) => {
        state.connectionId = payload.id
        state.httpMethod = payload.httpMethod
        state.headers = isEmpty(payload.headers)
          ? initialState.headers
          : mapKeyValueAsHeaderOptions(payload.headers as any)
        state.queryInterval = payload.queryInterval
        state.queryTimeout = payload.queryTimeout
        state.scrapeSteps = payload.scrapeSteps
        state.isFetching = false
      })
  },
})

export const {
  changeSourceValues,
  changeSourceHeaders,
  resetState,
  changeSourceId,
  changeSourceName,
  changeSourceType,
  setSourceValues,
  changeConnectionState,
} = sourceSlice.actions

export default sourceSlice
