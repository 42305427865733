import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { API_URL, USER_API } from '../consts'
import { userAuthSubject$ } from '../observables'
import { readCookie } from '../utils'

const IGNORE_UNAUTHORISED_ROUTES = [USER_API.VERIFY, USER_API.SIGN_IN]

const shouldIgnoreUnAuthorisedStatus = (url: string): boolean =>
  IGNORE_UNAUTHORISED_ROUTES.reduce((a, c) => a || url.includes(c), false)

// create a new mutex
const mutex = new Mutex()
// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: 'include',
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 })
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)

  const url = typeof args === 'string' ? args : args.url

  if (result.error && result.error.status === 401 && !shouldIgnoreUnAuthorisedStatus(url)) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          { url: USER_API.REFRESH_TOKEN, method: 'POST' },
          api,
          extraOptions
        )
        if (refreshResult.data) {
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          const isRememberMe = readCookie('isRememberMe')

          if (isRememberMe) {
            const loginResult = await baseQuery(
              {
                url: `${USER_API.SIGN_IN}`,
                method: 'POST',
                body: JSON.parse(isRememberMe),
              },
              api,
              extraOptions
            )

            if (loginResult.data) {
              // retry the initial query
              result = await baseQuery(args, api, extraOptions)
            } else {
              api.dispatch(splitApi.util.resetApiState())
              userAuthSubject$.next(false)
            }
          } else {
            api.dispatch(splitApi.util.resetApiState())
            userAuthSubject$.next(false)
          }
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const tagTypes = {
  ANOMALIES: 'ANOMALIES',
  BREACHES: 'BREACHES',
  METRICS: 'METRICS',
  SOURCES: 'SOURCES',
  TRENDS: 'TRENDS',
  APPLICATIONS: 'APPLICATIONS',
  QUERIES: 'QUERIES',
  FORECASTS: 'FORECASTS',
  COMPARISONS: 'COMPARISONS',
  AGGREGATIONS: 'AGGREGATIONS',
  CAUSATIONS: 'CAUSATIONS',
  CORELATIONS: 'CORELATIONS',
  ANOMALYTRENDS: 'ANOMALYTRENDS',
}

export type TagTypes = keyof typeof tagTypes

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const splitApi = createApi({
  reducerPath: 'splitApi',
  keepUnusedDataFor: 300,
  refetchOnReconnect: true,
  baseQuery: baseQueryWithReauth,
  tagTypes: Object.values(tagTypes),
  endpoints: () => ({}),
})

type CustomErrorType = { data: { message: string } }
/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export const isFetchBaseQueryError = (error: unknown): error is CustomErrorType =>
  typeof error === 'object' && error != null && 'data' in error
    ? typeof error.data === 'object' && error.data != null && 'message' in error.data
      ? true
      : false
    : false
