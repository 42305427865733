import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const TIME_OUTLINED_TEST_ID = 'TIME_OUTLINED_TEST_ID'

export const TimeOutlined = ({
  testId = TIME_OUTLINED_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  onClick,
  pathClasses,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={color}
        className={pathClasses}
        d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 26a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z"
      />
      <path className={pathClasses} fill={color} d="M17 7h-2v9.66l7.61 3.26.78-1.84L17 15.34V7z" />
    </svg>
  </div>
)
