import { secureLocalStorage } from './cryptography'
import Cookies from 'js-cookie'

export const saveToCookie = (cookieName: string, cookieValue: string | Object) => {
  const encodedCookieValue = secureLocalStorage.encrypt(JSON.stringify(cookieValue))
  Cookies.set(cookieName, encodedCookieValue, { path: '/' })
}

export const readCookie = (cookieName: string): string => {
  const formCookie = Cookies.get(cookieName)
  return formCookie ? secureLocalStorage.decrypt(formCookie) : ''
}

export const deleteCookie = (cookieName: string) => {
  Cookies.remove(cookieName)
}
