import { useEffect } from 'react'
import { BasicModal } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import SetAlertCard from '../../../partials/set-alert-card'
import { useTestAlertNotificationMutation } from '../../../store/alert-rules/services'
import {
  changeAlertInputValues,
  changeConnectionState,
  resetState,
  resetTestConnectionState,
} from '../../../store/alert-rules/slice'
import { useGetApplicationsQuery } from '../../../store/anomaly-detection'
import { useGetQueriesQuery } from '../../../store/application'
import { AlertRule } from '../../../store/alert-rules/types'
import { queriesSelector } from '../../../store/application/selectors'

interface SetAlertProps {
  show: boolean
  onClose?: () => void
  onSave: () => void
  addAlertValues: AlertRule
  isSubmittingForm: boolean
}
export default function SetAlertModal({
  show,
  onClose,
  onSave,
  addAlertValues,
  isSubmittingForm,
}: SetAlertProps) {
  const dispatch = useAppDispatch()
  const queriesState = useAppSelector(queriesSelector)
  const [testAlertNotification, { data = { status: '', message: '' }, isLoading }] =
    useTestAlertNotificationMutation()

  const handleTestAlertClick = async () => {
    const result: any = await testAlertNotification({
      channelType: 'email',
      channelValues: addAlertValues.values,
      period: Number(addAlertValues.anomalyPeriod),
      threshold: Number(addAlertValues.anomalyCount),
      silencePeriod: Number(addAlertValues.silencePeriod),
      severityOption: addAlertValues.severityOption,
      queryIds: addAlertValues.queryIds,
      message: addAlertValues.message,
      ruleName: addAlertValues.ruleName,
    })
    dispatch(changeConnectionState({ status: result.data.status }))
  }

  const handleOnClose = () => {
    dispatch(resetState())
    onClose?.()
  }

  const handleAlertInputChange = (type: string, value: string | number | string[]) => {
    dispatch(changeAlertInputValues({ type, value }))
    dispatch(resetTestConnectionState())
  }

  const handleOnClear = () => {
    dispatch(resetState())
    onClose?.()
  }

  const { data: applications = [], isLoading: isAppLoading } = useGetApplicationsQuery(
    { sourceId: addAlertValues.sourceId },
    {
      skip: addAlertValues.sourceId === '',
    }
  )

  const { data: queries = [], isLoading: isQueryLoading } = useGetQueriesQuery(
    addAlertValues.appId as string,
    {
      skip: addAlertValues.appId === '',
    }
  )

  useEffect(() => {
    if (addAlertValues.sourceId !== '' && !isAppLoading) {
      const applicationOptions = applications.map((application) => ({
        label: application.appName,
        value: application.id,
      }))
      dispatch(changeAlertInputValues({ type: 'applicationOptions', value: applicationOptions }))
    }
  }, [addAlertValues.sourceId, applications, isAppLoading])

  useEffect(() => {
    if (addAlertValues.appId !== '' && !isQueryLoading) {
      const metricOptions = queries.map((query) => ({ label: query.queryString, value: query.id }))
      dispatch(changeAlertInputValues({ type: 'queryOptions', value: metricOptions }))
    }
  }, [addAlertValues.appId, queries, isQueryLoading])

  return (
    <BasicModal isOpen={show}>
      <SetAlertCard
        {...addAlertValues}
        onClose={handleOnClose}
        onSave={onSave}
        handleAlertInputChange={handleAlertInputChange}
        onClear={handleOnClear}
        handleTestAlertNotification={handleTestAlertClick}
        isTestAlertLoading={isLoading}
        connectionState={addAlertValues.connectionState}
        isAddAlertLoading={isSubmittingForm}
      />
    </BasicModal>
  )
}
