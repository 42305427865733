const numberFormats = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
]

export const numberFormatter = (num: number, digits: number) => {
  const regx = /\.0+$|(\.\d*[1-9])0+$/
  const format = numberFormats
    .slice()
    .reverse()
    .find((item) => num >= item.value)
  return format ? (num / format.value).toFixed(digits).replace(regx, '$1') + format.symbol : 0
}

export const onlyIntegerKeyDown = (e: any) => {
  e.key.match(/\D/) &&
    !['ArrowRight', 'ArrowLeft', 'Backspace'].includes(e.key) &&
    e.preventDefault()
}

export const getRandomInteger = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const parsedNumber = (graphParameter: number, precision: number) =>
  !isNaN(graphParameter) ? graphParameter.toFixed(precision) : '-'
