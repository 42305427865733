import { RootState } from '..'
import { reducerPath } from './slice'
import { SourceTabType } from './types'

export const tabSourcesSelector = (state: RootState) => state[reducerPath].sources
export const currentTabSelector = (id: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === id)

export const previousAnomalyPathSelector = (id: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === id).prevAnomalyPath

export const previousBehaviourPathSelector = (id: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === id).prevBehaviourPath

export const behaviourDateRangeSelector = (id: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === id).behaviourDateRange

export const behaviourCurrentApplicationIdSelector = (id: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === id).behaviourAppId

export const multivariateDateRangeSelector = (id: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === id).multivariateDateRange

export const multivariateBehaviourDateRangeSelector = (id: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === id).multivariateBehaviourDateRange

export const behaviourAutoRefreshSelector = (id: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === id).behaviourAutoRefresh
export const sourceDetailsSelector = (sourceId: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === sourceId).details
export const behaviourQueriesSelector = (sourceId: string) => (state: RootState) =>
  state[reducerPath].sources.find((s: SourceTabType) => s.id === sourceId).behaviourQueries
