import { COLORS } from '../../../themes/default/colors'

export const QUESTION_TEST_ID = 'QUESTION_TEST_ID'

export const Questionicon = ({
  testId = QUESTION_TEST_ID,
  className,
  color = COLORS.WARM,
}: any) => (
  <div data-testid={testId} className={className}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <circle fill={color} cx={16} cy={22.5} r={1.5} />
      <path
        fill={color}
        d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 26a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z"
      />
      <path
        fill={color}
        d="M16 8a4.49 4.49 0 0 0-4.5 4.5v.5h2v-.5A2.5 2.5 0 1 1 16 15a1 1 0 0 0-1 1v3h2v-2.12A4.5 4.5 0 0 0 16 8Z"
      />
    </svg>
  </div>
)
