import { CortexIcon, PrometheusIcon } from '../../../components/icons'

const SOURCE_TYPES = {
  prometheus: 'prometheus',
  cortex: 'cortex',
}

const sourceTypeIcons = {
  [SOURCE_TYPES.prometheus]: {
    id: SOURCE_TYPES.prometheus,
    description: 'Time series database',
    Icon: PrometheusIcon,
  },
  [SOURCE_TYPES.cortex]: {
    id: SOURCE_TYPES.cortex,
    description: 'Cortex Prometheus storage',
    Icon: CortexIcon,
  },
}

interface ISelectSourceType {
  selected: string
  onSelect: (id: string) => void
}
export default function SelectSourceType({ selected, onSelect }: ISelectSourceType) {
  return (
    <div className="flex w-full flex-col text-white">
      <div className="text-xs">
        Choose source type <span className="text-red-500">*</span>
      </div>
      <div className="mt-2 grid grid-cols-3 gap-3">
        {Object.values(sourceTypeIcons).map(({ id, Icon, description }) => (
          <div
            key={id}
            className={`flex h-16 cursor-pointer flex-row items-center rounded-md border-[1.5px] shadow-sm ${
              selected === id
                ? 'border-primary bg-primary bg-opacity-10'
                : 'border-light_grey bg-light_grey'
            }`}
            onClick={() => onSelect(id)}
          >
            <div className="flex w-3/12 justify-center">
              <Icon className="w-8" />
            </div>
            <div className="flex w-9/12 flex-col">
              <div className="text-sm capitalize">{id}</div>
              <div className="text-xs text-warm_grey">{description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
