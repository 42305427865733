import Snackbar from '@mui/material/Snackbar'
import { ToastProps } from './types'
import { Slide } from '@mui/material'
import { COLORS } from '../../../themes/default/colors'

export const TOAST_TEST_ID = 'TOAST_TEST_ID'

export default function Toast({
  testId = TOAST_TEST_ID,
  open,
  vertical,
  horizontal,
  action,
  message,
  onClose,
  autoHideDuration,
}: ToastProps) {
  return (
    <Snackbar
      data-testid={testId}
      ContentProps={{
        sx: {
          background: COLORS.PRIMARY,
          color: COLORS.DARK2,
        },
      }}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      action={action}
      onClose={onClose}
      message={message}
      key={vertical + horizontal}
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
    />
  )
}
