import { IconProps } from './types'
import { COLORS } from '../../../themes/default/colors'

export const SETTINGS_TEST_ID = 'SETTINGS_TEST_ID'

export const Settings = ({
  testId = SETTINGS_TEST_ID,
  className,
  pathClasses,
  color = COLORS.PRIMARY,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <defs>
        <style></style>
      </defs>
      <path
        className={pathClasses}
        d="M28,13h-.91A11.62,11.62,0,0,0,26,10.28l.64-.64a2,2,0,0,0,0-2.83L25.19,5.39a2,2,0,0,0-1.41-.58,2,2,0,0,0-1.42.58L21.72,6A11.62,11.62,0,0,0,19,4.91V4a2,2,0,0,0-2-2H15a2,2,0,0,0-2,2v.91A11.62,11.62,0,0,0,10.28,6l-.64-.64a2,2,0,0,0-1.42-.58,2,2,0,0,0-1.41.58L5.39,6.81a2,2,0,0,0,0,2.83l.64.64A11.62,11.62,0,0,0,4.91,13H4a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2h.91A11.62,11.62,0,0,0,6,21.72l-.64.64a2,2,0,0,0,0,2.83l1.42,1.42a2,2,0,0,0,1.41.58,2,2,0,0,0,1.42-.58l.64-.64A11.62,11.62,0,0,0,13,27.09V28a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2v-.91A11.62,11.62,0,0,0,21.72,26l.64.64a2,2,0,0,0,1.42.58,2,2,0,0,0,1.41-.58l1.42-1.42a2,2,0,0,0,0-2.83L26,21.72A11.62,11.62,0,0,0,27.09,19H28a2,2,0,0,0,2-2V15A2,2,0,0,0,28,13Zm0,4H25.56l-.4,1.48a9.45,9.45,0,0,1-.93,2.25l-.76,1.32,1.08,1.09.64.64-1.41,1.41-.64-.64-1.09-1.08-1.33.76a9.21,9.21,0,0,1-2.24.93l-1.48.4V28H15V25.56l-1.48-.4a9.21,9.21,0,0,1-2.24-.93L10,23.47,8.86,24.55l-.64.64L6.81,23.78l.64-.64,1.08-1.09-.76-1.32a9.45,9.45,0,0,1-.93-2.25L6.44,17H4V15H6.44l.4-1.48a9.45,9.45,0,0,1,.93-2.25L8.53,10,7.45,8.86l-.64-.64L8.22,6.81l.64.64L10,8.53l1.33-.76a9.21,9.21,0,0,1,2.24-.93L15,6.44V4h2V6.44l1.48.4a9.21,9.21,0,0,1,2.24.93l1.33.76,1.09-1.08.64-.64,1.41,1.41-.64.64L23.47,10l.76,1.32a9.45,9.45,0,0,1,.93,2.25l.4,1.48H28Z"
      />
      <path
        fill={color}
        d="M16,9.5A6.5,6.5,0,1,0,22.5,16,6.5,6.5,0,0,0,16,9.5Zm0,11A4.5,4.5,0,1,1,20.5,16,4.51,4.51,0,0,1,16,20.5Z"
      />
    </svg>
  </div>
)
