import { COLORS } from './colors'

const MuiPaper = {
  styleOverrides: {
    root: {
      backgroundColor: COLORS.DARK3,
      color: COLORS.WHITE,
    },
  },
}

export default MuiPaper
