import { RingingBellIcon } from '../../../components/icons/ringing-bell-icon'

interface NoAlertsMessageProps {
  onClick: () => void
}

export default function NoAlertsMessage({ onClick }: NoAlertsMessageProps) {
  return (
    <div className="flex h-[62vh] w-full flex-col items-center justify-center">
      <div>
        <RingingBellIcon />
      </div>
      <div className="flex w-full items-center justify-center gap-1">
        <div className="text-base text-white opacity-50">No alert available.</div>
        <div onClick={onClick} className="text-primary hover:cursor-pointer">
          Set Alert
        </div>
      </div>
    </div>
  )
}
