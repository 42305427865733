import { createSlice } from '@reduxjs/toolkit'
import { AddApplicationStateType } from './types'
import { HttpMethods } from './consts'
import { addApplication, addSource, validateQueryOfJobMetric } from './actions'

export const reducerPath = 'addApplication'

export const initialCurrentSourceState = {
  id: '',
  tenantId: '',
  connectionId: '',
  metricsCount: 0,
  anomalyCount: 0,
  applicationCount: 0,
  sourceName: '',
  sourceType: '',
  createdBy: '',
  modifiedBy: '',
  createdOn: '',
  modifiedOn: '',
  updateDuration: 0,
  url: '',
  isValid: false,
}

const initialState: AddApplicationStateType = {
  sourceId: '',
  sourceName: '',
  sourceType: '',
  httpUrl: '',
  httpMethod: HttpMethods.GET,
  headers: [{ name: '', value: '' }],
  queryInterval: 60,
  queryTimeout: 1,
  scrapeSteps: 60,
  connectionState: { status: null, message: '' },
  applicationName: '',
  metricType: '',
  jobs: [],
  metrics: [],
  selectedJob: { label: '', value: '' },
  selectedMetric: { label: '', value: '' },
  currentQuery: '',
  queries: [],
  includeHistory: false,
  historyDays: 1,
  basicAuth: false,
  basicAuthId: null,
  basicAuthPassword: null,
  tlsClientAuth: false,
  withCaCert: false,
  isLoading: false,
  applicationId: '',
}

export const addApplicationSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeInputValues: (prevState, { payload }) => {
      let newState = {}

      if (
        (payload.type === 'selectedJob' || payload.type === 'selectedMetric') &&
        payload.value == null
      ) {
        newState = {
          [payload.type]: { label: '', value: '' },
        }
      }

      if (payload.type === 'httpUrl' || payload.type === 'httpMethod') {
        newState = {
          connectionState: initialState.connectionState,
        }
      }

      return {
        ...prevState,
        [payload.type]: payload.value,
        ...newState,
      }
    },
    changeHeaderValues: (prevState, { payload }) => {
      const newHeaders = [...prevState.headers]

      newHeaders[payload.index] = {
        name: payload.type,
        value: payload.value,
      }

      if (
        (newHeaders[newHeaders.length - 1].name !== '' ||
          newHeaders[newHeaders.length - 1].value !== '') &&
        newHeaders.length < 5
      ) {
        newHeaders.push({ name: '', value: '' })
      }

      return {
        ...prevState,
        connectionState: initialState.connectionState,
        headers: newHeaders,
      }
    },
    changeCurrentQuery: (prevState, { payload }) => {
      return {
        ...prevState,
        currentQuery: payload,
      }
    },
    deleteQuery: (prevState, { payload }) => {
      return {
        ...prevState,
        queries: prevState.queries.filter((q, i) => i !== payload),
      }
    },
    changeUnitOfQuery: (prevState, { payload }) => {
      const queries = [...prevState.queries].map((q, i) =>
        i === payload.index
          ? {
              ...q,
              unit: payload.unit,
            }
          : q
      )
      return {
        ...prevState,
        queries,
      }
    },
    resetState: (prevState) => {
      return {
        ...initialState,
        applicationId: prevState.applicationId,
      }
    },
    resetApplicationId: (prevState) => {
      return {
        ...prevState,
        applicationId: '',
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(validateQueryOfJobMetric.pending, (state, { meta }) => {
        state.queries = [
          ...state.queries,
          {
            id: meta.requestId,
            value: state.currentQuery,
            metricType: state.metricType,
            unit: '',
            status: 'loading',
            message: 'validating query',
            hasUnit: false,
          },
        ]
        state.currentQuery = ''
      })
      .addCase(validateQueryOfJobMetric.fulfilled, (state, { payload, meta }) => {
        state.queries = state.queries.map((q) =>
          q.id === meta.requestId
            ? {
                ...q,
                status: payload.valid ? 'success' : 'error',
                message: payload.valid ? 'Valid' : 'No data available',
                unit:
                  payload.unit !== null && payload.unit !== '' ? payload.unit.toLowerCase() : '',
                hasUnit: payload.unit !== null && payload.unit !== '',
              }
            : q
        )
      })
      .addCase(validateQueryOfJobMetric.rejected, (state, { payload, meta }) => {
        state.queries = state.queries.map((q) =>
          q.id === meta.requestId
            ? {
                ...q,
                status: 'error',
                message: 'Invalid query',
              }
            : q
        )
      })
      .addCase(addSource.pending, (state, _) => {
        state.isLoading = true
      })
      .addCase(addSource.fulfilled, (state, { payload, meta }) => {
        state.sourceId = payload.id
        state.sourceName = meta.arg.sourceName
        state.applicationId = ''
        state.isLoading = false
      })
      .addCase(addSource.rejected, (state, _) => {
        state.isLoading = false
      })
      .addCase(addApplication.pending, (state, _) => {
        state.isLoading = true
      })
      .addCase(addApplication.fulfilled, (state, { payload }) => {
        state.applicationId = payload.id
        state.isLoading = false
      })
      .addCase(addApplication.rejected, (state, _) => {
        state.isLoading = false
      })
  },
})

export const {
  changeInputValues,
  changeHeaderValues,
  changeCurrentQuery,
  deleteQuery,
  resetState,
  resetApplicationId,
  changeUnitOfQuery,
} = addApplicationSlice.actions
