import { ListProps } from './types'
import { classNames } from '../../utils'

export const LIST_TEST_ID = 'LIST_TEST_ID'

export default function List({ testId = LIST_TEST_ID, children, className = '' }: ListProps) {
  return (
    <ul data-testid={testId} className={classNames('divide-y-2 divide-dark94', className)}>
      {children}
    </ul>
  )
}
