import moment from 'moment'
import { motion, AnimatePresence } from 'framer-motion'
import { COLORS } from '../../../themes/default/colors'
import { AlertIcon, ChevronUp, MaximiseIcon, TiltedArrowIcon } from '../icons'
import Badge from '../badge'
import { AnomalyCardProps } from './types'
import { SEVERITY_ICON_COLOR_CLASS } from '../../store/anomalies/const'
import { getBreachInfo } from '../../store/anomalies'
import Tooltip from '../tooltip'

export default function AnomalyCard({
  title,
  eventTime,
  breachesCount,
  deviationCount,
  application,
  lastUpdatedTime,
  status,
  severity,
  onMaximiseClick,
  onChevronClick,
  isOpen = false,
  anomalyType,
  trend,
  lastAnomaly,
}: AnomalyCardProps) {
  const isMetricAnomaly = 'eventTime' in lastAnomaly
  const { direction, thresholdType, value } = isMetricAnomaly
    ? getBreachInfo(lastAnomaly.groundTruth, lastAnomaly.upperThreshold, lastAnomaly.lowerThreshold)
    : { direction: lastAnomaly?.trend, thresholdType: '', value: lastAnomaly?.deviation }

  return (
    <div className="flex w-full flex-row bg-charcolGrey laptop:h-[220px] desktop:h-[220px]">
      <div
        className={`h-full w-[0.150rem] ${
          isMetricAnomaly ? SEVERITY_ICON_COLOR_CLASS.background[severity] : 'bg-orange-500'
        }`}
      />
      <div className="flex w-full flex-row p-4">
        <div className="relative flex w-20 pt-2">
          <div
            className={`flex h-16 w-16 items-center justify-center rounded-full ${
              isMetricAnomaly ? SEVERITY_ICON_COLOR_CLASS.outer[severity] : 'bg-orange-100'
            }`}
          >
            <div
              className={`flex h-11 w-11 items-center justify-center rounded-full ${
                isMetricAnomaly ? SEVERITY_ICON_COLOR_CLASS.inner[severity] : 'bg-orange-200'
              }`}
            >
              {isMetricAnomaly && (
                <AlertIcon
                  className="w-8"
                  pathClasses={SEVERITY_ICON_COLOR_CLASS.stroke[severity]}
                />
              )}
              {anomalyType !== 'metric' && (
                <div>
                  <div className="absolute right-0 top-0  mr-1 border-2 border-black">
                    <div className="flex h-4 w-7 items-center justify-center rounded-md bg-beta font-RakutenSansBold text-[10px] text-black ">
                      BETA
                    </div>
                  </div>
                  <TiltedArrowIcon
                    className="w-8"
                    pathClasses={'stroke-orange-500'}
                    transform={trend === 1 ? '' : 'rotate(90)'}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col justify-between pl-1 text-white">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row justify-between gap-2 text-2xl font-semibold">
              {title}
              <div className="flex flex-row items-center gap-4">
                <div
                  className={`flex h-6 w-14 items-center justify-between rounded-xl ${
                    status ? 'bg-green-200' : 'bg-red-200'
                  } p-2`}
                >
                  <div
                    className={`h-2 w-2 rounded-full ${status ? 'bg-green-600' : 'bg-red-600'}`}
                  />
                  <div className={`text-xs ${status ? ' text-green-500' : 'text-red-500'}`}>
                    {status ? 'open' : 'closed'}
                  </div>
                </div>
                <MaximiseIcon className="w-10 cursor-pointer" onClick={onMaximiseClick} />
                <AnimatePresence initial={false} mode="wait">
                  <motion.div
                    key={isOpen ? 'up' : 'down'}
                    initial={{
                      rotate: isOpen ? -90 : 90,
                    }}
                    animate={{
                      zIndex: 1,
                      rotate: 0,
                      transition: {
                        type: 'tween',
                        duration: 0.15,
                        ease: 'circOut',
                      },
                    }}
                    exit={{
                      zIndex: 0,
                      rotate: isOpen ? -90 : 90,
                      transition: {
                        type: 'tween',
                        duration: 0.15,
                        ease: 'circIn',
                      },
                    }}
                  >
                    <ChevronUp
                      className={`w-5 cursor-pointer ${!isOpen ? 'rotate-180 transform' : ''}`}
                      color={COLORS.PRIMARY}
                      onClick={onChevronClick}
                    />
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
            <div>
              <div className="h-14 w-10/12 whitespace-normal text-warm laptop:text-sm desktop:text-base">
                {isMetricAnomaly ? (
                  <>
                    <span className="pr-1 text-primary">Latest Breach: </span>
                    <span className="font-RakutenSansBold"> {lastAnomaly.metricName} </span> of{' '}
                    <span className="font-RakutenSansBold"> {lastAnomaly.applicationName}</span>{' '}
                    <span className="pr-1">{direction}</span>to{' '}
                    <span className="font-RakutenSansBold">{lastAnomaly.groundTruth}</span> and
                    breached{' '}
                    <span className="font-RakutenSansBold"> {thresholdType} threshold</span> of{' '}
                    <span className="font-RakutenSansBold">{value}</span>
                  </>
                ) : (
                  <div className="flex flex-row">
                    <div className="flex gap-x-2 whitespace-nowrap">
                      <span className="pr-1 text-primary">
                        Latest {lastAnomaly.level} {lastAnomaly.type}:{' '}
                      </span>
                      <div className="w-full whitespace-normal">
                        {lastAnomaly.metricName} of {lastAnomaly.applicationName} showed{' '}
                        {lastAnomaly.type === 'deviation'
                          ? `${
                              lastAnomaly.trend === 1 ? `gradual rise` : `gradual degradation`
                            } of ${lastAnomaly.deviation} from the previous pattern since`
                          : `deflection of ${lastAnomaly.deviation} from previous ${lastAnomaly.level}'s behaviour`}
                        {lastAnomaly.type === 'deviation' ? (
                          <span className="ml-1 font-RakutenSansBold">
                            {lastAnomaly.level === 'day'
                              ? moment(lastAnomaly.deviationStartTime).format('hh:mm A')
                              : moment(lastAnomaly.deviationStartTime).format('dddd')}
                          </span>
                        ) : lastAnomaly.level === 'day' ? (
                          <span className="ml-1">
                            at
                            <span className="ml-1 font-RakutenSansBold">
                              {moment(lastAnomaly.anomalyTime).format('hh:mm A')}
                            </span>
                          </span>
                        ) : (
                          <span className="ml-1">
                            on
                            <span className="ml-1 font-RakutenSansBold">
                              {moment(lastAnomaly.anomalyTime).format('dddd')}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex font-bold text-warm">
              {moment(eventTime).format('ddd, Do MMMM YYYY [at] HH:mm')}
            </div>
          </div>
          <div className="flex w-full flex-row pb-2">
            <div className="flex w-1/3 flex-col gap-1">
              <div className="text-sm font-bold">Anomalies </div>
              <div className="flex gap-2">
                {breachesCount > 0 ? <Badge cue={breachesCount} label={'Breaches'} /> : null}
                {deviationCount > 0 ? <Badge cue={deviationCount} label={'Divergence'} /> : null}
              </div>
            </div>
            <div className="flex w-1/3 flex-col">
              <div className="text-sm font-bold"> Application</div>
              <Tooltip title={application} placement="top-start">
                <div className="w-60 truncate capitalize">{application} </div>
              </Tooltip>
            </div>
            <div className="flex w-1/3 flex-col gap-1">
              <div className="text-sm font-bold"> Last Updated</div>
              <div>{moment(lastUpdatedTime).format('ddd, Do MMMM YYYY [at] HH:mm')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
