import { createSlice } from '@reduxjs/toolkit'
import { SourceTabInitialState } from './types'
import moment from 'moment'
import { sourceApi } from '../source/services'

export const reducerPath = 'source-tab'

const initialState: SourceTabInitialState = {
  sources: [],
}

export const sourceTabSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    addTab: (prevState, { payload }) => {
      if ([...prevState.sources].findIndex((s) => s.id === payload.id) === -1) {
        return {
          sources: [
            ...prevState.sources,
            {
              ...payload,
              behaviourDateRange: payload.behaviourDateRange || {
                showForecast: true,
                rangeType: 'relative',
                relativeTime: '30-minutes',
                fromDate: moment().subtract(120, 'minutes').valueOf(),
                toDate: moment().valueOf(),
              },
              multivariateDateRange: { fromDate: null, toDate: null },
              multivariateBehaviourDateRange: {
                showForecast: false,
                rangeType: 'absolute',
                relativeTime: '30-minutes',
                fromDate: null,
                toDate: null,
              },
              anomaliesDateRange: payload.anomaliesDateRange || {
                showForecast: false,
                rangeType: 'relative',
                relativeTime: '30-minutes',
                fromDate: moment().subtract(30, 'minutes').seconds(0).milliseconds(0).valueOf(),
                toDate: moment().valueOf(),
              },
              behaviourAutoRefresh: true,
              behaviourQueries: {},
            },
          ],
        }
      }
    },
    removeTab: (prevState, { payload }) => {
      return {
        sources: [...prevState.sources].filter((s) => s.id !== payload),
      }
    },
    swapTabs: (prevState, { payload }) => {
      const indexToSwap = prevState.sources.findIndex((s) => s.id === payload)
      if (indexToSwap !== -1 && indexToSwap !== 4) {
        const updatedSources = [...prevState.sources]
        ;[updatedSources[indexToSwap], updatedSources[4]] = [
          updatedSources[4],
          updatedSources[indexToSwap],
        ]
        return {
          sources: updatedSources,
        }
      }
      return prevState
    },

    setRouteState: (prevState, { payload }) => {
      const { sourceId, ...otherPayload } = payload
      const updatedSources = [...prevState.sources].map((source) => {
        if (source.id === sourceId) {
          return { ...source, ...otherPayload }
        }
        return source
      })

      return { ...prevState, sources: updatedSources }
    },
    changeBehaviourDateRange: (prevState, { payload }) => {
      const { sourceId, ...otherParams } = payload
      const updatedSources = [...prevState.sources].map((source) => {
        if (source.id === sourceId) {
          return { ...source, behaviourDateRange: { ...source.behaviourDateRange, ...otherParams } }
        }
        return source
      })
      return { ...prevState, sources: updatedSources }
    },
    changeAnomaliesDateRange: (prevState, { payload }) => {
      const { sourceId, ...otherParams } = payload
      const updatedSources = [...prevState.sources].map((source) => {
        if (source.id === sourceId) {
          return { ...source, anomaliesDateRange: { ...source.anomaliesDateRange, ...otherParams } }
        }
        return source
      })
      return { ...prevState, sources: updatedSources }
    },
    changeMultivariateDateRange: (prevState, { payload }) => {
      const { sourceId, ...otherParams } = payload
      const updatedSources = [...prevState.sources].map((source) => {
        if (source.id === sourceId) {
          return {
            ...source,
            multivariateDateRange: otherParams,
            multivariateBehaviourDateRange: {
              ...source.multivariateBehaviourDateRange,
              ...otherParams,
            },
          }
        }
        return source
      })
      return { ...prevState, sources: updatedSources }
    },
    changeMultivariateBehaviourDateRange: (prevState, { payload }) => {
      const { sourceId, ...otherParams } = payload
      const updatedSources = [...prevState.sources].map((source) => {
        if (source.id === sourceId) {
          return {
            ...source,
            multivariateBehaviourDateRange: {
              ...source.multivariateBehaviourDateRange,
              ...otherParams,
            },
          }
        }
        return source
      })
      return { ...prevState, sources: updatedSources }
    },
    toggleCompareMode: (prevState, { payload }) => {
      const { sourceId, queryId, ...otherParams } = payload
      const updatedSources = [...prevState.sources].map((source) => {
        if (source.id === sourceId) {
          return {
            ...source,
            behaviourQueries: {
              ...source.behaviourQueries,
              [queryId]: {
                ...source.behaviourQueries[queryId],
                ...otherParams,
              },
            },
          }
        }
        return source
      })
      return { ...prevState, sources: updatedSources }
    },
    setSourceDetails: (prevState, { payload }) => {
      const { sourceId, ...otherParams } = payload
      const updatedSources = [...prevState.sources].map((source) => {
        if (source.id === sourceId) {
          return { ...source, details: { ...otherParams } }
        } else {
          return source
        }
      })

      return { ...prevState, sources: updatedSources }
    },
    changeSourceDetailsValues: (prevState, { payload }) => {
      const updatedSources = [...prevState.sources].map((source) => {
        if (source.id === payload.sourceId) {
          return { ...source, details: { ...source.details, [payload.type]: payload.value } }
        } else {
          return source
        }
      })

      return { ...prevState, sources: updatedSources }
    },
    changeSourceDetailsHeaders: (prevState, { payload }) => {
      const updatedSources = [...prevState.sources].map((_source) => {
        const source = JSON.parse(JSON.stringify(_source))
        if (source.id === payload.sourceId) {
          const newHeaders = [...source.details.headers]
          newHeaders[payload.index] = {
            name: payload.type,
            value: payload.value,
          }
          if (
            (newHeaders[newHeaders.length - 1].name !== '' ||
              newHeaders[newHeaders.length - 1].value !== '') &&
            newHeaders.length < 5
          ) {
            newHeaders.push({ name: '', value: '' })
          }

          return { ...source, details: { ...source.details, headers: newHeaders } }
        } else {
          return source
        }
      })
      return { ...prevState, sources: updatedSources }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(sourceApi.endpoints.addSource.matchFulfilled, (state) => {
      state.sources = [...state.sources].filter((s) => s.id !== 'add')
    })
  },
})

export const {
  addTab,
  removeTab,
  swapTabs,
  setRouteState,
  changeBehaviourDateRange,
  changeAnomaliesDateRange,
  changeMultivariateDateRange,
  changeMultivariateBehaviourDateRange,
  toggleCompareMode,
  setSourceDetails,
  changeSourceDetailsValues,
  changeSourceDetailsHeaders,
} = sourceTabSlice.actions
