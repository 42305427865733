import { COLORS } from './colors'

const MuiInputLabel = {
  styleOverrides: {
    root: {
      marginBottom: '0.75em',
      color: COLORS.WHITE,
      fontSize: '0.8em',
    },
  },
}

export default MuiInputLabel
