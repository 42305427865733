import MenuDropdown from '../menu-dropdown'
import { BreadcrumbProps } from './types'
import { BreadcrumbIcon, ChevronRight } from '../icons'
import Tooltip from '../tooltip'

export const BREADCRUMB_TEST_ID = 'BREADCRUMB_TEST_ID'

export default function Breadcrumb({
  items,
  containerClasses,
  itemClasses,
  testId = BREADCRUMB_TEST_ID,
}: BreadcrumbProps) {
  const shouldShowDropdown = items.length > 4

  const routes = shouldShowDropdown
    ? items.slice(0, items.length - 3).map((item) => ({
        label: item.routeName,
        action: item.onClick,
      }))
    : []

  const remainingRoutesInitial = shouldShowDropdown ? items.slice(-3) : items.slice(0)
  const remainingRoutes = remainingRoutesInitial.filter((route) => route.routeName !== undefined)

  return (
    <div className="flex" aria-label="breadcrumb" data-testid={testId}>
      <ul className={`flex items-center ${containerClasses} `}>
        {shouldShowDropdown && (
          <MenuDropdown
            itemsClasses="z-20 absolute left-0 border-none rounded-md w-28 block truncate"
            menuText={() => (
              <div className="text-2xl text-primary">
                <BreadcrumbIcon className="h-8 w-8" />
              </div>
            )}
            menuItems={routes}
          />
        )}

        {shouldShowDropdown && <ChevronRight className="w-6" />}
        {remainingRoutes.length &&
          remainingRoutes?.map((remainingRoute, index) => {
            const isLast = index === remainingRoutes.length - 1
            return (
              <li
                className={`flex items-center ${itemClasses}`}
                onClick={remainingRoute.onClick}
                key={`${remainingRoute.routeName}_${index}`}
              >
                {remainingRoute.routeName.length > 28 ? (
                  <Tooltip
                    placement="bottom"
                    className="border-1 w-auto whitespace-normal"
                    title={remainingRoute.routeName}
                  >
                    <span
                      className={`block w-40 max-w-[27rem] truncate whitespace-nowrap font-RakutenSansBold font-light capitalize hover:cursor-pointer laptop:text-xs desktop:text-base ${
                        isLast ? 'text-white' : 'text-primary'
                      }`}
                    >
                      {remainingRoute.routeName}
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    className={`max-w-sm whitespace-nowrap font-RakutenSansBold font-light hover:cursor-pointer laptop:text-xs desktop:text-base ${
                      isLast ? 'text-white' : 'text-primary'
                    }`}
                  >
                    {remainingRoute.routeName}
                  </span>
                )}

                {!isLast && <ChevronRight className="w-6" />}
              </li>
            )
          })}
      </ul>
    </div>
  )
}
