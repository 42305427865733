import { APPLICATION_API } from '../../consts'
import {
  QUERIES_API,
  PROMETHEUS_LABEL_API,
  PROMETHEUS_METRIC_VALUE_API,
  VALIDATE_QUERY,
} from '../../consts/api'
import { OptionType } from '../../types'
import axios from '../../utils/axios'
import {
  GetMetricValueRequestType,
  GetMetricValueResponseType,
  GetSourceLabelRequestType,
  GetSourceLabelResponseType,
} from '../application'
import { splitApi, tagTypes } from '../api'
import {
  EditApplicationRequestType,
  EditApplicationResponseType,
  GetQueriesResponseType,
  QueryWithId,
} from './types'

export const validateQuery = (sourceId: string, query: string) => {
  return axios.post(VALIDATE_QUERY, { sourceId, query }).then((res) => res.data)
}

// export const addPrometheusApplication = (params: ApplicationStateType) => {
//   return axios
//     .post(`${APPLICATION_API}`, {
//       sourceId: params.sourceId,
//       applicationName: params.applicationName,
//       includeHistory: params.includeHistory,
//       historyDays: params.historyDays,
//       queries: mapQueriesForBody(params.queries),
//     })
//     .then((res) => res.data)
// }

export const getApplicationQueries = (appId: string) => {
  return axios.get(`${QUERIES_API}?appId=${appId}`).then((res) => res.data)
}

export const applicationApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    editPrometheusApplication: build.mutation<
      EditApplicationResponseType,
      EditApplicationRequestType
    >({
      query(body) {
        return {
          url: `${APPLICATION_API}`,
          method: 'PUT',
          body: body,
        }
      },
      transformResponse: (result: EditApplicationResponseType) => result,
      invalidatesTags: [
        { type: tagTypes.QUERIES, id: 'LIST' },
        {
          type: tagTypes.APPLICATIONS,
          id: 'LIST',
        },
      ],
    }),
    getQueries: build.query<QueryWithId[], string>({
      query: (applicationId) => `${QUERIES_API}?appId=${applicationId}`,
      transformResponse: (rawResult: GetQueriesResponseType) => rawResult.queries,
      providesTags: () => [{ type: tagTypes.QUERIES, id: 'LIST' }],
    }),
    getSourceLabels: build.query<OptionType[], GetSourceLabelRequestType>({
      query: ({ sourceId }) => `${PROMETHEUS_LABEL_API}?sourceId=${sourceId}`,
      transformResponse: (rawResult: GetSourceLabelResponseType): OptionType[] =>
        rawResult.data?.map((item) => ({ value: item, label: item })),
    }),
    getSourceMetricValues: build.query<OptionType[], GetMetricValueRequestType>({
      query: ({ sourceId, label }) =>
        `${PROMETHEUS_METRIC_VALUE_API}?sourceId=${sourceId}&label=${label}`,
      transformResponse: (rawResult: GetMetricValueResponseType): OptionType[] =>
        rawResult.data?.map((item) => ({ value: item, label: item })),
    }),
  }),
})

export const {
  useGetQueriesQuery,
  useGetSourceLabelsQuery,
  useGetSourceMetricValuesQuery,
  useEditPrometheusApplicationMutation,
} = applicationApi
