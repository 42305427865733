import { SOURCE_TYPES } from '../../../consts'
import PrometheusForm, { IPrometheusForm } from './prometheus-form'

export default function SourceForm(props: IPrometheusForm) {
  switch (props.sourceType) {
    case SOURCE_TYPES.prometheus:
      return <PrometheusForm {...props} />
    case SOURCE_TYPES.cortex:
      return <PrometheusForm {...props} />
    default:
      return null
  }
}
