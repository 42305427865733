import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const COPY_FILLED_TEST_ID = 'COPY_FILLED_TEST_ID'

export const CopyFilled = ({
  testId = COPY_FILLED_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  onClick,
  pathClasses,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg
      className="!first:fill-red-500"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path
        className={pathClasses}
        d="M14 3h13a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-5V10a3 3 0 0 0-3-3h-7V5a2 2 0 0 1 2-2ZM5 9a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V11a2 2 0 0 0-2-2Z"
        fill={color}
      />
    </svg>
  </div>
)
