/** This file contains all dashboard level routes */
import { RouteObject } from 'react-router-dom'
import MainDashboard from '../pages/dashboard/main-dashboard'
import { DASHBOARD_ROUTES, DASHBOARD_ROUTES_NAMES } from './route-names'
import Source from '../pages/dashboard/source'
import { sourcesRoutes } from './sources-routes'
import AddEditSource from '../pages/dashboard/add-edit-source'
import SearchSpace from '../pages/dashboard/search-space'

export const dashboardRoutes: RouteObject[] = [
  {
    id: DASHBOARD_ROUTES_NAMES.HOME,
    index: true,
    element: <MainDashboard />,
  },
  {
    id: DASHBOARD_ROUTES_NAMES.SOURCES,
    path: DASHBOARD_ROUTES.SOURCES,
    element: <MainDashboard />,
  },
  {
    id: DASHBOARD_ROUTES_NAMES.ADD_SOURCE,
    path: DASHBOARD_ROUTES.ADD_SOURCE,
    element: <AddEditSource />,
  },
  {
    id: DASHBOARD_ROUTES_NAMES.EDIT_SOURCE,
    path: DASHBOARD_ROUTES.EDIT_SOURCE,
    element: <AddEditSource />,
  },
  {
    id: DASHBOARD_ROUTES_NAMES.SEARCH_SPACE,
    path: DASHBOARD_ROUTES.SEARCH_SPACE,
    element: <SearchSpace />,
  },
  {
    id: DASHBOARD_ROUTES_NAMES.SOURCE,
    path: DASHBOARD_ROUTES.SOURCE,
    element: <Source />,
    children: sourcesRoutes,
  },
]
