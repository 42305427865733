import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const THUMBS_UP_FILLED_TEST_ID = 'THUMBS_UP_FILLED_TEST_ID'
export const THUMBS_UP_OUTLINED_TEST_ID = 'THUMBS_UP_OUTLINED_TEST_ID'

export const ThumbsUpFilled = ({
  testId = THUMBS_UP_FILLED_TEST_ID,
  className,
  color = COLORS.PRIMARY,
  pathClasses,
  transform,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      transform={transform}
    >
      <path
        className={pathClasses}
        d="M5,14H2V30H5a2,2,0,0,0,2-2V16A2,2,0,0,0,5,14Z"
        fill={color}
      />
      <path
        className={pathClasses}
        d="M28,14H19s2-6,2-8V3.5A1.5,1.5,0,0,0,19.5,2H19c-.53,0-1,1-1,1L16,7,9.59,13.41A2,2,0,0,0,9,14.83V26a4,4,0,0,0,4,4H23.17a2,2,0,0,0,1.42-.59l4.82-4.82A2,2,0,0,0,30,23.17V16A2,2,0,0,0,28,14Z"
        fill={color}
      />
    </svg>
  </div>
)

export const ThumbsUpOutlined = ({
  testId = THUMBS_UP_OUTLINED_TEST_ID,
  className,
  color = COLORS.PRIMARY,
  pathClasses,
  onClick,
  transform,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      transform={transform}
    >
      <path
        className={pathClasses}
        d="M5,14H2v2H5V28H2v2H5a2,2,0,0,0,2-2V16A2,2,0,0,0,5,14Z"
        fill={color}
      />
      <path
        className={pathClasses}
        d="M28,14H20s2-6,2-8V5a2.94,2.94,0,0,0-2.5-3H18c-.53,0-1,1-1,1L15,7,9.59,13.41A2,2,0,0,0,9,14.83V26a4,4,0,0,0,4,4H23.17a2,2,0,0,0,1.42-.59l4.82-4.82A2,2,0,0,0,30,23.17V16A2,2,0,0,0,28,14Zm0,9.17L23.17,28H13a2,2,0,0,1-2-2V14.83l.06-.06,0-.07,5.42-6.41.15-.18.11-.22L18.74,4h.65A1.06,1.06,0,0,1,20,5V6a41.25,41.25,0,0,1-1.9,7.37L17.23,16H28Z"
        fill={color}
      />
    </svg>
  </div>
)
