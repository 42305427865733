import { SOURCE_API } from '../../consts'
import { DASHBOARD_API, ANOMALY_TRENDS, CAUSATION_IMPACT } from '../../consts/api'
import { splitApi, tagTypes } from '../api'
import {
  IGetSourcesByTenantIdResponse,
  IGetTrendsRequest,
  IGetTrendsResponse,
  SourceType,
  AnomalyTrendResponse,
  AnomalyTrendType,
  ImpactResponse,
} from './types'

export const dashboardApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    getSourcesByTenantId: build.query<SourceType[], {}>({
      query: () => `${SOURCE_API}`,
      transformResponse: (rawResult: IGetSourcesByTenantIdResponse): SourceType[] =>
        rawResult.sources,
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: tagTypes.SOURCES, id } as const)),
        { type: tagTypes.SOURCES, id: 'LIST' },
      ],
    }),
    getTrends: build.query<IGetTrendsResponse, IGetTrendsRequest>({
      query: ({ fromDate, toDate }) => `${DASHBOARD_API}?fromDate=${fromDate}&toDate=${toDate}`,
      providesTags: (result = {} as IGetTrendsResponse) => [
        ...Object.keys(result).map((id) => ({ type: tagTypes.TRENDS, id } as const)),
        { type: tagTypes.TRENDS, id: 'LIST' },
      ],
    }),
    getAnomalyTrends: build.query<AnomalyTrendResponse, AnomalyTrendType>({
      query: ({ fromDate, toDate, count, includeTotalAnomalyTrend, level, id }) =>
        `${ANOMALY_TRENDS}?fromDate=${fromDate}&toDate=${toDate}&number=${count}&includeTotalAnomalyTrend=${includeTotalAnomalyTrend}&level=${level}${
          level !== 'tenant' ? `&id=${id}` : ''
        }`, // Append id only if level is not 'tenant'
      transformResponse: (rawResult: AnomalyTrendResponse) => {
        return rawResult
      },
      providesTags: (result = {} as AnomalyTrendResponse) => [
        ...Object.keys(result).map((id) => ({ type: tagTypes.ANOMALYTRENDS, id } as const)),
        { type: tagTypes.ANOMALYTRENDS, id: 'LIST' },
      ],
    }),
    getCausationImpacts: build.query<ImpactResponse, AnomalyTrendType>({
      query: ({ fromDate, toDate, count, includeTotalAnomalyTrend, level, id }) =>
        `${CAUSATION_IMPACT}?fromDate=${fromDate}&toDate=${toDate}&number=${count}&includeTotalAnomalyTrend=${includeTotalAnomalyTrend}&level=${level}${
          level !== 'tenant' ? `&id=${id}` : ''
        }`, // Append id only if level is not 'tenant'
      transformResponse: (rawResult: ImpactResponse) => {
        return rawResult
      },
      providesTags: (result = {} as ImpactResponse) => [
        ...Object.keys(result).map((id) => ({ type: tagTypes.ANOMALYTRENDS, id } as const)),
        { type: tagTypes.ANOMALYTRENDS, id: 'LIST' },
      ],
    }),
  }),
})

export const {
  useGetSourcesByTenantIdQuery,
  useLazyGetSourcesByTenantIdQuery,
  useGetTrendsQuery,
  useGetAnomalyTrendsQuery,
  useGetCausationImpactsQuery,
} = dashboardApi
