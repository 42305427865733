import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const MENU_VERTICAL_TEST_ID = 'MENU_VERTICAL_TEST_ID'

export const MenuVertical = ({
  testId = MENU_VERTICAL_TEST_ID,
  className,
  color = COLORS.WHITE,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M16,26.5a2.25,2.25,0,1,0-2.25-2.25A2.25,2.25,0,0,0,16,26.5Z" />
      <path fill={color} d="M16,18.25A2.25,2.25,0,1,0,13.75,16,2.25,2.25,0,0,0,16,18.25Z" />
      <path fill={color} d="M16,10a2.25,2.25,0,1,0-2.25-2.25A2.25,2.25,0,0,0,16,10Z" />
    </svg>
  </div>
)
