import { createSlice } from '@reduxjs/toolkit'
import { AlertsInitialState } from './types'

export const reducerPath = 'alertRulesDashboard'

const initialState: AlertsInitialState = {
  alertsRules: [],
  applications: [],
}

export const alertRulesDashboardSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeActiveStatus: (prevState, { payload }) => {
      console.log('payload', payload)
      return {
        ...prevState,
        active: payload.active,
      }
    },
    changeApplicationsState: (prevState, { payload }) => {
      return {
        ...prevState,
        applications: payload,
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
  },
})
export const { changeActiveStatus, changeApplicationsState } = alertRulesDashboardSlice.actions
