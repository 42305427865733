import MUICheckbox from '@mui/material/Checkbox'
import { classNames } from '../../utils'
import { CheckboxProps } from './types'

export const CHECKBOX_INPUT_TEST_ID = 'CHECKBOX_INPUT_TEST_ID'

export default function Checkbox({
  testId = CHECKBOX_INPUT_TEST_ID,
  styles,
  className = '',
  onChange,
  ...otherProps
}: CheckboxProps) {
  return (
    <MUICheckbox
      onClick={onChange}
      data-testid={testId}
      className={classNames('', className)}
      sx={styles}
      {...otherProps}
    />
  )
}
