export enum COLORS {
  PRIMARY = '#F59600',
  SECONDARY = '#3a3b3f',
  BROWN = '#9D6823',
  DARK94 = '#151515',
  DARK = '#292929',
  DARK2 = '#1F1F1F',
  WHITE = '#ffffff',
  WARM_GREY = '#888888',
  PRIMARY100 = '#0089ff4f',
  WARMGREY28 = '#9f9f9f',
  ORANGERED20 = '#ff1616',
  DARK3 = '#23252c',
  WARM = '#bfbbbb',
  LIGHT_GREY = '#2D2D2D',
  SEAL_BROWN = '#131313',
  CHARCOL_GREY = '#1C1917',
  BLUE100 = '#007DD7',
  LEMON_YELLOW = '#FFFFBA',
  GRAPE = '#671BB3',
  CORNELL_RED = '#B31B1B',
  SILVER_GREY = '#BFBFBF',
  BLACK = '#000000',
  BLACK_BEAUTY = '#222529',
  DARK_ELF = '#3D3F43',
  VIVID_RED = '#FF2D2E',
  LIME_GREEN = '#1EA95E',
  DARK_GRAY = '#6a6a6a',
  HOVER_GRAY = '#4b4b4b',
  TRANSPARENT = '#ffffff00'
}
