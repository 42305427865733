import { createSlice } from '@reduxjs/toolkit'
import { AlertRule } from './types'
import { isEmpty } from 'underscore'

export const reducerPath = 'alertRules'

const initialState: AlertRule = {
  appId: '',
  ruleName: '',
  message: '',
  queryIds: [],
  channels: [],
  severityOption: 0,
  silencePeriod: '',
  type: '',
  values: [],
  connectionState: { status: '', message: '' },
  sourceId: '',
  queryId: '',
  applicationOptions: [],
  queryOptions: [],
  value: '',
  anomalyCount: '',
  anomalyPeriod: '',
  isEdit: false,
}

export const alertRuleSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeAlertInputValues: (prevState, { payload }) => {
      return {
        ...prevState,
        [payload.type]: payload.value,
      }
    },
    resetState: () => {
      return initialState
    },
    changeConnectionState: (prevState, { payload }) => {
      return {
        ...prevState,
        connectionState: payload,
      }
    },
    setAlertValues: (prevState, { payload }) => {
      const emails = !isEmpty(payload.channelValues)
        ? payload.channelValues.EMAIL
        : initialState.values
      return {
        ...prevState,
        ...payload,
        anomalyPeriod: payload.period,
        anomalyCount: payload.threshold,
        sourceId: payload.sourceId,
        queryId: payload.queryIds[0],
        appId: payload.applicationId,
        values: emails,
        value: emails.toString(),
      }
    },
    resetTestConnectionState: (prevState) => {
      return { ...prevState, connectionState: { status: '', message: '' } }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
  },
})
export const {
  changeAlertInputValues,
  resetState,
  changeConnectionState,
  setAlertValues,
  resetTestConnectionState,
} = alertRuleSlice.actions
