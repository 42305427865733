export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
}

export enum ConnectionStates {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export enum MetricTypes {
  load = 'load',
  error = 'error',
  response_time = 'response_time',
}
