export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
}

export enum ConnectionStates {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export enum MetricTypes {
  load = 'success',
  error = 'error',
  response_time = 'response_time',
}
export enum SortTypes {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ModalTypes {
  DEFFINE_SOURCE = 'DEFFINE_SOURCE',
  ADD_SOURCE = 'ADD_SOURCE',
  ADD_APPLICATION = 'ADD_APPLICATION',
  EDIT_SOURCE = 'EDIT_SOURCE',
  SET_ALERT = 'SET_ALERT',
  DELETE_ALERT = 'DELETE_ALERT',
}

export const PIECHART_COLORS = [
  '#840008',
  '#A62520',
  '#C94139',
  '#ED5D52',
  '#FF7C70',
  '#FF9D8E',
  '#FFBEAE',
  '#FFDECE',
  '#FFE7E6',
  '#FFF8F7',
]
