import moment from 'moment'
import { AnomalyTypes, GetAnomalylistApiResponseType, GroupedAnomaliesType } from './types'
import { TrendTypes } from './const'
import { AggregationLevels } from '../anomaly-detection'
import _ from 'underscore'

const TrendCode = Object.keys(TrendTypes)

export const getBreachInfo = (
  groundTruth: number,
  upperThreshold: number,
  lowerThreshold: number
) =>
  groundTruth > upperThreshold
    ? {
        direction: 'increased',
        thresholdType: 'upper',
        value: upperThreshold,
      }
    : {
        direction: 'decreased',
        thresholdType: 'lower',
        value: lowerThreshold,
      }

export function getAnomalyGraphDateRange(
  time: string,
  type: AnomalyTypes,
  level: keyof typeof AggregationLevels
): { fromDate: number; toDate: number } {
  if (type === 'metric') {
    return {
      fromDate: moment(time).utc().subtract(30, 'minutes').valueOf(),
      toDate: moment(time).utc().add(30, 'minutes').valueOf(),
    }
  } else if (type === 'deviation') {
    if (level === 'hour') {
      /** hour level -> 1 day data 1440 data points
       * to = anomaly time
       * from = anomaly time - 1 day */
      return {
        fromDate: moment(time).utc().subtract(1, 'day').valueOf(),
        toDate: moment(time).utc().valueOf(),
      }
    } else if (level === 'day') {
      /** day level -> 1 week data 168 points
       * to =  anomaly time
       * from = anomaly time - 1 week */
      return {
        fromDate: moment(time).utc().subtract(1, 'week').valueOf(),
        toDate: moment(time).utc().valueOf(),
      }
    } else {
      /** week level -> 5 weeks 35 points
       * to =  anomaly time
       * from = anomaly time - 5 weeks */
      return {
        fromDate: moment(time).utc().subtract(5, 'weeks').valueOf(),
        toDate: moment(time).utc().valueOf(),
      }
    }
  } else {
    /** Deflection */
    if (level === 'day') {
      /** day level -> 1 business day duration from 00 to 23 hrs */
      return {
        fromDate: moment(time).utc().hours(0).minutes(0).seconds(0).milliseconds(0).valueOf(),
        toDate: moment(time).utc().hours(23).minutes(59).seconds(59).milliseconds(0).valueOf(),
      }
    } else {
      /** week level -> 1 business week duration from monday to sunday */
      return {
        fromDate: moment(time)
          .isoWeekday(1)
          .utc()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .valueOf(),
        toDate: moment(time)
          .isoWeekday(7)
          .utc()
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(0)
          .valueOf(),
      }
    }
  }
}

export function transformGetAnomaliesResponse(
  rawResult: GetAnomalylistApiResponseType
): GroupedAnomaliesType[] {
  return rawResult.data?.map((anomaly) => {
    const latestEventTime = anomaly.latestEventTime

    const firstMetricAnomaly =
      anomaly.metricAnomalies.length > 0
        ? anomaly.metricAnomalies[anomaly.metricAnomalies.length - 1]
        : null
    const lastMetricAnomaly = anomaly.metricAnomalies.length > 0 ? anomaly.metricAnomalies[0] : null
    const firstDefdevAnomaly =
      anomaly.degradations.length > 0 ? anomaly.degradations[anomaly.degradations.length - 1] : null
    const lastDefdevAnomaly = anomaly.degradations.length > 0 ? anomaly.degradations[0] : null

    let lastUpdated = '',
      createdAt = '',
      type = '',
      trend = 0,
      severity = 0,
      level = 'hour'

    if (lastMetricAnomaly && lastDefdevAnomaly) {
      const isMetricLatest = moment(lastMetricAnomaly.eventTime).isAfter(
        lastDefdevAnomaly.anomalyTime
      )
      lastUpdated = isMetricLatest ? lastMetricAnomaly.eventTime : lastDefdevAnomaly.anomalyTime
      type = isMetricLatest ? 'metric' : lastDefdevAnomaly.type
      trend = isMetricLatest ? 0 : lastDefdevAnomaly.trend
      severity = isMetricLatest ? lastMetricAnomaly.sev : 0
      level = isMetricLatest ? 'hour' : lastDefdevAnomaly.level
    } else if (lastMetricAnomaly) {
      lastUpdated = lastMetricAnomaly.eventTime
      type = 'metric'
      severity = lastMetricAnomaly.sev
    } else if (lastDefdevAnomaly) {
      lastUpdated = lastDefdevAnomaly.anomalyTime
      type = lastDefdevAnomaly.type
      trend = lastDefdevAnomaly.trend
      level = lastDefdevAnomaly.level
    }

    if (firstMetricAnomaly && firstDefdevAnomaly) {
      createdAt = moment(firstMetricAnomaly.eventTime).isBefore(firstDefdevAnomaly.anomalyTime)
        ? firstMetricAnomaly.eventTime
        : firstDefdevAnomaly.anomalyTime
    } else if (firstMetricAnomaly) {
      createdAt = firstMetricAnomaly.eventTime
    } else if (firstDefdevAnomaly) {
      createdAt = firstDefdevAnomaly.anomalyTime
    }

    const status = anomaly.metricAnomalies.reduce((acc, curr) => acc && curr.ack, true)

    const breaches = anomaly.metricAnomalies.map((m) => ({
      eventTime: m.eventTime,
      unit: m.unit,
      lowerThreshold: m.lt,
      upperThreshold: m.ut,
      groundTruth: m.gt,
      forecastValue: m.fv,
      severity: m.sev,
      acknowledge: m.ack,
      metricName: anomaly.metricName,
      applicationName: anomaly.applicationName,
      applicationId: anomaly.applicationId,
      type: 'metric',
    }))

    const defdevs = anomaly.degradations.map((d) => ({
      level: d.level as AggregationLevels,
      anomalyTime: d.anomalyTime,
      deviationStartTime: d.deviationStartTime,
      deviationEndTime: d.deviationEndTime,
      trend: d.trend,
      deviation: d.deviation,
      type: d.type,
      metricName: anomaly.metricName,
      applicationName: anomaly.applicationName,
      applicationId: anomaly.applicationId,
      id: d.id,
    }))

    const anomalyTime =
      type === 'metric' ? lastMetricAnomaly?.eventTime || '' : lastDefdevAnomaly?.anomalyTime || ''

    const { fromDate: graphFromDate, toDate: graphToDate } = getAnomalyGraphDateRange(
      anomalyTime,
      type as AnomalyTypes,
      level as AggregationLevels
    )

    const breachInfo =
      lastMetricAnomaly &&
      getBreachInfo(lastMetricAnomaly?.gt, lastMetricAnomaly?.ut, lastMetricAnomaly?.lt)

    const description =
      type === 'metric'
        ? `${anomaly.metricName} of ${anomaly.applicationName} ${breachInfo?.direction} to ${lastMetricAnomaly?.gt} and breached ${breachInfo?.thresholdType} threshold of ${breachInfo?.value}`
        : `${anomaly.metricName} of ${anomaly.applicationName} showed gradual ${
            lastDefdevAnomaly?.trend === 1 ? 'rise' : 'drop'
          } of ${lastDefdevAnomaly?.deviation} from the previous ${
            lastDefdevAnomaly?.level
          }'s behaviour since ${moment(lastDefdevAnomaly?.deviationStartTime).format('HH:mm')}`

    const timeline = _.sortBy(
      [
        ...breaches.map((b) => ({
          type: 'metric',
          time: b.eventTime,
          severity: b.severity,
          trend: 0,
        })),
        ...defdevs.map((d) => ({
          type: d.type,
          time: d.anomalyTime,
          severity: 0,
          trend: d.trend,
        })),
      ],
      'time'
    ).slice(-10)

    return {
      queryId: anomaly.queryId,
      applicationId: anomaly.applicationId,
      applications: [anomaly.applicationName],
      metricName: anomaly.metricName,
      metricType: anomaly.metricType,
      breachesCount: anomaly.metricAnomalyCount,
      defdevsCount: anomaly.degradationCount,
      createdAt,
      lastUpdated,
      type: type as AnomalyTypes,
      level: level as AggregationLevels,
      description,
      severity,
      status: !status ? 'open' : 'closed',
      trend,
      graphFromDate,
      graphToDate,
      breaches,
      defdevs,
      timeline,
      latestEventTime,
    }
  })
}
