import { IconProps } from './types'

export const ARROW_LEFT_TEST_ID = 'ARROW_LEFT_TEST_ID'

export const ArrowLeft = ({
  testId = ARROW_LEFT_TEST_ID,
  className,
  color,
  onClick,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="m14 28 1.41-1.41L5.83 17H30v-2H5.83l9.58-9.59L14 4 2 16l12 12z" />
    </svg>
  </div>
)
