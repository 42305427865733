import moment from 'moment'
import { AlertIcon } from '../../../../../components/icons'
import { SEVERITY_ICON_COLOR_CLASS, getBreachInfo } from '../../../../../store/anomalies'

interface CausalAnomalyCardProps {
  severity: number
  selectedBreach: any
}

export default function CausalAnomalyCard({ severity, selectedBreach }: CausalAnomalyCardProps) {
  const { direction, thresholdType, value } = getBreachInfo(
    selectedBreach.groundTruth,
    selectedBreach.upperThreshold,
    selectedBreach.lowerThreshold
  )

  return (
    <>
      <div className="flex h-full w-full bg-light_grey">
        <div
          className={`flex h-full w-[0.150rem] flex-row ${SEVERITY_ICON_COLOR_CLASS.background[severity]}`}
        />

        <div className="flex flex-col p-4">
          <div className="relative flex w-12 pt-2">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-full ${SEVERITY_ICON_COLOR_CLASS.outer[severity]}`}
            >
              <div
                className={`flex h-8 w-8 items-center justify-center rounded-full ${SEVERITY_ICON_COLOR_CLASS.inner[severity]}`}
              >
                <AlertIcon
                  className="w-5"
                  pathClasses={SEVERITY_ICON_COLOR_CLASS.stroke[severity]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col gap-y-2">
          <div className="flex flex-col">
            <div className="flex flex-row font-RakutenSansBold text-lg">
              {selectedBreach.metricName}
            </div>
            <div className="whitespace-normal font-RakutenSans text-white opacity-50">
              <span>{selectedBreach.metricName}</span> of{' '}
              <span> {selectedBreach.applicationName}</span> is {direction} to{' '}
              <span>{selectedBreach.groundTruth}</span> and breached{' '}
              <span> {thresholdType} threshold</span> of <span>{value}</span>
            </div>
          </div>
          <div className="flex flex-row gap-x-3 text-base text-warm_grey">
            <span>{moment(selectedBreach.eventTime).format('ddd, Do MMMM YYYY [at] HH:mm')}</span>
            <span>|</span>
            <span> Application: {selectedBreach.applicationName}</span>
          </div>
        </div>
      </div>
    </>
  )
}
