import { Typography } from '@mui/material'
import MuiTooltip from '@mui/material/Tooltip'
import { TooltipProps } from './types'

export const TOOLTIP_TEST_ID = 'TOOLTIP_TEST_ID'

export default function Tooltip({
  testId = TOOLTIP_TEST_ID,
  className,
  title,
  children,
  placement,
  ...props
}: TooltipProps) {
  return (
    <MuiTooltip className={className} placement={placement} title={title} data-testid={testId}>
      <Typography component={'div'}>{children}</Typography>
    </MuiTooltip>
  )
}
