import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { AnomaliesInitialState } from './types'
import { CausalArrayType } from '../../utils'

export const reducerPath = 'anomalies'

export const initialPredictions = {
  queryId: '',
  metricType: '',
  eventTimes: [],
  upperThresholds: [],
  lowerThresholds: [],
  groundTruths: [],
  forecastValues: [],
  minorAnomalies: [],
  majorAnomalies: [],
  criticalAnomalies: [],
  feedbacks: {},
  feedbackColors: [],
  firstTimetamp: null,
  lastTimetamp: null,
  peakValue: null,
}

export const initialSelectedBreach = {
  metric: {
    queryId: '',
    applicationName: '',
    applicationId: '',
    metricName: '',
    metricType: '',
    applications: [],
    eventTime: '',
    severity: 0,
    score: 0,
    groundTruth: 0,
    upperThreshold: 0,
    lowerThreshold: 0,
    acknowledge: false,
  },
  defdev: {
    queryId: '',
    applicationId: '',
    level: 'day',
    anomalyTime: '',
    deviationStartTime: '',
    deviationEndTime: '',
    trend: 0,
    deviation: 0,
    type: '',
    metricName: '',
    applicationName: '',
  },
}

export const initialAggregationsState = {
  y: [],
  labels: [],
  titles: [],
  severity: [],
  counts: [],
  average: [],
  totalAnomaliesCount: [],
}

export const initialCausalGraphState: CausalArrayType = {
  source: [],
  target: [],
  value: [],
  labelValue: [],
}

const initialState: AnomaliesInitialState = {
  selectedBreach: {
    metric: {},
    defdev: {},
  },
  dateRange: {
    showForecast: false,
    rangeType: 'relative',
    relativeTime: '30-minutes',
    fromDate: moment().subtract(30, 'minutes').valueOf(),
    toDate: moment().seconds(0).milliseconds(0).valueOf(),
  },
}

export const anomaliesSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeMetricSelectedBreach: (state, { payload }) => {
      const { sourceId, ...breach } = payload
      state.selectedBreach = {
        ...state.selectedBreach,
        metric: { ...state.selectedBreach.metric, [sourceId]: breach },
      }
    },
    changeDefDevSelectedBreach: (state, { payload }) => {
      const { sourceId, ...defDev } = payload
      state.selectedBreach = {
        ...state.selectedBreach,
        defdev: { ...state.selectedBreach.defdev, [sourceId]: defDev },
      }
    },

    deleteSelectedBreach: (state, { payload }) => {
      state.selectedBreach = {
        ...state.selectedBreach,
        [payload]: initialSelectedBreach,
      }
    },
    updateSelectedBreachAck: (prevState, { payload }) => {
      const { sourceId, ...breach } = payload
      if (
        prevState.selectedBreach.metric.eventTime === payload.eventTime &&
        prevState.selectedBreach.metric.eventTime === payload.queryId
      ) {
        prevState.selectedBreach = {
          ...prevState.selectedBreach,
          metric: {
            [sourceId]: { ...prevState.selectedBreach.metric[sourceId], acknowledge: true },
          },
        }
      } else {
        return prevState
      }
    },
    changeAnomaliesDateRange: (prevState, { payload }) => {
      return {
        ...prevState,
        dateRange: {
          ...prevState.dateRange,
          ...payload,
        },
      }
    },
  },
  extraReducers: (builder) => {},
})

export const {
  changeMetricSelectedBreach,
  deleteSelectedBreach,
  updateSelectedBreachAck,
  changeDefDevSelectedBreach,
  changeAnomaliesDateRange,
} = anomaliesSlice.actions
