import { COLORS } from '../default/colors'

export const antTable = {
  colorBgContainer: COLORS.BLACK_BEAUTY,
  colorText: COLORS.SILVER_GREY,
  headerColor: COLORS.SILVER_GREY,
  headerSplitColor: COLORS.SILVER_GREY,
  footerBg: COLORS.BLACK_BEAUTY,
  headerBg: COLORS.DARK_ELF,
  rowExpandedBg: COLORS.BLACK,
  lineWidth: 0.02,
  rowSelectedBg: 'none',
  rowSelectedHoverBg: 'none',
  borderColor: COLORS.WARM_GREY,
  footerColor: COLORS.WHITE,
  headerBorderRadius: 0,
  cellPaddingBlock: 13,
  headerSortActiveBg: COLORS.DARK_ELF,
  headerSortHoverBg: COLORS.DARK_ELF,
}
