import { HelperText, LabelInput, Switch, Tooltip } from '../../components'
import MetricQueryList from './metric-query-list'
import { QueryType } from '../../store/application'
import { ReactNode } from 'react'
import { InfoOutlined } from '../../components/icons'
import { COLORS } from '../../../themes/default/colors'
export const RIGHT_SECTION_TEST_ID = 'RIGHT_SECTION_TEST_ID'

interface AddEditApplicationCardRightSectionProps {
  queries: QueryType[]
  includeHistory: boolean
  historyDays: number
  onChange: (type: string, value: string | number | boolean) => void
  onDeleteQuery: (id: string) => void
  onQueryUnitChange: (id: string, unit: string) => void
}

export default function AddEditApplicationCardRightSection({
  queries,
  includeHistory,
  historyDays,
  onChange,
  onDeleteQuery,
  onQueryUnitChange,
}: AddEditApplicationCardRightSectionProps) {
  const displayQueries = queries.filter((q) => !q.isDeleted)
  return (
    <div className="flex w-6/12 flex-col pt-16" data-testid={RIGHT_SECTION_TEST_ID}>
      <div className="flex-1 flex-col">
        <div className="h-auto w-full text-xs text-white">
          Selected metric queries ({displayQueries.length})
        </div>
        <div className="mt-1 flex-1">
          <MetricQueryList
            onDelete={onDeleteQuery}
            queries={displayQueries}
            onUnitChange={onQueryUnitChange}
          />
        </div>
      </div>
      <div className="mt-1 flex flex-col justify-end">
        <div className="flex flex-row items-center text-white">
          <span className="mr-2 text-sm">Scrape historical data</span>{' '}
          <Switch
            checked={includeHistory}
            onChange={() => onChange('includeHistory', includeHistory)}
          />
          {includeHistory && (
            <>
              <span className="mx-2">from past</span>
              <LabelInput
                disabled={true}
                type="text"
                inputStyles={{
                  padding: 0,
                  '& input.MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
                    '-webkit-text-fill-color': 'white',
                  },
                }}
                className="w-10 p-0"
                value={historyDays}
                onChange={(e) => onChange('historyDays', Number(e.target.value))}
              />
              <span className="mx-2">days</span>
              <Tooltip placement="right" title={'Days allowed minimum 1 day, maximum 10 days'}>
                <span>
                  <InfoOutlined className="ml-1.5 mt-0.5 h-4 w-4" color={COLORS.PRIMARY} />
                </span>
              </Tooltip>
            </>
          )}
        </div>
        <HelperText text="History of metric behaviour will reduce the learning time" />
      </div>
    </div>
  )
}
