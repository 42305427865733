export const sortingOptions = [
  { label: 'Created on', value: 'createdOn' },
  { label: 'Source name', value: 'sourceName' },
  { label: 'Number of metrics', value: 'metricsCount' },
  { label: 'Number of applications', value: 'applicationCount' },
  { label: 'Number of anomalies', value: 'anomalyCount' },
]

export const metricOptions = [
  { label: 'All', value: 'metricType' },
  { label: 'Error', value: 'error' },
  { label: 'Load', value: 'load' },
  { label: 'Response Time', value: 'response_time' },
]

export const sourceDefault = { label: 'All Sources', value: 'All' }

export const appDefault = { label: 'All Applications', value: 'All' }

export const handleClick = (event: any) => event.target.select()
