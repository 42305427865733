import moment from 'moment'
import List from '../list'
import ListItem from '../list-item'
import { DefDevListProps } from './types'

export default function DefDevList({ item }: DefDevListProps) {
  return (
    <List className="flex w-full flex-col bg-dark94 bg-opacity-50">
      <ListItem
        className="pointer-events-none flex w-full flex-row items-center !p-0"
        key={`${item.applicationId}-${moment(item.anomalyTime).valueOf()}`}
      >
        <div className={`h-20 rounded-r-md bg-red-500 pr-1`} />
        <div className="flex w-full flex-col gap-y-1">
          <div className="flex gap-1 pl-3 font-RakutenSans  text-white">
            <div className="capitalize">{item.level} </div>
            <div>{item.type} </div>
          </div>
          <div className="w-full pl-3 text-sm text-white opacity-50">
            <span className="font-RakutenSansBold">{item.metricName}</span> of{' '}
            <span className="font-RakutenSansBold">{item.applicationName}</span> showed{' '}
            <span className="mr-1 font-RakutenSansBold">
              {item.type === 'deviation'
                ? `${item.trend === 1 ? `gradual rise` : 'gradual degradation'}`
                : 'deflection'}
            </span>
            of <span className="mr-1 font-RakutenSansBold">{item.deviation}</span>
            from the
            {item.type === 'deviation'
              ? ' previous pattern since'
              : ` previous ${item.level}'s behaviour`}
            {item.type === 'deviation' ? (
              <span className="ml-1 font-RakutenSansBold">
                {item.level === 'day'
                  ? moment(item.deviationStartTime).format('hh:mm A')
                  : moment(item.deviationStartTime).format('dddd')}
              </span>
            ) : item.level === 'day' ? (
              <span className="ml-1">
                at
                <span className="ml-1 font-RakutenSansBold">
                  {moment(item.anomalyTime).format('hh:mm A')}
                </span>
              </span>
            ) : (
              <span className="ml-1">
                on
                <span className="ml-1 font-RakutenSansBold">
                  {moment(item.anomalyTime).format('dddd')}
                </span>
              </span>
            )}
          </div>
        </div>
      </ListItem>
    </List>
  )
}
