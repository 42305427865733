import { NavLink } from 'react-router-dom'
import { BreachList, Tooltip } from '../../../../components'
import { CorelationOrCausationType, MetricAnomalyType } from '../../../../store/anomalies'
import { SeverityTypes } from '../../../../store/anomaly-detection'
import { severityColorClass } from '../behaviour/consts'
import { appPaths } from '../../../../routes'
import { InfoOutlined } from '../../../../components/icons'

export const BREACH_SECTION_TEST_ID = 'BREACH_SECTION_TEST_ID'

interface BreachSectionProps {
  testId?: string
  selectedBreach: MetricAnomalyType & { queryId: string }
  breaches: CorelationOrCausationType[]
  causations: CorelationOrCausationType[]
  breachCount: number
  sourceId: string
  onViewMultivariateClick: (params: string) => void
  isFetching: boolean
}

export default function BreachSection({
  breaches,
  causations,
  breachCount,
  selectedBreach,
  testId = BREACH_SECTION_TEST_ID,
  sourceId,
  onViewMultivariateClick,
  isFetching,
}: BreachSectionProps) {
  return (
    <div className="flex w-full flex-col" data-testid={testId}>
      {/* <div className="flex h-10 w-full flex-row items-center gap-1 bg-dark94 text-lg text-primary"> */}
      {/* <span className="p-2 font-RakutenSansBold text-lg"> {breachCount} </span> */}
      {/* </div> */}
      <div className="flex flex-col">
        <div className="flex items-center bg-dark94 bg-opacity-50 py-1 pl-[1.625rem]">
          <div className="flex items-center gap-2">
            <div className="font-RakutenSans text-lg">Latest Breach</div>
            <Tooltip
              placement="right"
              title={
                <div className="text-xs backdrop-blur-3xl">
                  {'Abnormal behaviour compared to the forecast'}
                </div>
              }
            >
              <InfoOutlined className="w-5 pt-1" />
            </Tooltip>
          </div>
        </div>
        <div className="h-[8rem]">
          <BreachList
            key={`${selectedBreach.queryId}_${selectedBreach.eventTime}_latest`}
            items={[selectedBreach] as unknown as CorelationOrCausationType[]}
            severityTypes={SeverityTypes}
            severityColors={severityColorClass}
          />
        </div>
        <div className="flex h-[0.5px] w-[95%] self-center bg-warmgrey28 opacity-50" />
        <div className="flex w-full flex-row items-center justify-between bg-dark94 bg-opacity-50 px-[1.625rem]">
          <div className="flex items-center gap-2">
            <div className="flex grow py-5 font-RakutenSans text-lg">
              Observed correlated temporal behaviour
            </div>
            <Tooltip
              placement="right"
              title={
                <div className="text-xs backdrop-blur-3xl">
                  {
                    'Observed behaviour of these metrics are found similar to the breaching metric at the same timeframe'
                  }
                </div>
              }
            >
              <InfoOutlined className="w-5 pt-1" />
            </Tooltip>
          </div>

          <NavLink
            onClick={() =>
              isFetching
                ? null
                : onViewMultivariateClick(
                    appPaths.multivariatePage({
                      sourceId,
                      applicationId: selectedBreach.applicationId,
                    })
                  )
            }
            to={
              isFetching
                ? ''
                : appPaths.multivariatePage({
                    sourceId,
                    applicationId: selectedBreach.applicationId,
                  })
            }
            className={`flex items-center justify-center text-sm text-primary hover:cursor-pointer ${
              isFetching
                ? 'pointer-events-none cursor-not-allowed opacity-50'
                : 'pointer-events-auto cursor-pointer hover:underline'
            }`}
          >
            View multivariate analysis
          </NavLink>
        </div>
        <BreachList
          key={`${selectedBreach.queryId}_${selectedBreach.eventTime}_correlation`}
          items={breaches}
          severityTypes={SeverityTypes}
          severityColors={severityColorClass}
        />
        <div className="flex h-[0.5px] w-[95%] self-center bg-warmgrey28 opacity-50" />
      </div>
      <div className="flex w-full flex-row items-center justify-between bg-dark94 bg-opacity-50 px-[1.625rem]">
        <div className="flex items-center gap-2">
          <div className="flex grow py-5 font-RakutenSans text-lg">
            {causations.length} Suspected Causes
          </div>
          <Tooltip
            placement="top"
            title={
              <div className="text-xs backdrop-blur-3xl">
                {'Suspected metrics behaviour that have caused this breach'}
              </div>
            }
          >
            <InfoOutlined className="w-5 pt-1" />
          </Tooltip>
          <span className="pt-1">
            <div className="flex h-5 w-11 items-center justify-center rounded-md bg-beta font-RakutenSansBold text-xs text-black">
              BETA
            </div>
          </span>
        </div>

        {/* //commented for next release
        <NavLink
          className={'hover: cursor-pointer text-sm text-primary hover:underline hover:opacity-75'}
          to={''}
        >
          view casual analysis
        </NavLink> */}
      </div>
      <BreachList
        key={`${selectedBreach.queryId}_${selectedBreach.eventTime}_causation`}
        items={causations}
        severityTypes={SeverityTypes}
        severityColors={severityColorClass}
      />
    </div>
  )
}
