import { COLORS } from './colors'

const MuiPickersArrowSwitcher = {
  styleOverrides: {
    root: {
      '& .MuiPickersArrowSwitcher-button': { fontSize: '1rem' },
    },
  },
}

export default MuiPickersArrowSwitcher
