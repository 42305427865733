import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const MAXIMISE_TEST_ID = 'MAXIMISE_TEST_ID'

export const MaximiseIcon = ({
  testId = MAXIMISE_TEST_ID,
  className,
  color = COLORS.PRIMARY,
  pathClasses,
  onClick,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    {
      <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" fill={color}>
        <path
          className={pathClasses}
          d="M21.6667 18.3333L27.5 12.5M27.5 12.5H22.5M27.5 12.5V17.5M18.3333 21.6667L12.5 27.5M12.5 27.5H17.5M12.5 27.5L12.5 22.5"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    }
  </div>
)
