import moment from 'moment'
import { AlertIcon, TiltedArrowIcon } from '../../../../components/icons'
import { SEVERITY_ICON_COLOR_CLASS } from '../../../../store/anomaly-detection'
import { AnomalyTypes, Timeline } from '../../../../store/anomalies'
import { Tooltip } from '@mui/material'

interface BreachTimelineProps {
  breaches: Timeline[]
  isFetching: boolean
  onViewMultivariateClick: () => void
  sourceId: string
  metrics: JSX.Element[]
  anomalyType: AnomalyTypes
  onViewApplicationClick: () => void
}

export default function BreachTimeline({
  breaches,
  onViewMultivariateClick,
  metrics,
  anomalyType,
  onViewApplicationClick,
}: BreachTimelineProps) {
  const timeGap = breaches.length > 0 ? 100 / breaches.length : 0
  return (
    <div className="flex w-full flex-col gap-y-2 p-6 text-white">
      <div className="text-base font-semibold">Anomaly Timeline (Latest 10)</div>
      <div className="flex h-full w-full flex-col items-center justify-center py-8">
        <div className="relative flex h-1 w-[95%] rounded-sm border-l-2 border-r-2 border-primary bg-primary">
          {breaches.map((breach, index) => (
            <div
              style={{ left: `${timeGap * (index + 1)}%` }}
              className={`absolute -top-3.5 -ml-6`}
              key={breach.time}
            >
              <div className="flex items-center">
                <div
                  className={`flex h-7 w-7 items-center justify-center rounded-full ${
                    breach.type === 'metric'
                      ? SEVERITY_ICON_COLOR_CLASS.inner[breach.severity]
                      : 'bg-orange-200'
                  }`}
                >
                  {breach.type === 'metric' ? (
                    <AlertIcon
                      pathClasses={SEVERITY_ICON_COLOR_CLASS.stroke[breach.severity]}
                      className="h-6 w-6 items-center justify-center "
                    />
                  ) : (
                    <TiltedArrowIcon
                      pathClasses={'stroke-orange-500'}
                      className="h-6 w-6 items-center justify-center "
                      transform={breach.trend === 1 ? '' : 'rotate(90)'}
                    />
                  )}
                </div>
              </div>
              <div className="-ml-6 mt-3 flex w-20 flex-col items-center justify-center">
                <div className="text-base font-semibold">{moment(breach.time).format('HH:mm')}</div>
                <div className="text-base font-semibold">
                  {moment(breach.time).format('DD MMM')}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row justify-between pt-16">
        <div className="w-9/12 flex-col truncate font-semibold">
          Observed Correlated Events
          <div className="flex flex-row text-sm">
            <span className="font-bold">Metric: &nbsp;</span>
            <Tooltip placement="top" title={metrics}>
              <span className={`block w-full truncate`}>{metrics}</span>
            </Tooltip>
          </div>
        </div>
        {anomalyType === 'metric' ? (
          <button
            onClick={onViewMultivariateClick}
            className="mr-4 flex flex-row font-bold text-primary hover:underline"
          >
            View multivariate analysis
          </button>
        ) : (
          <span
            className="text-primary hover:cursor-pointer hover:underline"
            onClick={onViewApplicationClick}
          >
            View application
          </span>
        )}
      </div>
    </div>
  )
}
