import { MetricTypes } from '../add-application'

export const MetricTypeTitles: { [key in MetricTypes]: string } = {
  [MetricTypes.load]: 'No of requests',
  [MetricTypes.error]: 'No of errors',
  [MetricTypes.response_time]: 'Response time',
}

export const BREACH_COLORS = ['#FFDA55', '#CE5700', '#C72D19']

export const SEVERITY_COLORS = ['green', ...BREACH_COLORS, 'purple']

export const SeverityTypes = ['Normal behaviour', 'Minor breach', 'Major breach', 'Critical breach']

export const compareGraphLineColors = ['#AAF255', '#9965F4', '#FFAF49']

export enum AggregationLevels {
  week = 'week',
  day = 'day',
  hour = 'hour',
}

export enum QueryEntities {
  ground_truth = 'ground_truth',
  forecast_value = 'forecast_value',
  lower_threshold = 'lower_threshold',
  upper_threshold = 'upper_threshold',
}

export const xAggregationLevels = {
  week: 'day',
  day: 'hour',
  hour: 'min',
}

export const ANOMALY_GRAPH_LABELS = [
  'Upper threshold',
  'Lower theshold',
  'Ground truth',
  'Forecast value',
  'Minor breach',
  'Major breach',
  'Critical breach',
  'Feedbacks',
]

export const SEVERITY_ICON_COLOR_CLASS = {
  outer: ['bg-green-100', 'bg-yellow-100', 'bg-orange-100', 'bg-red-100'],
  inner: ['bg-green-200', 'bg-yellow-200', 'bg-orange-200', 'bg-red-200'],
  background: ['bg-green-500', 'bg-yellow-500', 'bg-orange-500', 'bg-red-500'],
  border: ['border-green-500', 'border-yellow-500', 'border-orange-500', 'border-red-500'],
  stroke: ['stroke-green-500', 'stroke-yellow-500', 'stroke-orange-500', 'stroke-red-500'],
}
