import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const INFO_TEST_ID = 'INFO_TEST_ID'

export const InfoOutlined = ({
  testId = INFO_TEST_ID,
  className,
  color = COLORS.WARM,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M15 14h2v9h-2z" />
      <circle fill={color} cx={16} cy={10.5} r={1.5} />
      <path
        fill={color}
        d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 26a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z"
      />
    </svg>
  </div>
)
