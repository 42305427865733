import { PropsWithChildren, createContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Observable } from 'rxjs'
import { Transition } from '@headlessui/react'
import { ANOMALY_NOTIFICATION_API } from '../consts'
import { NotificationBanner } from '../components'
import moment from 'moment'

const EVENT_TYPE = 'TEXT_EVENT_STREAM_VALUE'
const ICON_PATH = '/favicon.ico'

interface INotificationProvider {
  enable: boolean
}

const NotificationContext = createContext<INotificationProvider>({ enable: false })

const portalContentEl = document.getElementById('root')

export function notificationObserver$<T>(message: string): Observable<{ data: T }> {
  return new Observable<{ data: T }>((obs) => {
    const es = new EventSource(ANOMALY_NOTIFICATION_API, { withCredentials: true })
    es.addEventListener(message, (evt) => {
      obs.next(JSON.parse(evt.data))
    })
    return () => es.close()
  })
}

async function requestPermission() {
  if ('Notification' in window) {
    if (Notification.permission !== 'denied' && Notification.permission !== 'granted') {
      // Ask the user for permission
      await Notification.requestPermission()
      window.location.reload()
    }
  }
}

export const NotificationProvider = ({
  children,
  enable,
}: PropsWithChildren & INotificationProvider) => {
  const [showNotification, setShowNotification] = useState(false)
  const [{ title, desc, color }, setNotificationDetails] = useState({
    title: '',
    desc: '',
    color: '',
  })

  const handleOnClick = () => {
    setShowNotification(false)
  }
  const handleDismissClick = () => {
    setShowNotification(false)
  }

  useEffect(() => {
    document.addEventListener('click', () => {
      if (enable) {
        requestPermission()
      }
    })
  }, [enable])

  // useEffect(() => {
  //   if (enable) {
  //     const notification$ = notificationObserver$(EVENT_TYPE).subscribe((data: any) => {
  //       if (document.visibilityState === 'visible') {
  //         setShowNotification(true)
  //         setNotificationDetails({
  //           title: data.title,
  //           desc: `${data.message} on ${moment(data.data.eventTime).format(
  //             'ddd, Do MMMM YYYY [at] HH:mm'
  //           )}`,
  //           color: 'red',
  //         })
  //       } else {
  //         if (Notification.permission === 'granted') {
  //           setShowNotification(false)
  //           var notification = new Notification(data.title, {
  //             body: data.message,
  //             icon: ICON_PATH,
  //             vibrate: [200, 100, 200],
  //           })
  //           notification.onclick = () => {
  //             notification.close()
  //             handleOnClick()
  //           }
  //         }
  //       }
  //     })
  //     return () => {
  //       notification$.unsubscribe()
  //     }
  //   }
  // }, [enable])

  const Portal = createPortal(
    <NotificationBanner
      title={title}
      description={desc}
      bgColor={color}
      onClick={handleOnClick}
      onDismiss={handleDismissClick}
    />,
    portalContentEl as Element
  )

  return (
    <NotificationContext.Provider value={{ enable }}>
      {children}
      <Transition
        show={showNotification && enable}
        enter="transform transition ease-in-out duration-500"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        as="div"
      >
        {Portal}
      </Transition>
    </NotificationContext.Provider>
  )
}
