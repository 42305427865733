import { COLORS } from '../../../../themes/default/colors'
import { BasicModal, Button } from '../../../components'
import { Close } from '../../../components/icons'
import { useAppDispatch } from '../../../hooks'
import { resetState } from '../../../store/source'

interface DeleteAlertProps {
  alertName: string[]
  show: boolean
  onClose?: () => void
  onDelete: (alertId: string[]) => void
}
export default function DeleteAlertModal({ alertName, show, onClose, onDelete }: DeleteAlertProps) {
  const dispatch = useAppDispatch()

  const handleOnClose = () => {
    dispatch(resetState())
    onClose?.()
  }

  const MultipleAlertsDelete = () => {
    return (
      <div className="flex !max-h-[45vh] w-full flex-col gap-5 overflow-scroll py-5">
        <div className="px-5 font-light text-white">
          All {alertName.length} alerts will be deleted permanently.
        </div>
        <div className="max-h-50 flex flex-col overflow-scroll">
          <ol className="flex list-decimal flex-col gap-2 px-10">
            {alertName.map((value, index) => (
              <li key={index} className="text-sm font-extralight text-white">
                {value}
              </li>
            ))}
          </ol>
        </div>
      </div>
    )
  }
  const title = alertName?.length === 1 ? 'Delete alert' : 'Delete alerts'
  return (
    <BasicModal isOpen={show}>
      <div className="laptop:max-h-4/6 relative flex flex-col gap-3 rounded-md border border-warm_grey bg-dark94 py-5 laptop:w-5/12">
        <div className="flex w-full flex-row items-center justify-between px-5">
          <div className="text-l font-RakutenSans font-bold text-white">{title}</div>
          <div className="flex flex-row hover:cursor-pointer" onClick={onClose}>
            <Close className="w-5 hover:cursor-pointer" color={COLORS.WARM_GREY} />
          </div>
        </div>

        <div className="flex w-full flex-col justify-center border border-y-[0.5px] border-x-dark94 border-y-warm_grey">
          {alertName?.length === 1 ? (
            <div className="px-5 py-5 text-sm text-white">
              "{alertName}" will be deleted permanently.
            </div>
          ) : (
            <MultipleAlertsDelete />
          )}
        </div>

        <div className="flex w-full flex-row items-center justify-end gap-5 px-5">
          <Button
            variant="text"
            size="large"
            buttonTextClass="text-base text-warm_grey"
            onClick={handleOnClose}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            size="medium"
            buttonTextClass="text-sm text-white"
            onClick={onDelete}
          >
            Delete Alert
          </Button>
        </div>
      </div>
    </BasicModal>
  )
}
