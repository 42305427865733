import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const CHANGE_ORDER_TEST_ID = 'CHANGE_ORDER_TEST_ID'

export const ChangeOrder = ({
  testId = CHANGE_ORDER_TEST_ID,
  className,
  color = COLORS.PRIMARY,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <polygon
        fill={color}
        points="22.99 4 16 10.99 17.37 12.36 22 7.72 22 28 24 28 24 7.75 28.61 12.36 29.98 10.99 22.99 4"
      />
      <polygon
        fill={color}
        points="9.98 24.28 9.98 4 7.98 4 7.98 24.25 3.37 19.64 2 21.01 8.99 28 15.98 21.01 14.61 19.64 9.98 24.28"
      />
    </svg>
  </div>
)
