import { useMemo } from 'react'
import { Button } from '../../components'
import { QueryType } from '../../store/application'
import { QueryValidateInputProps } from './types'

const isDuplicateQuery = (queries: QueryType[], currentQuery: string) =>
  queries.filter((q) => q.queryString === currentQuery).length > 0

export default function QueryValidateInput({
  value,
  onChange,
  onAddQuery,
  placeholder,
  queries,
  metricType,
}: QueryValidateInputProps) {
  const isDuplicate = useMemo(() => isDuplicateQuery(queries, value), [queries, value])
  return (
    <div className="flex flex-col">
      <div className="flex-1">
        <textarea
          rows={10}
          id="message"
          className={`w-full flex-1 resize-none rounded-sm border border-dark2 bg-dark2 p-3 text-sm text-white placeholder:text-warm_grey focus:border-primary focus:outline-none focus:ring-primary ${
            isDuplicate ? 'focus:border-red-500 focus:ring-red-500' : ''
          }`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      <div className="flex flex-row justify-between">
        <span className={`my-1 text-xs text-warm_grey ${isDuplicate ? '!text-red-500' : ''}`}>
          {isDuplicate
            ? 'Duplicate query'
            : 'Queries with no data will not be added to the application'}
        </span>
        <Button
          styles={{ height: '20px', fontSize: '10px', textTransform: 'none' }}
          className="!mt-0.5 w-3/12"
          variant="contained"
          color="primary"
          onClick={onAddQuery}
          disabled={isDuplicate || value === '' || metricType === ''}
        >
          Add query
        </Button>
      </div>
    </div>
  )
}
