import { ChangeEvent, useMemo, useRef, useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import {
  BasicCard,
  BasicModal,
  Button,
  Checkbox,
  LabelInput,
  Tooltip,
  TryMeModal,
} from '../../components'
import { Close, EyeBanFilled, EyeFilled, ProfileIcon, Questionicon } from '../../components/icons'
import { isFormValid, validateField } from '../../utils'
import { validationMessages, TRY_ME_URL } from '../../consts'
import { changeRegisterValue, registerSelector, useLazySignUpQuery } from '../../store/auth'
import { setAlert } from '../../store/alert'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { isFetchBaseQueryError } from '../../store/api'
import { LOGIN_ROUTES } from '../../routes'
import { PrivacyNotice, TermsOfService } from '../../templates'

export default function SignUp() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState<boolean>(true)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(true)
  const [showTryMeModal, setShowTryMeModal] = useState<boolean>(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isOpenModal, setIsModalOpen] = useState<string | null>(null)
  const signUpCredentials = useAppSelector(registerSelector)
  const iframeRef = useRef(null)
  const [signUp, { isFetching }] = useLazySignUpQuery()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeRegisterValue({ type: e.target.name, value: e.target.value }))
  }

  const isValidEmail = useMemo(
    () => validateField(signUpCredentials.email, ['required', 'email']),
    [signUpCredentials.email]
  )
  const isValidPassword = useMemo(
    () => validateField(signUpCredentials.password, ['required', 'password']),
    [signUpCredentials.password]
  )
  const isValidConfirmPassword = useMemo(
    () =>
      validateField(signUpCredentials.confirmPassword, [
        'required',
        `match:${signUpCredentials.password}`,
      ]),
    [signUpCredentials.confirmPassword, signUpCredentials.password]
  )

  const isValidOrgName = useMemo(
    () => validateField(signUpCredentials.orgName, ['required', 'minChar:2']),
    [signUpCredentials.orgName]
  )
  const isValidDesignation = useMemo(
    () => validateField(signUpCredentials.designation, ['required', 'minChar:2']),
    [signUpCredentials.designation]
  )

  const isSignInDisabled = useMemo(
    () =>
      !isFormValid(
        { ...signUpCredentials },
        {
          email: ['required', 'email'],
          password: ['required', 'password'],
          confirmPassword: ['required', 'password', `match:${signUpCredentials.password}`],
          orgName: ['required', 'minChar:2'],
          designation: ['required', 'minChar:2'],
        }
      ) || !isCheckboxChecked,
    [signUpCredentials, isCheckboxChecked]
  )

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked)
  }

  const handleSubmit = () => {
    const { confirmPassword, ...otherfields } = signUpCredentials
    signUp(otherfields).then(({ isSuccess, isError, data, error }) => {
      if (isSuccess) {
        navigate(`/${LOGIN_ROUTES.VERIFY}`)
      }
      if (isError) {
        if (isFetchBaseQueryError(error)) {
          dispatch(setAlert({ message: error?.data.message }))
        }
      }
    })
  }

  const handleOpenModal = (content: string) => {
    setIsModalOpen(content)
  }

  const handleCloseModal = () => {
    setIsModalOpen(null)
  }

  return (
    <div className="flex w-full items-center justify-center">
      <BasicModal isOpen={!!isOpenModal}>
        <Close
          className="absolute right-10 top-3 h-6 w-6 cursor-pointer"
          pathClasses="fill-primary"
          onClick={handleCloseModal}
        />
        {isOpenModal === 'TERMS_OF_SERVICE' && <TermsOfService />}
        {isOpenModal === 'PRIVACY_NOTICE' && <PrivacyNotice />}
      </BasicModal>
      <TryMeModal initialFocus={iframeRef} isOpen={showTryMeModal} onClose={() => null}>
        <div className="flex h-screen w-full p-10">
          <Close
            className="absolute right-10 top-3 h-6 w-6 cursor-pointer"
            pathClasses="fill-primary"
            onClick={() => setShowTryMeModal(false)}
          />
          <iframe
            ref={iframeRef}
            className="w-full rounded-md border-2 border-gray-500"
            title="CogAI Ops"
            src={TRY_ME_URL}
          />
        </div>
      </TryMeModal>
      <BasicCard
        contentClasses="flex-1"
        containerClasses="flex !p-3 laptop:w-4/12 laptop:h-[42rem] desktop:h-[48rem] desktop:w-4/12 justify-center items-center bg-black "
      >
        <div className="flex w-full flex-col">
          <div className="flex h-20 flex-row justify-between px-2 desktop:px-6">
            <div className="items-center py-1">
              <ProfileIcon className="w-14 desktop:w-14" />
            </div>
            <div className="flex flex-col text-warm desktop:gap-y-1">
              <span className="flex flex-row text-2xl desktop:text-3xl">Signup</span>
              <span className="text-lg desktop:text-2xl">Get started with a free account</span>
            </div>
            <Tooltip
              placement="top"
              title={'Create an account to try SixthSense Cognitive AIOps for free'}
            >
              <Questionicon color="white" className="h-5 w-5 cursor-pointer pt-1.5" />
            </Tooltip>
          </div>
          <div className="flex w-full flex-col gap-y-1 p-2 desktop:gap-y-2 desktop:p-6">
            <LabelInput
              name="email"
              type="email"
              autoComplete="email"
              error={isValidEmail !== ''}
              onKeyDown={(e) => e.key === ' ' && e.preventDefault()}
              value={signUpCredentials.email}
              placeholder="Email"
              onChange={handleChange}
              errorMessage={isValidEmail ? validationMessages.username[isValidEmail] : ''}
            />
            <LabelInput
              name="password"
              type={!showPassword ? 'text' : 'password'}
              value={signUpCredentials.password}
              placeholder="Password"
              onKeyDown={(e) => e.key === ' ' && e.preventDefault()}
              onChange={handleChange}
              trailingComponent={
                showPassword ? (
                  <div onClick={() => setShowPassword(false)}>
                    <EyeFilled className="w-5 cursor-pointer" />
                  </div>
                ) : (
                  <div onClick={() => setShowPassword(true)}>
                    <EyeBanFilled className="w-5 cursor-pointer" />
                  </div>
                )
              }
              error={isValidPassword !== ''}
              errorMessage={isValidPassword ? validationMessages.password[isValidPassword] : ''}
            />
            <LabelInput
              name="confirmPassword"
              type={!showConfirmPassword ? 'text' : 'password'}
              value={signUpCredentials.confirmPassword}
              placeholder="Confirm Password"
              onKeyDown={(e) => e.key === ' ' && e.preventDefault()}
              onChange={handleChange}
              trailingComponent={
                showConfirmPassword ? (
                  <div onClick={() => setShowConfirmPassword(false)}>
                    <EyeFilled className="w-5 cursor-pointer" />
                  </div>
                ) : (
                  <div onClick={() => setShowConfirmPassword(true)}>
                    <EyeBanFilled className="w-5 cursor-pointer" />
                  </div>
                )
              }
              error={isValidConfirmPassword !== ''}
              errorMessage={
                isValidConfirmPassword
                  ? validationMessages.confirmPassword[isValidConfirmPassword]
                  : ''
              }
            />
            <LabelInput
              name="orgName"
              type="text"
              autoComplete="text"
              error={isValidOrgName !== ''}
              value={signUpCredentials.orgName}
              placeholder="Organisation Name"
              onChange={handleChange}
              errorMessage={isValidOrgName ? validationMessages.orgName[isValidOrgName] : ''}
            />
            <LabelInput
              name="designation"
              type="text"
              autoComplete="text"
              error={isValidDesignation !== ''}
              value={signUpCredentials.designation}
              placeholder="Designation"
              onChange={handleChange}
              errorMessage={
                isValidDesignation ? validationMessages.designation[isValidDesignation] : ''
              }
            />
            <div className="whitespace-nowrap text-warm">
              <Checkbox onChange={handleCheckboxChange} checked={isCheckboxChecked} /> I Agree to{' '}
              <span
                className="cursor-pointer text-primary"
                onClick={() => handleOpenModal('TERMS_OF_SERVICE')}
              >
                {' '}
                Terms of service{' '}
              </span>{' '}
              and{' '}
              <span
                className="cursor-pointer text-primary"
                onClick={() => handleOpenModal('PRIVACY_NOTICE')}
              >
                Privacy Notice
              </span>
            </div>

            <Button
              isLoading={isFetching}
              disabled={isSignInDisabled}
              className="h-10 w-full"
              buttonTextClass="text-base"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Send OTP
            </Button>
            <div className="flex flex-row justify-center py-2 text-warm">
              We'll send your verification OTP to your email.
            </div>

            <div className="flex flex-row justify-center py-2 text-warm">
              Already have an account?&nbsp;
              <NavLink to={'/'} className="cursor-pointer text-primary">
                Login
              </NavLink>
            </div>
            {/* <div className="flex flex-row pt-4">
              <Button
                className="h-10 w-full"
                buttonTextClass="font-semibold text-base"
                variant="contained"
                color="primary"
                onClick={() => setShowTryMeModal(true)}
              >
                New here? Take a tour
              </Button>
            </div> */}
          </div>
        </div>
      </BasicCard>
    </div>
  )
}
