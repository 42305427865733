import { useMemo } from 'react'
import { Button, Card } from '../../components'
import { QueryType } from '../../store/application'
import { AddEditApplicationCardProps } from './types'
import AddEditApplicationCardLeftSection from './left-section'
import AddEditApplicationCardRightSection from './right-section'
import { isFormValid } from '../../utils'

export const ADD_EDIT_APPLICATION_CARD_TEST_ID = 'ADD_EDIT_APPLICATION_CARD_TEST_ID'

const isValidQueries = (queries: QueryType[]) =>
  queries.reduce(
    (acc, curr) =>
      curr.id === undefined
        ? (acc && curr.status === 'success' && curr.unit !== '') ||
          (acc && (curr.isDeleted as boolean))
        : acc,
    true
  )

const isAllDeleted = (queries: QueryType[]) =>
  queries.reduce((acc, curr) => acc && (curr.isDeleted as boolean), true)

export default function AddEditApplicationCard({
  sourceName,
  sourceType,
  applicationName,
  metricType,
  jobs,
  metrics,
  selectedJob,
  selectedMetric,
  queries,
  isLoading,
  currentQuery,
  includeHistory,
  historyDays,
  onChange,
  onSave,
  onCancel,
  onAddQuery,
  onDeleteQuery,
  onQueryUnitChange,
}: AddEditApplicationCardProps) {
  const disableSave = useMemo(
    () =>
      isLoading ||
      applicationName === '' ||
      queries.length === 0 ||
      !isValidQueries(queries) ||
      !isFormValid(
        { applicationName: applicationName },
        { applicationName: ['required', 'entityName'] }
      ) ||
      isAllDeleted(queries),
    [applicationName, isLoading, queries]
  )

  return (
    <Card
      testId={ADD_EDIT_APPLICATION_CARD_TEST_ID}
      className="mobile:w-12/12 flex w-6/12 flex-col px-14 py-7 tablet_p:w-11/12 tablet_l:w-10/12 laptop:w-10/12 desktop:w-10/12"
    >
      <div className="flex flex-row">
        <AddEditApplicationCardLeftSection
          sourceName={sourceName}
          sourceType={sourceType}
          applicationName={applicationName}
          metricType={metricType}
          jobs={jobs}
          metrics={metrics}
          selectedJob={selectedJob}
          selectedMetric={selectedMetric}
          queries={queries}
          currentQuery={currentQuery}
          onChange={onChange}
          onAddQuery={onAddQuery}
        />
        <div className="w-5" />
        <AddEditApplicationCardRightSection
          queries={queries}
          includeHistory={includeHistory}
          historyDays={historyDays}
          onChange={onChange}
          onDeleteQuery={onDeleteQuery}
          onQueryUnitChange={onQueryUnitChange}
        />
      </div>
      <div className="mt-2 flex flex-row justify-between">
        <Button className="w-2/12" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          testId={`${ADD_EDIT_APPLICATION_CARD_TEST_ID}_SAVE_BUTTTON`}
          className="w-2/12"
          variant="contained"
          disabled={disableSave}
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </Card>
  )
}
