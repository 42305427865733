import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const HOME_OUTLINE_TEST_ID = 'HOME_OUTLINE_TEST_ID'
export const HOME_FILLED_TEST_ID = 'HOME_FILLED_TEST_ID'

export const HomeOutlined = ({
  testId = HOME_OUTLINE_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  pathClasses,
  onClick,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        className={pathClasses}
        fill={color}
        d="M16 3 2 17h4v10a2 2 0 0 0 2 2h2v-2H8V15H6.83L16 5.83 25.17 15H24v12h-2v2h2a2 2 0 0 0 2-2V17h4Z"
      />
      <path
        className={pathClasses}
        fill={color}
        d="M18 19h-4a2 2 0 0 0-2 2v8h8v-8a2 2 0 0 0-2-2Zm0 8h-4v-6h4Z"
      />
    </svg>
  </div>
)

export const HomeFilled = ({
  testId = HOME_FILLED_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  pathClasses,
  onClick,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 32 32">
      <path
        className={pathClasses}
        fill={color}
        d="M16 3 2 17h4v10a2 2 0 0 0 2 2h3.5v-6.09A2.92 2.92 0 0 1 14.41 20h3.18a2.92 2.92 0 0 1 2.91 2.91V29H24a2 2 0 0 0 2-2V17h4Z"
      />
      <path
        className={pathClasses}
        fill={color}
        d="M17.59 22h-3.18a.91.91 0 0 0-.91.91V29h5v-6.09a.91.91 0 0 0-.91-.91Z"
      />
    </svg>
  </div>
)
