import { SOURCE_API, TEST_CONNECTION_API } from '../../consts'
import { removeTrailingSlash } from '../../utils'
import axios from '../../utils/axios'
import { splitApi, tagTypes } from '../api'
import {
  SourceStateType,
  TestConnectionResponseType,
  TestConnectionRequestType,
  AddSourceRequestType,
  AddSourceResponseType,
  UpdateSourceRequestType,
  GetConnectionResponse,
} from './types'
import {
  mapHeaderAsKeyValue,
  transformAddSourceRequest,
  transformUpdateSourceRequest,
} from './utils'

export const sourceApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    testConnection: build.mutation<TestConnectionResponseType, TestConnectionRequestType>({
      query: ({ url, sourceType, prometheusPrefix }) => ({
        url: TEST_CONNECTION_API,
        method: 'POST',
        body: {
          url,
          sourceType,
          ...(sourceType === 'cortex' ? { prometheusPrefix } : {}),
        },
      }),
    }),
    addSource: build.mutation<string, AddSourceRequestType>({
      query(body) {
        return {
          url: `${SOURCE_API}`,
          method: 'POST',
          body: transformAddSourceRequest(body),
        }
      },
      transformResponse: (result: AddSourceResponseType) => result.message,
      invalidatesTags: [{ type: tagTypes.SOURCES, id: 'LIST' }],
    }),
    updateSource: build.mutation<string, UpdateSourceRequestType>({
      query(body) {
        return {
          url: `${SOURCE_API}`,
          method: 'PUT',
          body: transformUpdateSourceRequest(body),
        }
      },
      transformResponse: (result: AddSourceResponseType) => result.message,
      invalidatesTags: [{ type: tagTypes.SOURCES, id: 'LIST' }],
    }),
    getSourceConnection: build.query({
      query: ({ sourceId }) => `${SOURCE_API}/${sourceId}/connection`,
      transformResponse: (rawResult: GetConnectionResponse) => rawResult,
    }),
  }),
})

export const updatePrometheusSource = (params: SourceStateType) => {
  return axios
    .put(SOURCE_API, {
      id: params.sourceId,
      sourceName: params.sourceName,
      url: removeTrailingSlash(params.url),
      httpMethod: params.httpMethod,
      headers: mapHeaderAsKeyValue(params.headers),
      queryInterval: params.queryInterval,
      scrapeSteps: params.scrapeSteps,
      queryTimeout: params.queryTimeout,
    })
    .then((res) => res.data)
}

export const {
  useTestConnectionMutation,
  useAddSourceMutation,
  useUpdateSourceMutation,
  useLazyGetSourceConnectionQuery,
} = sourceApi
