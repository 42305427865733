export const TrendTypes = {
  UP: 'UP',
  DOWN: 'DOWN',
}

export const SEVERITY_ICON_COLOR_CLASS = {
  outer: ['bg-green-100', 'bg-yellow-100', 'bg-orange-100', 'bg-red-100'],
  inner: ['bg-green-200', 'bg-yellow-200', 'bg-orange-200', 'bg-red-200'],
  background: ['bg-green-500', 'bg-yellow-500', 'bg-orange-500', 'bg-red-500'],
  border: ['border-green-500', 'border-yellow-500', 'border-orange-500', 'border-red-500'],
  stroke: ['stroke-green-500', 'stroke-yellow-500', 'stroke-orange-500', 'stroke-red-500'],
}
