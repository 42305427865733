import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../../hooks'
import { motion } from 'framer-motion'
import { pageTransitionAnimation } from '../../../../../consts'
import { ANOMALIES_ROUTES, SOURCES_ROUTES, appPaths } from '../../../../../routes'
import { ArrowLeft, InfoOutlined } from '../../../../../components/icons'
import { COLORS } from '../../../../../../themes/default/colors'
import { useGetAnomaliesQuery } from '../../../../../store/anomalies'
import { useSourceTabsContext } from '../../../../../providers'
import moment from 'moment'
import { Tooltip } from '../../../../../components'
import { getQueryRange } from '../../../../../store/anomaly-detection'

export default function AnomaliesView() {
  const navigate = useNavigate()
  const { sourceId = '', queryId = '' } = useParams()
  const fromDate = moment().utc().subtract(1, 'day').seconds(0).milliseconds(0).valueOf()
  const toDate = moment().utc().seconds(0).milliseconds(0).valueOf()
  const { currentSourceTab, setCurrentTabState, changeSourceMultivariateDateRange } =
    useSourceTabsContext()
  const { anomaliesDateRange } = currentSourceTab
  const graphDateRange = anomaliesDateRange
  const { fromDate: currentFromDate, toDate: currentToDate } = getQueryRange(graphDateRange)

  const handleOnBackClick = () => {
    const path = appPaths.anomaliesPage({ sourceId })
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: path,
      sourceId,
    })

    navigate(path)
  }
  const { data: anomalies } = useGetAnomaliesQuery({
    id: sourceId,
    level: 'source',
    fromDate: currentFromDate,
    toDate: currentToDate,
  })
  const { breaches, defdevs }: any = anomalies?.find((a) => a.queryId === queryId) || {
    breaches: [],
    defdevs: [],
  }

  return (
    <>
      <div className="flex h-12 w-full flex-row items-center px-10 text-lg">
        <div className="flex w-9">
          <ArrowLeft
            className="w-5 cursor-pointer"
            color={COLORS.PRIMARY}
            onClick={handleOnBackClick}
          />
        </div>
        All anomalies
      </div>
      <div className="flex w-full flex-col px-10">
        <div className="flex h-12 w-2/6 flex-row items-center rounded-t border-b-[0.5px] border-secondary bg-dark94">
          <NavLink
            to={appPaths.anomaliesBreachesTab({
              sourceId,
              queryId: queryId,
            })}
            className={({ isActive }) =>
              `h-12 w-[15em] cursor-pointer justify-center py-3 capitalize
            ${
              isActive || window.location.pathname.includes(SOURCES_ROUTES.ANOMALIES_VIEW)
                ? 'border-b-[2px] border-y-primary text-primary'
                : ''
            }`
            }
          >
            <div className="flex flex-row items-center gap-x-2 px-4">
              Breaches
              <div
                className={`flex min-h-[theme('spacing[4]')] min-w-[theme('spacing[7]')] items-center justify-center rounded-full p-[1.5px] ${
                  window.location.pathname.includes(ANOMALIES_ROUTES.BREACHES)
                    ? 'bg-primary'
                    : 'bg-warm_grey'
                }`}
              >
                <span className="flex h-4 text-xs text-white">{breaches.length}</span>
              </div>
            </div>
          </NavLink>
          <NavLink
            to={appPaths.anomaliesDefDevTab({
              sourceId,
              queryId: queryId,
            })}
            className={({ isActive }) =>
              `h-12 w-[15em] cursor-pointer items-center justify-center py-3 capitalize ${
                isActive && 'border-b-[2px] border-y-primary text-primary '
              }`
            }
          >
            <div className="flex flex-row items-center gap-x-2">
              Divergence
              <div
                className={`flex min-h-[theme('spacing[4]')] min-w-[theme('spacing[7]')] items-center justify-center rounded-full p-[1.5px] ${
                  window.location.pathname.includes(ANOMALIES_ROUTES.DEF_DEV)
                    ? 'bg-primary'
                    : 'bg-warm_grey'
                }`}
              >
                <span className="flex h-4 text-xs text-white">{defdevs.length}</span>
              </div>
              <Tooltip
                placement="top"
                title={
                  <div className="text-xs backdrop-blur-3xl">
                    {'Deviations and deflections from the behaviour in the recent past'}
                  </div>
                }
              >
                <InfoOutlined className="w-5" />
              </Tooltip>
              <div className="flex h-5 w-11 items-center justify-center rounded-md bg-beta font-RakutenSansBold text-xs text-black">
                BETA
              </div>
            </div>
          </NavLink>
        </div>
      </div>
      <motion.div {...pageTransitionAnimation} className="flex-1">
        <Outlet />
      </motion.div>
    </>
  )
}
