import Plot from 'react-plotly.js'
import { BarChartProps } from './types'
import { ellipsizeString } from '../../../utils'
import Card from '../../card'
import { EntityCount } from '../../../store/dashboard'
import { Annotations } from 'plotly.js'
import { COLORS } from '../../../../themes/default/colors'

export const BAR_CHART_TEST_ID = 'BAR_CHART_TEST_ID'

const mapGraphData = (
  x: (string | Date | number)[],
  y: (string | number | null)[][],
  colors: string[],
  orientation: 'h' | 'v' | undefined,
  barTexts: string[][],
  hoverComponents: string[][] | undefined,
  clickData: EntityCount[][] | undefined
): Plotly.Data[] =>
  y.map((data, index) => {
    const customData = clickData ? clickData[index] : undefined
    return {
      x: x,
      y: data,
      name: '',
      type: 'bar',
      orientation: orientation,
      marker: {
        color: colors[index],
      },
      hovertemplate: hoverComponents ? hoverComponents[index] : '<extra></extra>',
      hoverlabel: { align: 'left' },
      customdata: customData as any[],
    }
  })

const BarChart = ({
  testId = BAR_CHART_TEST_ID,
  xAxisData,
  yAxisData,
  orientation,
  colors,
  containerBackgroundColor,
  plotBackgroundColor,
  fontColor,
  xAxisTitle,
  yAxisTitle,
  annotationTexts = [],
  onHover,
  scrollZoom,
  onClick, // Add onClick prop
  heading,
  subheading,
  showViewAllButton,
  onViewAllClick,
  barTexts,
  hoverComponents,
  clickData,
}: BarChartProps) => {
  return (
    <Card className="relative flex !w-1/3 flex-col rounded-md !p-4">
      <div className="mb-2 flex items-center justify-between">
        {/* Render the heading and subheading if provided */}
        <div>
          {heading && <h2 className=" text-white">{heading}</h2>}
          {subheading && <p className="text-xs text-warm_grey">{subheading}</p>}
        </div>
        {/* Conditionally render the "View All" button */}
        {showViewAllButton && (
          <button className="px-4 py-2 text-primary" onClick={onViewAllClick}>
            View All
          </button>
        )}
      </div>
      <Plot
        data-testid={testId}
        className="h-full w-full"
        onHover={onHover}
        onClick={onClick}
        data={mapGraphData(
          xAxisData,
          yAxisData,
          colors,
          orientation,
          barTexts,
          hoverComponents,
          clickData
        )} // Pass data prop
        layout={{
          margin: { r: 10, l: 40, t: 20, b: 40 },
          autosize: true,
          paper_bgcolor: containerBackgroundColor,
          plot_bgcolor: plotBackgroundColor,
          font: {
            color: fontColor,
            size: 12,
          },
          xaxis: {
            tickmode: 'array',
            gridwidth: 1,
            gridcolor: 'rgba(255, 255, 255, 0.5)',
            tickvals: [
              0,
              Math.max(...xAxisData.map((val) => Number(val))) / 2,
              Math.max(...xAxisData.map((val) => Number(val))),
            ],
            ticklen: 5,
            tickwidth: 1,
            title: xAxisTitle,
            titlefont: { size: 12 },
            showspikes: true,
            spikedash: 'solid',
            spikethickness: 0.5,
            spikecolor: 'white',
            tickcolor: 'transparent',
            fixedrange: true,
            showgrid: true,
            griddash: 'dot',
          },
          yaxis: {
            title: yAxisTitle,
            titlefont: { size: 12 },
            showgrid: true,
            rangemode: 'tozero',
            tickvals: yAxisData[0],
            ticktext: xAxisData.map(String),
            automargin: true,
            ticklen: 8,
            tickcolor: 'transparent',
            fixedrange: true,
            autorange: 'reversed',
          },
          annotations: barTexts.flat().map((text, index) => {
            const truncatedText = text.length > 35 ? text.slice(0, 32) + '...' : text
            return {
              x: 0, // Align annotation with the start of the x-axis
              y: yAxisData[Math.floor(index / xAxisData.length)][index % xAxisData.length], // Position annotation at the height of the bar
              text: truncatedText,
              showarrow: false,
              font: {
                size: 12,
                color: COLORS.WHITE,
              },
              xanchor: 'left',
              yanchor: 'middle',
            }
          }) as Partial<Annotations>[],
          hoverlabel: {
            namelength: 20,
            bgcolor: COLORS.HOVER_GRAY,
            font: { color: COLORS.WHITE },
            bordercolor: COLORS.HOVER_GRAY,
          },
          hovermode: 'closest',
        }}
        config={{
          displaylogo: false,
          doubleClick: 'reset+autosize',
          watermark: false,
          scrollZoom,
          modeBarButtons: [[]],
        }}
        useResizeHandler
      />
    </Card>
  )
}

export default BarChart
