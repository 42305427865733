import BasicCard from '../basic-card'
import { SourceCardProps } from './types'
import { PrometheusIcon, MenuVertical, PlusFilled, KafkaIcon, CortexIcon } from '../icons'
import Tooltip from '../tooltip'
import MenuDropdown from '../menu-dropdown'
import { COLORS } from '../../../themes/default/colors'

export const SOURCE_CARD_TEST_ID = 'SOURCE_CARD_TEST_ID'

export default function SourceCard({
  testId = SOURCE_CARD_TEST_ID,
  sourceName,
  applications,
  metrics,
  anomalies,
  sourceType,
  sourceUpdates,
  createdOn,
  containerClasses,
  contentClasses,
  contentStyles,
  onClick,
  onPlusIconClick,
  menuItems,
  isActive,
}: SourceCardProps) {
  const handlePlusIconClick = (e: React.ChangeEvent<HTMLDivElement>) => {
    onPlusIconClick()
    e.stopPropagation()
  }
  return (
    <BasicCard
      testId={testId}
      contentStyles={contentStyles}
      containerClasses={`rounded-md ${
        isActive ? 'border-l-4 border-[#1EA95E]' : 'border-l-4 border-[#E75225]'
      } ${containerClasses}`}
      contentClasses={`${contentClasses}`}
      onClick={onClick}
    >
      <div className="flex flex-row">
        <div className="flex w-4/6 truncate">
          <Tooltip className="border-1 w-auto whitespace-normal" title={sourceName}>
            <span className="w-4/6 font-RakutenSansBold text-base text-white">{sourceName}</span>
          </Tooltip>
        </div>
        <div className="flex w-2/6 flex-row items-center justify-end">
          <div className="mr-1 flex items-center justify-center rounded-sm p-0.5">
            <Tooltip placement="top" title="Add application">
              <PlusFilled
                className="pointer-events-auto flex h-4 w-4"
                color={COLORS.PRIMARY}
                onClick={handlePlusIconClick}
              />
            </Tooltip>
          </div>
          <div
            className="-mr-2 flex items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <MenuDropdown
              menuText={() => <MenuVertical className="h-5 w-5" color={COLORS.PRIMARY} />}
              itemsClasses="w-32 border border-primary"
              menuItems={menuItems}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row text-sm">
        <span className="text-white opacity-50">Source type</span>
        <span className="ml-1 font-RakutenSansBold capitalize text-primary"> {sourceType} </span>
      </div>
      <div className="my-3 flex flex-row justify-between text-sm text-white">
        <div className="flex flex-col">
          <div className="flex flex-col items-center font-RakutenSansBold text-xl text-primary">
            {applications}
          </div>
          <span className="opacity-50 ">Applications</span>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col items-center font-RakutenSansBold text-xl text-primary">
            {metrics}
          </div>
          <span className="opacity-50">Metrics</span>
        </div>
        <div className="flex flex-col ">
          <div className="flex flex-col items-center font-RakutenSansBold text-xl text-primary">
            {anomalies}
          </div>
          <span className="opacity-50">Anomalies</span>
        </div>
      </div>
      <div className="flex w-full flex-row justify-between text-sm">
        <div className="flex w-4/5 flex-col justify-start">
          <div className="flex flex-row whitespace-nowrap text-white opacity-50">
            {sourceType !== 'sixthsense' && (
              <span>
                Updates every <span className=" ml-1 font-RakutenSansBold"> {sourceUpdates} </span>
              </span>
            )}
          </div>
          <div className="mt-1 flex flex-row text-xs text-white opacity-50">
            Created on {createdOn}
          </div>
        </div>
        <div className="flex flex-col items-center pr-1">
          {' '}
          {sourceType == 'sixthsense' ? (
            <KafkaIcon className="h-9 w-[70px]" />
          ) : sourceType == 'cortex' ? (
            <CortexIcon className="h-9 w-9" />
          ) : (
            <span className="pr-3">
              <PrometheusIcon className=" h-9 w-9" />
            </span>
          )}
        </div>
      </div>
    </BasicCard>
  )
}
