import { BasicModal } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import AddEditSourceCard from '../../../partials/add-edit-source-card'
import { tagTypes } from '../../../store/api'
import { dashboardApi } from '../../../store/dashboard'
import {
  changeSourceHeaders,
  changeSourceValues,
  resetState,
  sourceValuesSelector,
  updateSource,
  sourceTypeSelector,
  useTestConnectionMutation,
} from '../../../store/source'

interface EditSourceModalProps {
  show: boolean
  onClose: () => void
}

export const EDIT_SOURCE_MODAL_TEST_ID = 'EDIT_SOURCE_MODAL_TEST_ID'

export default function EditSourceModal({ show, onClose }: EditSourceModalProps) {
  const dispatch = useAppDispatch()
  const source = useAppSelector(sourceValuesSelector)
  const sourceType = useAppSelector(sourceTypeSelector)
  const [
    submitSourceUrl,
    { data: connectionState = { status: null }, isLoading, reset: resetTestConnectionState },
  ] = useTestConnectionMutation()

  const handleInputChange = (type: string, value: string, check?: boolean) => {
    if (check) {
      Number(value) >= 0 && dispatch(changeSourceValues({ type, value }))
    } else {
      dispatch(changeSourceValues({ type, value }))
    }
    if (type === 'url' || type === 'httpMethod' || type === 'prometheusPrefix') {
      resetTestConnectionState()
    }
  }

  const handleHeaderInputChange = (index: number, type: string, value: string) => {
    dispatch(changeSourceHeaders({ index, type, value }))
  }

  const handleTestConnection = async () => {
    await submitSourceUrl({
      url: source.url,
      sourceType: sourceType,
      prometheusPrefix: source.prometheusPrefix,
    })
  }

  const handleOnSaveClick = () => {
    dispatch(updateSource(source)).then(() =>
      dispatch(dashboardApi.util.invalidateTags([tagTypes.SOURCES]))
    )
  }

  const handleOnClose = () => {
    resetTestConnectionState()
    dispatch(resetState())
    onClose?.()
  }

  return (
    <BasicModal testId={`${EDIT_SOURCE_MODAL_TEST_ID}_MODAL`} isOpen={show}>
      <AddEditSourceCard
        {...source}
        onClose={handleOnClose}
        onSave={handleOnSaveClick}
        onHeaderInputChange={handleHeaderInputChange}
        onTestConnection={handleTestConnection}
        onInputChange={handleInputChange}
        connectionState={connectionState as any}
        testConnectionIsLoading={isLoading}
      />
    </BasicModal>
  )
}
