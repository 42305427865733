import { IconProps } from './types'

export const CORTEX_TEST_ID = 'CORTEX_TEST_ID'

export const CortexIcon = ({ testId = CORTEX_TEST_ID, className }: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill="#3B697E" clipPath="url(#clip0_238_35678)">
        <path d="M16 .705C7.564.705.698 7.565.698 16.005c0 8.44 6.86 15.301 15.3 15.301 8.44 0 15.301-6.861 15.301-15.3C31.3 7.565 24.44.704 16 .704zm0 31.302c-8.824 0-16-7.178-16-16C0 7.186 7.177 0 16 0c8.822 0 16 7.179 16 16 0 8.822-7.177 16-16 16"></path>
        <path d="M18.644 6.256a2.648 2.648 0 11-5.297 0 2.648 2.648 0 015.297 0zM27.298 16.362a2.648 2.648 0 11-5.297 0 2.648 2.648 0 015.297 0zM18.585 26.47a2.648 2.648 0 11-5.296 0 2.648 2.648 0 015.296 0z"></path>
        <path d="M7.773 16.356a2.648 2.648 0 11-5.297 0 2.648 2.648 0 015.297 0zm3.345 5.326l-2.092-5.226.437-.175 1.67 4.176 1.894-4.383h2.229l1.995-4.957 1.797 5.134h1.008v.47h-1.34l-1.494-4.268-1.648 4.092h-2.23l-2.226 5.137zm11.187-9.015l-4.701-4.466.323-.34 4.702 4.465-.324.341zm-4.314 12.1l-.332-.331 4.466-4.466.331.332-4.465 4.466z"></path>
        <path d="M24.65 12.21a4.143 4.143 0 00-4.139 4.138 4.144 4.144 0 004.139 4.138 4.144 4.144 0 004.138-4.138 4.144 4.144 0 00-4.139-4.138zm0 8.746a4.615 4.615 0 01-4.608-4.608 4.616 4.616 0 014.607-4.608 4.616 4.616 0 014.609 4.608 4.616 4.616 0 01-4.608 4.608zM9.458 16.715H7.53v-.47h1.929v.47z"></path>
      </g>
    </svg>
  </div>
)
