import { FunctionComponent, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  anomalyStateSelector,
  setShowEditApplicationModal,
  changeGraphDateRange,
  getQueryRange,
  changeCurrentApplicationId,
  ApplicationType,
  useGetApplicationsQuery,
} from '../../../../store/anomaly-detection'
import GraphLoadingMessage from './graph-loading-message'
import EditApplicationModal from './edit-application-modal'
import {
  changeUnitOfQuery,
  resetApplicationState,
  setEditApplication,
} from '../../../../store/application'
import AnomalyGraph from './anomaly-graph'
import { OptionType } from '../../../../types'
import { COLORS } from '../../../../../themes/default/colors'
import { GraphDateRange, Select, Switch } from '../../../../components'
import OptionsWrapper from './option-wrapper'
import { EditOutlined } from '../../../../components/icons'
import { GraphDaterangeType } from '../../../../components/graph-date-range/types'
import { graphDateTabs, sectionalForecastOptions } from './consts'
import { MetricTypes } from '../../../../store/source'
import { useGetQueriesQuery } from '../../../../store/application'
import { pageTransitionAnimation } from '../../../../consts'
import { useSourceTabsContext } from '../../../../providers'
import NoQueriesMessage from './no-queries-message'

export const BEHAVIOUR_TEST_ID = 'BEHAVIOUR_TEST_ID'

export default function Behaviour() {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { applicationId: paramApplicationId, sourceId = '' } = useParams()
  const {
    setCurrentTabState,
    currentSourceTab,
    changeSourceBehaviourDateRange,
    changeSourceMultivariateBehaviourDateRange,
    toggleSourceCompareMode,
  } = useSourceTabsContext()
  const { currentApplicationId, isFetching, showEditApplicationModal } =
    useAppSelector(anomalyStateSelector)
  const {
    behaviourDateRange,
    multivariateBehaviourDateRange,
    behaviourAutoRefresh,
    behaviourAppId,
    behaviourQueries,
  } = currentSourceTab

  const graphDateRange =
    paramApplicationId !== undefined ? multivariateBehaviourDateRange : behaviourDateRange
  const { fromDate: currentFromDate, toDate: currentToDate } = getQueryRange(graphDateRange)

  //RTK query for applications
  const { data: applications = [] } = useGetApplicationsQuery({ sourceId }, { skip: !sourceId })
  const applicationOptions = useMemo(
    () =>
      applications.map((p: ApplicationType) => ({
        label: p.appName,
        value: p.id,
      })),
    [applications]
  )

  //RTK query for queries
  const {
    data: queries = [],
    isLoading: isQueriesLoading,
    isFetching: isQueriesFetching,
  } = useGetQueriesQuery(currentApplicationId, {
    skip: currentApplicationId === '',
  })

  const onGraphDateRange = (params: any) => {
    paramApplicationId === undefined
      ? changeSourceBehaviourDateRange({ sourceId, ...params })
      : changeSourceMultivariateBehaviourDateRange({ sourceId, ...params })
  }

  useEffect(() => {
    if (paramApplicationId !== undefined) {
      dispatch(changeCurrentApplicationId(paramApplicationId))
    } else if (behaviourAppId) {
      dispatch(changeCurrentApplicationId(behaviourAppId))
    } else if (behaviourAppId === undefined && applications.length) {
      setCurrentTabState({ sourceId, behaviourAppId: applications[0].id })
    }
  }, [pathname, behaviourAppId])

  useEffect(() => {
    if (behaviourDateRange === undefined) {
      setCurrentTabState({ sourceId, behaviourDateRange: graphDateRange })
    } else {
      dispatch(changeGraphDateRange(behaviourDateRange))
    }
  }, [pathname])

  const handleEditApplicationClick = () => {
    dispatch(setShowEditApplicationModal(true))
    const application = applications.find((a: ApplicationType) => a.id === currentApplicationId)
    dispatch(
      setEditApplication({
        applicationId: application?.id,
        applicationName: application?.appName,
        includeHistory: application?.includeHistory,
        historyDays: application?.historyDays,
      })
    )
  }

  const handleOnShowForecastChange = (value: boolean) => {
    if (
      value &&
      graphDateRange.rangeType === 'relative' &&
      ['hours', 'days'].includes(graphDateRange.relativeTime.split('-')[1])
    ) {
      onGraphDateRange({ relativeTime: '60-minutes' })
    }
    onGraphDateRange({ showForecast: value })
  }

  const handleGraphDateTabChange = (value: string) => {
    onGraphDateRange({ rangeType: value })
  }

  const handleGraphDateRangeChange = (value: string | { fromDate: number; toDate: number }) => {
    const graphDateRangeValue =
      graphDateRange.rangeType === 'relative'
        ? { relativeTime: value }
        : (value as { [key: string]: number | string })

    onGraphDateRange(graphDateRangeValue)
  }

  const handleAutoRefreshDateRangeChange = (interval: number) => {
    onGraphDateRange({
      fromDate: moment(currentFromDate).add(interval, 'minutes').valueOf(),
      toDate: moment(currentToDate).add(interval, 'minutes').valueOf(),
    })
  }

  const handleCompareIconClick = (queryId: string, compareType: string) => {
    toggleSourceCompareMode({ sourceId, queryId, compareType, isCompareModeOn: true })
  }

  const handlecloseCompareMode = (queryId: string) => {
    toggleSourceCompareMode({ sourceId, queryId, isCompareModeOn: false })
  }

  const onApplicationChange = (option: OptionType) => {
    if (currentApplicationId !== option.value) {
      dispatch(changeCurrentApplicationId(option.value))
      setCurrentTabState({ sourceId, behaviourAppId: option.value })
    }
  }

  const handleQueryUnitChange = (index: string, unit: string) => {
    dispatch(changeUnitOfQuery({ index, unit }))
  }

  const handleMaximiseIconClick = (date: string) => {
    onGraphDateRange({
      fromDate: moment(date).hours(0).minutes(0).seconds(0).valueOf(),
      toDate: moment(date).hours(23).minutes(59).seconds(59).valueOf(),
      rangeType: 'absolute',
    })
  }

  const isAbsoluteRange = graphDateRange?.rangeType === 'absolute'

  useEffect(() => {
    if (currentApplicationId === '' || currentApplicationId === 'all') {
      dispatch(changeCurrentApplicationId(applicationOptions[0]?.value))
      setCurrentTabState({ sourceId, behaviourAppId: applicationOptions[0]?.value })
    }
  }, [])

  return (
    <motion.div {...pageTransitionAnimation}>
      <div
        className="flex h-12 w-full flex-row items-center justify-center px-4 py-1"
        key={`${sourceId}_behaviour_index`}
      >
        <Select
          iconColor={COLORS.SECONDARY}
          className="!w-44 bg-dark2"
          buttonClasses="hover:opacity-70 !h-5 bg-dark2 shadow-none focus:border-none"
          selectedValueClasses={'text-xs'}
          value={
            applicationOptions.find((a: OptionType) => a.value === currentApplicationId) ||
            applicationOptions[0]
          }
          placeholder="Application"
          options={applicationOptions}
          onChange={onApplicationChange}
          OptionsWrapper={OptionsWrapper as FunctionComponent}
        />
        <EditOutlined
          className="ml-2 w-4 cursor-pointer"
          color={COLORS.PRIMARY}
          onClick={handleEditApplicationClick}
        />

        <div className="my-1 flex flex-grow items-center justify-end">
          <div className="flex">
            <GraphDateRange
              tabs={graphDateTabs}
              showForecast={graphDateRange?.showForecast}
              rangeType={graphDateRange?.rangeType}
              options={sectionalForecastOptions}
              infoText="Newer forecast range increases with increase in accuracy of ML models"
              fromDate={graphDateRange?.fromDate}
              toDate={graphDateRange?.toDate}
              relativeValue={graphDateRange?.relativeTime}
              onChange={handleGraphDateRangeChange}
              onTabChange={handleGraphDateTabChange}
              onShowForecastChange={handleOnShowForecastChange}
            />
          </div>

          <div className="flex min-w-[2rem] items-center justify-end pl-2">
            <Switch
              className={isAbsoluteRange ? '!border-warm_grey opacity-50' : ''}
              circleClassName={isAbsoluteRange ? '!opacity-50' : ''}
              checked={behaviourAutoRefresh && !isAbsoluteRange}
              onChange={() =>
                !isAbsoluteRange &&
                setCurrentTabState({ sourceId, behaviourAutoRefresh: !behaviourAutoRefresh })
              }
            />
            <span className={`pl-2 text-xs ${isAbsoluteRange ? '!opacity-50' : ''}`}>
              Auto refresh
            </span>
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto px-4" data-testid="BEHAVIOUR_TEST_ID">
        <EditApplicationModal
          show={showEditApplicationModal}
          onClose={() =>
            dispatch(resetApplicationState()) && dispatch(setShowEditApplicationModal(false))
          }
        />
        <div className="flex h-[84vh] w-full flex-col">
          {isQueriesFetching && isQueriesLoading && queries.length > 0 && <GraphLoadingMessage />}
          {!isQueriesFetching && !isQueriesLoading && queries.length === 0 ? (
            <NoQueriesMessage />
          ) : (
            <div className="grid grid-flow-row grid-cols-1 items-center gap-6">
              {queries.map((query) => {
                const { compareType, isCompareModeOn } =
                  behaviourQueries && query.id in behaviourQueries
                    ? behaviourQueries[query.id]
                    : {
                        compareType: '',
                        isCompareModeOn: false,
                      }
                return (
                  <AnomalyGraph
                    key={query.id}
                    queryId={query.id}
                    applicationId={currentApplicationId}
                    fromDate={currentFromDate}
                    toDate={currentToDate}
                    isAutoRefresh={behaviourAutoRefresh}
                    isAbsoluteGraph={graphDateRange?.rangeType !== 'relative'}
                    onCompareIconClick={handleCompareIconClick}
                    compareType={compareType}
                    isCompareModeOn={isCompareModeOn}
                    onCompareModeBackButtonPress={handlecloseCompareMode}
                    onAutoRefreshIntervalChange={handleAutoRefreshDateRangeChange}
                    metricName={query.queryString}
                    metricType={query.metricType as MetricTypes}
                    onMaximiseIconClick={handleMaximiseIconClick}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}
