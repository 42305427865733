export default function AlertsMainLoader() {
  const tableArray = new Array(10).fill(null)
  return (
    <div className="flex flex-col gap-5 p-5">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <div className="h-[2vh] w-[5vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
          <div className="h-[2vh] w-[15vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        </div>
        <div className="flex flex-row gap-2">
          {tableArray.slice(0, 3).map((_, index) => (
            <div
              key={index}
              className="h-[2vh] w-[8vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"
            ></div>
          ))}
        </div>
      </div>
      <div>
        {tableArray.map((_, index) => (
          <div
            key={index}
            className={`flex !h-[6vh] flex-row items-center justify-between bg-[#222529] px-2 ${
              index !== tableArray.length - 1 ? 'border-b-[0.5px] border-[#3D3F43]' : ''
            }`}
          >
            {new Array(8).fill(null).map((_, index) => (
              <div
                key={index}
                className="h-[2vh] w-[8vw] animate-shimmer rounded-[12px] bg-shimmer-gradient bg-shimmer"
              ></div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
