import Modal from '@mui/material/Modal'
import { BasicModalProps } from './types'

export const MODAL_TEST_ID = 'MODAL_TEST_ID'

export default function BasicModal({
  testId = MODAL_TEST_ID,
  children,
  isOpen,
  onClose,
  className,
}: BasicModalProps) {
  return (
    <Modal
      data-testid={testId}
      className={className}
      open={isOpen}
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <div className="absolute flex h-full w-full items-center justify-center shadow-lg backdrop-blur-[4px]">
        {children}
      </div>
    </Modal>
  )
}
