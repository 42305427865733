import { createSlice } from '@reduxjs/toolkit'
import { ErrorInitialState } from './types'
import { addApplication, addSource } from '../add-application'
import { sourceApi, updateSource } from '../source'
import { authApi } from '../auth'

export const reducerPath = 'alert'

const initialState: ErrorInitialState = {
  message: '',
  severity: 'error',
}

export const alertSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    setAlert: (prevState, { payload }) => {
      return {
        ...prevState,
        message: payload.message,
        severity: payload.severity || 'error',
      }
    },
    setToastMessage: (prevState, { payload }) => {
      return {
        ...prevState,
        ...payload,
      }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(addSource.pending, (state) => {
        state.message = ''
      })
      .addCase(addSource.rejected, (state, { error }) => {
        state.message = error.message
      })
      .addCase(addApplication.pending, (state) => {
        state.message = ''
      })
      .addCase(addApplication.rejected, (state, { error }) => {
        state.message = error.message
      })
      .addCase(updateSource.pending, (state) => {
        state.message = ''
      })
      .addCase(updateSource.fulfilled, (state, { payload }) => {
        state.severity = 'success'
        state.message = payload.message
      })
      .addCase(updateSource.rejected, (state, { error }) => {
        state.message = error.message
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, { error }) => {
        state.message = error.message
      })
      .addMatcher(sourceApi.endpoints.addSource.matchRejected, (state, { payload }) => {
        state.message = `Error ${payload?.status}: Something went wrong`
      })
      .addMatcher(sourceApi.endpoints.addSource.matchFulfilled, (state, { payload }) => {
        state.severity = 'success'
        state.message = payload
      })
  },
})

export const { setAlert, setToastMessage } = alertSlice.actions
