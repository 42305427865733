export const API_URL = `${process.env.REACT_APP_API_URL}/api/`
export const TRY_ME_URL = process.env.REACT_APP_TRY_ME_URL

export const POLLING_INTERVAL = 120000

export const USER_API = {
  SIGN_UP: 'auth/register',
  VERIFY: 'auth/verify',
  RESEND_OTP: 'auth/resend',
  SIGN_IN: 'auth/signin',
  SIGN_OUT: 'auth/signout',
  REFRESH_TOKEN: 'auth/refreshtoken',
}

export const SOURCE_API = 'sources'

export const SOURCE_CONNECTION_API = 'sources/connection'

export const APPLICATION_API = 'applications'

export const METRIC_API = 'metrics'

export const DASHBOARD_API = 'dashboard'

export const QUERIES_API = 'queries'

export const STREAM_METRIC_API = `${API_URL}stream-metrics`
export const STREAM_METRIC_EVENT_TYPE = 'fetch-metrics'
export const STREAM_API_REFRESH_INTERVAL = 60 //Seconds

export const WEBHOOK_API = 'webhook'

export const COMPARE_API = 'compare'

export const FEEDBACK_API = 'metrics/feedback'

export const BREACH_LIST_API = 'correlations'

export const ANOMALY_LIST_API = 'anomalies'

export const ACKNOWLEDGE_ANOMALY_API = 'anomaly/acknowledge'

export const VALIDATE_QUERY = 'queries/validate'

export const ANOMALY_NOTIFICATION_API = `${API_URL}stream-notification?type=anomaly`

export const TEST_CONNECTION_API = 'sources/verify'

export const PROMETHEUS_LABEL_API = 'prometheus/labels'

export const PROMETHEUS_METRIC_VALUE_API = 'prometheus/name'

export const AGGREGATION_API = 'aggregations'

export const CAUSATION_LIST_API = 'causations'

export const CAUSATIONS_GRAPH_API = 'causationGraph'

export const GET_ALL_ALERTS = 'rules'

export const ALERT_RULE_STATUS = 'rules/status'

export const TEST_ALERT_NOTIFICATION = 'rules/channels/test'

export const ANOMALY_TRENDS = 'anomaly/trend'

export const CAUSATION_IMPACT = 'causation/impact'
