import { GraphDaterangeType } from '../../../../components/graph-date-range/types'
import { SectionalOptionType } from '../../../../components/select/types'
import { OptionType } from '../../../../types'

export const graphDateTabs: GraphDaterangeType[] = ['relative', 'absolute']

export const forecastOptions: OptionType[] = [
  { label: 'No forecast', value: 0 },
  { label: '10 mins', value: 10 },
  { label: '30 mins', value: 30 },
  { label: '60 mins', value: 60 },
]

export const sectionalForecastOptions = {
  Minutes: {
    options: [
      { label: '5 mins', value: '5-minutes' },
      { label: '10 mins', value: '10-minutes' },
      { label: '30 mins', value: '30-minutes' },
      { label: '60 mins', value: '60-minutes' },
    ],
    forecastAvailable: true,
  },
  Hours: {
    options: [
      { label: '3 h', value: '3-hours' },
      { label: '6 h', value: '6-hours' },
      { label: '12 h', value: '12-hours' },
      { label: '18 h', value: '18-hours' },
    ],
    forecastAvailable: false,
  },
  Days: {
    options: [
      { label: '1 d', value: '1-days' },
      { label: '2 d', value: '2-days' },
      { label: '4 d', value: '4-days' },
      { label: '7 d', value: '7-days' },
    ],
    forecastAvailable: false,
  },
}
export const anomaliesForecastOptions = {
  Minutes: {
    options: [
      { label: '5 mins', value: '5-minutes' },
      { label: '10 mins', value: '10-minutes' },
      { label: '30 mins', value: '30-minutes' },
      { label: '60 mins', value: '60-minutes' },
    ],
    forecastAvailable: true,
  },
  Hours: {
    options: [
      { label: '3 h', value: '3-hours' },
      { label: '6 h', value: '6-hours' },
      { label: '12 h', value: '12-hours' },
      { label: '18 h', value: '18-hours' },
    ],
    forecastAvailable: false,
  },
  Days: {
    options: [
      { label: '1 d', value: '1-days' },
      { label: '2 d', value: '2-days', disabled: true },
      { label: '4 d', value: '4-days', disabled: true },
      { label: '7 d', value: '7-days', disabled: true },
    ],
    forecastAvailable: false,
  },
}

export const compareIntervalOptions: SectionalOptionType[] = [
  {
    name: 'Hours',
    options: [
      { label: '2 hours', value: '2-hour' },
      { label: '3 hours', value: '3-hour' },
    ],
  },
  {
    name: 'Days',
    options: [
      { label: '2 days', value: '2-day' },
      { label: '3 days', value: '3-day' },
    ],
  },
  {
    name: 'Weeks',
    options: [
      { label: '2 weeks', value: '2-week' },
      { label: '3 weeks', value: '3-week' },
    ],
  },
]

export const severityColorClass = [
  'bg-green-500',
  'bg-yellow-500',
  'bg-orange-500',
  'bg-red-500',
  'bg-purple-500',
]
