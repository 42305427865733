import { BellOutlined } from '../icons/bell'

interface INotificationBanner {
  title: string
  description: string
  bgColor?: string
  onClick?: () => void
  onDismiss?: () => void
}

export default function NotificationBanner({
  title,
  description,
  bgColor,
  onClick,
  onDismiss,
}: INotificationBanner) {
  return (
    <div className="fixed right-5 top-14 z-50 flex cursor-pointer justify-center" onClick={onClick}>
      <div
        className={`mb-4 flex w-96 items-center rounded-lg bg-${bgColor}-500 p-4 text-white shadow-lg`}
      >
        <div className="w-96">
          <h4 className="text-sm font-bold">{title}</h4>
          <p className="text-xs">{description}</p>
        </div>
        <div className="flex w-10 flex-col items-center">
          <div className={`bg-${bgColor}-600 w-full rounded-full p-2`}>
            <BellOutlined pathClasses="fill-white" />
          </div>
          <div className="-mb-3 cursor-pointer py-1 text-xs underline" onClick={onDismiss}>
            Dismiss
          </div>
        </div>
      </div>
    </div>
  )
}
