import { useEffect, useMemo, useRef, useState } from 'react'
import { PlotHoverEvent, PlotMouseEvent } from 'plotly.js'
import { BehaviorSubject, interval } from 'rxjs'
import moment from 'moment'
import {
  AggregationLevels,
  QueryEntities,
  useGetMetricsAverageQuery,
  useLazyGetComparisonsQuery,
  useLazyGetForcastQuery,
  useSubmitFeedbackMutation,
} from '../../../../store/anomaly-detection'
import { ComparisonArrayType, addFeedbackToPredictions } from '../../../../utils'
import { DayWiseTrendType, PredictionType } from './anomaly-graph-informative-section'
import { SeverityTypes } from '../../../../store/anomaly-detection'
import { OptionType } from '../../../../types'
import AnomalyGraphCard, { CompareGraphParamType, QueryGraphStateType } from './anomaly-graph-card'
import { initialAggregationsState, initialPredictions } from '../../../../store/anomalies'
import { MetricTypes } from '../../../../store/source'
import { GraphTooltip } from '../../../../components'
import { SEVERITY_ICON_COLOR_CLASS } from '../../../../store/anomalies'
import { useMousePosition } from '../../../../hooks'
import { POLLING_INTERVAL } from '../../../../consts'

interface AnomalyGraphProps {
  applicationId: string
  queryId: string
  fromDate: number
  toDate: number
  isAutoRefresh: boolean
  isAbsoluteGraph: boolean
  compareType: string
  isCompareModeOn: boolean
  metricName: string
  metricType: keyof typeof MetricTypes
  onCompareIconClick: (queryId: string, compareType: string) => void
  onCompareModeBackButtonPress: (queryId: string) => void
  onAutoRefreshIntervalChange: (interval: number) => void
  onMaximiseIconClick: (date: string) => void
}

const defaultPredictionType: PredictionType = {
  eventTime: '-',
  upperThreshold: '-',
  lowerThreshold: '-',
  groundTruth: '-',
  forecastValue: '-',
  severity: 'None',
  severityType: 0,
  feedback: null,
  showFeedbackIcon: false,
}

const defaultDayWiseTrendsType: DayWiseTrendType = {
  eventTimeDay: '-',
  upperThresholdAvg: 0,
  lowerThresholdAvg: 0,
  groundTruthAvg: 0,
  forecastValueAvg: 0,
  severity: 0,
  totalAnomalies: 0,
}

const predictionTypeKeyMap = new Map([
  ['Upper threshold', 'upperThreshold'],
  ['Confidence', 'lowerThreshold'],
  ['Ground truth', 'groundTruth'],
  ['Forecast value', 'forecastValue'],
  ['Minor breach', 'severity'],
  ['Major breach', 'severity'],
  ['Critical breach', 'severity'],
])

const initialComparisonState = {
  y: [],
  labels: [],
  titles: [],
  severity: [],
}

const initialState: AnomalyGraphState = {
  metricType: '',
  metric: '',
  predictions: {
    eventTimes: [],
    upperThresholds: [],
    lowerThresholds: [],
    groundTruths: [],
    forecastValues: [],
    minorAnomalies: [],
    majorAnomalies: [],
    criticalAnomalies: [],
    feedbacks: {},
    feedbackColors: [],
  },
  anomalyCount: 0,
  isForecastGraphLoading: true,
  isZoomable: false,
  isCompareGraphLoading: false,
  currentCompareInterval: '2-day',
  currentComparisonData: initialComparisonState,
  isSingleLineGraphLoading: false,
}

const initialSingleLineGraphTooltipState = {
  top: 0,
  left: 0,
  minorCount: 0,
  majorCount: 0,
  criticalCount: 0,
  pointerColor: '',
}

const REFRESH_INTERVAL = 60000

interface AnomalyGraphState extends QueryGraphStateType {
  isCompareGraphLoading: boolean
  currentCompareInterval: `${number}-${AggregationLevels}`
  currentComparisonData: ComparisonArrayType
  isSingleLineGraphLoading: boolean
}

const aggregationEntities = [
  QueryEntities.upper_threshold,
  QueryEntities.lower_threshold,
  QueryEntities.forecast_value,
  'counts',
]

export default function AnomalyGraph(props: AnomalyGraphProps) {
  const {
    queryId,
    applicationId,
    fromDate,
    toDate,
    isCompareModeOn,
    metricName,
    metricType,
    onCompareIconClick,
    onCompareModeBackButtonPress,
    compareType,
    isAutoRefresh,
    isAbsoluteGraph,
    onAutoRefreshIntervalChange,
    onMaximiseIconClick,
  } = props

  const dateRangeDiff = useMemo(() => moment(toDate).diff(fromDate, 'days') < 3, [fromDate, toDate])

  const forecastGraphParametersRef$ = useRef<BehaviorSubject<PredictionType>>(
    new BehaviorSubject(defaultPredictionType)
  )

  const compareGraphParametersRef$ = useRef<BehaviorSubject<CompareGraphParamType>>(
    new BehaviorSubject(new Map<string, { x: string; y: string }>([]))
  )

  const singleLineGraphParametersRef$ = useRef<BehaviorSubject<DayWiseTrendType>>(
    new BehaviorSubject(defaultDayWiseTrendsType)
  )

  const currentHoverEventTime = useRef('')

  const shouldChangeOnHover = useRef(true)

  const [currentState, setCurrentState] = useState(initialState)
  const [currentSingleLineGraphTooltip, setCurrentSingleLineGraphTooltip] = useState(
    initialSingleLineGraphTooltipState
  )

  // RTK Query for Get Forecasts
  const [
    getForecast,
    { data: predictions = initialPredictions, isLoading: isForecastGraphLoading },
  ] = useLazyGetForcastQuery({
    pollingInterval: isAutoRefresh ? POLLING_INTERVAL : 0,
  })

  // RTK Query for Get Comparisons
  const [
    getComparisonData,
    { data: currentComparisonData = initialComparisonState, isLoading: isCompareGraphLoading },
  ] = useLazyGetComparisonsQuery()

  const [submitFeedback] = useSubmitFeedbackMutation()

  const { data: dayWiseTrends = initialAggregationsState, isLoading: isSingleLineGraphLoading } =
    useGetMetricsAverageQuery(
      {
        queryId: queryId,
        fromDate: fromDate,
        toDate: toDate,
        aggregationLevel: AggregationLevels.week,
        includeComparison: false,
        includeAggregationEntities: aggregationEntities.join(','),
      },
      { skip: dateRangeDiff }
    )

  useEffect(() => {
    const refresh$ = interval(REFRESH_INTERVAL).subscribe((i) => {
      if (isAutoRefresh && !isAbsoluteGraph) {
        onAutoRefreshIntervalChange(i)
      }
    })

    return refresh$.unsubscribe()
  }, [fromDate, toDate, isAbsoluteGraph, isAutoRefresh])

  useEffect(() => {
    if (dateRangeDiff) {
      getForecast(
        {
          queryId,
          applicationId,
          fromDate,
          toDate,
        },
        false
      )
    }
  }, [applicationId, queryId, fromDate, toDate, getForecast, dateRangeDiff])

  useEffect(() => {
    if (isCompareModeOn) {
      const [durationNumber, aggregationLevel] = currentState.currentCompareInterval.split('-') as [
        number,
        AggregationLevels
      ]
      const entity = compareType.replace(/ /g, '_') as QueryEntities
      getComparisonData(
        {
          queryId,
          aggregationLevel,
          number: durationNumber,
          entity,
        },
        true
      )
    }
  }, [isCompareModeOn, currentState.currentCompareInterval])

  useEffect(
    () => () => {
      //Removing the compare mode on unmount
      onCompareModeBackButtonPress(queryId)
    },
    []
  )

  const onForecastGraphHover = (e: PlotHoverEvent) => {
    if (!shouldChangeOnHover) return

    const { predictions } = currentState

    const { feedbacks } = predictions

    const graphItems = e.points.reduce((acc: PredictionType, curr): PredictionType => {
      const key = curr.data.name
      const severityIndex = SeverityTypes.findIndex((s) => s === key)
      currentHoverEventTime.current = curr.x as string
      return {
        ...acc,
        eventTime: currentHoverEventTime.current,
        [predictionTypeKeyMap.get(key) as string]: curr.y,
        severityType: severityIndex > 0 ? severityIndex : 0,
        showFeedbackIcon: severityIndex > 0,
        feedback: feedbacks[moment(currentHoverEventTime.current).valueOf()],
      }
    }, defaultPredictionType)

    forecastGraphParametersRef$.current.next(graphItems)
  }

  const onDayTrendsGraphHover = (e: PlotHoverEvent) => {
    const dayAverages = dayWiseTrends.average[e.points[0].pointIndex]
    const severity = dayWiseTrends.severity[e.points[0].pointIndex]
    const eventTimeDay = dayWiseTrends.labels[e.points[0].pointIndex]
    const totalAnomalies = dayWiseTrends.totalAnomaliesCount[e.points[0].pointIndex]
    const count = dayWiseTrends.counts[e.points[0].pointIndex]
    singleLineGraphParametersRef$.current.next({
      ...dayAverages,
      severity,
      eventTimeDay,
      totalAnomalies,
    })
  }

  const onForecastGraphClick = (e: PlotMouseEvent) => {
    const selectedData = e.points
    const hasBreach = selectedData.reduce((acc, dataPoint) => {
      return acc || dataPoint.data.name.toLowerCase().includes('breach')
    }, false)

    if (hasBreach) {
      shouldChangeOnHover.current = true
      onForecastGraphHover(e as PlotHoverEvent)
    }
    shouldChangeOnHover.current = !hasBreach
  }

  const onCompareGraphHover = (e: PlotHoverEvent) => {
    const infoMap = new Map<string, { x: string; y: string }>([])

    e.points.forEach((p) => {
      infoMap.set(p.data.name, {
        x: p.x as string,
        y: p.y as string,
      })
    })

    compareGraphParametersRef$.current.next(infoMap)
  }

  const onScrollToZoomChange = (isZoomable: boolean) => {
    setCurrentState({ ...currentState, isZoomable })
  }

  const onIntervalChange = (option: OptionType) => {
    setCurrentState({
      ...currentState,
      currentCompareInterval: option.value as `${number}-${AggregationLevels}`,
    })
  }

  const handleFeedback = async (feedback: boolean) => {
    const eventTime = moment(currentHoverEventTime.current).valueOf()
    const { feedbacks, feedbackColors } = addFeedbackToPredictions(predictions, eventTime, feedback)

    await submitFeedback({
      eventTime,
      feedback,
      queryId,
      applicationId,
      fromDate,
      toDate,
      feedbacks,
      feedbackColors,
    })

    forecastGraphParametersRef$.current.next({
      ...forecastGraphParametersRef$.current.value,
      feedback,
    })
  }

  const { currentCompareInterval, isZoomable, anomalyCount } = currentState

  return (
    <>
      <GraphTooltip
        style={currentSingleLineGraphTooltip}
        pointerColor={currentSingleLineGraphTooltip.pointerColor}
      >
        <p className="pl-2 text-red-400">Critical: {currentSingleLineGraphTooltip.criticalCount}</p>
        <p className="pl-2 text-orange-400">Major: {currentSingleLineGraphTooltip.majorCount}</p>
        <p className="pl-2 text-yellow-400">Minor: {currentSingleLineGraphTooltip.minorCount}</p>
      </GraphTooltip>
      <AnomalyGraphCard
        key={queryId}
        predictions={predictions}
        isForecastGraphLoading={isForecastGraphLoading}
        isZoomable={isZoomable}
        metric={metricName}
        metricType={metricType}
        anomalyCount={anomalyCount}
        onClickFeedback={handleFeedback}
        onIntervalChange={onIntervalChange}
        onScrollZoomChange={onScrollToZoomChange}
        onCompareGraphHover={onCompareGraphHover}
        onForecastGraphClick={onForecastGraphClick}
        onForecastGraphHover={onForecastGraphHover}
        comparisons={currentComparisonData}
        isCompareGraphLoading={isCompareGraphLoading}
        isCompareModeOn={isCompareModeOn}
        onCompareIconClick={(compareType) => onCompareIconClick(queryId, compareType)}
        compareType={compareType}
        forecastGraphParametersRef$={forecastGraphParametersRef$.current}
        compareGraphParametersRef$={compareGraphParametersRef$.current}
        currentCompareInterval={currentCompareInterval}
        onCompareModeBackButtonPress={() => onCompareModeBackButtonPress(queryId)}
        dayWiseTrends={dayWiseTrends}
        showForecastGraph={dateRangeDiff}
        handleMaximiseIconClick={onMaximiseIconClick}
        singleLineGraphParametersRef$={singleLineGraphParametersRef$.current}
        onDayTrendsGraphHover={onDayTrendsGraphHover}
        isSingleLineGraphLoading={isSingleLineGraphLoading}
      />
    </>
  )
}
