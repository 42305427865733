import { IconProps } from './types'
import { COLORS } from '../../../themes/default/colors'

export const DELETE_OUTLINE_TEST_ID = 'DELETE_OUTLINE_TEST_ID'
export const DELETE_FILLED_TEST_ID = 'DELETE_FILLED_TEST_ID'

export const DeleteOutline = ({
  testId = DELETE_OUTLINE_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill={color} d="M24 28H8V9H6v19a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9h-2Z" />
      <path
        fill={color}
        d="M11 11h2v15h-2zM19 11h2v15h-2zM15 11h2v15h-2zM19 5a3 3 0 0 0-6 0H4v2h24V5Z"
      />
    </svg>
  </div>
)

export const DeleteFilled = ({
  testId = DELETE_FILLED_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  onClick,
  pathClasses,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        className={pathClasses}
        fill={color}
        d="M6 28.17A1.92 1.92 0 0 0 8 30h16a1.92 1.92 0 0 0 2-1.83V9H6ZM20 12h2v15h-2Zm-5 0h2v15h-2Zm-5 0h2v15h-2ZM19 5a3 3 0 0 0-6 0H4v2h24V5Z"
      />
    </svg>
  </div>
)
