import { useState } from 'react'
import moment from 'moment'
import { Button, Card, Icon, List, Select, Tooltip } from '../../components'
import { QueryType } from '../../store/application'
import { ChevronDown } from '../../components/icons'
import { COLORS } from '../../../themes/default/colors'
import { unitOptions } from '../../pages/add-application/metric-query-list'

export const EDIT_METRIC_QUERY_LIST_TEST = 'EDIT_METRIC_QUERY_LIST_TEST'

type StatusMapType = 'true' | 'false' | 'loading' | 'success' | 'no_data' | 'error'

const statusMap: {
  [key in StatusMapType]: {
    text: string
    color: string
  }
} = {
  true: { text: 'Active', color: 'border-green-900 bg-green-300 text-green-900' },
  false: { text: 'Inactive', color: 'border-red-900 bg-red-300 text-red-900' },
  loading: { text: 'validating query', color: 'border-yellow-900 bg-yellow-300 text-yellow-900' },
  success: { text: 'Valid', color: 'border-green-900 bg-green-300 text-green-900' },
  no_data: { text: 'No data available', color: 'border-red-900 bg-red-300 text-red-900' },
  error: { text: 'Invalid query', color: 'border-red-900 bg-red-300 text-red-900' },
}

export function CopyTooltip({ value }: { value: string }) {
  const [copyMessage, setCopyMessage] = useState('Copy to clipboard')
  const handleCopyToClipboard = (textToCopy: string) => {
    setCopyMessage('Copied')
    navigator.clipboard.writeText(textToCopy)
    setTimeout(() => {
      setCopyMessage('Copy to clipboard')
    }, 1000)
  }

  return (
    <Tooltip placement="top" className="border-1 w-auto whitespace-normal" title={copyMessage}>
      <Icon.CopyFilled
        pathClasses="hover:fill-primary"
        className="w-4 cursor-pointer"
        onClick={() => handleCopyToClipboard(value)}
      />
    </Tooltip>
  )
}

export default function MetricQueryList({
  queries,
  onDelete,
  onUnitChange,
}: {
  queries: QueryType[]
  onDelete: (id: string) => void
  onUnitChange: (id: string, unit: string) => void
}) {
  const [showMoreInfo, setShowMoreInfo] = useState<string[]>([])
  const [showDeleteAlert, setShowDeleteAlert] = useState<string | null>(null)

  const handleChevronClick = (id: string) =>
    showMoreInfo.includes(id)
      ? setShowMoreInfo(showMoreInfo.filter((i) => i !== id))
      : setShowMoreInfo([...showMoreInfo, id])

  const onDeleteIconClick = (id: string) => {
    setShowDeleteAlert(id)
  }

  const renderDeleteAlert = (id: string) => (
    <div className="flex w-full flex-row bg-red-300 p-5 text-sm text-red-900" role="alert">
      <span className="font-medium">Are you sure ?</span> Data queried through this query will be
      deleted permanently
      <Button
        className="w-2/12 !border-red-900 !text-red-900"
        variant="outlined"
        onClick={() => {
          onDelete(id)
          setShowDeleteAlert(null)
        }}
      >
        Yes
      </Button>
      <div className="h-5 w-5"></div>
      <Button
        className="w-2/12 !bg-red-900"
        variant="contained"
        onClick={() => setShowDeleteAlert(null)}
      >
        No
      </Button>
    </div>
  )

  return (
    <List
      className="h-[22.5rem] w-full flex-col overflow-y-auto rounded-sm border border-warm_grey p-2"
      testId={`${EDIT_METRIC_QUERY_LIST_TEST}_LIST`}
    >
      {queries.map((query) => {
        const id = (query.id || query.requestId) as string
        const isOpen = showMoreInfo.includes(id)
        const showAlert = showDeleteAlert === id
        return (
          <Card
            key={query.queryString}
            className={`mb-1 w-full flex-col rounded-sm !border !bg-dark2 ${
              !showAlert ? '!border-primary py-2 pl-5 pr-2' : '!border-red-900 !p-0'
            } text-white`}
          >
            {showAlert && renderDeleteAlert(id)}
            {!showAlert && (
              <>
                <div className="flex h-7 w-full justify-between">
                  <div className="flex items-end text-sm capitalize">{query.metricType}</div>
                  <div
                    className={`flex h-5 justify-center rounded border px-5 py-0.5 text-[10px] capitalize ${
                      statusMap[
                        (query.id ? String(Boolean(query.active)) : query.status) as StatusMapType
                      ].color
                    }`}
                  >
                    {
                      statusMap[
                        (query.id ? String(Boolean(query.active)) : query.status) as StatusMapType
                      ].text
                    }
                  </div>
                </div>
                <div className="mt-1 flex h-7 w-full justify-between">
                  <div className="w-8/12 truncate">
                    <Tooltip className="border-1 whitespace-normal" title={query.queryString}>
                      <div className="w-full overflow-hidden text-[0.65rem]">
                        {query.queryString}
                      </div>
                    </Tooltip>
                  </div>
                  <div className="flex w-4/12 flex-row items-center justify-end gap-2">
                    {<CopyTooltip value={query.queryString} />}
                    {query.status !== 'loading' && (
                      <Tooltip placement="top" title={'Delete'}>
                        <Icon.DeleteFilled
                          className="w-5 cursor-pointer"
                          onClick={() => onDeleteIconClick(id)}
                          pathClasses="hover:fill-primary"
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="flex w-full text-xs">
                  {(query.id || query.hasUnit) && (
                    <div className="w-2/12 truncate">
                      <div className="flex items-center text-sm">{query.unit}</div>
                    </div>
                  )}
                  {!query.hasUnit && !query.id && (
                    <div className="w-2/12">
                      <Select
                        iconColor={COLORS.PRIMARY}
                        className="!w-full"
                        buttonClasses="hover:opacity-70 !h-6 shadow-none focus:border-none"
                        optionItemClasses="!pl-2 text-xs"
                        selectedIconClasses={'!hidden'}
                        selectedValueClasses={'text-xs'}
                        value={{ label: query.unit, value: query.unit }}
                        placeholder="select unit"
                        options={unitOptions}
                        onChange={(opt) => onUnitChange(id, opt.value.toString())}
                      />
                    </div>
                  )}
                </div>
                <div className="h-[0.5px] w-full bg-warm_grey"></div>
                <div className="mt-1 flex h-6 w-full flex-row justify-between py-1">
                  <div className="w-6/12 font-RakutenSansLight text-xs">
                    Query interval:
                    <span className="ml-1 font-RakutenSans text-sm">
                      {query.queryInterval ? query.queryInterval : '-'}
                    </span>{' '}
                    {query.queryInterval && 'secs'}
                  </div>
                  <div className="w-6/12 font-RakutenSansLight text-xs">
                    Last queried at:
                    <span className="ml-1 font-RakutenSans text-sm">
                      {query.createdOn
                        ? moment(query.createdOn).format('Do MMM YYYY HH:mm:ss')
                        : '-'}
                    </span>
                  </div>
                </div>
              </>
            )}
          </Card>
        )
      })}
    </List>
  )
}
