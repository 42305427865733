import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const SUCCESS_ICON_TEST_ID = 'SUCCESS_ICON_TEST_ID'

export const SuccessIcon = ({
  testId = SUCCESS_ICON_TEST_ID,
  className,
  color = COLORS.WARM,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8 16C26.8 21.9647 21.9647 26.8 16 26.8C10.0353 26.8 5.2 21.9647 5.2 16C5.2 10.0353 10.0353 5.2 16 5.2C21.9647 5.2 26.8 10.0353 26.8 16ZM28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM20.8847 13.4208C21.1203 13.1877 21.1223 12.8078 20.8892 12.5723C20.6562 12.3367 20.2763 12.3347 20.0407 12.5678L13.5399 18.9997L10.6676 16.1335C10.4331 15.8994 10.0532 15.8998 9.81912 16.1344C9.58506 16.3689 9.58546 16.7488 9.82002 16.9829L13.1143 20.2702C13.3478 20.5032 13.7256 20.504 13.9601 20.272L20.8847 13.4208Z"
        fill={color}
      />
    </svg>
  </div>
)
