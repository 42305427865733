export function SeverityStyles(value: number) {
  if (value === 3) {
    return [
      {
        styles: 'text-red-600 text-opacity-90 bg-red-500 bg-opacity-20 px-2 py-0.5 rounded-sm',
        label: 'Critical',
      },
      {
        styles:
          'text-yellow-600 text-opacity-90 bg-yellow-500 bg-opacity-10 px-2 py-0.5 rounded-sm',
        label: 'Major',
      },
    ]
  } else if (value === 2) {
    return {
      styles: 'text-red-600 text-opacity-90 bg-red-500 bg-opacity-20 px-2 py-0.5 rounded-sm',
      label: 'Critical',
    }
  } else if (value === 1) {
    return {
      styles: 'text-yellow-600 text-opacity-90 bg-yellow-500 bg-opacity-10 px-2 py-0.5 rounded-sm',
      label: 'Major',
    }
  }
}

export const severityoptions = [
  { label: 'Major', value: 1 },
  { label: 'Critical', value: 2 },
  { label: 'Major and critical', value: 3 },
]

export const severityOptions = [
  { label: '[Major]', value: 1 },
  { label: '[Critical]', value: 2 },
  { label: '[Major, Critical]', value: 3 },
]
interface ToastMessages {
  [key: string]: {
    line1?: string
    line2?: string
  }
}

export function ToastMessage({ state }: { state: { line1?: string; line2?: string } }) {
  return (
    <div className="flex flex-col gap-2">
      <p className="font-bold text-black">{state.line1}</p>
      <p className="text-sm text-black">{state.line2}</p>
    </div>
  )
}

export const toastMessages: ToastMessages = {
  deleteAlertSuccess: {
    line1: 'Alert(s) deleted!',
    line2: 'Alert(s) has been deleted successfully.',
  },
  newAlertSuccess: {
    line1: 'New alert set!',
    line2: 'New alert has been set successfully.',
  },
  alertEnableSuccess: {
    line1: 'Alert(s) enabled!',
    line2: 'Alert(s) has been enabled successfully.',
  },
  alertDisableSuccess: {
    line1: 'Alert(s) disabled!',
    line2: 'Alert(s) has been disabled successfully.',
  },
  editAlertSuccess: {
    line1: 'Edited alert!',
    line2: 'Alert has been edited successfully.',
  },
  ruleNameExists: {
    line1: 'Alert name already exists',
    line2: 'Enter a different alert name',
  },
}

export function pageSizeOptions(size: number) {
  const options = ['10', '25', '50', '75']
  if (size <= 25) {
    return options.slice(0, 2)
  }
  if (size <= 50) {
    return options.slice(0, 3)
  }
  if (size <= 75) {
    return options
  }
  return options
}
