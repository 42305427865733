import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { AnomalyDetectionState, QueryGraphType } from './types'
import { getQueries } from '../application'
import { anomalyDetectionApi } from './services'

const initialState: AnomalyDetectionState = {
  queries: [],
  applications: [],
  currentApplicationId: '',
  currentSourceId: '',
  applicationOptions: [],
  isLoading: false,
  metricType: '',
  isFirstTimeLoading: false,
  showEditApplicationModal: false,
  isFetching: false,
  graphDateRange: {
    showForecast: true,
    rangeType: 'relative',
    relativeTime: '30-minutes',
    fromDate: moment().subtract(120, 'minutes').valueOf(),
    toDate: moment().valueOf(),
  },
}

export const reducerPath = 'anomalyDetection'

const anomalyDetectionSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    changeCurrentApplicationId: (prevState, { payload }) => {
      return {
        ...prevState,
        currentApplicationId: payload,
        queries: [],
      }
    },
    changeCurrentMetricType: (prevState, { payload }) => {
      return {
        ...prevState,
        metricType: payload,
        queries: [],
      }
    },
    changeCurrentSourceId: (prevState, { payload }) => {
      return {
        ...prevState,
        currentSourceId: payload,
      }
    },
    setGraphFirstTimeLoading: (prevState, { payload }) => {
      return { ...prevState, isFirstTimeLoading: payload }
    },
    setShowEditApplicationModal: (prevState, { payload }) => {
      return { ...prevState, showEditApplicationModal: payload }
    },
    changeGraphDateRange: (prevState, { payload }) => {
      return {
        ...prevState,
        graphDateRange: {
          ...prevState.graphDateRange,
          ...payload,
        },
      }
    },
    toggleCompareMode: (prevState, { payload }) => {
      const queries = prevState.queries.map((q) =>
        q.id === payload.queryId
          ? {
              ...q,
              isCompareModeOn: payload.isCompareModeOn,
              compareType: payload.compareType,
            }
          : q
      )
      return { ...prevState, queries }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQueries.pending, (state) => {
        state.isFetching = true
      })
      .addCase(getQueries.fulfilled, (state, { payload }) => {
        state.isFetching = false
        state.queries = payload.map((p: QueryGraphType) => ({
          ...p,
          metric: p.queryString,
          predictions: [],
          isLoading: true,
          isFetching: true,
          zoomable: false,
          isCompareModeOn: false,
        }))
      })
      .addCase(getQueries.rejected, (state) => {
        state.isFetching = false
      })
      .addMatcher(
        anomalyDetectionApi.endpoints.getApplications.matchFulfilled,
        (state, { payload }) => {
          if (state.currentApplicationId === '') {
            state.currentApplicationId = ''
          }
          if (state.metricType === '') {
            state.metricType = ''
          }
        }
      )
  },
})

export const {
  changeCurrentApplicationId,
  changeCurrentMetricType,
  changeCurrentSourceId,
  setGraphFirstTimeLoading,
  setShowEditApplicationModal,
  changeGraphDateRange,
  toggleCompareMode,
} = anomalyDetectionSlice.actions

export default anomalyDetectionSlice
