import type { ThemeConfig } from 'antd'
import { theme } from 'antd'
import { antSwitch } from './antSwitch'
import { antTable } from './antTable'
import { antCheckbox } from './antCheckbox'
import { antPagination } from './antPagination'
import { antDropdown } from './antDropdown'
import { COLORS } from '../default/colors'

const { getDesignToken, useToken } = theme

const config: ThemeConfig = {
  token: {
    fontFamily: 'RakutenSans',
    colorPrimary: COLORS.PRIMARY,
  },
  components: {
    Switch: antSwitch,
    Table: antTable,
    Checkbox: antCheckbox,
    Pagination: antPagination,
    Dropdown: antDropdown,
  },
}

const globalToken = getDesignToken(config)

export default config
