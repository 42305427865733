import { COLORS } from './colors'

const MuiFormControl = {
  styleOverrides: {
    root: {
      // backgroundColor: COLORS.DARK, // commenting this to check if this is actually needed
      color: COLORS.WHITE,
      border: 'none',
    },
  },
}

export default MuiFormControl
