import Plot from 'react-plotly.js'
import { AnomalyChartProps } from './types'

export const ANOMALY_CHART_TEST_ID = 'ANOMALY_CHART_TEST_ID'

const mapGraphData = (
  x: string[] | Date[],
  y: (number | null)[][],
  colors: string[],
  texts: string[]
): Plotly.Data[] =>
  y.map((data, index) => ({
    x: x,
    y: data,
    fill: 'none',
    line: {
      color: colors[index],
    },
    mode: index < 4 ? 'lines' : 'markers',
    hoverinfo: 'x+y+text',
    text: texts[index],
    showlegend: false,
    ...(index < 2 ? { fill: index === 1 ? 'tonexty' : 'none', fillcolor: '#0089FF4D' } : {}),
  }))

export default function AnomalyChart({
  testId = ANOMALY_CHART_TEST_ID,
  x,
  y,
  colors,
  containerColor,
  plotColor,
  fontColor,
  xTitle,
  yTitle,
  onHover,
  onClick,
  marginTop = 30,
  yTexts,
  colouredArea,
  height = 250,
  marginBottom = 65,
  title,
  titleColor,
}: AnomalyChartProps) {
  return (
    <Plot
      data-testid={testId}
      className="h-full w-full"
      onHover={onHover}
      onClick={onClick}
      data={mapGraphData(x, y, colors, yTexts)}
      layout={{
        height: height,
        margin: { r: 35, t: marginTop, b: marginBottom, l: 70 },
        autosize: true,
        paper_bgcolor: containerColor,
        plot_bgcolor: plotColor,
        font: {
          color: fontColor,
          size: 10,
        },
        xaxis: {
          title: xTitle,
          showspikes: true,
          spikedash: 'solid',
          spikethickness: 1,
          spikecolor: 'white',
          showgrid: false,
        },
        yaxis: {
          title: yTitle,
          griddash: 'dot',
          rangemode: 'tozero',
        },
        hoverlabel: { namelength: 20 },
        hovermode: 'closest',
        shapes: colouredArea?.y1
          ? [
              {
                ...colouredArea,
                type: 'rect',
                line: {
                  color: '#D7465433',
                  width: 0,
                },
                fillcolor: '#D7465433',
              },
            ]
          : [],
        ...(title
          ? {
              title: {
                text: title,
                font: {
                  color: titleColor,
                  size: 10,
                },
                yref: 'paper',
              },
            }
          : {}),
      }}
      config={{
        displaylogo: false,
        doubleClick: 'reset+autosize',
        watermark: false,
        scrollZoom: false,
        modeBarButtons: [[]],
      }}
      useResizeHandler
    />
  )
}
