interface AnomalyCardLoaderProps {
  numberOfItems: number
}

export default function AnomalyCardLoader({ numberOfItems }: AnomalyCardLoaderProps) {
  const anomalyCard = () => {
    return [...Array(numberOfItems)].map((_, index) => (
      <div key={index} className="flex h-52 w-full animate-pulse flex-row bg-dark p-4">
        <div className="flex h-full w-24 flex-col">
          <div className="flex h-16 w-16 flex-col items-center justify-center rounded-full bg-seal_brown" />
        </div>
        <div className="flex w-full flex-col gap-y-4">
          <div className="flex flex-row justify-between">
            <div className="flex h-10 w-2/5 flex-row rounded-md bg-seal_brown" />
            <div className="flex h-6 w-44 flex-row justify-between">
              <div className="flex  w-14 flex-row rounded-lg bg-seal_brown" />
              <div className="flex  w-12 flex-row rounded-lg bg-seal_brown" />
              <div className="flex  w-10 flex-row rounded-lg bg-seal_brown" />
            </div>
          </div>
          <div className="flex h-8 w-4/5 flex-row rounded-md bg-seal_brown" />
          <div className="flex w-full flex-col">
            <div className="flex h-6 w-1/5 flex-row rounded-md bg-seal_brown" />
            <div className="flex h-10 flex-row items-end justify-between">
              <div className="flex h-4 w-36 flex-col rounded-md bg-seal_brown" />
              <div className="flex h-4 w-36 flex-col rounded-md bg-seal_brown" />
              <div className="flex h-4 w-36 flex-col rounded-md bg-seal_brown" />
            </div>
          </div>
        </div>
      </div>
    ))
  }
  return <div className="flex h-full w-full flex-col gap-y-5 px-20 py-10">{anomalyCard()}</div>
}
