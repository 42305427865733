import { COLORS } from './colors'

const MuiTypography = {
  styleOverrides: {
    root: {
      '&.MuiDayPicker-weekDayLabel': {
        color: COLORS.WHITE,
      },
      '&.MuiDayCalendar-weekDayLabel': {
        color: COLORS.PRIMARY,
        width: '30px',
        height: '25px',
      },
    },
  },
}

export default MuiTypography
