const isValidUrl = (value: string) => {
  try {
    new URL(value)
  } catch (error) {
    return true
  }
  return false
}

const isRequired = (value: string) => String(value).trim() === ''

const isEntityName = (value: string) =>
  value.match(/^(?=.{5,50}$)(?:[a-zA-Z\d]+(?:(?:\_)[a-zA-Z\d])*)+$/) === null

const isMin = (value: string, length: number) => Number(value) < length
const isMax = (value: string, length: number) => Number(value) > length

const isCharLength = (value: string, length: number) => value.length < length

const isMaxCharLength = (value: string, length: number) => value.length > length

const isSameLength = (value: string, length: number) => value.length !== Number(length)

const isMatch = (value1: string, value2: string) => value1 !== value2

export const isEmail = (value: string) =>
  value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null

const isEmails = (value: string) =>
  value.match(/^[\w.-]+@\w+(?:\.\w{2,3})+(?:,\s*[\w.-]+@\w+(?:\.\w{2,3})+)*$/) === null

const isArrayLength = (value: string, length: number) => value.split(',').length > length

const isGreaterThan = (value1: string, value2: string) => Number(value2) > Number(value1)

//Password should be minimum 8 characters long and must have one capital letter, one small letter and one special character
const isPassword = (value: string) =>
  value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[$*.[\]{}()?"!@#%&/,><':;|_~`]).{8,50}$/) ===
  null

export const validations: { [key: string]: (v: any, p?: any) => boolean } = {
  url: isValidUrl,
  required: isRequired,
  entityName: isEntityName,
  min: isMin,
  max: isMax,
  email: isEmail,
  password: isPassword,
  minChar: isCharLength,
  maxChar: isMaxCharLength,
  match: isMatch,
  length: isSameLength,
  number: isNaN,
  emails: isEmails,
  arrayLength: isArrayLength,
  greaterThan: isGreaterThan,
}
