export const QIntervalTooltipList = () => (
  <ul className="list-disc pl-[12px]">
    <li>The source will be queried after every interval duration defined in seconds.</li>
    <li>Interval limit min 60 seconds, max 86400 seconds.</li>
  </ul>
)

export const QTimeoutTooltipList = () => (
  <ul className="list-disc pl-[12px]">
    <li>
      Connections will be considered timed out if a response is not received in this duration.
    </li>
    <li> Query timeout Limit: min 1 second, max 5 seconds.</li>
  </ul>
)

export const EntityTooltipList = ({ entityType }: { entityType: string }) => (
  <ul className="list-disc pl-[12px]">
    <li>Enter unique {entityType}.</li>
    <li>Character limit: min 5, max 50.</li>
    <li>No special characters allowed except underscore (No leading or trailing).</li>
    <li>No space allowed.</li>
  </ul>
)

export const ApplicationTooltipList = () => (
  <ul className="list-disc pl-[12px]">
    <li>Enter unique name for your application for which the metrics will be scraped.</li>
    <li>Character limit: min 5, max 50.</li>
    <li>No special characters allowed except underscore (No leading or trailing).</li>
    <li>No space allowed.</li>
  </ul>
)
