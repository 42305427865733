import { Table } from 'antd'
import { AntTableProps } from './types'

export const TABLE_COMPONENT_TEST_ID = 'TABLE_COMPONENT_TEST_ID'

const AntTable = <T extends object>(props: AntTableProps<T>) => {
  return <Table {...props} />
}

export default AntTable
