import { Card } from './../../../components'
import NoDataGraph from '../../../components/icons/no-data-graph.svg'

interface Props {
  heading: any
}

const NoDataMessage: React.FC<Props> = ({ heading }) => {
  return (
    <Card className="relative flex !w-1/3 flex-col justify-center rounded-md !p-4">
      {heading && <h2 className="text-white">{heading}</h2>}
      <div className="flex h-80 flex-col items-center justify-center text-white">
        <img src={NoDataGraph} alt="No Data" className="h-20 w-20" />
        <div className="text-xs text-gray-600">No data available</div>
      </div>
    </Card>
  )
}

export default NoDataMessage
