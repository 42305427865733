import { COLORS } from '../../../themes/default/colors'
import { Button, Card, LabelInput, Select, Spinner } from '../../components'
import { Close } from '../../components/icons'
import { POLLING_INTERVAL, validationMessages } from '../../consts'
import { useGetSourcesByTenantIdQuery } from '../../store/dashboard'
import { SetAlertCardProps } from './types'
import { useEffect, useMemo } from 'react'
import { isFormValid, onlyIntegerKeyDown, validateField } from '../../utils'
import { OptionType } from '../../types'
import { EntityTooltipList } from '../../consts/tooltips'
import TextArea from '../../components/text-area'
import { severityoptions } from '../../pages/dashboard/alert-rules/consts'
import { useAppDispatch } from '../../hooks'
import { resetState } from '../../store/alert-rules/slice'

export default function SetAlertCard({
  onSave,
  onClose,
  handleAlertInputChange,
  onClear,
  handleTestAlertNotification,
  isTestAlertLoading,
  connectionState,
  isAddAlertLoading,
  ruleName,
  anomalyPeriod,
  silencePeriod,
  anomalyCount,
  message,
  values,
  applicationOptions,
  queryOptions,
  appId,
  value,
  severityOption,
  sourceId,
  queryId,
  isEdit,
}: SetAlertCardProps) {
  const dispatch = useAppDispatch()
  const { data: sources = [] } = useGetSourcesByTenantIdQuery(
    {},
    { pollingInterval: POLLING_INTERVAL }
  )

  const sourceOptions = sources.map((source) => ({ label: source.sourceName, value: source.id }))

  const isValidRuleName = useMemo(
    () => validateField(ruleName, ['required', 'entityName']),
    [ruleName]
  )

  const isValidSilencePeriod = useMemo(
    () => validateField(silencePeriod, ['required', 'min:0', 'max:30']),
    [silencePeriod]
  )

  const isValidAnomalyPeriod = useMemo(
    () => validateField(anomalyPeriod, ['required', 'min:1', 'max:60']),
    [anomalyPeriod]
  )

  const isValidMessage = useMemo(() => validateField(message, ['required']), [message])

  const isValidAnomalyCount = useMemo(
    () => validateField(anomalyCount, ['required', 'min:1', 'max:30']),
    [anomalyCount]
  )

  const isValidEmails = useMemo(
    () => validateField(value, ['arrayLength:50', 'required', 'emails']),
    [value]
  )

  const onEmailValuesChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    handleAlertInputChange('value', e.target.value)
    handleAlertInputChange('values', e.target.value.split(','))
  }

  const isValid = !isFormValid(
    {
      ruleName: ruleName,
      message: message,
      anomalyCount: anomalyCount,
      anomalyPeriod: anomalyPeriod,
      silencePeriod: silencePeriod,
      value: value,
    },
    {
      ruleName: ['required', 'entityName'],
      message: ['required'],
      anomalyCount: ['required', 'min:1', 'max:30'],
      anomalyPeriod: ['required', 'min:1', 'max:60'],
      silencePeriod: ['required', 'min:0', 'max:30'],
      value: ['required', 'emails', 'arrayLength:50'],
    }
  )

  const onSourceChange = async (option: OptionType) => {
    handleAlertInputChange('sourceId', option.value as string)
    handleAlertInputChange('queryId', '')
    handleAlertInputChange('appId', '')
    handleAlertInputChange('queryOptions', [])
  }

  const onApplicationChange = async (option: OptionType) => {
    handleAlertInputChange('queryId', '')
    handleAlertInputChange('appId', option.value as string)
  }

  const onQueryIdChange = (option: OptionType) => {
    handleAlertInputChange('queryId', option.value as string)
    handleAlertInputChange('queryIds', [option.value as string])
  }

  const disableTestAlert = useMemo(
    () =>
      isValid ||
      !appId ||
      !queryId ||
      !severityOption ||
      Number(anomalyCount) > Number(anomalyPeriod) ||
      !sourceId,
    [isValid, appId, queryId, severityOption, sourceId, anomalyPeriod, anomalyCount]
  )

  const disableSave = useMemo(
    () => connectionState.status !== 'SUCCESS' || isAddAlertLoading || isValid,
    [connectionState.status, isValid, isAddAlertLoading]
  )

  useEffect(() => {
    if (!isEdit) dispatch(resetState())
  }, [])

  return (
    <Card className="relative flex flex-col overflow-y-auto text-white laptop:h-[48rem] laptop:w-9/12">
      <div className="px-5 ">
        <div className="flex h-8 flex-row justify-between ">
          <div className="flex flex-row text-2xl">
            {isEdit ? 'Edit alert rule' : 'Set alert rule'}
          </div>
          <div className="flex flex-row">
            {' '}
            <button
              role="cancelButton"
              className="right-2.8 top-2.7 absolute text-primary"
              onClick={onClose}
            >
              <Close className="w-5 pt-3 " color={COLORS.WHITE} />
            </button>
          </div>
        </div>
        <div className="flex h-10 flex-row font-RakutenSansLight">
          Configure an alert rule on anomalies
        </div>
        <div className="h-[0.5px] w-full bg-warm"></div>
        <div className="flex flex-col py-5">
          <div className="flex h-[6rem] flex-row justify-between">
            <div>
              <LabelInput
                inputStyles={{ width: '30rem' }}
                required
                label="Alert name"
                error={isValidRuleName !== ''}
                placeholder="Enter alert name"
                value={ruleName}
                onChange={(e) => handleAlertInputChange('ruleName', e.target.value)}
                errorMessage={
                  isValidRuleName !== '' ? validationMessages.ruleName[isValidRuleName] : ''
                }
                labelClasses="!text-sm"
                toolTipMessage={<EntityTooltipList entityType="alert name" />}
                toolTipPlacement="top-start"
                entityValidation={isValidRuleName}
              />
            </div>
            <div>
              <Select
                required
                placeholder="Select severity"
                labelClasses="text-sm"
                options={severityoptions}
                label="Severity"
                value={severityoptions.find((h) => h.value === severityOption)}
                onChange={(option) =>
                  handleAlertInputChange('severityOption', option.value as string)
                }
                className="pt-1"
                buttonClasses="!h-10 !w-[30rem] rounded-t-md bg-dark2 "
                selectedValueClasses="pl-2"
                listboxClasses={'!z-50'}
              />
            </div>
          </div>
          <div className="flex h-[6rem] flex-row justify-between">
            <div>
              <Select
                required
                placeholder="Select source"
                labelClasses="text-sm"
                options={sourceOptions}
                label="Source"
                value={sourceOptions.find((h) => h.value === sourceId)}
                onChange={(option) => onSourceChange(option)}
                className="pt-1"
                buttonClasses="!h-10 !w-72 rounded-t-md bg-dark2"
                selectedValueClasses="pl-2"
                disabled={isEdit}
              />
            </div>
            <div>
              <Select
                required
                placeholder="Select application"
                labelClasses="text-sm"
                options={applicationOptions}
                label="Application name"
                value={applicationOptions.find((h) => h.value === appId)}
                onChange={(option) => onApplicationChange(option)}
                className="pt-1"
                buttonClasses="!h-10 !w-72 rounded-t-md bg-dark2"
                selectedValueClasses="pl-2"
                disabled={sourceId == '' || isEdit}
              />
            </div>
            <div>
              <Select
                required
                placeholder="Select metric"
                labelClasses="text-sm"
                options={queryOptions}
                label="Metric"
                value={queryOptions.find((h) => h.value === queryId)}
                onChange={(option) => onQueryIdChange(option)}
                className="pt-1"
                buttonClasses="!h-10 !w-72 rounded-t-md bg-dark2"
                selectedValueClasses="pl-2"
                disabled={appId == ''}
              />
            </div>
          </div>
          <div className="flex h-[6rem] flex-row justify-between">
            <div>
              <LabelInput
                inputStyles={{ width: '18rem' }}
                type="number"
                required
                label="Anomaly count"
                error={isValidAnomalyCount !== ''}
                placeholder="Enter"
                value={anomalyCount}
                onChange={(e) => handleAlertInputChange('anomalyCount', e.target.value)}
                errorMessage={
                  isValidAnomalyCount ? validationMessages.anomalyCount[isValidAnomalyCount] : ''
                }
                labelClasses="!text-sm"
                toolTipMessage={
                  <ul style={{ listStyleType: 'disc', paddingLeft: '12px' }}>
                    <li>
                      Anomaly count refers to the number of anomalies triggering an alert, ranging
                      from 1 to 30.
                    </li>
                    <li>Ensure that anomaly count is lower than anomaly period.</li>
                  </ul>
                }
                toolTipPlacement="top-start"
                entityValidation={isValidAnomalyCount}
                onKeyDown={onlyIntegerKeyDown}
              />
            </div>
            <div>
              <LabelInput
                type="number"
                inputStyles={{ width: '18rem' }}
                required
                label="Anomaly period"
                error={isValidAnomalyPeriod !== '' || Number(anomalyPeriod) < Number(anomalyCount)}
                placeholder="Enter"
                value={anomalyPeriod}
                onChange={(e) => handleAlertInputChange('anomalyPeriod', e.target.value)}
                errorMessage={
                  isValidAnomalyPeriod
                    ? validationMessages.anomalyPeriod[isValidAnomalyPeriod]
                    : Number(anomalyPeriod) < Number(anomalyCount)
                    ? 'Enter a valid anomaly period'
                    : ''
                }
                labelClasses="!text-sm"
                toolTipMessage={
                  'Anomaly period refers to the duration for counting anomalies,ranging from 1 to 60 minutes. Ensure the anomaly period exceeds anomaly count duration.'
                }
                toolTipPlacement="top-start"
                entityValidation={isValidAnomalyPeriod}
                onKeyDown={onlyIntegerKeyDown}
              />
            </div>
            <div>
              <LabelInput
                type="number"
                inputStyles={{ width: '18rem' }}
                required
                label="Silence period"
                error={isValidSilencePeriod !== ''}
                placeholder="Enter"
                value={silencePeriod}
                onChange={(e) => handleAlertInputChange('silencePeriod', e.target.value)}
                errorMessage={
                  isValidSilencePeriod ? validationMessages.silencePeriod[isValidSilencePeriod] : ''
                }
                labelClasses="!text-sm"
                toolTipMessage={
                  'Specifies the duration during which alert will remains silent, ranging from 0 to 30.'
                }
                toolTipPlacement="top-start"
                entityValidation={isValidSilencePeriod}
                onKeyDown={onlyIntegerKeyDown}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <TextArea
              required
              entityValidation={isValidMessage}
              error={isValidMessage !== ''}
              infoText="Additional message is limited to 50 characters."
              label="Additional message"
              placeholder="Enter message"
              value={message}
              onChange={(e) => handleAlertInputChange('message', e.target.value)}
              errorMessage={isValidMessage ? validationMessages.emails[isValidMessage] : ''}
              maxLength={50}
            />
            <TextArea
              required
              entityValidation={isValidEmails}
              error={isValidEmails !== ''}
              infoText="Email list is limited to 50 addresses per alert.Please enter addresses seperated by comma."
              label="Set emails"
              placeholder="Enter email"
              value={value.trim()}
              onKeyDown={(e) => e.key === ' ' && e.preventDefault()}
              onChange={(e) => onEmailValuesChange(e)}
              errorMessage={isValidEmails ? validationMessages.emails[isValidEmails] : ''}
            />
          </div>
          <div className="flex flex-row pt-3">
            <Button
              className="h-8 w-40"
              styles={{ border: 'rounded-md' }}
              variant="contained"
              disabled={disableTestAlert}
              onClick={handleTestAlertNotification}
              buttonTextClass="text-white"
            >
              Test alert notification
            </Button>
            <div className="flex flex-row items-center justify-between px-3">
              {isTestAlertLoading && <Spinner className="h-6 w-6" />}
              {connectionState.status !== '' && !isTestAlertLoading && (
                <div className="flex flex-row items-center">
                  <span
                    className={`mx-2 rounded border px-2 text-base ${
                      connectionState.status === 'SUCCESS'
                        ? 'border-green-900 bg-green-300 text-green-900'
                        : 'border-red-900 bg-red-300 text-red-900'
                    }`}
                  >
                    {connectionState.status}
                  </span>
                  {connectionState.status === 'SUCCESS' ? (
                    <span className="text-primary"> Notification sent successfully </span>
                  ) : (
                    <span className="text-red-600"> Unable to send notification </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="mt-6 h-[0.5px] w-full bg-warm"></div>
          <div className="bottom-0 mt-6 flex flex-row justify-end gap-x-5">
            <Button
              className="w-30"
              buttonTextClass="font-RakutenSansBold text-sm"
              onClick={onClear}
            >
              Cancel
            </Button>
            <Button className="w-30" variant="contained" onClick={onSave} disabled={disableSave}>
              Set alert
            </Button>
          </div>
        </div>
      </div>
    </Card>
  )
}
