import { PlotHoverEvent, PlotMouseEvent } from 'plotly.js'
import { BehaviorSubject } from 'rxjs'
import { COLORS } from '../../../../../themes/default/colors'
import { Card, ForecastChart } from '../../../../components'
import {
  ANOMALY_GRAPH_LABELS,
  AggregationLevels,
  BREACH_COLORS,
  MetricTypeTitles,
  SEVERITY_COLORS,
  xAggregationLevels,
} from '../../../../store/anomaly-detection'
import {
  AggregationArrayType,
  ComparisonArrayType,
  PredictionArrayType,
  handleNullValues,
} from '../../../../utils'
import AnomalyGraphInformativeSection, {
  DayWiseTrendType,
  PredictionType,
} from './anomaly-graph-informative-section'
import AnomalyGraphLoader from './anomaly-graph-loader'
import NoDataMessage from './no-data-message'
import { MetricTypesType } from '../../../../store/application'
import { CompareChart, SingleLineChart } from '../../../../components/charts'
import AnomalyComparePanel from './anomaly-compare-panel'
import { OptionType } from '../../../../types'
import { compareIntervalOptions, severityColorClass } from './consts'

export type CompareIntervalType = `${number}-${AggregationLevels}`

export type CompareGraphParamType = Map<
  string,
  {
    x: string
    y: string
  }
>
export interface QueryGraphStateType {
  metricType: MetricTypesType | ''
  metric: string
  predictions: PredictionArrayType
  anomalyCount: number
  isForecastGraphLoading: boolean
  isZoomable: boolean
}

interface AnomalyGraphCardProps extends QueryGraphStateType {
  comparisons: ComparisonArrayType
  isCompareGraphLoading: boolean
  isCompareModeOn: boolean
  onForecastGraphClick: (e: PlotMouseEvent) => void
  onForecastGraphHover: (e: PlotHoverEvent) => void
  onCompareGraphHover: (e: PlotHoverEvent) => void
  onCompareIconClick: (compareType: string) => void
  onClickFeedback: (feedback: boolean) => void
  onScrollZoomChange: (zoomable: boolean) => void
  compareType: string
  forecastGraphParametersRef$: BehaviorSubject<PredictionType>
  compareGraphParametersRef$: BehaviorSubject<CompareGraphParamType>
  singleLineGraphParametersRef$: BehaviorSubject<DayWiseTrendType>
  currentCompareInterval: CompareIntervalType
  onIntervalChange: (value: OptionType) => void
  onCompareModeBackButtonPress: () => void
  dayWiseTrends: AggregationArrayType
  showForecastGraph: boolean
  handleMaximiseIconClick: (props: any) => void
  onDayTrendsGraphHover: (e: PlotHoverEvent) => void
  isSingleLineGraphLoading: boolean
  onDayTrendsGraphUnHover?: (e: PlotMouseEvent) => void
}

const chartLegend = (name: string, index: number) => {
  return (
    <div className="flex flex-row items-center gap-x-1">
      <div
        className={`h-[10px] w-[10px] rounded-full border border-none ${severityColorClass[index]}`}
      />
      <span className="text-sm text-white">{name}</span>
    </div>
  )
}

const anomalyChartAnnotation = (anomalyCount: number, anomalyType: string) => {
  return (
    <div className="flex flex-row items-center gap-x-2">
      <div className="text-sm text-primary">{anomalyCount}</div>
      <span className="text-xs text-white">{anomalyType}</span>
    </div>
  )
}

export default function AnomalyGraphCard({
  comparisons,
  isCompareGraphLoading,
  isCompareModeOn,
  onForecastGraphClick,
  onForecastGraphHover,
  onCompareGraphHover,
  onCompareIconClick,
  onClickFeedback,
  onScrollZoomChange,
  compareType,
  forecastGraphParametersRef$,
  onIntervalChange,
  onCompareModeBackButtonPress,
  compareGraphParametersRef$,
  currentCompareInterval,
  predictions,
  metric,
  metricType,
  isZoomable,
  isForecastGraphLoading,
  dayWiseTrends,
  showForecastGraph,
  handleMaximiseIconClick,
  singleLineGraphParametersRef$,
  onDayTrendsGraphHover,
  onDayTrendsGraphUnHover,
  isSingleLineGraphLoading,
}: AnomalyGraphCardProps) {
  const {
    eventTimes,
    upperThresholds,
    lowerThresholds,
    groundTruths,
    forecastValues,
    minorAnomalies,
    majorAnomalies,
    criticalAnomalies,
    feedbackColors,
  } = predictions

  const lowBreachCount = minorAnomalies.filter(Number.isFinite).length
  const moderateBreachCount = majorAnomalies.filter(Number.isFinite).length
  const criticalBreachCount = criticalAnomalies.filter(Number.isFinite).length
  const anomalyCount = lowBreachCount + moderateBreachCount + criticalBreachCount
  let totalAnomalies = 0
  let totalCriticalAnomalies = 0
  let totalMajorAnomalies = 0
  let totalMinorAnomalies = 0

  const updatedUpperThresholds = handleNullValues(upperThresholds)
  const updatedLowerThresholds = handleNullValues(lowerThresholds)

  dayWiseTrends.counts.forEach((count) => {
    totalAnomalies += count.critical + count.major + count.minor
    totalCriticalAnomalies += count.critical
    totalMajorAnomalies += count.major
    totalMinorAnomalies += count.minor
  })

  const [durationNumber, aggregationLevel] = currentCompareInterval.split('-') as [
    number,
    AggregationLevels
  ]

  const selectedCompareInterval = compareIntervalOptions
    .map((o) => o.options)
    .flat()
    .find((c) => c.value === currentCompareInterval)

  return (
    <Card
      className={
        'flex h-80 !w-full items-center justify-center space-x-1 border-none !bg-dark94 !p-0'
      }
    >
      <div className="flex h-full w-10/12 items-center justify-center border-r-2 !border-dark2">
        {((isForecastGraphLoading && !isCompareModeOn) ||
          isCompareGraphLoading ||
          isSingleLineGraphLoading) && <AnomalyGraphLoader />}

        {!isForecastGraphLoading &&
          eventTimes.length <= 0 &&
          !isCompareModeOn &&
          showForecastGraph && <NoDataMessage />}

        {!isForecastGraphLoading &&
          !isCompareModeOn &&
          !isSingleLineGraphLoading &&
          (showForecastGraph
            ? eventTimes.length > 0 && (
                <ForecastChart
                  onClick={onForecastGraphClick}
                  onHover={onForecastGraphHover}
                  xAxisData={eventTimes}
                  yAxisData={[
                    updatedUpperThresholds,
                    updatedLowerThresholds,
                    groundTruths,
                    forecastValues,
                    minorAnomalies,
                    majorAnomalies,
                    criticalAnomalies,
                    feedbackColors,
                  ]}
                  colors={[
                    '#0089FF66',
                    '#0B8BE866',
                    '#F59600',
                    '#0089FF',
                    ...BREACH_COLORS,
                    'purple',
                  ]}
                  labels={[
                    'Upper threshold',
                    'Confidence',
                    'Ground truth',
                    'Forecast value',
                    'Minor breach',
                    'Major breach',
                    'Critical breach',
                    'Feedbacks',
                  ]}
                  plotBackgroundColor={COLORS.DARK94}
                  containerBackgroundColor={COLORS.DARK94}
                  fontColor={COLORS.WHITE}
                  yAxisTitle={MetricTypeTitles[metricType as MetricTypesType]}
                  xAxisTitle={'Time'}
                  annotationTexts={[metric]}
                  scrollZoom={isZoomable}
                  yTexts={ANOMALY_GRAPH_LABELS}
                  children={
                    <div className="absolute right-10 top-1 z-10 flex h-8 w-[60rem] flex-1 flex-row items-center justify-end gap-x-5 overflow-hidden whitespace-nowrap">
                      {anomalyChartAnnotation(anomalyCount, 'Anomalies')}
                      {anomalyChartAnnotation(criticalBreachCount, 'Critical anomalies')}
                      {anomalyChartAnnotation(moderateBreachCount, 'Major anomalies')}
                      {anomalyChartAnnotation(lowBreachCount, 'Minor anomalies')}
                    </div>
                  }
                />
              )
            : !isForecastGraphLoading &&
              !isCompareModeOn &&
              !isSingleLineGraphLoading &&
              (dayWiseTrends.labels.length > 0 ? (
                <SingleLineChart
                  anomalyCounts={dayWiseTrends.counts}
                  x={dayWiseTrends?.labels as string[]}
                  y={dayWiseTrends?.y}
                  onHover={onDayTrendsGraphHover}
                  colors={[...SEVERITY_COLORS]}
                  containerColor={COLORS.DARK94}
                  fontColor={COLORS.WHITE}
                  yTitle={MetricTypeTitles[metricType as MetricTypesType]}
                  xTitle={'Time in days'}
                  annotationTexts={[metric]}
                  plotColor={COLORS.DARK94}
                  severity={dayWiseTrends?.severity as number[]}
                  children={
                    <>
                      <div className="absolute bottom-5 left-16 z-10 flex h-10 w-[35rem] flex-row items-center gap-x-8 whitespace-nowrap">
                        {chartLegend('Critical Anomalies', 3)}
                        {chartLegend('Major Anomalies', 2)}
                        {chartLegend('Minor Anomalies', 1)}
                        {chartLegend('No Anomalies', 0)}
                        <div className="flex flex-row items-center gap-x-1">
                          <div className={`h-1 w-4 border border-none bg-primary`} />
                          <span className="text-sm text-white">Ground Value</span>
                        </div>
                      </div>
                      <div className="absolute right-10 top-6 z-10 flex h-5 w-[60rem] flex-1 flex-row items-center justify-end gap-x-5 overflow-hidden whitespace-nowrap">
                        {anomalyChartAnnotation(totalAnomalies, 'Anomalies')}
                        {anomalyChartAnnotation(totalCriticalAnomalies, 'Critical anomalies')}
                        {anomalyChartAnnotation(totalMajorAnomalies, 'Major anomalies')}
                        {anomalyChartAnnotation(totalMinorAnomalies, 'Minor anomalies')}
                      </div>
                    </>
                  }
                />
              ) : (
                <NoDataMessage />
              )))}

        {!isCompareGraphLoading && comparisons.y.length <= 0 && isCompareModeOn && (
          <NoDataMessage />
        )}
        {!isCompareGraphLoading && comparisons.y.length > 0 && isCompareModeOn && (
          <CompareChart
            onHover={onCompareGraphHover}
            yAxisData={comparisons.y}
            xlabels={comparisons.labels}
            legendSuffix={aggregationLevel}
            plotBackgroundColor={COLORS.DARK94}
            containerBackgroundColor={COLORS.DARK94}
            fontColor={COLORS.WHITE}
            yAxisTitle={MetricTypeTitles[metricType as MetricTypesType]}
            xAxisTitle={`Time in ${xAggregationLevels[aggregationLevel]}`}
            annotationTexts={[metric]}
            tickAngle={-50}
          />
        )}
      </div>
      <div className="!m-0 flex h-full w-2/12 items-center justify-center bg-dark94">
        {!isCompareModeOn && (
          <AnomalyGraphInformativeSection
            onCompareIconClick={(compareType) => onCompareIconClick(compareType)}
            graphInfoSubject={forecastGraphParametersRef$}
            isZoomable={isZoomable}
            onScrollZoomChange={onScrollZoomChange}
            onClickFeedback={onClickFeedback}
            showDayTrends={!showForecastGraph}
            onMaximiseClick={handleMaximiseIconClick}
            dayWiseTrends={singleLineGraphParametersRef$}
          />
        )}

        {isCompareModeOn && (
          <AnomalyComparePanel
            entity={compareType}
            labels={comparisons.titles}
            durationNumber={durationNumber}
            interval={aggregationLevel}
            onIntervalChange={onIntervalChange}
            selectedInterval={selectedCompareInterval as OptionType}
            onBackButtonPress={onCompareModeBackButtonPress}
            graphInfoSubject={compareGraphParametersRef$}
          />
        )}
      </div>
    </Card>
  )
}
