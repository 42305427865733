import { USER_API } from '../../consts'
import { splitApi } from '../api'
import {
  LoginRequestType,
  LoginResponseType,
  OtpResponseType,
  SignUpRequestType,
  SignUpResponseType,
} from './types'

export const authApi = splitApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.query<LoginResponseType, LoginRequestType>({
      query({ username, password }) {
        return {
          url: `${USER_API.SIGN_IN}`,
          method: 'POST',
          body: { username, password },
        }
      },
    }),
    logout: build.query({
      query() {
        return {
          url: `${USER_API.SIGN_OUT}`,
          method: 'POST',
        }
      },
    }),
    signUp: build.query<SignUpResponseType, SignUpRequestType>({
      query(body: SignUpRequestType) {
        return {
          url: `${USER_API.SIGN_UP}`,
          method: 'POST',
          body,
        }
      },
    }),
    otpVerification: build.query<OtpResponseType, number>({
      query(otp) {
        return {
          url: `${USER_API.VERIFY}?otp=${otp}`,
          method: 'GET',
        }
      },
    }),
    resendOtp: build.query<null, null>({
      query() {
        return {
          url: `${USER_API.RESEND_OTP}`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useLazyLoginQuery,
  useLazyLogoutQuery,
  useLazySignUpQuery,
  useLazyOtpVerificationQuery,
  useLazyResendOtpQuery,
} = authApi
