import { COLORS } from './colors'

const MuiInputBase = {
  styleOverrides: {
    root: {
      backgroundColor: COLORS.DARK2,
      color: COLORS.WHITE,
      border: 'none',
      height: '2.5em',
    },
  },
}

export default MuiInputBase
