import { useEffect, useState } from 'react'
import { classNames } from '../../utils'
import { ILabelInput } from './types'
import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  useFormControl,
} from '@mui/material'
import Tooltip from '../tooltip'
import { InfoOutlined } from '../icons'
import { COLORS } from '../../../themes/default/colors'

export const LABEL_INPUT_TEST_ID = 'LABEL_INPUT_TEST_ID'

function Input({
  id,
  error,
  label,
  inputStyles,
  showCount,
  resetCancel,
  inputClasses = '',
  leadingComponent,
  trailingComponent,
  errorMessage,
  errorMessageClasses,
  entityValidation,
  ...otherProps
}: ILabelInput) {
  const { focused } = useFormControl() || {}
  const [isTouched, setIsTouched] = useState(false)
  useEffect(() => {
    if (focused) {
      setIsTouched(true)
    }
    resetCancel && resetCancel(label)
  }, [focused])

  return (
    <>
      <FilledInput
        classes={{ underline: 'border-red-500' }}
        sx={
          !entityValidation
            ? {
                ...(error && { ':after': { border: '1px solid red' } }),
                ...(focused && { ':after': { border: '1px solid primary' } }),
                ...inputStyles,
              }
            : {
                ...(error &&
                  entityValidation !== 'required' && { ':after': { border: '1px solid red' } }),
                ...(focused &&
                  !error &&
                  entityValidation !== 'required' && { ':after': { border: '1px solid primary' } }),
                ...inputStyles,
              }
        }
        hiddenLabel
        inputProps={{ maxLength: 50 }}
        className={classNames('', inputClasses)}
        id={id}
        error={isTouched && error && !otherProps.clickedCancel}
        {...otherProps}
        startAdornment={<InputAdornment position="start">{leadingComponent}</InputAdornment>}
        endAdornment={<InputAdornment position="end">{trailingComponent}</InputAdornment>}
      />
      <div className="flex flex-row justify-between">
        <div
          className={`text-xs text-red-500 ${error !== undefined && 'h-4'} ${errorMessageClasses}`}
        >
          {!entityValidation
            ? isTouched && !focused && errorMessage
            : focused
            ? entityValidation == 'required'
              ? ''
              : errorMessage
            : isTouched && errorMessage}
        </div>
        <span className="text-xs">
          {showCount && `${otherProps.value ? (otherProps.value as string).length : 0}/50`}
        </span>
      </div>
    </>
  )
}

export default function LabelInput({
  testId = LABEL_INPUT_TEST_ID,
  id,
  label,
  labelClasses = '',
  inputClasses = '',
  helperTextClasses = '',
  leadingComponent,
  trailingComponent,
  toolTipMessage,
  toolTipPlacement,
  helperText,
  inputStyles,
  labelStyles,
  required,
  resetCancel,
  showCount,
  error,
  errorMessage,
  labelMessage,
  entityValidation,
  ...otherProps
}: ILabelInput) {
  return (
    <div data-testid={testId} className="flex flex-col ">
      {label && (
        <InputLabel sx={labelStyles} className={classNames('', labelClasses)} htmlFor={id}>
          <div className="flex flex-row">
            {label}
            {required && <span className="ml-0.5 text-red-500 ">*</span>}
            {labelMessage && (
              <span className="px-1 text-[11px] text-warm_grey ">{labelMessage}</span>
            )}
            {toolTipMessage && (
              <Tooltip
                placement={toolTipPlacement}
                title={<div className="text-xs">{toolTipMessage}</div>}
              >
                <InfoOutlined className="ml-1.5 mt-0.5 h-4 w-4" color={COLORS.PRIMARY} />
              </Tooltip>
            )}
          </div>
        </InputLabel>
      )}
      <FormControl fullWidth variant="filled">
        <Input
          data-testid={id}
          id={id}
          error={error}
          resetCancel={resetCancel}
          errorMessage={errorMessage}
          inputStyles={inputStyles}
          inputClasses={inputClasses}
          leadingComponent={leadingComponent}
          trailingComponent={trailingComponent}
          showCount={showCount}
          label={label}
          entityValidation={entityValidation}
          {...otherProps}
        />
      </FormControl>
      <FormHelperText
        data-testid={`${id}-helper-text`}
        id={`${id}-helper-text`}
        className={classNames('whitespace-nowrap', helperTextClasses)}
      >
        {helperText}
      </FormHelperText>
    </div>
  )
}
