import { getBreachInfo } from '../../store/anomalies/utils'
import { SEVERITY_ICON_COLOR_CLASS, SeverityTypes } from '../../store/anomaly-detection'
import Tooltip from '../tooltip'
import { AnomalyListProps } from './types'
import moment from 'moment'

export const ANOMALY_LIST_TEST_ID = 'ANOMALY_LIST_TEST_ID'

export default function AnomalyList({
  testId = ANOMALY_LIST_TEST_ID,
  items,
  listClasses,
  onItemClick,
  selectedQueryId,
  selectedEventTime,
  listRef = {},
  metricName,
  queryId,
}: AnomalyListProps) {
  function selectedItem(itemId: string, itemEventTime: string) {
    return selectedQueryId === itemId && selectedEventTime === itemEventTime
  }

  return (
    <div data-testid={testId} className={`flex flex-col font-RakutenSans ${listClasses}`}>
      {items.map((item, index) => {
        const isMetricAnomaly = 'eventTime' in item

        const { direction, thresholdType, value } = isMetricAnomaly
          ? getBreachInfo(item.groundTruth, item.upperThreshold, item.lowerThreshold)
          : { direction: item.trend, thresholdType: '', value: item.deviation }
        const eventTime = isMetricAnomaly ? item.eventTime : item.anomalyTime
        const severity = isMetricAnomaly ? item.severity : 2
        const isSelected = selectedItem(item.id!, eventTime)

        return (
          <div
            key={`${eventTime}-${index}`}
            ref={listRef[`${item.id}-${index}`]}
            className={`flex cursor-pointer flex-col justify-center px-4 text-base ${
              !isSelected && 'hover:bg-secondary100'
            } ${isSelected && 'bg-primary20'} w-4/8 border-l-2 bg-dark2
          laptop:h-32 desktop:h-28 ${SEVERITY_ICON_COLOR_CLASS.border[severity]}
        `}
            onClick={() => !isSelected && onItemClick(item)}
          >
            <div className="flex flex-col justify-between gap-y-2">
              {isMetricAnomaly ? (
                <div className="flex flex-row text-lg font-bold">{SeverityTypes[severity]}</div>
              ) : (
                <div>
                  <div className="flex flex-row text-lg font-bold capitalize">
                    {item.level} {item.type}
                  </div>
                </div>
              )}
              <div className="flex w-full flex-row text-sm text-white">
                {moment(eventTime).format('ddd, Do MMMM YYYY [at] HH:mm')}
              </div>

              <div className="line-clamp-2 text-sm text-white opacity-50">
                <Tooltip
                  placement="right"
                  title={
                    isMetricAnomaly
                      ? `${item.metricName} of ${item.applicationName} is ${direction} to ${item.groundTruth} and
                        breached ${thresholdType} threshold  of ${value}`
                      : `${item.metricName} of ${item.applicationName} showed 
                      
                    ${
                      item.type === 'deviation'
                        ? `${item.trend === 1 ? `gradual rise` : `gradual degradation`} of ${
                            item.deviation
                          } from the previous pattern since  ${
                            item.level === 'day'
                              ? `${moment(item.deviationStartTime).format('hh:mm A')}`
                              : `${moment(item.deviationStartTime).format('dddd')}`
                          }`
                        : `deflection of ${item.deviation} from the previous ${
                            item.level
                          }'s behaviour ${
                            item.level === 'day'
                              ? `at ${moment(item.anomalyTime).format('hh:mm A')}`
                              : `on ${moment(item.anomalyTime).format('dddd')}`
                          }`
                    }   
                    
                  `
                  }
                >
                  <>
                    <span className="mr-1 font-RakutenSansBold">{item.metricName}</span>
                    of <span className="mr-1 font-RakutenSansBold"> {item.applicationName}</span>
                    {isMetricAnomaly ? (
                      <>
                        is {direction} to{' '}
                        <span className="font-RakutenSansBold">{item.groundTruth}</span> and
                        breached{' '}
                        <span className="font-RakutenSansBold"> {thresholdType} threshold</span> of{' '}
                        <span className="font-RakutenSansBold">{value}</span>
                      </>
                    ) : (
                      <>
                        showed
                        {item.type === 'deviation' ? (
                          <>
                            <div className="font-RakutenSansBold text-primary">
                              {item.trend === 1 ? `gradual rise` : `gradual degradation`}
                            </div>
                            of <div className="font-RakutenSansBold"> {item.deviation}</div> from
                            the previous pattern since
                            <span className="ml-1 font-RakutenSansBold">
                              {moment(item.deviationStartTime).format('hh:mm A')}
                            </span>
                          </>
                        ) : (
                          <>
                            <div className="font-RakutenSansBold text-primary">deflection</div> of
                            <div className="font-RakutenSansBold text-primary">
                              {item.deviation}
                            </div>
                            from previous {item.level}'s behaviour $
                            {item.level === 'day'
                              ? `at ${moment(item.anomalyTime).format('hh:mm A')}`
                              : `on ${moment(item.anomalyTime).format('dddd')}`}
                          </>
                        )}
                      </>
                    )}
                  </>
                </Tooltip>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
