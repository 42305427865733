const MuiYearCalendar = {
  styleOverrides: {
    root: {
      width: '250px',
      '& .MuiPickersYear-yearButton': { fontSize: '0.7rem', height: '25px' },
      '& .MuiPickersYear-yearButton.Mui-selected': { width: '45px' },
    },
  },
}

export default MuiYearCalendar
