import { NavLink } from 'react-router-dom'
import { COLORS } from '../../../../../../themes/default/colors'
import { AnomalyChart, Card, Tooltip } from '../../../../../components'
import { LinkIcon } from '../../../../../components/icons'
import {
  ANOMALY_GRAPH_LABELS,
  BREACH_COLORS,
  SEVERITY_COLORS,
} from '../../../../../store/anomaly-detection'
import { appPaths } from '../../../../../routes'
import { initialPredictions, useGetMetricsQuery } from '../../../../../store/anomalies'
import { handleNullValues } from '../../../../../utils'

interface MultivariateGraphProps {
  sourceId: string
  queryId: string
  applicationId: string
  applicationName: string
  metric: string
  fromDate: number
  toDate: number
  severity: number
  onBehaviourClick: (params: any) => void
}
export default function MultivariateGraphCard({
  applicationName,
  metric,
  applicationId,
  sourceId,
  queryId,
  fromDate,
  toDate,
  severity,
  onBehaviourClick,
}: MultivariateGraphProps) {
  const { data: predictions = initialPredictions, isLoading } = useGetMetricsQuery(
    {
      queryId,
      applicationId,
      fromDate,
      toDate,
    },
    { skip: !queryId }
  )
  const {
    eventTimes,
    upperThresholds,
    lowerThresholds,
    groundTruths,
    forecastValues,
    minorAnomalies,
    majorAnomalies,
    criticalAnomalies,
    metricType,
    firstTimetamp,
    lastTimetamp,
    peakValue,
  } = predictions

  const updatedUpperThresholds = handleNullValues(upperThresholds)
  const updatedLowerThresholds = handleNullValues(lowerThresholds)

  return (
    <Card
      className={`flex !w-full flex-col space-x-1 border-none !bg-dark94 !p-0 ${
        isLoading && 'animate-pulse'
      }`}
    >
      <div className="flex w-full flex-row justify-between px-5 pt-3  text-white">
        {isLoading ? (
          <div className="block h-3 w-52 truncate rounded-md bg-dark" />
        ) : (
          <Tooltip placement="top" title={metric}>
            <div className="block w-60 truncate text-sm">{metric}</div>
          </Tooltip>
        )}

        <div className="flex w-6/12 flex-row items-center justify-end">
          {isLoading ? (
            <div className="block h-3 w-32 truncate rounded-md bg-dark" />
          ) : (
            <>
              <Tooltip placement="top" title={applicationName}>
                <span className="block w-52 truncate text-right text-sm">{applicationName}</span>
              </Tooltip>

              <Tooltip placement="top-start" title={'Go to app'}>
                <NavLink
                  to={appPaths.multivariateBehaviourPage({ sourceId, applicationId })}
                  onClick={() =>
                    onBehaviourClick(
                      appPaths.multivariateBehaviourPage({ sourceId, applicationId })
                    )
                  }
                >
                  <LinkIcon className="ml-2 h-6 w-6 cursor-pointer" />
                </NavLink>
              </Tooltip>
            </>
          )}
        </div>
      </div>

      <div className="mx-4 flex h-full flex-col items-center justify-center">
        {isLoading ? (
          <div className="h-60 w-full"></div>
        ) : (
          <AnomalyChart
            marginTop={20}
            x={eventTimes}
            y={[
              updatedUpperThresholds,
              updatedLowerThresholds,
              groundTruths,
              forecastValues,
              minorAnomalies,
              majorAnomalies,
              criticalAnomalies,
            ]}
            colors={['#0089FF66', '#0089FF66', '#F59600', '#0089FF', ...BREACH_COLORS]}
            plotColor={COLORS.DARK94}
            containerColor={COLORS.DARK94}
            fontColor={COLORS.WARM_GREY}
            yTitle={metricType}
            xTitle={'Time in mins'}
            yTexts={ANOMALY_GRAPH_LABELS}
            colouredArea={{ x0: firstTimetamp, y0: 0, x1: lastTimetamp, y1: peakValue }}
            title={Number(severity || 0) > 0 ? 'Anomalous behaviour' : 'Normal behaviour'}
            titleColor={SEVERITY_COLORS[Number(severity || 0)]}
          />
        )}
      </div>
    </Card>
  )
}
