import { createSlice } from '@reduxjs/toolkit'
import { getWebhook } from './actions'
import { WebhookIntegrationInitialState } from './types'

export const reducerPath = 'webhookIntegration'

const initialState: WebhookIntegrationInitialState = {
  sourceName: '',
  webhook: {
    id: '',
    tenantId: '',
    integrationId: '',
    mappingId: '',
    url: '',
    apiKey: '',
    name: '',
    createdBy: '',
    createdOn: '',
    status: '',
  },
  receivedPayload: '',
  mappedAttributes: [],
  incidentManagerName: '',
  identifierMap: [{ identifier: '', operator: '', identifierValue: '' }],
  currentStep: 0,
  payloadAttributesOptions: [],
  ceAttributeOptions: [],
  isLoading: false,
  identifierOptions: [],
  operatorOptions: [],
}

export const webhookIntegrationSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    goToNextStep: (state) => {
      state.currentStep = state.currentStep + 1
    },
    goToPreviousStep: (state) => {
      state.currentStep = state.currentStep - 1
    },
    changeWebhookFormValue: (prevState, { payload }) => {
      return {
        ...prevState,
        ...payload,
      }
    },
    addIdentifier: (state) => {
      state.identifierMap = [
        ...state.identifierMap,
        { identifier: '', operator: '', identifierValue: '' },
      ]
    },
    changeIdentifierMap: (state, { payload }) => {
      const { index, key, value } = payload
      const identifierMap = state.identifierMap.map((i, idx) =>
        idx === index ? { ...i, [key]: value } : i
      )
      return { ...state, identifierMap }
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getWebhook.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getWebhook.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getWebhook.fulfilled, (state, { payload }) => {
        state.webhook = payload
        state.isLoading = false
      })
  },
})

export const {
  goToNextStep,
  goToPreviousStep,
  changeWebhookFormValue,
  addIdentifier,
  changeIdentifierMap,
} = webhookIntegrationSlice.actions
