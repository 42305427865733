import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { Check, ChevronUp } from '../icons'
import { COLORS } from '../../../themes/default/colors'
import { ListOptionChildProps, OptionsWrapperType, SelectProps, SelectedIcontype } from './types'
import { classNames } from '../../utils'
import { OptionType } from '../../types'

export const SELECT_TEST_ID = 'SELECT_TEST_ID'

const ListOptionChild = ({
  label,
  selected,
  selectedIconType,
  selectedIconClasses,
  active,
}: ListOptionChildProps) => (
  <>
    <span className={`block w-11/12 truncate ${selected ? 'font-medium' : 'font-normal'}`}>
      {label}
    </span>
    <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-black">
      {selectedIconType === 'radio' && (
        <input
          className={`relative float-left mr-1 h-4 w-4 appearance-none rounded-full 
              border-2 border-solid border-primary 
              checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 
              checked:after:top-1/2 checked:after:h-[0.4rem] checked:after:w-[0.4rem] 
              checked:after:rounded-full checked:after:border-primary
               checked:after:bg-primary checked:after:content-[''] 
               checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer
               checked:focus:border-primary ${selectedIconClasses}`}
          type="radio"
          checked={selected}
          readOnly
        />
      )}
      {selected && selectedIconType === 'check' && (
        <Check
          className={`w-4 ${selectedIconClasses}`}
          pathClasses={`${selected && active ? 'fill-black' : 'fill-primary'}`}
          aria-hidden="true"
        />
      )}
    </span>
  </>
)

export const renderOptions = (
  options: OptionType[],
  OptionsWrapper: OptionsWrapperType | undefined,
  selectedIconType: SelectedIcontype,
  selectedIconClasses: string,
  optionItemClasses: string
) =>
  options.map((option) =>
    OptionsWrapper !== undefined ? (
      <OptionsWrapper key={option.label} {...option}>
        <Listbox.Option
          key={option.label}
          className={({ active }) =>
            `relative cursor-pointer select-none py-2 pl-8 pr-4 ${
              active ? 'bg-primary !text-black' : 'text-white'
            } ${optionItemClasses}`
          }
          value={option}
        >
          {({ selected, active }) => (
            <ListOptionChild
              key={option.label}
              selected={selected}
              label={option.label}
              selectedIconType={selectedIconType}
              selectedIconClasses={selectedIconClasses}
              active={active}
            />
          )}
        </Listbox.Option>
      </OptionsWrapper>
    ) : (
      <Listbox.Option
        key={option.label}
        className={({ active }) =>
          `relative cursor-pointer select-none py-2 pl-8 ${
            active ? 'bg-primary !text-black' : 'text-white'
          } ${optionItemClasses}`
        }
        value={option}
      >
        {({ selected, active }) => (
          <ListOptionChild
            key={option.label}
            selected={selected}
            label={option.label}
            selectedIconType={selectedIconType}
            selectedIconClasses={selectedIconClasses}
            active={active}
          />
        )}
      </Listbox.Option>
    )
  )

export default function Select({
  testId = SELECT_TEST_ID,
  className,
  helperText = '',
  options,
  label,
  labelClasses = '',
  id,
  placeholder,
  required,
  value,
  buttonClasses,
  optionClasses,
  selectedValueClasses,
  helperTextClasses,
  onChange,
  disabled = false,
  showIcon = true,
  OptionsWrapper,
  listboxClasses,
  selectedIconType = 'check',
  isSectionalOptions = false,
  openClasses,
  iconColor = COLORS.PRIMARY,
  showText,
  showTextValue,
  selectedIconClasses = '',
  optionItemClasses = '',
}: SelectProps) {
  const hasPlaceHolder = !!placeholder
  return (
    <div className={`w-full ${className}`}>
      {label && (
        <div className={classNames('mb-2 block', labelClasses)} html-for={id}>
          {label} {required && <span className="ml-0.5 text-red-500">*</span>}
        </div>
      )}
      <Listbox
        disabled={disabled}
        className={`relative z-10 ${listboxClasses}`}
        value={value}
        onChange={onChange}
        data-testid={testId}
        as="div"
      >
        {({ open }) => (
          <>
            <Listbox.Button
              className={`relative flex h-8 w-full cursor-pointer items-center rounded-sm border border-dark2  bg-dark  p-2 text-left shadow-md focus:outline-none sm:text-sm 
              ${open ? `border-primary ${openClasses}` : 'border-dark2'}
               ${buttonClasses} `}
            >
              {({ open }) => (
                <>
                  {showText && (
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 text-xs">
                      {showTextValue}
                    </div>
                  )}
                  <span
                    className={`block w-11/12 truncate text-base ${
                      disabled ? 'text-warm_grey' : 'text-white'
                    }
                     ${
                       hasPlaceHolder && !value?.label ? '!text-warm_grey' : ''
                     } ${selectedValueClasses}`}
                  >
                    {value?.label || placeholder}
                  </span>

                  {showIcon && (
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUp
                        color={iconColor}
                        className={`${!open ? 'rotate-180 transform' : ''} w-3`}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </>
              )}
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute mt-1 max-h-60 w-full overflow-auto rounded-sm border border-primary bg-dark py-1 text-base shadow-lg focus:outline-none sm:text-sm ${optionClasses}`}
              >
                {isSectionalOptions === true
                  ? options.map((opt: any, i) => (
                      <>
                        {renderOptions(
                          opt.options,
                          OptionsWrapper,
                          selectedIconType,
                          selectedIconClasses,
                          optionItemClasses
                        )}
                        {i < options.length - 1 && (
                          <div key={i} className="flex h-[0.5px] w-full justify-center">
                            <div key={i} className="w-4/6 bg-warm_grey" />
                          </div>
                        )}
                      </>
                    ))
                  : renderOptions(
                      options as OptionType[],
                      OptionsWrapper,
                      selectedIconType,
                      selectedIconClasses,
                      optionItemClasses
                    )}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
      {helperText !== '' && (
        <div className={`h-4 text-xs text-warm_grey ${helperTextClasses}`}>{helperText}</div>
      )}
    </div>
  )
}
