import { createRef, useEffect, useMemo, useRef } from 'react'
import { motion } from 'framer-motion'
import {
  MetricAnomalyType,
  changeMetricSelectedBreach,
  initialPredictions,
  selectedBreachStateSelector,
  updateSelectedBreachAck,
  useAcknowledgeAnomalyMutation,
  useGetAnomaliesQuery,
  useGetCorelationsQuery,
  useGetCausationsQuery,
  useGetMetricsQuery,
} from '../../../../../store/anomalies'
import AnomalySection from '../anomaly-section'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import moment from 'moment'
import { pageTransitionAnimation } from '../../../../../consts'
import { SOURCES_ROUTES, appPaths } from '../../../../../routes'
import AnomalyGraphSection from '../anomaly-graph-section'
import BreachSectionLoader from '../loaders/breach-section-loader'
import BreachSection from '../breach-section'
import { GraphAbsoluteRangeType } from '../../../../../components/graph-date-range/types'
import { useSourceTabsContext } from '../../../../../providers'
import AnomalySectionLoader from '../loaders/anomaly-section-loader'
import { getQueryRange } from '../../../../../store/anomaly-detection'

export default function BreachesTab() {
  const { sourceId = '', queryId = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { currentSourceTab, setCurrentTabState, changeSourceMultivariateDateRange } =
    useSourceTabsContext()
  const { anomaliesDateRange } = currentSourceTab
  const graphDateRange = anomaliesDateRange
  const selectedBreach = useAppSelector(selectedBreachStateSelector(sourceId))
  const scrollRef = useRef(false)
  const { fromDate: currentFromDate, toDate: currentToDate } = getQueryRange(graphDateRange)
  // RTK Query for Get Anomalies
  const { data: anomalies, isLoading: isBreachesLoading } = useGetAnomaliesQuery({
    id: sourceId,
    level: 'source',
    fromDate: currentFromDate,
    toDate: currentToDate,
  })
  const {
    breaches,
    metricName = '',
    metricType = '',
  }: any = anomalies?.find((a) => a.queryId === queryId) || {
    breaches: [],
  }

  // RTK Query for Get Correlations
  const {
    data: corelations = [],
    isLoading: isCorelationsLoading,
    isFetching: isCorelationsFetching,
  } = useGetCorelationsQuery(
    {
      eventTime: moment(selectedBreach.eventTime).utc().valueOf(),
      queryId: queryId,
    },
    { skip: !queryId || breaches.length === 0 }
  )
  // RTK Query for Get Metrics
  const { data: predictions = initialPredictions, isLoading: isPredictionsLoading } =
    useGetMetricsQuery(
      {
        queryId: queryId,
        applicationId: selectedBreach.applicationId,
        fromDate: moment(selectedBreach.eventTime).utc().subtract(30, 'minutes').valueOf(),
        toDate: moment(selectedBreach.eventTime).utc().add(30, 'minutes').valueOf(),
      },
      { skip: !queryId || breaches.length === 0 }
    )
  // RTK Query for acknowledging the anomaly
  const [ackAnomaly, { isLoading: isAcknowledging }] = useAcknowledgeAnomalyMutation()
  // RTK Query for Get Causations
  const {
    data: causations = [],
    isLoading: isCausationsLoading,
    isFetching: isCausationsFetching,
  } = useGetCausationsQuery(
    {
      queryId,
      eventTime: moment(selectedBreach.eventTime).utc().valueOf(),
    },
    { skip: !queryId || breaches.length === 0 }
  )

  const unacknowledgedAnomalies = breaches.filter(
    (breach: { acknowledge: boolean }) => breach.acknowledge !== true
  )
  const acknowledgedAnomalies = breaches.filter(
    (breach: { acknowledge: boolean }) => breach.acknowledge === true
  )
  const anomaliesRef = breaches.reduce(
    (acc: any, curr: any) => ({
      ...acc,
      [`${queryId}-${curr.eventTime}`]: createRef<HTMLDivElement>(),
    }),
    {} as any
  )

  useEffect(() => {
    if (
      scrollRef.current &&
      anomaliesRef[`${queryId}-${selectedBreach.eventTime}`].current != null
    ) {
      anomaliesRef[`${queryId}-${selectedBreach.eventTime}`].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
      scrollRef.current = false
    }
  })

  const minutesDifference = useMemo(
    () => moment().diff(moment(currentSourceTab.details.createdOn), 'minutes'),
    [currentSourceTab.details.createdOn]
  )

  const handleAnomalyListItemClick = (anomaly: MetricAnomalyType) =>
    dispatch(changeMetricSelectedBreach({ sourceId, queryId, ...anomaly }))

  const handleOnAcknowledge = async () => {
    await ackAnomaly({
      eventTime: moment(selectedBreach.eventTime).utc().valueOf().toString(),
      acknowledge: true,
      queryId: queryId,
    })
      .unwrap()
      .then(() => dispatch(updateSelectedBreachAck({ sourceId })))
    scrollRef.current = true
  }

  const handleViewMultivariateRouteState = (
    applicationId: string,
    value: string | GraphAbsoluteRangeType
  ) => {
    const nextPath = `${appPaths.multivariatePage({
      sourceId,
      applicationId: applicationId,
    })}`
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: nextPath,
      sourceId,
      multivariateDateRange: {
        fromDate: moment(selectedBreach.eventTime).utc().subtract(30, 'minutes').valueOf(),
        toDate: moment(selectedBreach.eventTime).utc().add(30, 'minutes').valueOf(),
      },
      multivariateBehaviourDateRange: {
        showForecast: false,
        rangeType: 'absolute',
        relativeTime: '30-minutes',
        fromDate: moment(selectedBreach.eventTime).utc().subtract(30, 'minutes').valueOf(),
        toDate: moment(selectedBreach.eventTime).utc().add(30, 'minutes').valueOf(),
      },
    })

    changeSourceMultivariateDateRange({
      sourceId,
      ...(value as GraphAbsoluteRangeType),
    })

    navigate(nextPath)
  }

  const goToApplicationBehaviour = (nextPath: string) => {
    dispatch(
      setCurrentTabState({
        prevActiveTab: SOURCES_ROUTES.ANOMALIES,
        prevAnomalyPath: nextPath,
        sourceId,
        multivariateBehaviourDateRange: {
          showForecast: false,
          rangeType: 'absolute',
          relativeTime: '30-minutes',
          fromDate: moment(selectedBreach.eventTime).utc().subtract(30, 'minutes').valueOf(),
          toDate: moment(selectedBreach.eventTime).utc().add(30, 'minutes').valueOf(),
        },
      })
    )
  }

  return (
    <motion.div {...pageTransitionAnimation}>
      <div className="flex h-[75vh] w-screen overflow-y-hidden px-10">
        <div className="flex h-full w-full flex-row bg-dark94 ">
          <div className="flex w-2/6">
            {isBreachesLoading ? (
              <AnomalySectionLoader numberOfItems={4} />
            ) : (
              <AnomalySection
                selectedBreach={selectedBreach}
                onItemClick={handleAnomalyListItemClick}
                acknowledgedAnomalies={acknowledgedAnomalies}
                unacknowledgedAnomalies={unacknowledgedAnomalies}
                listRef={anomaliesRef}
                queryId={queryId}
                metricName={metricName}
              />
            )}
          </div>
          <div className="my-4 w-0.5 bg-secondary"></div>
          {breaches.length > 0 && (
            <div className="flex w-4/6 flex-col py-4">
              <AnomalyGraphSection
                predictions={predictions}
                onAcknowledge={handleOnAcknowledge}
                isAnomalyListLoading={!queryId}
                isPredictionsLoading={isPredictionsLoading}
                isAcknowledging={isAcknowledging}
                queryId={selectedBreach.queryId}
                metricName={selectedBreach.metricName}
                metricType={metricType}
                eventTime={selectedBreach.eventTime}
                applicationName={selectedBreach.applicationName}
                acknowledge={selectedBreach.acknowledge}
                sourceId={sourceId}
                applicationId={selectedBreach.applicationId}
                onApplicationLinkClick={goToApplicationBehaviour}
              />
              <div className="flex w-full overflow-y-auto rounded-md">
                {isCorelationsLoading || isCausationsLoading || !queryId ? (
                  <BreachSectionLoader numberOfItems={3} />
                ) : (
                  <BreachSection
                    selectedBreach={selectedBreach}
                    breaches={corelations}
                    causations={causations}
                    breachCount={corelations.length}
                    sourceId={sourceId}
                    onViewMultivariateClick={() =>
                      handleViewMultivariateRouteState(selectedBreach.applicationId, {
                        fromDate: moment(selectedBreach.eventTime)
                          .subtract(120, 'minutes')
                          .valueOf(),
                        toDate: moment(selectedBreach.eventTime).valueOf(),
                      })
                    }
                    isFetching={isCorelationsFetching || isCausationsFetching}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}
