import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const ProfileIcon = ({ testId, className, color = COLORS.WARM, onClick }: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="16.0655" cy="10.5644" rx="4.34387" ry="4.18159" fill={color} />
      <path
        d="M23.3057 22.4676C23.3057 26.5055 20.0644 25.5064 16.066 25.5064C12.0675 25.5064 8.82617 26.5055 8.82617 22.4676C8.82617 18.4296 12.0675 15.1562 16.066 15.1562C20.0644 15.1562 23.3057 18.4296 23.3057 22.4676Z"
        fill={color}
      />
      <path
        d="M30.4374 16.0501C30.4063 24.0283 23.9164 30.4723 15.9432 30.4447C7.97007 30.417 1.53067 23.928 1.5618 15.9499C1.59293 7.97168 8.08279 1.52765 16.056 1.55534C24.0291 1.58304 30.4685 8.07197 30.4374 16.0501Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  </div>
)
