import { createRef, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import {
  AnomalyTypes,
  DefdevAnomalyType,
  changeDefDevSelectedBreach,
  initialAggregationsState,
  selectedDefDevStateSelector,
  useGetAggregationsQuery,
  useGetAnomaliesQuery,
} from '../../../../../store/anomalies'
import { NavLink, useParams } from 'react-router-dom'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import { pageTransitionAnimation } from '../../../../../consts'
import { AnomalyList, CompareChart } from '../../../../../components'
import { COLORS } from '../../../../../../themes/default/colors'
import { getAnomalyGraphDateRange } from '../../../../../store/anomalies/utils'
import { comparisonRangeTimestampsAggregator, getDeviationTimeFormat } from '../../../../../utils'
import AnomalySectionLoader from '../loaders/anomaly-section-loader'
import DefDevSection from '../def-dev-section'
import { SOURCES_ROUTES, appPaths } from '../../../../../routes'
import { LinkIcon } from '../../../../../components/icons'
import { useSourceTabsContext } from '../../../../../providers'
import { GraphAbsoluteRangeType } from '../../../../../components/graph-date-range/types'
import { useNavigate } from 'react-router-dom'
import { getQueryRange } from '../../../../../store/anomaly-detection'
export default function DefDevTab() {
  const { sourceId = '', queryId = '' } = useParams()
  const dispatch = useAppDispatch()
  const selectedDefDev = useAppSelector(selectedDefDevStateSelector(sourceId))
  const scrollRef = useRef(false)
  const navigate = useNavigate()
  const { setCurrentTabState, changeSourceBehaviourDateRange, currentSourceTab } =
    useSourceTabsContext()
  const { anomaliesDateRange } = currentSourceTab
  const graphDateRange = anomaliesDateRange
  const { fromDate: currentFromDate, toDate: currentToDate } = getQueryRange(graphDateRange)
  const handleOnViewApplicationClick = ({
    fromDate,
    toDate,
    applicationId,
  }: GraphAbsoluteRangeType & { applicationId: string }) => {
    const nextPath = `${appPaths.appBehaviourPage({
      sourceId,
      applicationId: applicationId,
    })}`
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: nextPath,
      sourceId,
      multivariateDateRange: {
        fromDate,
        toDate,
      },
      multivariateBehaviourDateRange: {
        showForecast: false,
        rangeType: 'absolute',
        relativeTime: '30-minutes',
        fromDate,
        toDate,
      },
    })

    changeSourceBehaviourDateRange({
      sourceId,
      fromDate,
      toDate,
    })

    navigate(nextPath)
  }
  // RTK Query for Get Anomalies
  const { data: anomalies = [], isLoading: isdefdevsLoading } = useGetAnomaliesQuery({
    id: sourceId,
    level: 'source',
    fromDate: currentFromDate,
    toDate: currentToDate,
  })
  const { defdevs = [], metricName = '' }: any = anomalies?.find((a) => a.queryId === queryId) || {
    defdevs: [],
    metricName: '',
  }
  const { fromDate: graphFromDate, toDate: graphToDate } = getAnomalyGraphDateRange(
    selectedDefDev.anomalyTime,
    selectedDefDev.type as AnomalyTypes,
    selectedDefDev.level
  )

  // RTK Query for Get Aggregations
  const { data: comparisons = initialAggregationsState, isLoading: isAggregationLoading } =
    useGetAggregationsQuery(
      {
        queryId: queryId,
        fromDate: graphFromDate,
        toDate: graphToDate,
        aggregationLevel: selectedDefDev.level,
        includeComparison: selectedDefDev.type === 'deflection',
      },
      { skip: !queryId || defdevs.length === 0 }
    )

  const anomaliesRef = defdevs.reduce(
    (acc: any, curr: any) => ({
      ...acc,
      [`${queryId}-${curr.anomalyTime}`]: createRef<HTMLDivElement>(),
    }),
    {} as any
  )

  useEffect(() => {
    if (
      scrollRef.current &&
      anomaliesRef[`${queryId}-${selectedDefDev.anomalyTime}`].current != null
    ) {
      anomaliesRef[`${queryId}-${selectedDefDev.anomalyTime}`].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
      scrollRef.current = false
    }
  })

  const handleAnomalyListItemClick = (anomaly: DefdevAnomalyType) =>
    dispatch(changeDefDevSelectedBreach({ sourceId, queryId, ...anomaly }))

  const goToApplicationBehaviour = (nextPath: string) => {
    dispatch(
      setCurrentTabState({
        prevActiveTab: SOURCES_ROUTES.ANOMALIES,
        prevAnomalyPath: nextPath,
        sourceId,
        multivariateBehaviourDateRange: {
          showForecast: false,
          rangeType: 'absolute',
          relativeTime: '30-minutes',
          fromDate: graphFromDate,
          toDate: graphToDate,
        },
      })
    )
  }

  return (
    <motion.div {...pageTransitionAnimation}>
      <div className="flex h-[75vh] w-screen overflow-y-hidden px-10">
        <div className="flex h-full w-full flex-row bg-dark94 ">
          <div className="flex w-2/6">
            {isdefdevsLoading ? (
              <AnomalySectionLoader numberOfItems={4} />
            ) : (
              <div className="flex h-[74vh] w-full flex-col overflow-y-auto">
                <AnomalyList
                  queryId={queryId}
                  metricName={metricName}
                  items={defdevs}
                  onItemClick={handleAnomalyListItemClick}
                  listClasses="p-4 gap-y-2"
                  selectedQueryId={selectedDefDev?.id}
                  selectedEventTime={selectedDefDev?.anomalyTime}
                />
              </div>
            )}
          </div>
          <div className="my-4 flex w-0.5 flex-col bg-secondary"></div>
          {defdevs.length > 0 && (
            <div className="flex w-4/6 flex-col py-4">
              <div className="w-full px-3">
                <div className="flex text-base">
                  {metricName}
                  <NavLink
                    to={appPaths.appBehaviourPage({
                      sourceId,
                      applicationId: selectedDefDev.applicationId,
                    })}
                    onClick={() =>
                      goToApplicationBehaviour &&
                      goToApplicationBehaviour(
                        appPaths.appBehaviourPage({
                          sourceId,
                          applicationId: selectedDefDev.applicationId,
                        })
                      )
                    }
                  >
                    <LinkIcon className="ml-2 h-6 w-6 cursor-pointer" />
                  </NavLink>
                </div>
                <div className="font-RakutenSansLight text-xs text-white opacity-50">
                  {moment(selectedDefDev.anomalyTime).format('ddd, Do MMMM YYYY [at] HH:mm')}{' '}
                  <span className="px-2">|</span> Applications: {selectedDefDev.applicationName}
                </div>
              </div>
              <div className="flex w-full overflow-y-auto rounded-md py-2 laptop:h-72 desktop:h-64">
                <CompareChart
                  yAxisData={comparisons.y}
                  xlabels={comparisons.labels}
                  plotBackgroundColor={COLORS.DARK94}
                  containerBackgroundColor={COLORS.DARK94}
                  fontColor={COLORS.WHITE}
                  annotationTexts={['']}
                  xAxisTitle={`Time in ${
                    comparisonRangeTimestampsAggregator[selectedDefDev.level].reduceTimeBy
                  }`}
                  yAxisTitle={'Ground truth'}
                  colouredArea={{
                    x0: getDeviationTimeFormat(
                      selectedDefDev.type,
                      selectedDefDev.level,
                      selectedDefDev.deviationStartTime
                    ),
                    x1: getDeviationTimeFormat(
                      selectedDefDev.type,
                      selectedDefDev.level,
                      selectedDefDev.deviationEndTime
                    ),
                    y0: 0,
                    y1: Math.max(...(comparisons.y.flat().filter((n) => n !== null) as number[])),
                  }}
                />
              </div>
              <DefDevSection
                defDev={selectedDefDev}
                sourceId={sourceId}
                applicationId={selectedDefDev.applicationId}
                fromDate={graphFromDate}
                toDate={graphToDate}
                onViewApplicationClick={() =>
                  handleOnViewApplicationClick({
                    fromDate: graphFromDate,
                    toDate: graphToDate,
                    applicationId: selectedDefDev.applicationId,
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}
