import { COLORS } from './colors'

const MuiDateCalendar = {
  styleOverrides: {
    root: {
      backgroundColor: COLORS.DARK,
      fontColor: COLORS.WHITE,
      width: '15rem',
      height: '15rem',
      borderColor: COLORS.WARMGREY28,
      borderWidth: '1px',
      borderRadius: '5px',
      '& .MuiPickersCalendarHeader-label': {
        fontSize: '0.8rem',
      },
      '& .MuiPickersCalendarHeader-root': {
        minHeight: '14px',
        paddingLeft: '13px',
        marginTop: '12px',
        marginBottom: '8px',
        paddingRight: '2px',
      },
      '& .MuiDayCalendar-weekContainer': {
        margin: ' 0px',
      },
      '.MuiYearCalendar-root': {
        height: '200px',
      },
    },
  },
}

export default MuiDateCalendar
