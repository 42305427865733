import moment from 'moment'
import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import { Tooltip } from '../../../../components'
import AnomalyGraphSettings from './anomaly-graph-settings'
import { SeverityTypes } from '../../../../store/anomaly-detection'
import { COLORS } from '../../../../../themes/default/colors'
import {
  CompareIcon,
  InfoOutlined,
  MaximiseIcon,
  ThumbsUpFilled,
  ThumbsUpOutlined,
} from '../../../../components/icons'
import { severityColorClass } from './consts'

moment.updateLocale(moment.locale(), { invalidDate: '-' })

export interface PredictionType {
  eventTime: string
  upperThreshold: string
  lowerThreshold: string
  groundTruth: string
  forecastValue: string
  severity: string
  severityType: number
  feedback: boolean | null
  showFeedbackIcon: boolean
}

export interface DayWiseTrendType {
  eventTimeDay: string
  upperThresholdAvg: number
  lowerThresholdAvg: number
  groundTruthAvg: number
  forecastValueAvg: number
  severity: number
  totalAnomalies: number
}

interface AnomalyGraphInformativeSectionProps {
  onCompareIconClick: (compareType: string) => void
  isZoomable: boolean
  graphInfoSubject: BehaviorSubject<PredictionType>
  onScrollZoomChange: (zoomable: boolean) => void
  onClickFeedback: (feedback: boolean) => void
  showDayTrends: boolean
  onMaximiseClick: (date: string) => void
  dayWiseTrends: BehaviorSubject<DayWiseTrendType>
}

function comparisonTooltip(compareType: string, onCompareIconClick: (compareType: string) => void) {
  return (
    <div className="flex flex-row items-center">
      <Tooltip
        placement="top-end"
        title={<div className="w-40 text-xs">Compare {compareType} with previous values</div>}
      >
        <CompareIcon
          onClick={() => onCompareIconClick(compareType)}
          className="h-8 w-8 cursor-pointer"
        />
      </Tooltip>
    </div>
  )
}

export default function AnomalyGraphInformativeSection({
  graphInfoSubject,
  onCompareIconClick,
  isZoomable,
  onScrollZoomChange,
  onClickFeedback,
  showDayTrends,
  onMaximiseClick,
  dayWiseTrends,
}: AnomalyGraphInformativeSectionProps) {
  const [graphParameters, setGraphParameters] = useState<PredictionType>({
    eventTime: '-',
    upperThreshold: '-',
    lowerThreshold: '-',
    groundTruth: '-',
    forecastValue: '-',
    severity: '-',
    severityType: 0,
    feedback: null,
    showFeedbackIcon: false,
  })

  const [dayTrendsGraphParameters, setDayTrendsGraphParameters] = useState<DayWiseTrendType>({
    eventTimeDay: '-',
    upperThresholdAvg: 0,
    lowerThresholdAvg: 0,
    groundTruthAvg: 0,
    forecastValueAvg: 0,
    severity: 0,
    totalAnomalies: 0,
  })

  useEffect(() => {
    const information$ = graphInfoSubject.subscribe((infoSection) => {
      setGraphParameters(infoSection)
    })

    return () => information$.unsubscribe()
  }, [graphInfoSubject])

  useEffect(() => {
    const dayTrendinformation$ = dayWiseTrends.subscribe((infoSection) => {
      setDayTrendsGraphParameters(infoSection)
    })

    return () => dayTrendinformation$.unsubscribe()
  }, [dayWiseTrends])

  const {
    eventTime,
    groundTruth,
    upperThreshold,
    lowerThreshold,
    forecastValue,
    severityType,
    showFeedbackIcon,
    feedback,
  } = graphParameters

  const {
    eventTimeDay,
    groundTruthAvg,
    upperThresholdAvg,
    lowerThresholdAvg,
    forecastValueAvg,
    severity,
    totalAnomalies,
  } = dayTrendsGraphParameters

  return (
    <div className="flex h-full w-full flex-col ">
      <div className="flex w-full grow flex-col text-white">
        <div className="flex flex-col">
          <div className="flex w-full flex-row justify-end pr-2.5 pt-4">
            <AnomalyGraphSettings
              isZoomable={isZoomable}
              onScrollZoomChange={() => onScrollZoomChange(!isZoomable)}
            />
          </div>
          <div className="flex h-4 flex-row items-center justify-between px-1">
            <div className="flex flex-row items-center gap-2">
              <div
                className={`h-3 w-3 rounded-full border border-none ${
                  showDayTrends ? severityColorClass[severity] : severityColorClass[severityType]
                }`}
              />
              <span className="text-sm font-semibold capitalize">
                {showDayTrends ? `${totalAnomalies} Breaches` : SeverityTypes[severityType]}
              </span>
            </div>
            {showFeedbackIcon ? (
              <div className="flex flex-row items-center justify-between gap-2">
                {feedback != null && feedback ? (
                  <Tooltip title="Marked as True positive" placement="top">
                    <ThumbsUpFilled className="w-5 hover:scale-150 hover:cursor-pointer" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Mark this breach as True positive" placement="top">
                    <ThumbsUpOutlined
                      className="w-5 hover:scale-150 hover:cursor-pointer"
                      onClick={() => onClickFeedback(true)}
                    />
                  </Tooltip>
                )}

                {feedback != null && !feedback ? (
                  <Tooltip title="Marked as False positive" placement="top">
                    <ThumbsUpFilled
                      transform="rotate(180)"
                      className="w-5 hover:scale-150 hover:cursor-pointer"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Mark this breach as False positive" placement="top">
                    <ThumbsUpOutlined
                      transform="rotate(180)"
                      className="w-5 hover:scale-150 hover:cursor-pointer"
                      onClick={() => onClickFeedback(false)}
                    />
                  </Tooltip>
                )}
              </div>
            ) : !showDayTrends ? (
              <Tooltip
                title="Breaches are anomalies detected outside the forecasted confidence, this confidence can become more accurate based on the feedback of True positive or False positive"
                placement="top"
              >
                <InfoOutlined
                  className="w-6 pr-[6.5px] pt-3 hover:cursor-pointer"
                  color={COLORS.PRIMARY}
                />
              </Tooltip>
            ) : eventTimeDay !== '-' ? (
              <MaximiseIcon
                className="w-9 cursor-pointer pt-4"
                onClick={() => onMaximiseClick(eventTimeDay)}
              />
            ) : (
              <Tooltip
                title="Hover on a date from aggregated chart to view behaviour of that day"
                placement="top"
              >
                <InfoOutlined
                  className="w-6 pr-[6.5px] pt-3 hover:cursor-pointer"
                  color={COLORS.PRIMARY}
                />
              </Tooltip>
            )}
          </div>
          <div className="flex w-full flex-col text-xs">
            <div className="flex flex-row items-center pb-2 pl-6">
              {showDayTrends ? (
                <span className="pr-1 text-sm opacity-70">
                  {moment(eventTimeDay).format('DD MMM, Y')}
                </span>
              ) : (
                <div>
                  <span className="pr-1 text-sm font-bold text-primary">
                    {moment(eventTime).format('HH:mm') || '-'}
                  </span>
                  | <span className="pl-1 opacity-70">{moment(eventTime).format('DD MMM, Y')}</span>{' '}
                </div>
              )}
            </div>
            <div className="flex flex-row justify-between py-2 pr-3 hover:bg-primary20">
              <div className="flex flex-col pl-6">
                <span className="opacity-70">
                  {showDayTrends ? 'Ground Truth Avg' : 'Ground Truth'}
                </span>
                <span className="text-bold w-44 truncate text-base text-primary">
                  {showDayTrends ? groundTruthAvg : groundTruth}
                </span>
              </div>
              {comparisonTooltip('ground truth', onCompareIconClick)}
            </div>

            <div className="flex flex-row justify-between py-2 pr-3 hover:bg-primary20">
              <div className="flex flex-col pl-6">
                <span className="opacity-70">
                  {showDayTrends ? 'Upper Threshold Avg' : 'Upper Threshold'}
                </span>
                <span className="text-bold w-44 truncate text-base text-primary">
                  {showDayTrends
                    ? upperThresholdAvg
                    : Number(upperThreshold) === 0 && Number(lowerThreshold) === 0
                    ? '-'
                    : upperThreshold}
                </span>
              </div>
              {comparisonTooltip('upper threshold', onCompareIconClick)}
            </div>

            <div className="flex flex-row justify-between py-2 pr-3 hover:bg-primary20">
              <div className="flex flex-col pl-6">
                <span className="opacity-70">
                  {showDayTrends ? 'Lower Threshold Avg' : 'Lower Threshold'}
                </span>
                <span className="text-bold w-44 truncate text-base text-primary">
                  {showDayTrends
                    ? lowerThresholdAvg
                    : Number(upperThreshold) === 0 && Number(lowerThreshold) === 0
                    ? '-'
                    : lowerThreshold}
                </span>
              </div>
              {comparisonTooltip('lower threshold', onCompareIconClick)}
            </div>

            <div className="flex flex-row justify-between py-2 pr-3 hover:bg-primary20">
              <div className="flex flex-col pl-6">
                <span className="opacity-70">{showDayTrends ? 'Forecast Avg' : 'Forecast'}</span>
                <span className="text-bold w-44 truncate text-base text-primary">
                  {showDayTrends ? forecastValueAvg : forecastValue}
                </span>
              </div>
              {comparisonTooltip('forecast value', onCompareIconClick)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
