import { useMemo } from 'react'
import { Button, LabelInput } from '../../components'
import { QueryType } from '../../store/add-application'
import { QueryValidateInputProps } from './types'

const isDuplicateQuery = (queries: QueryType[], currentQuery: string) =>
  queries.filter((q) => q.value === currentQuery).length > 0

const QUERY_VALIDATE_INPUT = 'QUERY_VALIDATE_INPUT'

export default function QueryValidateInput({
  testId = QUERY_VALIDATE_INPUT,
  value,
  onChange,
  onAddQuery,
  placeholder,
  queries,
  metricType,
}: QueryValidateInputProps) {
  const isDuplicate = useMemo(() => isDuplicateQuery(queries, value), [queries, value])
  return (
    <>
      <div
        data-testid={testId}
        className="flex h-8 w-1/6 items-center	justify-center border-[1px] border-primary text-sm text-primary"
      >
        Query
      </div>
      <div className="w-4/6">
        <LabelInput
          inputStyles={{ height: 32 }}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          error={isDuplicate}
          errorMessage={isDuplicate ? 'Duplicate query' : ''}
        />
      </div>
      <div className="w-1/6">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onAddQuery}
          disabled={isDuplicate || value === '' || metricType === ''}
        >
          Validate
        </Button>
      </div>
    </>
  )
}
