import { COLORS } from './colors'

const MuiFilledInput = {
  styleOverrides: {
    root: {
      backgroundColor: COLORS.DARK2,
      color: COLORS.WHITE,
      fontSize: '1em',
      '&:hover': {
        backgroundColor: COLORS.DARK2,
        color: COLORS.WHITE,
      },
      '&:after': {
        border: `1px solid ${COLORS.PRIMARY}`,
      },
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        display: 'none',
      },
      '& input.Mui-disabled': {
        '-webkit-text-fill-color': COLORS.WARM_GREY,
      },
    },
  },
}

export default MuiFilledInput
