import MUIButton from '@mui/material/Button'
import { ButtonProps } from './types'
import Spinner from '../spinner'

export const BUTTON_INPUT_TEST_ID = 'BUTTON_INPUT_TEST_ID'

export default function Button({
  testId = BUTTON_INPUT_TEST_ID,
  className,
  styles,
  children,
  buttonTextClass,
  isLoading,
  ...props
}: ButtonProps) {
  return (
    <MUIButton
      data-testid={testId}
      className={className}
      {...props}
      sx={{ borderRadius: 1, ...styles }}
    >
      <span className={`capitalize ${buttonTextClass}`}>
        {isLoading ? <Spinner className="w-5" /> : children}
      </span>
    </MUIButton>
  )
}
