import { Navigate, useLocation } from 'react-router-dom'
import { useEffect, useLayoutEffect } from 'react'
import { useAuth } from '../../hooks'
import { UNAUTHENTICATED_ROUTES } from '../../consts'
import { userAuthSubject$ } from '../../observables'
import { ProtectedRouteProps } from './types'
import { DASHBOARD_ROUTES, LOGIN_ROUTES } from '../../routes'
import { resetAllState } from '../../store'
import { useLazyLogoutQuery } from '../../store/auth'
import { splitApi } from '../../store/api'

export default function ProtectedRoute({
  children,
  dispatch,
  navigate,
  userId,
  persistor,
}: ProtectedRouteProps) {
  const { isLoggedIn, login, logout } = useAuth()
  const currentLocation = useLocation()

  const [logoutQuery] = useLazyLogoutQuery()

  const handleLogout = () => {
    logoutQuery({})
    navigate(LOGIN_ROUTES.LOGIN, { replace: true })
    dispatch(splitApi.util.resetApiState())
    dispatch(resetAllState())
    persistor.purge()
  }

  useLayoutEffect(() => {
    window.addEventListener('beforeunload', () => dispatch(splitApi.util.resetApiState()))
    return () => {
      window.removeEventListener('beforeunload', () => dispatch(splitApi.util.resetApiState()))
    }
  }, [])

  useEffect(() => {
    // Using behaviour subject to listen to auth events
    const userAuthSubject = userAuthSubject$.subscribe((isAuthenticated) => {
      if (isAuthenticated !== null) {
        if (isAuthenticated) {
          login()
        } else {
          logout(handleLogout)
        }
      }
    })

    return () => userAuthSubject.unsubscribe()
  }, [])

  if (
    !isLoggedIn &&
    !UNAUTHENTICATED_ROUTES.includes(currentLocation.pathname) &&
    !UNAUTHENTICATED_ROUTES.includes(
      currentLocation.pathname.slice(1, currentLocation.pathname.length)
    )
  ) {
    // If user is not authenticated redirect to login
    return <Navigate to={LOGIN_ROUTES.LOGIN} />
  } else if (
    isLoggedIn &&
    (UNAUTHENTICATED_ROUTES.includes(currentLocation.pathname) ||
      UNAUTHENTICATED_ROUTES.includes(
        currentLocation.pathname.slice(1, currentLocation.pathname.length)
      ))
  ) {
    // If user is  authenticated redirect to dashboard
    return <Navigate to={DASHBOARD_ROUTES.HOME} />
  }

  return <>{children}</>
}
