import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const BELL_PLUS_TEST_ID = 'BELL_PLUS_TEST_ID'

export const RingingBellIcon = ({
  testId = BELL_PLUS_TEST_ID,
  className,
  color,
  pathClasses,
  strokeColor = COLORS.PRIMARY,
  ...props
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width={102} height={92} fill="none" {...props}>
      <path
        className={pathClasses}
        fill="url(#b)"
        d="M22.723 36.033c.532-6.88 3.514-13.439 8.413-18.277 1.49-1.397 1.49-3.87.106-5.268-1.384-1.505-3.834-1.505-5.218-.107-6.283 6.128-10.01 14.406-10.65 23.115-.106 2.043 1.385 3.87 3.408 3.978h.213c1.917.107 3.514-1.505 3.727-3.44Z"
      />
      <path
        className={pathClasses}
        fill="url(#b)"
        d="M21.338 6.468c1.49-1.398 1.597-3.763.213-5.269-1.385-1.505-3.728-1.612-5.218-.215C6.108 10.553.252 24.1.252 38.184v.967c0 2.043 1.704 3.763 3.727 3.763 2.024 0 3.728-1.72 3.728-3.763v-.967c0-12.15 4.792-23.438 13.63-31.716Z"
      />
      <path
        className={pathClasses}
        fill="url(#c)"
        d="M70.758 12.408c-1.384 1.503-1.384 3.867.107 5.263a27.817 27.817 0 0 1 8.413 18.26c.107 1.934 1.81 3.438 3.728 3.438h.213c2.024-.107 3.621-1.934 3.408-3.974-.639-8.701-4.367-16.972-10.65-23.095-1.491-1.396-3.834-1.289-5.219.107Z"
      />
      <path
        className={pathClasses}
        fill="url(#d)"
        d="M85.666 1.022c-1.49-1.397-3.834-1.397-5.218.215-1.385 1.504-1.385 3.867.213 5.263 8.84 8.271 13.632 19.55 13.632 31.688v.967c0 2.04 1.704 3.76 3.727 3.76 2.024 0 3.728-1.72 3.728-3.76v-.967c-.106-14.072-5.964-27.606-16.082-37.166Z"
      />
      <path
        className={pathClasses}
        fill="url(#e)"
        d="M79.579 53.84c-3.328-2.884-5.524-6.726-6.284-11.039l-1.447-8.203c-1.743-9.886-10.13-16.866-19.739-17.232l-.686-3.892c-.426-2.419-2.777-4.065-5.196-3.638-2.419.426-4.065 2.777-3.638 5.196l.686 3.891c-8.903 3.63-14.398 13.058-12.655 22.945l1.446 8.203c.76 4.312.012 8.674-2.13 12.522l-2.691 5.029c-1.968 3.6 1.057 7.838 5.053 7.134L81.73 66.04c3.997-.705 5.39-5.722 2.309-8.433l-4.46-3.768Z"
      />
      <path
        className={pathClasses}
        fill="url(#f)"
        d="M59.905 86.804c5.785-1.02 9.683-6.587 8.663-12.372l-21.035 3.71c1.02 5.784 6.588 9.682 12.372 8.662Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={-1.792}
          x2={32.267}
          y1={14.305}
          y2={28.95}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333" />
          <stop offset={1} stopColor="#555353" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={-1.792}
          x2={32.267}
          y1={14.305}
          y2={28.95}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333" />
          <stop offset={1} stopColor="#555353" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={101.748}
          x2={71.776}
          y1={14.305}
          y2={29.972}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333" />
          <stop offset={1} stopColor="#555353" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={101.748}
          x2={71.776}
          y1={14.305}
          y2={29.972}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333" />
          <stop offset={1} stopColor="#555353" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={46.332}
          x2={59.907}
          y1={9.817}
          y2={86.804}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B4A4A" />
          <stop offset={1} stopColor="#6C6C6C" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={46.332}
          x2={59.907}
          y1={9.817}
          y2={86.804}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B4A4A" />
          <stop offset={1} stopColor="#6C6C6C" />
        </linearGradient>
      </defs>
    </svg>
  </div>
)
