import React, { Key, ReactElement, useEffect, useState } from 'react'
import { SeverityStyles, pageSizeOptions, severityOptions } from './consts'
import moment from 'moment'
import { MenuVertical, DeleteFilled, ChevronDown, EditOutlined } from '../../../components/icons'
import { CopyTooltip } from '../../../partials/add-edit-application-card/metric-query-list'
import { Dropdown, Switch } from 'antd'
import { AlertRuleType } from '../../../store/alert-rule-dashboard/types'
import { ExpandableConfig, TableRowSelection } from '../../../components/table/types'
import { Table } from '../../../components'
import { COLORS } from '../../../../themes/default/colors'

interface AlertTableProps {
  alertRows: AlertRuleType[]
  handleDelete: (alertId: string[], alertName: string[]) => void
  handleEdit: (alertId: string) => void
  handleToggle: (ruleId: string, status: boolean, alertName: string) => void
  onSetAlert: () => void
  emptyText: ReactElement
  onSelect: (selectedRowKeys: React.Key[], selectedRows: any) => void
  selectedRowKeys: Key[]
}

function AlertsTable({
  alertRows,
  handleDelete,
  handleEdit,
  handleToggle,
  onSetAlert,
  selectedRowKeys,
  onSelect,
  emptyText,
}: AlertTableProps) {
  const defaultExpandable = {
    expandedRowRender: (record: AlertRuleType) => {
      const value = {
        alertName: record.ruleName,
        Severity: severityOptions.find((val, index) => record.severityOption === val.value)?.label,
        Source: record.sourceName,
        Application: record.applicationName,
        Metric: record.metricName,
        'Anomaly Count': record.threshold,
        'Anomaly Period': record.period,
        'Silence Period': record.silencePeriod,
        'Additional Message': record.message,
        Emails: record.channelValues?.EMAIL || [],
      }
      const jsonValue = JSON.stringify(value, null, 2)
      return (
        <div className="flex flex-row items-baseline justify-between">
          <div className="pl-10 text-sm">
            <pre className="font-RakutenSans">{jsonValue}</pre>
          </div>
          <CopyTooltip value={jsonValue} />
        </div>
      )
    },
  }
  const [expandable, setExpandable] = useState<ExpandableConfig<AlertRuleType> | undefined>(
    defaultExpandable
  )
  const columns = [
    {
      title: 'Severity',
      key: 'severityOption',
      sorter: (a: AlertRuleType, b: AlertRuleType) => a.severityOption - b.severityOption,
      render: (params: AlertRuleType) => {
        const values = SeverityStyles(params.severityOption)
        return (
          <div className="flex flex-row gap-4">
            {Array.isArray(values) ? (
              values.map((value, index) => (
                <div key={index} className={value.styles}>
                  {value.label}
                </div>
              ))
            ) : (
              <div className={values?.styles}>{values?.label}</div>
            )}
          </div>
        )
      },
    },
    {
      title: 'Alert name',
      dataIndex: 'ruleName',
      sorter: (a: AlertRuleType, b: AlertRuleType) => a.ruleName?.localeCompare(b.ruleName),
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      align: 'center' as 'center',
      sorter: (a: AlertRuleType, b: AlertRuleType) => (a.emails ?? 0) - (b.emails ?? 0),
    },
    {
      title: 'Silence period',
      dataIndex: 'silencePeriod',
      align: 'center' as 'center',
      sorter: (a: AlertRuleType, b: AlertRuleType) =>
        a.silencePeriod?.localeCompare(b.silencePeriod) ?? 0,
    },
    {
      title: 'Created on',
      dataIndex: 'createdOn',
      sorter: (a: AlertRuleType, b: AlertRuleType) =>
        new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
    },
    {
      title: 'Modified on',
      dataIndex: 'modifiedOn',
      sorter: (a: AlertRuleType, b: AlertRuleType) =>
        new Date(a.modifiedOn).getTime() - new Date(b.modifiedOn).getTime(),
    },
    {
      title: 'State',
      key: 'state',
      align: 'center' as 'center',
      sorter: (a: AlertRuleType, b: AlertRuleType) => Number(a.active) - Number(b.active),
      render: (params: AlertRuleType) => (
        <Switch
          checked={params.active}
          onChange={(checked) => {
            handleToggle(params.id!, !checked, params.ruleName)
          }}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (params: AlertRuleType) => (
        <Dropdown
          className="w-5 hover:cursor-pointer"
          menu={{
            items: [
              {
                label: (
                  <div
                    className="flex flex-row gap-2 py-1 pl-3 pr-5 text-white hover:bg-primary20"
                    onClick={() => handleEdit(params.id!)}
                  >
                    <EditOutlined className="ml-1 h-5 w-5" color={COLORS.WHITE} />
                    Edit
                  </div>
                ),
                key: 'edit_rule',
              },
              {
                label: (
                  <div
                    className="flex flex-row gap-2 py-1 pl-3 pr-5 text-white hover:bg-primary20"
                    onClick={() => handleDelete([params.id!], [params.ruleName])}
                  >
                    <DeleteFilled
                      className="w-5 cursor-pointer fill-neutral-300"
                      color={COLORS.WHITE}
                    />
                    Delete
                  </div>
                ),
                key: 'delete_rule',
              },
            ],
          }}
          trigger={['click']}
        >
          <div onClick={(e) => e.preventDefault()}>
            <MenuVertical className="h-5 w-5 rotate-90 transform" color={COLORS.PRIMARY} />
          </div>
        </Dropdown>
      ),
    },
  ]

  const customExpandIcon = (props: any) => {
    if (props.expanded) {
      return (
        <ChevronDown
          className="hover:cursor-pointer"
          color={COLORS.PRIMARY}
          onClick={(e) => {
            props.onExpand(props.record, e)
          }}
        />
      )
    } else {
      return (
        <ChevronDown
          className="-rotate-90 transform hover:cursor-pointer"
          color={COLORS.PRIMARY}
          onClick={(e) => {
            props.onExpand(props.record, e)
          }}
        />
      )
    }
  }

  const tableColumns = columns.map((item) => ({
    ...item,
    ellipsis:
      item.dataIndex === 'ruleName' ||
      item.dataIndex === 'modifiedOn' ||
      item.dataIndex === 'createdOn'
        ? true
        : false,
  }))

  const rowSelections: TableRowSelection<AlertRuleType> = {
    selectedRowKeys,
    onChange: onSelect,
  }

  const defaultFooter = () => `Total ${alertRows.length} items`

  const tableProps = {
    expandable,
    footer: defaultFooter,
  }

  const modifiedAlertRules = alertRows.map((a, index) => {
    return {
      ...a,
      key: index,
      id: a.id,
      severityOption: a.severityOption,
      ruleName: a.ruleName,
      emails: a.channelValues?.EMAIL?.length || 0,
      silencePeriod: `${a.silencePeriod} mins`,
      createdOn: moment(a.createdOn).format('MMM DD, YYYY HH:mm:ss'),
      modifiedOn: moment(a.modifiedOn).format('MMM DD, YYYY HH:mm:ss'),
      active: a.active,
    }
  })
  return (
    <Table
      {...tableProps}
      scroll={{ y: 570 }}
      locale={{
        emptyText: emptyText,
      }}
      pagination={{
        pageSizeOptions: pageSizeOptions(modifiedAlertRules.length),
        showSizeChanger: modifiedAlertRules.length > 10,
      }}
      showSorterTooltip={false}
      footer={defaultFooter}
      columns={tableColumns}
      dataSource={modifiedAlertRules}
      rowSelection={rowSelections}
      expandIcon={customExpandIcon}
    />
  )
}

export default AlertsTable
