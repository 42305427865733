import { COLORS } from './colors'

const palette = {
  primary: {
    main: COLORS.PRIMARY,
  },
  secondary: {
    main: COLORS.DARK94,
  },
}

export default palette
