import Axios, { CreateAxiosDefaults } from 'axios'
import { API_URL, USER_API } from '../consts'
import { userAuthSubject$ } from '../observables'

const config = {
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
}

Axios.defaults.baseURL = API_URL

const axios = Axios.create(config as CreateAxiosDefaults)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.config && error.response && error.response.status === 401) {
      try {
        await Axios.post(USER_API.REFRESH_TOKEN)
        return await Axios.request(config)
      } catch (e) {
        userAuthSubject$.next(false)
        return Promise.reject('')
      }
    } else if (error.config && error.response && error.response.status === 400) {
      return Promise.reject(error.response?.data?.message)
    } else if (error.config && error.response && error.response.status) {
      return Promise.reject(
        `${error.code || ''} ${error.response.status}-${error.response?.data?.message}`
      )
    } else {
      return Promise.reject(error.code)
    }
  }
)

export default axios
