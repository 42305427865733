import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const EDIT_OUTLINED_TEST_ID = 'EDIT_OUTLINED_TEST_ID'

export const EditOutlined = ({
  className,
  color = COLORS.WARM_GREY,
  onClick,
  testId = EDIT_OUTLINED_TEST_ID,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={color}
        d="m22 4 4 4-1.44 1.43L26 10.84l1.41-1.44a2 2 0 0 0 0-2.83l-4-4a2 2 0 0 0-2.83 0L19.16 4l1.41 1.41ZM24.56 12.26l-1.42-1.42-5.39-5.39L6 17.24 4 26l8.76-1.95ZM6.63 23.37l1-4.44 3.45 3.45Zm2.14-6.13 9-9 4 4-9 9ZM4 28h24v2H4z"
      />
    </svg>
  </div>
)
