import { useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { AntTheme, defaultTheme } from './themes'
import { ProtectedRoute } from './app/components'
import { AuthProvider, useAppDispatch, useAppSelector } from './app/hooks'
import { authSelector } from './app/store/auth'
import { persistor } from './app/store'
import { NotificationProvider, SourceTabsProvider } from './app/providers'
import '../src/themes/antdTheme/antStyles.css'
import Pages from './app/pages'
import { ConfigProvider } from 'antd'

export const APP_TEST_ID = 'APP_TEST_ID'

export default function App() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(authSelector)

  return (
    <AuthProvider>
      <ProtectedRoute
        dispatch={dispatch}
        navigate={navigate}
        userId={user.userId}
        persistor={persistor}
      >
        <ConfigProvider theme={AntTheme}>
          <ThemeProvider theme={defaultTheme}>
            <NotificationProvider enable={user.userId !== ''}>
              <SourceTabsProvider>
                <Pages />
              </SourceTabsProvider>
            </NotificationProvider>
          </ThemeProvider>
        </ConfigProvider>
      </ProtectedRoute>
    </AuthProvider>
  )
}
//dummy
