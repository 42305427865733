interface AnomalySectionLoaderProps {
  testId?: string
  numberOfItems: number
}

export const ANOMALY_SECTION_LOADER_TEST_ID = 'ANOMALY_SECTION_LOADER_TEST_ID'

export default function AnomalySectionLoader({
  testId = ANOMALY_SECTION_LOADER_TEST_ID,
  numberOfItems,
}: AnomalySectionLoaderProps) {
  const anomalyListItems = () => {
    return [...Array(numberOfItems)].map((_, index) => (
      <div
        key={index}
        className="flex h-32 flex-shrink-0 flex-col justify-between border-b-[1px] border-light_grey bg-dark2 px-2 py-4"
      >
        <div className="flex h-4 flex-col rounded-md bg-dark94"></div>
        <div className="flex h-4 w-2/5 flex-col rounded-md bg-dark94"></div>
        <div className="flex flex-row ">
          <div className="flex h-9 w-[73px] rounded-md bg-dark94"></div>
          <div className="flex w-full flex-col justify-between pl-3">
            <div className="flex h-3 rounded-md bg-dark94"></div>
            <div className="flex h-3 w-[185px] rounded-md bg-dark94"></div>
          </div>
        </div>
      </div>
    ))
  }
  return (
    <div data-testid={testId} className="flex h-[90vh] w-full animate-pulse flex-col">
      <div className="flex h-12 flex-row items-center border-b-[1px] border-light_grey px-3">
        <div className="flex h-4 w-12 rounded-md bg-seal_brown"></div>
        <div className="ml-10 flex h-4 w-36 rounded-md bg-seal_brown"></div>
        <div className="ml-12 flex h-4 w-36 rounded-md bg-seal_brown"></div>
      </div>
      <div className="flex h-[90vh] flex-col overflow-y-scroll px-2 py-3">{anomalyListItems()}</div>
    </div>
  )
}
