import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const BREADCRUMB_ICON_TEST_ID = 'BREADCRUMB_ICON_TEST_ID'

export const BreadcrumbIcon = ({
  testId = BREADCRUMB_ICON_TEST_ID,
  className,
  color = COLORS.PRIMARY,
  pathClasses,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClasses}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57267 6C8.99027 5.99938 9.39374 6.15122 9.70732 6.42701C10.0209 6.70279 10.223 7.08357 10.2757 7.49783C10.3285 7.91209 10.2281 8.33135 9.99363 8.67688C9.75911 9.02242 9.40653 9.27046 9.00209 9.37445L9.00123 11.1427H13.7693C13.8737 10.7388 14.1219 10.3868 14.4672 10.1527C14.8125 9.91861 15.2314 9.8185 15.6452 9.87112C16.0591 9.92375 16.4396 10.1255 16.7153 10.4385C16.9911 10.7516 17.1432 11.1545 17.1432 11.5717C17.1432 11.9889 16.9911 12.3917 16.7153 12.7048C16.4396 13.0178 16.0591 13.2196 15.6452 13.2722C15.2314 13.3248 14.8125 13.2247 14.4672 12.9906C14.1219 12.7565 13.8737 12.4046 13.7693 12.0006L9.00123 11.9998V15.8568H13.7693C13.8737 15.4529 14.1219 15.1009 14.4672 14.8668C14.8125 14.6327 15.2314 14.5326 15.6452 14.5852C16.0591 14.6379 16.4396 14.8396 16.7153 15.1527C16.9911 15.4657 17.1432 15.8686 17.1432 16.2858C17.1432 16.703 16.9911 17.1058 16.7153 17.4189C16.4396 17.7319 16.0591 17.9337 15.6452 17.9863C15.2314 18.0389 14.8125 17.9388 14.4672 17.7047C14.1219 17.4707 13.8737 17.1187 13.7693 16.7148L8.14412 16.7139V9.37445C7.73974 9.27048 7.38721 9.0225 7.1527 8.67706C6.91819 8.33161 6.81781 7.91245 6.87041 7.49825C6.92302 7.08405 7.12498 6.70329 7.4384 6.42743C7.75182 6.15158 8.15515 5.99959 8.57267 6Z"
        fill={color}
      />
    </svg>
  </div>
)
