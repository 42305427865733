import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const ChevronUp = ({ className, color = COLORS.WARM_GREY, onClick }: IconProps) => (
  <div className={className} onClick={onClick}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <defs>
        <style></style>
      </defs>
      <polygon
        points="3 21.79 4.41 23.21 16 11.62 27.59 23.21 29 21.79 16 8.79 3 21.79"
        fill={color}
      />
    </svg>
  </div>
)
