import { useEffect, useState, useMemo, KeyboardEvent } from 'react'
import { COLORS } from '../../../themes/default/colors'
import { Button, BasicCard, LabelInput, Checkbox, Tooltip } from '../../components'
import { EyeBanFilled, EyeFilled, Questionicon, ProfileIcon } from '../../components/icons'
import { useAppDispatch } from '../../hooks'
import { changeLoginInputValue } from '../../store/auth'
import { validateField, isFormValid, saveToCookie, deleteCookie, readCookie } from '../../utils'
import { setAlert } from '../../store/alert'
import { validationMessages } from '../../consts'
import { useLazyLoginQuery } from '../../store/auth/services'
import { useLazyGetSourcesByTenantIdQuery } from '../../store/dashboard'
import { userAuthSubject$ } from '../../observables'
import { isFetchBaseQueryError } from '../../store/api'
import { NavLink, useNavigate } from 'react-router-dom'

export const LOGIN_TEST_ID = 'LOGIN_TEST_ID'

export default function Login() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isRemember, setIsRemember] = useState<boolean>(false)
  const [showPassword, setshowPassword] = useState<boolean>(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [login, { data: user, isLoading, isError, error }] = useLazyLoginQuery()
  const [getSources] = useLazyGetSourcesByTenantIdQuery()

  useEffect(() => {
    if (isFetchBaseQueryError(error)) {
      dispatch(setAlert({ message: error?.data.message }))
    }
  }, [isError])

  useEffect(() => {
    const isRememberMe = readCookie('isRememberMe 1 ')
    if (isRememberMe) {
      setIsRemember(true)
    }
  }, [])

  const handleSubmit = () => {
    login({ username, password }).then(({ isSuccess }) => {
      if (isSuccess)
        getSources({}).then(({ isError }) => {
          if (isError) {
            navigate(`/error`)
            return
          }

          if (isRemember) {
            saveToCookie('isRememberMe', { username, password })
          } else {
            deleteCookie('isRememberMe')
          }
          userAuthSubject$.next(true)
        })
    })
  }

  const handleChange = (type: string, value: string) => {
    if (type === 'username') {
      setUsername(value)
    } else if (type === 'password') {
      setPassword(value.replaceAll(' ', ''))
    }
    dispatch(changeLoginInputValue({ type, value }))
  }

  const isValidEmail = useMemo(() => validateField(username, ['required', 'email']), [username])

  const isValidPassword = useMemo(
    () => validateField(password, ['required', 'password']),
    [password]
  )

  const isDisabled = useMemo(
    () =>
      isLoading ||
      !isFormValid(
        { username, password },
        {
          username: ['required', 'email'],
          password: ['required', 'password'],
        }
      ),
    [isLoading, username, password]
  )

  const onPasswordInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      password !== '' &&
      username !== '' &&
      isValidEmail !== 'Enter a valid email' &&
      isValidPassword !== 'Enter a valid password'
    ) {
      handleSubmit()
    }
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const handleCheckboxChange = () => {
    setIsRemember(!isRemember)
  }

  return (
    <div className="flex w-full items-center justify-center">
      <BasicCard
        testId={`${LOGIN_TEST_ID}_LOGIN`}
        contentClasses="flex-1"
        containerClasses="flex !p-6 mobile:w-10/12 tablet_p:w-6/12 tablet_l:w-5/12 laptop:w-4/12 laptop:h-[24rem] desktop:w-4/12 desktop:h-[30rem] justify-center items-center"
      >
        <div className="flex h-16 w-full flex-row items-center">
          <div className="flex w-2/12 flex-col justify-center">
            <ProfileIcon className="mt-1 h-12 w-[3.2rem] mobile:w-12 tablet_p:w-12 tablet_l:w-12 desktop:h-16 desktop:w-16" />
          </div>
          <div className="flex w-10/12 flex-col justify-center">
            <div className="flex w-full flex-row items-center justify-between">
              <div className="text-xl text-warm desktop:text-3xl">Log In</div>
              <Tooltip title={'Login to see dashboard'}>
                <Questionicon className="h-5 w-5 cursor-pointer desktop:h-6 desktop:w-6" />
              </Tooltip>
            </div>
            <div className="flex w-full">
              <div className="text-l text-warm desktop:text-2xl">
                {' '}
                Log in to access your account
              </div>
            </div>
          </div>
        </div>

        <div className="h-10/12 mt-6 flex flex-col desktop:mt-12">
          <LabelInput
            testId={`${LOGIN_TEST_ID}_USERNAME`}
            type="email"
            autoComplete="email"
            error={isValidEmail !== '' && user?.id === ''}
            onKeyDown={(e) => e.key === ' ' && e.preventDefault()}
            value={username}
            placeholder="Username"
            onChange={(e) => handleChange('username', e.target.value)}
            errorMessage={isValidEmail ? validationMessages.username[isValidEmail] : ''}
          />
        </div>
        <div className="mt-4 flex flex-col justify-center desktop:mt-6">
          <LabelInput
            testId={`${LOGIN_TEST_ID}_PASSWORD`}
            type={!showPassword ? 'text' : 'password'}
            value={password}
            placeholder="Password"
            onKeyDown={onPasswordInputKeyPress}
            onChange={(e) => handleChange('password', e.target.value.replaceAll(' ', ''))}
            trailingComponent={
              showPassword ? (
                <div onClick={() => setshowPassword(false)}>
                  <EyeFilled
                    testId={`${LOGIN_TEST_ID}_DONT_SHOW_PASSWORD`}
                    className="w-5 cursor-pointer"
                  />
                </div>
              ) : (
                <div onClick={() => setshowPassword(true)}>
                  <EyeBanFilled
                    testId={`${LOGIN_TEST_ID}_SHOW_PASSWORD`}
                    className="w-5 cursor-pointer"
                  />
                </div>
              )
            }
            error={isValidPassword !== '' && user?.id === ''}
            errorMessage={isValidPassword ? validationMessages.password[isValidPassword] : ''}
          />
        </div>
        <div className="mb-2.5 flex flex-row items-center desktop:mt-6">
          <Checkbox
            testId={`${LOGIN_TEST_ID}_CHECKBOX`}
            className="!-ml-1 flex w-1/12 justify-start"
            checked={isRemember}
            styles={{ color: COLORS.PRIMARY }}
            onChange={handleCheckboxChange}
          />
          <span className="ml-2 flex text-base font-thin text-warm"> Remember Me </span>
        </div>
        <div className="!mb-4 flex justify-center text-xl desktop:text-2xl">
          <Button
            testId={`${LOGIN_TEST_ID}_SUBMIT`}
            disabled={isDisabled}
            className="w-full"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            login
          </Button>
        </div>
        <div className="mb-1 flex flex-row justify-center text-warm">
          Don't have an account?&nbsp;
          <NavLink to={'/signup'} className="cursor-pointer text-primary">
            Sign up{' '}
          </NavLink>
        </div>
      </BasicCard>
    </div>
  )
}
