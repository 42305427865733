import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { KpiCard, GraphDateRange, Autocomplete } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  SourceType,
  changeCurrentSource,
  dashboardStateSelector,
  TrendType,
  setCurrentModal,
  useGetSourcesByTenantIdQuery,
  useGetTrendsQuery,
  initialTrendsState,
  dashboardApi,
  useGetAnomalyTrendsQuery,
  EntityCount,
  LevelType,
  useGetCausationImpactsQuery,
  PIECHART_COLORS,
  changeDashboardDateRange,
  setDashboardRedirection,
  setSelectedApplication,
  setSelectedSource,
} from '../../../store/dashboard'
import {
  changeCurrentApplicationId,
  changeCurrentSourceId,
  getQueryRange,
  setGraphFirstTimeLoading,
  useGetApplicationsQuery,
} from '../../../store/anomaly-detection'
import { COLORS } from '../../../../themes/default/colors'
import { appDefault, handleClick, sourceDefault } from './consts'
import { makeTrendobject } from '../../../utils/array'
import { resetState } from '../../../store/add-application'
import { setApplicationSource } from '../../../store/application'
import { DASHBOARD_ROUTES, SOURCES_ROUTES, appPaths } from '../../../routes'
import KpiCardLoader from './loaders/kpi-card'
import { tagTypes } from '../../../store/api'
import { POLLING_INTERVAL, pageTransitionAnimation } from '../../../consts'
import { SourceTabType, useSourceTabsContext } from '../../../providers'
import { useLazyGetSourceConnectionQuery } from '../../../store/source'
import BarChart from '../../../components/charts/bar-chart'
import PieChart from '../../../components/charts/pie-chart'
import { OptionType } from '../../../types'
import DashboardAnomalyChart from '../../../components/charts/dashboard-anomaly-chart'
import { anomaliesForecastOptions, graphDateTabs } from '../source/behaviour/consts'
import {
  GetAnomalylistApiRequestType,
  GroupedAnomaliesType,
  changeDefDevSelectedBreach,
  changeMetricSelectedBreach,
  getAnomalyGraphDateRange,
  useGetAnomaliesQuery,
  useLazyGetAnomalyQuery,
} from '../../../store/anomalies'
import NoDataMessage from './no-data-message'
import DashboardCardLoader from './loaders/dashboard-card'

export const MAIN_DASHBOARD_TEST_ID = 'MAIN_DASHBOARD_TEST_ID'

const kpiCardOptions = (titles: string[], trends: TrendType[]) =>
  titles.map((title, i) => ({
    title,
    ...trends[i],
  }))

export default function MainDashboard() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { trendFromDate, trendToDate, dashboardDateRange, selectedApplication, selectedSource } =
    useAppSelector(dashboardStateSelector)
  const {
    sourceTabs,
    addSourceTab,
    swapSourceTab,
    setCurrentTabState,
    currentSourceTab,
    changeSourceBehaviourDateRange,
  } = useSourceTabsContext()
  useSourceTabsContext()
  const [showToast, setShowToast] = useState('')
  const [level, setLevel] = useState<LevelType>('tenant')
  const [id, setId] = useState<string>('')
  const graphDateRange = dashboardDateRange
  const { fromDate: currentFromDate, toDate: currentToDate } = getQueryRange(graphDateRange)

  const { data: sources = [], isLoading: isSourceCardLoading } = useGetSourcesByTenantIdQuery(
    {},
    { pollingInterval: POLLING_INTERVAL }
  )
  const {
    data: { sourceTrend, applicationTrend, anomalyTrend, metricTrend } = initialTrendsState,
    isLoading: isKpiCardLoading,
  } = useGetTrendsQuery(
    {
      fromDate: trendFromDate,
      toDate: trendToDate,
    },
    { pollingInterval: POLLING_INTERVAL }
  )
  const queryParameters = {
    fromDate: Math.floor(currentFromDate / (1000 * 60)) * (1000 * 60), // Convert milliseconds to minutes and round down
    toDate: Math.floor(currentToDate / (1000 * 60)) * (1000 * 60), 
    includeTotalAnomalyTrend: true,
    count: 10,
    level: level,
    id: id,
  }

  const { data: anomalyTrends, isLoading: isAnomalyTrendsLoading } = useGetAnomalyTrendsQuery(
    queryParameters,
    { pollingInterval: POLLING_INTERVAL }
  )

  const { data: causationImpacts, isLoading: isCausationImpactsLoading } =
    useGetCausationImpactsQuery(queryParameters, { pollingInterval: POLLING_INTERVAL })

  const { data: applications = [], isLoading: isAppLoading } = useGetApplicationsQuery(
    { sourceId: selectedSource?.value?.toString() },
    {
      skip: selectedSource?.value === 'All',
    }
  )
  const [fetchAnomalies, { isLoading, data: anomalies = [], error }] = useLazyGetAnomalyQuery()

  const redirectFunctions: { [key: string]: () => void } = {
    'Total sources': () => {
      const sourceId = 'allSources'
      const sourceTab = sourceTabs.find((s: SourceTabType) => s.id === sourceId)
      if (!sourceTab) {
        if (sourceTabs.length < 10) {
          dispatch(changeCurrentSource(sourceId))
          dispatch(changeCurrentSourceId(sourceId))
          const sourceTab = sourceTabs.find((s: SourceTabType) => s.id === sourceId)
          if (!sourceTab) {
            addSourceTab({
              id: 'allSources',
              name: 'All Sources',
              path: `/sources`,
              prevActiveTab: '/sources',
              details: sourceId,
            })
            if (sourceTabs.length > 5) {
              swapSourceTab(sourceId)
            }
            navigate(`/sources`)
            if (sourceTabs.length > 4) {
              swapSourceTab(sourceId)
            }
          } else {
            navigate(`/sources`)
          }
        } else {
          setShowToast('Maximum tabs Opened! Please close older source tabs to open alerts')
          setTimeout(() => {
            setShowToast('')
          }, 4000)
        }
      } else {
        if (sourceTabs.length >= 5) {
          swapSourceTab(sourceId)
        }
        navigate(`/sources`)
      }
    },

    // 'Total applications': undefined,
    // 'Total anomalies': undefined,
    // 'Total metrics': undefined,
  }

  const onGraphDateRange = (params: any) => {
    dispatch(changeDashboardDateRange({ ...params }))
  }
  const handleGraphDateTabChange = (value: string) => {
    onGraphDateRange({ rangeType: value })
  }
  const handleGraphDateRangeChange = (value: string | { fromDate: number; toDate: number }) => {
    const graphDateRangeValue =
      graphDateRange.rangeType === 'relative'
        ? { relativeTime: value }
        : (value as { [key: string]: number | string })
    onGraphDateRange(graphDateRangeValue)
  }

  const sourceOptions = sources.map((source) => ({ label: source.sourceName, value: source.id }))

  useEffect(() => {
    let newLevel: LevelType = 'tenant'
    let newId: string = ''
    if (selectedApplication?.value !== 'All') {
      newLevel = 'application'
      newId = selectedApplication?.value as string
    } else if (selectedSource?.value !== 'All') {
      newLevel = 'source'
      newId = selectedSource?.value as string
    }
    setLevel(newLevel)
    setId(newId)
  }, [selectedSource, selectedApplication])

  const applicationOptions = applications.map((application) => ({
    label: application.appName,
    value: application.id,
  }))

  applicationOptions.unshift(appDefault)
  sourceOptions.unshift(sourceDefault)
  function onSourceChange(val: OptionType) {
    if (val.value === 'All') {
      dispatch(setSelectedApplication(appDefault))
    }
    dispatch(setSelectedSource(val))
  }

  function onApplicationChange(val: OptionType) {
    dispatch(setSelectedApplication(val))
  }

  const trends = useMemo(
    () =>
      kpiCardOptions(
        ['Total sources', 'Total applications', 'Total metrics', 'Total anomalies'],
        [sourceTrend, applicationTrend, metricTrend, anomalyTrend]
      ),
    [metricTrend, anomalyTrend, applicationTrend, sourceTrend]
  )

  useEffect(() => {
    if (!isSourceCardLoading) {
      if (sources && sources.length === 0) {
        handleAddSourceClicked()
      }
    }
  }, [sources, isSourceCardLoading])

  const handleMetricRedirection = (
    type: string,
    index: number,
    sourceId: string,
    anomaly: GroupedAnomaliesType
  ) => {
    const queryId = anomaly.queryId
    const applications = anomaly.applications
    let path = ''
    if (anomaly.breaches.length > 0) {
      const selectedBreaches = anomaly.breaches[0]
      dispatch(
        changeMetricSelectedBreach({
          sourceId: sourceId,
          queryId: queryId,
          applications: applications,
          metricType: anomaly.metricType,
          ...selectedBreaches,
        })
      )
      path = appPaths.anomaliesBreachesTab({ sourceId: sourceId, queryId: queryId })
    } else if (anomaly.defdevs.length > 0) {
      const selectedDefDev = anomaly.defdevs[0]
      dispatch(
        changeDefDevSelectedBreach({
          sourceId: sourceId,
          queryId: queryId,
          applications: applications,
          ...selectedDefDev,
        })
      )
      path = appPaths.anomaliesDefDevTab({ sourceId: sourceId, queryId: queryId })
    }
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: path,
      sourceId,
    })
    navigate(path)
  }

  const onGraphClick = async (entity: EntityCount, type: string) => {
    if (type != 'source') dispatch(setDashboardRedirection(true))
    const sourceId = type === 'source' ? entity.id : entity.sourceId
    const sourceName = type === 'source' ? entity.name : entity.sourceName
    const sourceTab = sourceTabs.find((s: SourceTabType) => s.id === sourceId)

    if (!sourceTab) {
      if (sourceTabs.length < 10) {
        dispatch(changeCurrentSourceId(sourceId))
        dispatch(
          setApplicationSource({
            sourceId: sourceId,
            sourceName: sourceName,
          })
        )
        dispatch(setGraphFirstTimeLoading(true))

        addSourceTab({
          id: sourceId,
          name: sourceName,
          prevAnomalyPath: `/dashboard/${DASHBOARD_ROUTES.SOURCES}/${sourceId}/${SOURCES_ROUTES.ANOMALIES}`,
          prevBehaviourPath: `/dashboard/${DASHBOARD_ROUTES.SOURCES}/${sourceId}/${SOURCES_ROUTES.BEHAVIOUR}`,
          prevActiveTab: SOURCES_ROUTES.ANOMALIES,
          details: {},
          behaviourDateRange: graphDateRange,
          anomaliesDateRange: graphDateRange,
        })

        setCurrentTabState({
          sourceId,
          behaviourDateRange: graphDateRange,
          anomaliesDateRange: graphDateRange,
        })

        if (sourceTabs.length > 5) {
          swapSourceTab(sourceId)
        }
        if (type === 'application') {
          dispatch(changeCurrentApplicationId(entity.id))
        } else if (type === 'metric') {
          dispatch(changeCurrentApplicationId(entity.appId))
        }
      } else {
        setShowToast('Maximum tabs Opened! Please close older source tabs to open a new source')
        setTimeout(() => {
          setShowToast('')
        }, 4000)
      }
    } else {
      if (sourceTabs.length >= 5) {
        swapSourceTab(sourceId)
      }

      setCurrentTabState({
        sourceId,
        behaviourDateRange: graphDateRange,
        anomaliesDateRange: graphDateRange,
      })

      if (type === 'application') {
        dispatch(changeCurrentApplicationId(entity.id))
      } else if (type === 'metric') {
        dispatch(changeCurrentApplicationId(entity.appId))
      }
    }
    if (type != 'metric')
      navigate(`/dashboard/${DASHBOARD_ROUTES.SOURCES}/${sourceId}/${SOURCES_ROUTES.ANOMALIES}`)

    if (type === 'metric') {
      try {
        const res = await fetchAnomalies({
          id: sourceId || '',
          level: 'source',
          fromDate: currentFromDate,
          toDate: currentToDate,
        })
        if (res && res.data) {
          const ind = res.data.findIndex((obj) => obj.queryId === entity.id)
          const anomaly = res.data[ind]
          if (ind !== -1) {
            handleMetricRedirection(res.data[ind].metricType || '', ind, sourceId || '', anomaly)
          }
        }
      } catch (error) {
        console.log('Error fetching anomalies:', error)
      }
    }
  }

  const handleAddSourceClicked = () => {
    addSourceTab({
      id: 'add',
      name: 'New Source',
      prevAnomalyPath: `/dashboard/${DASHBOARD_ROUTES.ADD_SOURCE}`,
      prevBehaviourPath: `/dashboard/${DASHBOARD_ROUTES.ADD_SOURCE}`,
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      details: {},
      behaviourDateRange: graphDateRange,
      anomaliesDateRange: graphDateRange,
    })
    if (sourceTabs.length >= 5) {
      swapSourceTab('add')
    }
    dispatch(resetState())

    navigate(`/dashboard/${DASHBOARD_ROUTES.ADD_SOURCE}`)
  }

  const generateHoverComponents = (
    data: EntityCount[],
    includeSource: boolean = false,
    includeApp: boolean = false
  ): string[] => {
    const chunkSize = 30 // Define the chunk size for line breaks

    return data.map((entity) => {
      let hoverText = `Name: ${splitTextWithLineBreaks(entity.name, chunkSize)}<br>Anomalies: ${
        entity.count
      }`
      if (includeSource) {
        hoverText += `<br>Source name: ${splitTextWithLineBreaks(
          entity.sourceName || '',
          chunkSize
        )}`
      }
      if (includeApp) {
        hoverText += `<br>App name: ${splitTextWithLineBreaks(entity.appName || '', chunkSize)}`
      }
      return hoverText
    })
  }

  // Function to split text into chunks with line breaks
  const splitTextWithLineBreaks = (text: string, chunkSize: number): string => {
    const chunks = []
    for (let i = 0; i < text.length; i += chunkSize) {
      chunks.push(text.substr(i, chunkSize))
    }
    return chunks.join('<br>')
  }

  const totalAnomalyCountTrend = anomalyTrends?.data.totalAnomalyCountTrend

  // Filter the entity wise count data for the "query" entities
  const queryEntities = anomalyTrends?.data?.entityWiseCount?.find(
    (item) => item.entity === 'query'
  )
  const yAxisData = queryEntities ? queryEntities?.entityCount.map((item) => item.id) : []
  const barTexts = queryEntities ? queryEntities?.entityCount.map((item) => item.name) : []
  const xAxisData = queryEntities ? queryEntities?.entityCount.map((item) => item.count) : []
  const orientation = 'h' // Vertical orientation
  const labels = ['Query counts'] // Example label for the bars
  const hoverComponents = generateHoverComponents(
    queryEntities ? queryEntities?.entityCount : [],
    true,
    true
  )
  const queryEntityHeading = 'Worst performing metrics (10)'

  const sourceEntities = anomalyTrends?.data?.entityWiseCount?.find(
    (item) => item.entity === 'source'
  )
  const sourceCounts = sourceEntities ? sourceEntities?.entityCount.map((item) => item.count) : []
  const sourceLabels = sourceEntities ? sourceEntities?.entityCount.map((item) => item.name) : []
  const sourceHoverComponents = generateHoverComponents(
    sourceEntities ? sourceEntities?.entityCount : []
  )
  const sourceHeading = 'Worst performing sources'

  const applicationEntities = anomalyTrends?.data?.entityWiseCount?.find(
    (item) => item.entity === 'application'
  )
  const applicationCounts = applicationEntities
    ? applicationEntities?.entityCount.map((item) => item.count)
    : []
  const applicationLabels = applicationEntities
    ? applicationEntities?.entityCount.map((item) => item.name)
    : []
  const applicationHoverComponents = generateHoverComponents(
    applicationEntities ? applicationEntities?.entityCount : [],
    true
  )
  const handleBarClick = (customData: any) => {
    const info = customData.points[0].customdata
    onGraphClick(info, 'metric')
  }
  const applicationHeading = 'Worst performing applications'

  const impactedMetrics = causationImpacts?.data.find((item) => item.entity === 'impactedMetrics')
  const impactedYAxisData = impactedMetrics
    ? impactedMetrics?.entityCount.map((item) => item.id)
    : []
  const impactedBarTexts = impactedMetrics
    ? impactedMetrics?.entityCount.map((item) => item.name)
    : []
  const impactedXAxisData = impactedMetrics
    ? impactedMetrics?.entityCount.map((item) => item.count)
    : []
  const impactedHoverComponents = generateHoverComponents(
    impactedMetrics ? impactedMetrics?.entityCount : [],
    true,
    true
  )
  const impactingHeading = 'Top anomaly causing metrics (10)'

  const impactingMetrics = causationImpacts?.data.find((item) => item.entity === 'impactingMetrics')
  const impactingYAxisData = impactingMetrics
    ? impactingMetrics?.entityCount.map((item) => item.id)
    : []
  const impactingBarTexts = impactingMetrics
    ? impactingMetrics?.entityCount.map((item) => item.name)
    : []
  const impactingXAxisData = impactingMetrics
    ? impactingMetrics?.entityCount.map((item) => item.count)
    : []
  const impactingHoverComponents = generateHoverComponents(
    impactingMetrics ? impactingMetrics?.entityCount : [],
    true,
    true
  )
  const impactedHeading = 'Top impacted metrics (10)'

  const handlePieClickSource = (data: any) => {
    const info = data.customdata[0]
    onGraphClick(info, 'source')
  }

  const handlePieClickApplication = (data: any) => {
    const info = data.customdata[0]
    onGraphClick(info, 'application')
  }

  return (
    <motion.div
      data-testid={MAIN_DASHBOARD_TEST_ID}
      className="flex h-[93vh] w-full flex-col overflow-x-hidden overflow-y-scroll px-6"
      {...pageTransitionAnimation}
    >
      <div className="flex flex-row pt-3">
        <div className="basis-10/12 border-r border-solid border-gray-700 pr-6">
          <div className="mb-3 flex justify-between">
            <div className="flex w-11/12 items-center text-xl font-semibold text-white">Home</div>
            <div className="flex items-center">
              <div className="mr-2 flex flex-row gap-2">
                <Autocomplete
                  iconColor={COLORS.PRIMARY}
                  className="border-1 !w-40 border-primary bg-dark2 "
                  inputClasses="text-xs border-primary !text-primary font-semibold"
                  value={sourceOptions.find((a: OptionType) => selectedSource?.value === a.value)}
                  placeholder="Source Name"
                  options={sourceOptions}
                  onChange={onSourceChange}
                  onFocus={handleClick}
                  optionClasses={'!w-auto !right-0'}
                  maxChars={8}
                  noDataWidth={'!w-40'}
                />
                <div className="w-40 border-primary">
                  <Autocomplete
                    iconColor={selectedSource?.value === 'All' ? COLORS.WARMGREY28 : COLORS.PRIMARY}
                    className="border-1 !w-30 border-primary bg-dark2"
                    inputClasses={`text-xs font-semibold ${
                      selectedSource?.value === 'All'
                        ? '!text-warm_grey border-warm_grey !focus:border-none'
                        : '!text-primary border-primary'
                    }`}
                    value={
                      selectedSource?.value === 'All'
                        ? applicationOptions[0]
                        : applicationOptions.find(
                            (a: OptionType) => selectedApplication?.value === a.value
                          )
                    }
                    placeholder="Application"
                    options={applicationOptions}
                    onChange={onApplicationChange}
                    onFocus={handleClick}
                    disabled={selectedSource?.value === 'All'}
                    optionClasses={'!w-auto !right-0'}
                    maxChars={8}
                    noDataWidth={'!w-40'}
                  />
                </div>
              </div>
              <div className="flex">
                <GraphDateRange
                  tabs={graphDateTabs}
                  rangeType={graphDateRange?.rangeType}
                  options={anomaliesForecastOptions}
                  infoText={''}
                  fromDate={graphDateRange?.fromDate}
                  toDate={graphDateRange?.toDate}
                  relativeValue={graphDateRange?.relativeTime}
                  onChange={handleGraphDateRangeChange}
                  onTabChange={handleGraphDateTabChange}
                  onShowForecastChange={() => null}
                  showForecast={false}
                  displayRelativeForecast={false}
                  maxAllowedDays={1}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="mb-4 flex gap-4">
              {!isCausationImpactsLoading ? (
                <DashboardAnomalyChart
                  currentDurationCount={totalAnomalyCountTrend?.currentDurationCount || 0}
                  previousDurationCount={totalAnomalyCountTrend?.previousDurationCount || 0}
                  change={totalAnomalyCountTrend?.change || 0}
                  changeType={totalAnomalyCountTrend?.changeType || 'no change'}
                  toDate={new Date(currentToDate)}
                  fromDate={new Date(currentFromDate)}
                />
              ) : (
                <DashboardCardLoader numberOfItems={3} heading={'Anomalies'} />
              )}

              {!isCausationImpactsLoading && (
                <>
                  {(!impactingMetrics ||
                    (causationImpacts && causationImpacts.data.length === 0) ||
                    (impactingMetrics && impactingMetrics?.entityCount.length === 0)) && (
                    <NoDataMessage heading={impactingHeading} />
                  )}
                  {impactingMetrics && impactingMetrics?.entityCount.length > 0 && (
                    <BarChart
                      xAxisData={impactingXAxisData}
                      yAxisData={[impactingYAxisData]}
                      orientation={orientation}
                      colors={[COLORS.CORNELL_RED]}
                      labels={labels}
                      plotBackgroundColor={COLORS.DARK94}
                      containerBackgroundColor={COLORS.DARK94}
                      fontColor={COLORS.WARM_GREY}
                      xAxisTitle="Impacts"
                      yAxisTitle=""
                      annotationTexts={['']}
                      barTexts={[impactingBarTexts]}
                      heading={impactingHeading}
                      subheading="by Impacts"
                      showViewAllButton={false}
                      hoverComponents={[impactingHoverComponents]}
                      clickData={impactingMetrics ? [impactingMetrics?.entityCount] : []}
                      onClick={handleBarClick}
                    />
                  )}
                </>
              )}
              {isCausationImpactsLoading && (
                <DashboardCardLoader numberOfItems={3} heading={impactingHeading} />
              )}

              {!isCausationImpactsLoading && (
                <>
                  {(!impactedMetrics ||
                    (causationImpacts && causationImpacts.data.length === 0) ||
                    (impactedMetrics && impactedMetrics?.entityCount.length === 0)) && (
                    <NoDataMessage heading={impactedHeading} />
                  )}
                  {impactedMetrics && impactedMetrics?.entityCount.length > 0 && (
                    <BarChart
                      xAxisData={impactedXAxisData}
                      yAxisData={[impactedYAxisData]}
                      orientation={orientation}
                      colors={[COLORS.GRAPE]}
                      labels={labels}
                      plotBackgroundColor={COLORS.DARK94}
                      containerBackgroundColor={COLORS.DARK94}
                      fontColor={COLORS.WARM_GREY}
                      xAxisTitle="Anomalies"
                      yAxisTitle=""
                      annotationTexts={['']}
                      barTexts={[impactedBarTexts]}
                      heading={impactedHeading}
                      subheading="by Causes"
                      showViewAllButton={false}
                      hoverComponents={[impactedHoverComponents]}
                      clickData={impactedMetrics ? [impactedMetrics?.entityCount] : []}
                      onClick={handleBarClick}
                    />
                  )}
                </>
              )}
              {isCausationImpactsLoading && (
                <DashboardCardLoader numberOfItems={3} heading={impactedHeading} />
              )}
            </div>
            <div className="flex gap-4">
              {!isAnomalyTrendsLoading && (
                <>
                  {(!queryEntities ||
                    (anomalyTrends && anomalyTrends.data?.entityWiseCount?.length == 0) ||
                    (queryEntities && queryEntities?.entityCount.length === 0)) && (
                    <NoDataMessage heading={queryEntityHeading} />
                  )}
                  {queryEntities && queryEntities?.entityCount.length > 0 && (
                    <BarChart
                      xAxisData={xAxisData}
                      yAxisData={[yAxisData]}
                      orientation={orientation}
                      colors={[COLORS.CORNELL_RED]}
                      labels={labels}
                      plotBackgroundColor={COLORS.DARK94}
                      containerBackgroundColor={COLORS.DARK94}
                      fontColor={COLORS.WARM_GREY}
                      xAxisTitle="Anomalies"
                      yAxisTitle=""
                      annotationTexts={['']}
                      barTexts={[barTexts]}
                      heading={queryEntityHeading}
                      showViewAllButton={false}
                      hoverComponents={[hoverComponents]}
                      clickData={queryEntities ? [queryEntities?.entityCount] : []}
                      onClick={handleBarClick}
                    />
                  )}
                </>
              )}
              {isAnomalyTrendsLoading && (
                <DashboardCardLoader numberOfItems={3} heading={queryEntityHeading} />
              )}

              {!isAnomalyTrendsLoading && (
                <>
                  {(!sourceEntities ||
                    (anomalyTrends && anomalyTrends.data?.entityWiseCount?.length == 0) ||
                    (sourceEntities && sourceEntities?.entityCount.length === 0)) && (
                    <NoDataMessage heading={sourceHeading} />
                  )}
                  {sourceEntities && sourceEntities?.entityCount.length > 0 && (
                    <PieChart
                      data={sourceCounts}
                      labels={sourceLabels}
                      colors={PIECHART_COLORS}
                      containerBackgroundColor="transparent"
                      plotBackgroundColor="transparent"
                      fontColor={COLORS.WHITE}
                      heading={sourceHeading}
                      showViewAllButton={false}
                      hoverData={sourceHoverComponents}
                      onClick={handlePieClickSource}
                      clickData={sourceEntities ? sourceEntities?.entityCount : []}
                    />
                  )}
                </>
              )}
              {isAnomalyTrendsLoading && (
                <DashboardCardLoader numberOfItems={3} heading={sourceHeading} />
              )}
              {!isAnomalyTrendsLoading && (
                <>
                  {(!applicationEntities ||
                    (anomalyTrends && anomalyTrends.data?.entityWiseCount?.length == 0) ||
                    (applicationEntities && applicationEntities?.entityCount.length === 0)) && (
                    <NoDataMessage heading={applicationHeading} />
                  )}
                  {applicationEntities && applicationEntities?.entityCount.length > 0 && (
                    <PieChart
                      data={applicationCounts}
                      labels={applicationLabels}
                      colors={PIECHART_COLORS}
                      hoverData={applicationHoverComponents}
                      containerBackgroundColor="transparent"
                      plotBackgroundColor="transparent"
                      fontColor={COLORS.WHITE}
                      heading={applicationHeading}
                      showViewAllButton={false}
                      onClick={handlePieClickApplication}
                      clickData={applicationEntities ? applicationEntities?.entityCount : []}
                    />
                  )}
                </>
              )}
              {isAnomalyTrendsLoading && (
                <DashboardCardLoader numberOfItems={3} heading={applicationHeading} />
              )}
            </div>
          </div>
        </div>
        <div className="basis-2/12 pl-6">
          <div className="mb-4 flex w-11/12 items-center text-base font-semibold text-white">
            Overview
          </div>
          <div className="flex flex-col space-y-4">
            {isKpiCardLoading ? (
              <KpiCardLoader numberOfItems={4} />
            ) : (
              trends.map((trend) => (
                <KpiCard
                  key={trend.title}
                  title={trend.title}
                  total={trend.count}
                  percentage={trend.change}
                  changeType={trend.changeType}
                  chartData={makeTrendobject(trend.data)}
                  backgroundColor={COLORS.DARK94}
                  {...(redirectFunctions[trend.title] && {
                    onClick: () => redirectFunctions[trend.title](),
                  })}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </motion.div>
  )
}
