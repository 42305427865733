import { HelperTextProps } from './types'
export const HELPER_TEXT_TEST_ID = 'HELPER_TEXT_TEST_ID'

export default function HelperText({
  testId = HELPER_TEXT_TEST_ID,
  text,
  color,
  size,
}: HelperTextProps) {
  return (
    <span data-testid={testId} className={`my-1 text-xs text-warm_grey text-${size} text-${color}`}>
      {text}
    </span>
  )
}
