import { NavLink, Outlet, useMatches, useNavigate, useParams } from 'react-router-dom'
import {
  ANOMALIES_ROUTES_NAMES,
  DASHBOARD_ROUTES,
  SOURCES_ROUTES,
  SOURCES_ROUTES_NAMES,
  appPaths,
} from '../../../routes'
import { Breadcrumb } from '../../../components'
import { useAppSelector } from '../../../hooks'
import { selectedBreachStateSelector } from '../../../store/anomalies'
import { useSourceTabsContext } from '../../../providers'

export const SOURCE_TEST_ID = 'SOURCE_TEST_ID'

export default function Source() {
  const navigate = useNavigate()
  const { sourceId = '' } = useParams()
  const matches = useMatches()
  const { setCurrentTabState, currentSourceTab } = useSourceTabsContext()
  const selectedBreach = useAppSelector(selectedBreachStateSelector(sourceId))
  const lastRouteId = matches[matches.length - 1].id

  const handleRouteState = (nextPath: string) => {
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.BEHAVIOUR,
      prevBehaviourPath: nextPath,
      sourceId,
    })
  }

  const handleMultivariateClick = () => {
    const path = appPaths.multivariatePage({
      sourceId,
      applicationId: selectedBreach.applicationId,
    })
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: path,
      sourceId,
    })

    navigate(path)
  }

  const handleFeatureTabClick = () => {
    const path = appPaths.anomaliesPage({ sourceId })
    setCurrentTabState({
      prevActiveTab: SOURCES_ROUTES.ANOMALIES,
      prevAnomalyPath: path,
      sourceId,
    })

    navigate(path)
  }

  const inititalRoutes = [
    { routeName: 'All sources', onClick: () => navigate(`/${DASHBOARD_ROUTES.HOME}`) },
    { routeName: currentSourceTab.name, onClick: () => null },
  ]

  const breadcrumbs = {
    // Route for Anomalies dashboard screen
    [SOURCES_ROUTES_NAMES.ANOMALIES]: inititalRoutes.concat([
      { routeName: 'Anomalies', onClick: handleFeatureTabClick },
    ]),

    // Route for Behaviour screen
    [SOURCES_ROUTES_NAMES.BEHAVIOUR]: inititalRoutes.concat([
      { routeName: 'Behaviour', onClick: () => handleFeatureTabClick },
    ]),
  }

  // Route for view multivariate screen
  breadcrumbs[SOURCES_ROUTES_NAMES.VIEW_MULTIVARIATE] = breadcrumbs[
    SOURCES_ROUTES_NAMES.ANOMALIES
  ].concat([{ routeName: 'Multivariate analysis', onClick: () => handleMultivariateClick }])

  // Route for view multivariate behaviour screen
  breadcrumbs[SOURCES_ROUTES_NAMES.MULTIVARIATE_BEHAVIOUR] = breadcrumbs[
    SOURCES_ROUTES_NAMES.ANOMALIES
  ].concat([
    { routeName: 'Multivariate analysis', onClick: handleMultivariateClick },
    { routeName: 'Behaviour', onClick: () => null },
  ])

  //Route for app behaviour screen
  breadcrumbs[SOURCES_ROUTES_NAMES.APP_BEHAVIOUR] = breadcrumbs[
    SOURCES_ROUTES_NAMES.ANOMALIES
  ].concat([{ routeName: 'Behaviour', onClick: () => null }])

  //Route for Breaches tab in Anomalies view
  breadcrumbs[ANOMALIES_ROUTES_NAMES.BREACHES] = breadcrumbs[SOURCES_ROUTES_NAMES.ANOMALIES].concat(
    [{ routeName: 'Breaches', onClick: () => null }]
  )

  //Route for Divergence tab in Anomalies view
  breadcrumbs[ANOMALIES_ROUTES_NAMES.DEF_DEV] = breadcrumbs[SOURCES_ROUTES_NAMES.ANOMALIES].concat([
    { routeName: 'Divergence', onClick: () => null },
  ])

  return (
    <div className="flex-1 text-white" data-testid={SOURCE_TEST_ID}>
      <div className="flex h-11 w-full flex-row items-center pl-4">
        <div className="flex w-[40rem]">
          <Breadcrumb items={breadcrumbs[lastRouteId]} />
        </div>

        <div className="flex w-[30rem] flex-row justify-center">
          <NavLink
            end
            to={currentSourceTab.prevAnomalyPath}
            className={({ isActive }) =>
              `cursor-pointer justify-center px-2 py-3 capitalize ${
                isActive || window.location.pathname.includes(SOURCES_ROUTES.ANOMALIES)
                  ? 'border-b-[3px] border-y-primary text-primary'
                  : ''
              }`
            }
            onClick={() => {
              handleFeatureTabClick()
            }}
          >
            {SOURCES_ROUTES.ANOMALIES}
          </NavLink>
          <NavLink
            onClick={() => handleRouteState(currentSourceTab.prevBehaviourPath)}
            to={currentSourceTab.prevBehaviourPath}
            className={({ isActive }) =>
              `cursor-pointer items-center justify-center px-2 py-3 capitalize ${
                isActive && 'border-b-[3px] border-y-primary text-primary'
              }`
            }
          >
            {SOURCES_ROUTES.BEHAVIOUR}
          </NavLink>
        </div>
        <div className="w-2/6"></div>
      </div>
      <div className="h-[0.5px] w-full bg-secondary" />
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  )
}
