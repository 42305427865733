import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks'
import {
  SourceTabType,
  addTab,
  swapTabs,
  changeBehaviourDateRange,
  changeAnomaliesDateRange,
  changeMultivariateBehaviourDateRange,
  changeMultivariateDateRange,
  removeTab,
  setSourceDetails,
  setRouteState,
  tabSourcesSelector,
  toggleCompareMode,
  changeSourceDetailsHeaders,
  changeSourceDetailsValues,
} from '../store/source-tab'
import { DASHBOARD_ROUTES, SOURCES_ROUTES } from '../routes'

export type { SourceTabType } from '../store/source-tab'

interface ISourceTabsProvider {
  sourceTabs: SourceTabType[]
  currentSourceTab: SourceTabType
  addSourceTab: any
  swapSourceTab: any
  removeSourceTab: any
  setCurrentTabState: any
  changeSourceBehaviourDateRange: any
  changeSourceAnomaliesDateRange: any
  changeSourceMultivariateDateRange: any
  changeSourceMultivariateBehaviourDateRange: any
  toggleSourceCompareMode: any
  setSourceTabValues: any
  setSourceDetailsValues: any
  setSourceTabDetailsHeader: any
}

const SourceTabsContext = createContext<ISourceTabsProvider>({
  sourceTabs: [],
  currentSourceTab: {} as SourceTabType,
  addSourceTab: () => null,
  removeSourceTab: () => null,
  swapSourceTab: () => null,
  setCurrentTabState: () => null,
  changeSourceBehaviourDateRange: () => null,
  changeSourceAnomaliesDateRange: () => null,
  changeSourceMultivariateDateRange: () => null,
  changeSourceMultivariateBehaviourDateRange: () => null,
  toggleSourceCompareMode: () => null,
  setSourceTabValues: () => null,
  setSourceDetailsValues: () => null,
  setSourceTabDetailsHeader: () => null,
})

export const SourceTabsProvider = ({ children }: PropsWithChildren) => {
  const { sourceId } = useParams()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const tabs = useAppSelector(tabSourcesSelector)
  const sourceTabs = useMemo(
    () =>
      tabs.map(
        ({
          id,
          name,
          prevActiveTab,
          prevAnomalyPath,
          prevBehaviourPath,
          anomaliesDateRange,
          ...others
        }: SourceTabType) => ({
          id,
          name,
          path: prevActiveTab === SOURCES_ROUTES.ANOMALIES ? prevAnomalyPath : prevBehaviourPath,
          isActive:
            (pathname === '/sources' && id == 'allSources') ||
            (pathname != '/sources' &&
              (pathname.includes(`/${DASHBOARD_ROUTES.HOME}/${DASHBOARD_ROUTES.SOURCES}/${id}`) ||
                pathname.includes(`/${DASHBOARD_ROUTES.HOME}/${id}`))),
          prevActiveTab,
          prevAnomalyPath,
          prevBehaviourPath,
          anomaliesDateRange,
          ...others,
        })
      ),
    [pathname, tabs]
  )

  const currentSourceTab: SourceTabType = useMemo(
    () => sourceTabs.find((s: SourceTabType) => s.id === sourceId) || {},
    [sourceTabs, sourceId]
  )

  const addSourceTab = (params: any) => dispatch(addTab(params))
  const swapSourceTab = (params: any) => dispatch(swapTabs(params))
  const removeSourceTab = (params: any) => dispatch(removeTab(params))
  const setCurrentTabState = (params: any) => dispatch(setRouteState(params))
  const changeSourceBehaviourDateRange = (params: any) => dispatch(changeBehaviourDateRange(params))
  const changeSourceAnomaliesDateRange = (params: any) => dispatch(changeAnomaliesDateRange(params))
  const changeSourceMultivariateDateRange = (params: any) =>
    dispatch(changeMultivariateDateRange(params))
  const changeSourceMultivariateBehaviourDateRange = (params: any) =>
    dispatch(changeMultivariateBehaviourDateRange(params))
  const toggleSourceCompareMode = (params: any) => dispatch(toggleCompareMode(params))
  const setSourceTabValues = (params: any) => dispatch(setSourceDetails(params))
  const setSourceDetailsValues = (params: any) => dispatch(changeSourceDetailsValues(params))
  const setSourceTabDetailsHeader = (params: any) => dispatch(changeSourceDetailsHeaders(params))

  return (
    <SourceTabsContext.Provider
      value={{
        sourceTabs,
        currentSourceTab,
        swapSourceTab,
        addSourceTab,
        removeSourceTab,
        setCurrentTabState,
        changeSourceBehaviourDateRange,
        changeSourceAnomaliesDateRange,
        changeSourceMultivariateDateRange,
        changeSourceMultivariateBehaviourDateRange,
        toggleSourceCompareMode,
        setSourceTabValues,
        setSourceDetailsValues,
        setSourceTabDetailsHeader,
      }}
    >
      {children}
    </SourceTabsContext.Provider>
  )
}

export const useSourceTabsContext = (sourceId?: string) => {
  const context = useContext(SourceTabsContext)

  if (!context) {
    throw new Error('useSourceTabsContext must be used inside the SourceTabsProvider')
  }

  return context
}
