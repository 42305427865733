import { CSSProperties, PropsWithChildren } from 'react'

interface GraphTooltipProps extends PropsWithChildren {
  style: CSSProperties
  pointerColor: string
}

export default function GraphTooltip({ children, style, pointerColor }: GraphTooltipProps) {
  return (
    <div className="fixed z-10 h-0 w-28" style={style}>
      <div
        className={`group relative flex h-full w-full items-center justify-center before:absolute before:left-0 before:top-2/4 before:z-[2] before:h-2 before:w-2 before:-translate-x-2/4 before:-translate-y-2/4 before:content-[''] before:[clip-path:polygon(50%_0,100%_50%,50%_100%,0_50%)] before:${pointerColor}`}
      >
        <span
          className={`visible absolute left-0 top-2/4 -ml-2.5 -mt-2.5 mb-0 mr-0 h-5 w-5 scale-100 rounded-[50%] opacity-50 transition-[background-color,opacity,visibility,-webkit-transform] duration-[0.5s] ease-[ease,ease,ease,cubic-bezier(0.5,0,0,1)] ${pointerColor}`}
        />
        <div
          className="pointer-events-none invisible absolute -left-[1.85rem] -top-[5.15rem] ml-14 w-full
        p-1 text-xs text-white opacity-0 [transition:opacity_0.7s_ease,visibility_0.7s_ease] before:absolute before:bottom-0 before:left-0 before:h-px
        before:w-0 before:bg-white before:transition-[width] before:duration-[0.4s] before:content-[''] after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-10 after:origin-[0_50%]
        after:rotate-[-225deg] after:bg-white after:opacity-100 after:transition-opacity after:delay-[0s]
        after:duration-[0.5s] after:ease-[ease] after:content-[''] group-hover:pointer-events-none group-hover:visible group-hover:absolute group-hover:w-full group-hover:text-white group-hover:opacity-100 group-hover:[transition:opacity_0.7s_ease,visibility_0.7s_ease]
        group-hover:before:w-20 group-hover:before:transition-[width] group-hover:before:duration-[0.4s] group-hover:after:visible group-hover:after:opacity-100"
        >
          {children}
        </div>
      </div>
    </div>
  )
}
