import Plot from 'react-plotly.js'
import { SingleLineChartProps } from './types'
import { ellipsizeString } from '../../../utils'
import { COLORS } from '../../../../themes/default/colors'

export default function SingleLineChart({
  x,
  y,
  colors,
  containerColor,
  plotColor,
  fontColor,
  xTitle,
  yTitle,
  onHover,
  onUnhover,
  onClick,
  marginTop = 60,
  marginBottom = 100,
  annotationTexts = [],
  severity,
  children,
  anomalyCounts,
}: SingleLineChartProps) {
  return (
    <div className="relative flex h-full w-full flex-col">
      {children}
      <Plot
        className="h-full w-full"
        onHover={onHover}
        onClick={onClick}
        onUnhover={onUnhover}
        data={[
          {
            name: 'Ground Truth',
            x,
            y,
            hovertemplate: anomalyCounts.map(
              (count) =>
                `<b style="color:red;l">Critical :</b>${count.critical}<br>` +
                `<b style="color:orange;">Major:</b> ${count.major}<br>` +
                `<b style="color:yellow;">Minor: </b>${count.minor}<br>` +
                '<extra></extra>'
            ),
            fill: 'none',
            line: {
              color: COLORS.PRIMARY,
            },
            mode: 'lines+markers',
            marker: {
              color: severity.map((s) => colors[s]),
              size: 10,
            },
            hoverinfo: 'none',
            showlegend: false,
            textposition: 'bottom center',
          },
        ]}
        layout={{
          margin: { r: 35, t: marginTop, b: marginBottom, l: 70 },
          autosize: true,
          paper_bgcolor: containerColor,
          plot_bgcolor: plotColor,
          font: {
            color: fontColor,
            size: 10,
          },
          xaxis: {
            title: xTitle,
            showspikes: false,
            showgrid: false,
          },
          yaxis: {
            title: yTitle,
            rangemode: 'tozero',
            titlefont: { size: 10 },
            gridcolor: '#3A3A3A', // Set the color of the X-axis grid lines
            gridwidth: 1, // Set the width of the X-axis grid lines
            griddash: 'dot',
          },
          annotations: [
            {
              xref: 'paper',
              yref: 'paper',
              x: -0.01,
              y: 1.1,
              xanchor: 'left',
              yanchor: 'bottom',
              text: ellipsizeString(annotationTexts[0], 20),
              showarrow: false,
              font: {
                size: 12,
              },
              hovertext: annotationTexts[0],
            },
          ],
          hoverlabel: {
            namelength: 20,
            bgcolor: COLORS.DARK2,
          },
          legend: {
            yanchor: 'bottom',
            y: -0.45,
            xanchor: 'center',
            x: 0.05,
            orientation: 'h',
            font: { size: 10 },
          },
        }}
        config={{
          displaylogo: false,
          doubleClick: 'reset+autosize',
          watermark: false,
          scrollZoom: false,
          modeBarButtons: [[]],
        }}
        useResizeHandler
      />
    </div>
  )
}
