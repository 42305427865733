import { AlertRuleType } from '../../../../store/alert-rule-dashboard/types'
import { Table } from '../../../../components'

export default function AlertsTableLoader() {
  const tableArray = new Array(10).fill(null)
  const columns = [
    {
      title: 'Severity',
      key: 'severityOption',
      sorter: (a: AlertRuleType, b: AlertRuleType) => a.severityOption - b.severityOption,
      render: (params: AlertRuleType) => {
        return (
          <div className="m-1 h-4 !w-[8vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        )
      },
    },
    {
      title: 'Alert name',
      dataIndex: 'ruleName',
      sorter: (a: AlertRuleType, b: AlertRuleType) => a.ruleName?.localeCompare(b.ruleName),
      render: (params: AlertRuleType) => {
        return (
          <div className="m-1 h-4 !w-[8vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        )
      },
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      align: 'center' as 'center',
      sorter: (a: AlertRuleType, b: AlertRuleType) => (a.emails ?? 0) - (b.emails ?? 0),
      render: (params: AlertRuleType) => {
        return (
          <div className="m-1 h-4 !w-[8vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        )
      },
    },
    {
      title: 'Silence period',
      dataIndex: 'silencePeriod',
      align: 'center' as 'center',
      sorter: (a: AlertRuleType, b: AlertRuleType) =>
        a.silencePeriod?.localeCompare(b.silencePeriod) ?? 0,
      render: (params: AlertRuleType) => {
        return (
          <div className="m-1 h-4 !w-[8vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        )
      },
    },
    {
      title: 'Created on',
      dataIndex: 'createdOn',
      sorter: (a: AlertRuleType, b: AlertRuleType) =>
        new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      render: (params: AlertRuleType) => {
        return (
          <div className="m-1 h-4 !w-[8vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        )
      },
    },
    {
      title: 'Modified on',
      dataIndex: 'modifiedOn',
      sorter: (a: AlertRuleType, b: AlertRuleType) =>
        new Date(a.modifiedOn).getTime() - new Date(b.modifiedOn).getTime(),
      render: (params: AlertRuleType) => {
        return (
          <div className="m-1 h-4 !w-[8vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        )
      },
    },
    {
      title: 'State',
      key: 'state',
      align: 'center' as 'center',
      sorter: (a: AlertRuleType, b: AlertRuleType) => Number(a.active) - Number(b.active),
      render: (params: AlertRuleType) => {
        return (
          <div className="!w-[8vw]] m-1 h-4 animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        )
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (params: AlertRuleType) => {
        return (
          <div className="m-1 h-4 !w-[8vw] animate-shimmer rounded-[10px] bg-shimmer-gradient bg-shimmer"></div>
        )
      },
    },
  ]

  const defaultFooter = () => <div className="h-5"></div>

  const modifiedAlertRules = tableArray.map((a, index) => {
    return {
      ...a,
      key: index,
      id: 'id',
      severityOption: 'severityOption',
      ruleName: 'ruleName',
      emails: 'emails',
      silencePeriod: 'silencePeriod',
      createdOn: 'createdOn',
      modifiedOn: 'modifiedOn',
      active: 'active',
    }
  })
  return (
    <Table
      scroll={{ y: 570 }}
      showSorterTooltip={false}
      footer={defaultFooter}
      columns={columns}
      dataSource={modifiedAlertRules}
    />
  )
}
