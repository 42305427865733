import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const RAKUTEN_LOGO_TEST_ID = 'RAKUTEN_LOGO_TEST_ID'

export const RakutenLogo = ({
  testId = RAKUTEN_LOGO_TEST_ID,
  className,
  color = COLORS.WHITE,
  pathClasses,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg viewBox="0 0 60 18" xmlns="http://www.w3.org/2000/svg" fill="none">
      <g fill={color} clipPath="url(#a)">
        <path d="M48.282 14.973H12.007l2.894 2.893 33.381-2.893ZM54.105 13.13V7.343c0-1.085.76-1.99 1.844-1.99 1.085 0 1.845.905 1.845 1.99v5.787h2.242V7.343c0-2.387-1.627-4.304-4.014-4.304-1.085 0-1.917.615-1.917.615v-.398h-2.242v9.837h2.242v.037ZM46.69 3c-2.603 0-4.448 2.278-4.448 5.172 0 3.038 2.315 5.172 4.666 5.172 1.193 0 2.676-.398 3.942-2.207L48.86 9.98c-1.518 2.242-4.086 1.121-4.375-1.157h6.437C51.501 5.35 49.222 3 46.691 3Zm1.954 3.906h-4.015c.47-2.279 3.58-2.423 4.015 0Z" />
        <path d="M41.519 10.779c-.217.145-.47.253-.76.253-.361 0-1.048-.29-1.048-1.194V5.607h1.916v-2.35h-1.916V.796h-2.243v2.46h-1.193v2.35h1.193v4.231c0 2.207 1.664 3.509 3.328 3.509.614 0 1.483-.217 2.17-.615l-1.447-1.953ZM33.273 3.254V9.04c0 1.085-.76 1.989-1.844 1.989-1.085 0-1.845-.904-1.845-1.99V3.255h-2.242V9.04c0 2.386 1.627 4.303 4.014 4.303 1.085 0 1.917-.614 1.917-.614v.361h2.242V3.254h-2.242Z" />
        <path d="m23.146 7.884 3.834-4.629h-3.11l-2.713 3.436V0H18.88v13.128h2.278v-4.05l3.328 4.05h3.11l-4.448-5.244ZM15.37 3.253v.434C14.685 3.253 14.106 3 13.274 3c-2.532 0-4.485 2.315-4.485 5.172 0 2.857 1.917 5.172 4.485 5.172.832 0 1.447-.254 2.098-.688v.434h2.242V3.253h-2.242Zm-2.133 7.74c-1.266 0-2.17-1.23-2.17-2.785 0-1.555.904-2.785 2.17-2.785s2.134 1.23 2.134 2.785c.036 1.555-.868 2.785-2.134 2.785Z" />
        <path d="M2.35 13.13V9.296h1.628l2.857 3.797h2.93L6.293 8.5c1.085-.76 1.772-2.025 1.772-3.472A4.237 4.237 0 0 0 3.834.797H0v12.296h2.35v.037Zm0-9.982h1.52a1.93 1.93 0 0 1 1.917 1.917A1.93 1.93 0 0 1 3.87 6.98H2.35V3.148Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path className={pathClasses} fill={color} d="M0 0h60v17.866H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
)
