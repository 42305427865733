import { OptionType } from '../../../../types'
import { Tooltip } from '../../../../components'

interface OptionsWrapperProps extends OptionType {
  children: React.ReactElement
}

export default function OptionsWrapper({ label, children }: OptionsWrapperProps) {
  return (
    <Tooltip placement="right" className="border-1 whitespace-normal" title={label}>
      {children}
    </Tooltip>
  )
}
