import { ReactNode, useState } from 'react'
import { TimelineDot } from '@mui/lab'
import { HelperText, Icon, List, ListItem, Select, Tooltip } from '../../components'
import { Check, Close } from '../../components/icons'
import { MetricUnitTypes } from '../../store/application'
import { COLORS } from '../../../themes/default/colors'
import { QueryType } from '../../store/add-application'

export const METRIC_QUERY_LIST_TEST = 'METRIC_QUERY_LIST_TEST'

export const unitOptions = Object.values(MetricUnitTypes).map((k) => ({ label: k, value: k }))

export interface MetricQuery extends QueryType {}

const queryStatus: { [key: string]: ReactNode } = {
  success: <Check className="w-5" color={'#00ff00'} />,
  loading: <TimelineDot className="h-3 w-3" color={'warning'} />,
  error: <Close className="w-4" color={'#ff0000'} />,
}

function CopyTooltip({ value }: { value: string }) {
  const [copyMessage, setCopyMessage] = useState('Copy to clipboard')
  const handleCopyToClipboard = (textToCopy: string) => {
    setCopyMessage('Copied')
    navigator.clipboard.writeText(textToCopy)
    setTimeout(() => {
      setCopyMessage('Copy to clipboard')
    }, 1000)
  }
  return (
    <Tooltip placement="top" className="border-1 w-auto whitespace-normal" title={copyMessage}>
      <Icon.CopyFilled
        testId={`${METRIC_QUERY_LIST_TEST}_COPY`}
        pathClasses="hover:fill-primary"
        className="w-4 cursor-pointer"
        onClick={() => handleCopyToClipboard(value)}
      />
    </Tooltip>
  )
}

export default function MetricQueryList({
  queries,
  onDelete,
  onUnitChange,
}: {
  queries: MetricQuery[]
  onDelete: (index: number) => void
  onUnitChange: (index: number, unit: string) => void
}) {
  return (
    <>
      <div className="h-40 w-full overflow-y-scroll bg-dark">
        <List>
          {queries.map((query, index) => (
            <ListItem
              testId={`${METRIC_QUERY_LIST_TEST}_LIST_ITEM`}
              key={query.value}
              className="h-10 gap-1 border-none px-3 py-2"
            >
              <div className="w-2/12 truncate">
                <div className="flex items-center overflow-hidden text-sm">{query.metricType}</div>
              </div>
              {!query.hasUnit ? (
                <div className="w-2/12">
                  <Select
                    listboxClasses="!z-[unset]"
                    optionClasses="!z-30"
                    iconColor={COLORS.PRIMARY}
                    className="!w-full"
                    buttonClasses="hover:opacity-70 !h-6 shadow-none focus:border-none"
                    optionItemClasses="!pl-2 text-xs"
                    selectedIconClasses={'!hidden'}
                    selectedValueClasses={'text-xs'}
                    value={{ label: query.unit, value: query.unit }}
                    placeholder="select unit"
                    options={unitOptions}
                    onChange={(opt) => onUnitChange(index, opt.value.toString())}
                  />
                </div>
              ) : (
                <div className="w-2/12 truncate text-center">
                  <div className="flex items-center justify-center text-sm">{query.unit}</div>
                </div>
              )}
              <div className="w-5/12 truncate">
                <Tooltip className="border-1 whitespace-normal" title={query.value}>
                  <div className="flex w-full items-center overflow-hidden text-sm">
                    {query.value}
                  </div>
                </Tooltip>
              </div>
              <div className="ml-4 flex w-3/12 items-center">
                <div>{queryStatus[query.status || 'error']} </div>
                <div className="ml-2 font-RakutenSansLight text-sm">{query.message}</div>
              </div>

              <div className="flex flex-row items-center justify-end gap-3">
                {<CopyTooltip value={query.value} />}

                <Tooltip placement="top" title={'Delete'}>
                  <Icon.DeleteFilled
                    testId={`${METRIC_QUERY_LIST_TEST}_DELETE`}
                    pathClasses="hover:fill-primary"
                    className="w-4 cursor-pointer"
                    onClick={() => onDelete(index)}
                  />
                </Tooltip>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
      <HelperText text="Invalid queries will not be added to the application" />
    </>
  )
}
