import { ReactNode } from 'react'
import { LabelInput } from '../../../components'
import { validationMessages } from '../../../consts'
import { EntityTooltipList } from '../../../consts/tooltips'

interface IDefineSourceType {
  sourceName: string
  onSourceNameChange: (value: string) => void
  isValidName: string
  isEditModeOn: boolean
  sourceType: string
  clickedCancel: boolean
  resetCancel: (value: string | ReactNode) => void
}

export default function DefineSourceType({
  sourceName,
  isValidName,
  onSourceNameChange,
  isEditModeOn,
  sourceType,
  clickedCancel = true,
  resetCancel,
}: IDefineSourceType) {
  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex flex-col gap-1">
        <div className="font-RakutenSansBold text-lg text-white">
          {isEditModeOn ? `Edit ${sourceType}` : 'Start by adding data source'}
        </div>
        <div className="text-xs text-warm_grey">
          {isEditModeOn
            ? 'Edit this source from where a time series data can be retrieved'
            : 'Add a source from where a time series data can be retrieved'}
        </div>
      </div>
      <LabelInput
        required
        error={isValidName !== ''}
        label="Data source name"
        toolTipMessage={<EntityTooltipList entityType="source name" />}
        toolTipPlacement="right-start"
        placeholder="Enter Source Name"
        value={sourceName}
        onChange={(e) => onSourceNameChange(e.target.value)}
        errorMessage={
          isValidName
            ? !clickedCancel
              ? validationMessages.sourceName[isValidName]
              : ''
            : validationMessages.sourceName[isValidName]
        }
        showCount={true}
        clickedCancel={clickedCancel}
        resetCancel={resetCancel}
      />
    </div>
  )
}
