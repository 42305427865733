import Card from '../../card'
import { ArrowUp, ArrowDown } from '../../../components/icons'
import { COLORS } from '../../../../themes/default/colors'

const DashboardAnomalyChart = ({
  currentDurationCount,
  previousDurationCount,
  change,
  changeType,
  fromDate,
  toDate,
}: {
  currentDurationCount: number
  previousDurationCount: number
  change: number
  changeType: string
  fromDate: Date
  toDate: Date
}) => {
  const values = [previousDurationCount, currentDurationCount]
  const labels = ['Previous', 'Current']
  const colors = [COLORS.DARK_GRAY, COLORS.PRIMARY]

  const maxValue = Math.max(...values)
  const numBars = labels.length
  const barWidth = (80 - (numBars - 1) * 20) / numBars

  let icon = null
  let textColor = '#6A6A6A'
  let changeText = 'No change'

  if (changeType === 'up') {
    icon = (
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-vividRed/[.25]">
        <ArrowUp className="w-6" color={COLORS.VIVID_RED} strokeWidth="2" />
      </div>
    )
    textColor = COLORS.VIVID_RED
    changeText = 'increased'
  } else if (changeType === 'down') {
    icon = (
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-limeGreen/[.25]">
        <ArrowDown className="w-6" color={COLORS.LIME_GREEN} strokeWidth="2" />
      </div>
    )
    textColor = COLORS.LIME_GREEN
    changeText = 'decreased'
  }

  const timePeriods: string[] = []
  const dates: string[] = []

  for (let i = numBars; i > 0; i--) {
    const startTime = new Date(
      fromDate.getTime() - (i - 1) * 60000 - (i - 1) * (toDate.getTime() - fromDate.getTime())
    )
    let endTime = new Date(
      toDate.getTime() - (i - 1) * 60000 - (i - 1) * (toDate.getTime() - fromDate.getTime())
    )
    const startTimeString = `${startTime.getHours()}:${startTime
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
    const endTimeString = `${endTime.getHours()}:${endTime
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
    let dateString = `${endTime.toDateString().slice(4, 10)}, ${endTime.getFullYear()}`
    if (startTime.toDateString() != endTime.toDateString()) {
      dateString = `${startTime.toDateString().slice(4, 10)} - ${endTime
        .toDateString()
        .slice(4, 10)}, ${endTime.getFullYear()}`
    }
    timePeriods.push(`${startTimeString} - ${endTimeString}`)
    dates.push(dateString)
  }

  return (
    <Card className="relative flex !w-1/3 flex-col rounded-md !p-4 sm:w-auto">
      <div className="mb-2 flex items-center justify-between">
        <h2 className="text-white">Anomalies</h2>
      </div>
      <div className="graph-container" style={{ marginTop: '6.25rem', height: '8.75rem' }}>
        <div style={{ position: 'relative', minHeight: '6.25rem', width: '100%' }}>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '.0625rem',
              backgroundColor: 'grey',
            }}
          ></div>
          {values.map((value, index) => (
            <div
              key={index}
              className="absolute font-bold"
              style={{
                left: `${index * (barWidth + 20) + 20 / 2}%`,
                bottom: '.0625rem',
                width: `${barWidth}%`,
                height: `${(value / maxValue) * 100}%`,
                backgroundColor: colors[index],
                color: colors[index],
                textAlign: 'center',
                paddingTop: '.25rem',
              }}
              title={`${labels[index]}: ${value}`}
            >
              <div className="text-5xl" style={{ position: 'relative', marginTop: '-3.5rem' }}>
                {value}
              </div>
              <div
                className="text-sm"
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {timePeriods[index]}
              </div>
              <div
                className="text-sm"
                style={{
                  position: 'absolute',
                  bottom: '-2.5rem',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center',
                  width: '150%',
                }}
              >
                {dates[index]}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 flex items-center justify-center">
        {icon}
        <div className="ml-4 text-2xl" style={{ color: textColor }}>
          {changeText === 'No change' ? null : (
            <span className="font-semibold">{Math.abs(change).toFixed(2)}%</span>
          )}{' '}
          {changeText}
        </div>
      </div>
    </Card>
  )
}

export default DashboardAnomalyChart
