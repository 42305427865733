import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const PLUS_TEST_ID = 'PLUS_TEST_ID'

export const PlusFilled = ({
  testId = PLUS_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  onClick,
  pathClasses,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path className={pathClasses} fill={color} d="M28 15H17V4h-2v11H4v2h11v11h2V17h11v-2z" />
    </svg>
  </div>
)
