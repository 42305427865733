import Plot from 'react-plotly.js'
import { ellipsizeString } from '../../../utils'
import { CompareChartProps } from './types'
import { compareGraphLegendName } from '../../../utils/chart'
import { AggregationLevels, compareGraphLineColors } from '../../../store/anomaly-detection'

export const COMPARE_CHART_TEST_ID = 'COMPARE_CHART_TEST_ID'

export function mapGraphData(
  yAxisData: (number | null)[][],
  xlabels: Date[] | string[],
  legendSuffix: string
): Plotly.Data[] {
  return yAxisData.map((data, index) => ({
    name: compareGraphLegendName(index, legendSuffix),
    x: xlabels,
    y: data,
    fill: 'none',
    line: {
      color: compareGraphLineColors[index],
    },
    mode: 'lines',
    hoverinfo: 'x+y',
  }))
}

export default function CompareChart({
  testId = COMPARE_CHART_TEST_ID,
  yAxisData,
  containerBackgroundColor,
  plotBackgroundColor,
  fontColor,
  xAxisTitle,
  yAxisTitle,
  tickAngle = -30,
  title,
  annotationTexts = [],
  onHover,
  legendSuffix = '',
  scrollZoom = false,
  xlabels,
  colouredArea,
}: CompareChartProps) {
  const compareGraphData = mapGraphData(yAxisData, xlabels, legendSuffix)
  const xtickvals = [...Array(xlabels.length)].map((_, i) => i)
  return (
    <Plot
      data-testid={testId}
      className="h-full w-full"
      onHover={onHover}
      data={compareGraphData}
      layout={{
        margin: { r: 30, t: 40, b: 60, l: 60 },
        autosize: true,
        paper_bgcolor: containerBackgroundColor,
        plot_bgcolor: plotBackgroundColor,
        font: {
          color: fontColor,
          size: 10,
        },
        xaxis: {
          linewidth: 1,
          linecolor: 'white',
          showspikes: true,
          spikedash: 'solid',
          spikethickness: 1,
          spikecolor: 'white',
          title: {
            text: xAxisTitle,
          },
          domain: [0, 1],
          tickangle: legendSuffix !== AggregationLevels.week ? tickAngle : 'auto',
          nticks: 24,
          tickmode: 'array',
        },
        yaxis: {
          title: yAxisTitle,
          showgrid: true,
          dtick: 0,
          rangemode: 'tozero',
        },
        shapes: colouredArea?.y1
          ? [
              {
                ...colouredArea,
                type: 'rect',
                line: {
                  color: '#D7465433',
                  width: 0,
                },
                fillcolor: '#D7465433',
                xref: 'x',
                yref: 'y',
                opacity: 0.6,
              },
            ]
          : [],
        title: { text: title, font: { size: 15 }, xanchor: 'auto', yanchor: 'top' },
        annotations: [
          {
            xref: 'paper',
            yref: 'paper',
            x: 0,
            y: 1.1,
            xanchor: 'left',
            yanchor: 'bottom',
            text: ellipsizeString(annotationTexts[0], 20),
            showarrow: false,
            font: {
              size: 12,
            },
            hovertext: annotationTexts[0],
          },
        ],
        legend: {
          yanchor: 'bottom',
          y: -0.4,
          xanchor: 'auto',
          orientation: 'h',
          itemwidth: 1,
          font: { size: 10 },
        },
        hoverlabel: { namelength: 20 },
        hovermode: 'x',
      }}
      config={{
        displaylogo: false,
        doubleClick: 'reset+autosize',
        watermark: false,
        modeBarButtons: [[]],
        scrollZoom,
      }}
      useResizeHandler
    />
  )
}
