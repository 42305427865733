import Plot from 'react-plotly.js'
import { SankeyDiagramProps } from './types'

export const sankeyDiagramLegend = (name: string, color: string) => {
  return (
    <div className="flex flex-row items-center gap-x-1">
      <div className={`h-[10px] w-[10px] rounded-full border border-none ${color}`} />
      <span className="text-sm text-white">{name}</span>
    </div>
  )
}

export default function SankeyDiagram({
  nodeColor,
  labelValue,
  sources,
  targets,
  value,
  title,
  containerColor,
  className,
}: SankeyDiagramProps) {
  const labels = labelValue.map((a) => a.name)
  const formattedValue = value.map((val: number) => val.toFixed(2))

  const customArr = labelValue.map((label, index) => [
    // Calculate incoming flow count
    targets.filter((target) => target === label.queryId).length,
    // Calculate outgoing flow count
    sources.filter((source) => source === label.queryId).length,
  ])

  var data = {
    type: 'sankey',
    orientation: 'h',
    valuesuffix: '%',
    node: {
      pad: 15,
      thickness: 30,
      line: {
        color: 'black',
        width: 0.5,
      },
      label: labels,
      color: nodeColor,
      hoverinfo: 'all', // Show all node information on hover
      hovertemplate:
        '%{label}<br>%{value:.2f}%<br>Incoming flow count : %{customdata[0]}<br>Outgoing flow count : %{customdata[1]}<extra></extra>', // Custom hover template for nodes
      customdata: customArr,
      hoverlabel: { align: 'left' },
    },
    link: {
      source: sources.map((source: string) =>
        labelValue.findIndex((item) => item.queryId === source)
      ),
      target: targets.map((target: string) =>
        labelValue.findIndex((item) => item.queryId === target)
      ),
      value: formattedValue,
      color: sources
        .map((source: string) => labelValue.findIndex((item) => item.queryId === source))
        .map((a: any) => {
          return `${nodeColor[a]}30`
        }),
      hovertemplate:
        'Source : %{source.label}<br>Target : %{target.label}<br>%{value:.2f}%<extra></extra>', // Custom hover template for links
      hoverlabel: { align: 'left' },
    },
  }

  const layout = {
    title: {
      text: `${title}`,
      x: 0.01,
    },
    font: {
      size: 12,
      color: 'white',
    },
    paper_bgcolor: `${containerColor}`,
  }

  return (
    <div className="bg-light_grey">
      <Plot
        data={[data as any]}
        layout={layout}
        config={{
          displayModeBar: true,
          modeBarButtonsToRemove: ['select2d', 'lasso2d', 'resetScale2d'],
          displaylogo: false,
          scrollZoom: true,
          responsive: true,
        }}
        className={className}
      />{' '}
    </div>
  )
}
