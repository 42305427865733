import { IconProps } from './types'
import { COLORS } from '../../../themes/default/colors'

export const CLOSE_TEST_ID = 'CLOSE_TEST_ID'

export const Close = ({
  testId = CLOSE_TEST_ID,
  className,
  color = COLORS.WARM_GREY,
  pathClasses,
  onClick,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path fill="none" d="M0 0h32v32H0z" />
      <path
        className={pathClasses}
        fill={color}
        d="M27 6.45 25.55 5 16 14.55 6.45 5 5 6.45 14.55 16 5 25.55 6.45 27 16 17.45 25.55 27 27 25.55 17.45 16 27 6.45z"
      />
    </svg>
  </div>
)
