import { MouseEventHandler } from 'react'
import { DefDevList } from '../../../../components'
import { appPaths } from '../../../../routes'
import { DefdevAnomalyType } from '../../../../store/anomalies'
import { NavLink } from 'react-router-dom'
import { applicationApi } from '../../../../store/application'

interface DefDevSectionProps {
  defDev: DefdevAnomalyType
  sourceId: string
  applicationId: string
  fromDate: number
  toDate: number
  onViewApplicationClick: () => void
}

export default function DefDevSection({
  defDev,
  fromDate,
  toDate,
  applicationId,
  onViewApplicationClick,
}: DefDevSectionProps) {
  return (
    <div className="flex w-full flex-col gap-4 px-3">
      <div className="flex w-full flex-row justify-between rounded-sm bg-warm600 bg-opacity-40 py-3">
        <div className="pl-4 text-white opacity-50">Latest Divergence </div>
        <div
          className="mr-5 text-primary hover:cursor-pointer hover:underline"
          onClick={onViewApplicationClick}
        >
          View application
        </div>
      </div>
      <div>
        <DefDevList item={defDev} />
      </div>
    </div>
  )
}
