import { IconProps } from './types'
export const TiltedArrowIcon = ({ className, color, transform, pathClasses }: IconProps) => (
  <div className={className}>
    <svg viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg" transform={transform}>
      <path
        fill={color}
        className={pathClasses}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4 8.5 5-5m0 0H4m5 0v5"
      />
    </svg>
  </div>
)
