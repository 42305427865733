import moment, { DurationInputArg2, unitOfTime } from 'moment'
import _ from 'underscore'
import {
  AggregationArrayType,
  CausalArrayType,
  ComparisonArrayType,
  DeviationArrayType,
  PredictionArrayType,
} from './types'
import { ORDINALS } from '../consts'
import {
  AggregationLevels,
  GetAggregationApiResponseTypeData,
  GetComparisonApiResponseTypeData,
  PredictionsType,
  QueryEntities,
} from '../store/anomaly-detection'
import { CausationGraphResponseType, ImpactedMetricsType, NodesType } from '../store/anomalies'

const mapSeverityToIndividualArray = (
  isAnomaly: boolean,
  acc: (number | null)[][],
  curr: number,
  groundTruth: number
) => {
  const index = isAnomaly && curr < 4 ? curr || 0 : 0
  acc[index] = acc[index].concat(groundTruth)

  for (let i = 0; i < 4; i++) {
    if (i !== index) acc[i] = acc[i].concat(null)
  }
  return acc
}

const xAxisFormat = (unit: number) => ({
  week: 'ddd',
  day: 'HH:mm',
  hour: `mm${
    unit === 1 ? '[st]' : unit === 2 || unit === 3 ? `[${ORDINALS[unit - 1]}]` : `[${ORDINALS[3]}]`
  } [min]`,
})

export const comparisonRangeTimestampsAggregator = {
  week: { iterations: 7, reduceTimeBy: 'days' },
  day: { iterations: 24, reduceTimeBy: 'hours' },
  hour: { iterations: 60, reduceTimeBy: 'minutes' },
}

export const transformComparisonData = (
  comparisons: GetComparisonApiResponseTypeData[],
  durationNumber: number,
  aggregationLevel: AggregationLevels,
  compareType: QueryEntities
) => {
  const modifiedComparisons: ComparisonArrayType = {
    y: [],
    labels: [],
    titles: [],
    severity: [],
  }
  const { iterations, reduceTimeBy } = comparisonRangeTimestampsAggregator[aggregationLevel]

  const startDate =
    aggregationLevel === AggregationLevels.day
      ? moment().utc().minutes(0).format()
      : aggregationLevel === AggregationLevels.week
      ? moment().utc().subtract(1, 'day').hours(0).minutes(0).format()
      : moment().utc().format()

  const timestamps = getTimestampsForIterations(
    startDate,
    durationNumber * iterations,
    reduceTimeBy
  )

  comparisons.forEach((comparison, ci) => {
    const comparisonDataMap = new Map()
    const dt = comparison[compareType]

    comparison.event_time.forEach((et, i) => {
      comparisonDataMap.set(moment(et).utc().format(), dt[i])
    })

    const eventTimestamps = timestamps.slice(ci * iterations, (ci + 1) * iterations)

    const modifiedComparison: number[] = []

    modifiedComparisons.labels = []

    eventTimestamps.forEach((et, i) => {
      const label = moment(et).format(xAxisFormat(Number(moment(et).format('m')))[aggregationLevel])
      modifiedComparisons.labels.push(label)

      modifiedComparison.push(comparisonDataMap.has(et) ? comparisonDataMap.get(et) : null)
    })

    modifiedComparisons.y.push(modifiedComparison)
    modifiedComparisons.titles.push({
      fromTime: eventTimestamps.at(0) || '',
      toTime: eventTimestamps.at(-1) || '',
    })
  })

  return modifiedComparisons
}

export function compareGraphLegendName(index: number, legendSuffix: string) {
  return `Previous ${index === 0 ? '' : index + 1}${ORDINALS[index]} ${legendSuffix}`
}

export function addFeedbackToPredictions(
  currentState: PredictionArrayType,
  eventTime: number,
  feedback: boolean
) {
  const eventIndex = currentState.eventTimes.findIndex((e) => moment(e).valueOf() === eventTime)
  const feedbacks = { ...currentState.feedbacks, [eventTime]: feedback }
  const feedbackColors = [...currentState.feedbackColors]
  feedbackColors[eventIndex] = feedback === false ? currentState.groundTruths[eventIndex] : null
  return { feedbackColors, feedbacks }
}

export function getTimestampsForIterations(
  startDate: string | number,
  noOfIterations: number,
  interval: string
) {
  const timestamps = []

  for (let i = 0; i < noOfIterations; i++) {
    timestamps.push(
      moment(startDate)
        .utc()
        .subtract(i, interval as DurationInputArg2)
        .seconds(0)
        .milliseconds(0)
        .format()
    )
  }

  return timestamps
}

export function getTimestampsForRange(
  fromDate: number,
  toDate: number,
  interval: DurationInputArg2 = 'minutes'
): string[] {
  const timestamps = []
  const noOfIterations = moment(toDate).diff(fromDate, 'minutes')

  for (let i = 0; i < noOfIterations; i++) {
    timestamps.push(
      moment(toDate)
        .utc()
        .subtract(i, interval as DurationInputArg2)
        .seconds(0)
        .milliseconds(0)
        .format()
    )
  }

  return timestamps
}

export function transformUnivariateData(
  predictions: PredictionsType[],
  fromDate: number,
  toDate: number
) {
  const eventTimes: PredictionArrayType['eventTimes'] = []
  const upperThresholds: PredictionArrayType['upperThresholds'] = []
  const lowerThresholds: PredictionArrayType['lowerThresholds'] = []
  const groundTruths: PredictionArrayType['groundTruths'] = []
  const forecastValues: PredictionArrayType['forecastValues'] = []
  const minorAnomalies: PredictionArrayType['minorAnomalies'] = []
  const majorAnomalies: PredictionArrayType['majorAnomalies'] = []
  const criticalAnomalies: PredictionArrayType['criticalAnomalies'] = []
  let feedbacks: PredictionArrayType['feedbacks'] = {}
  const feedbackColors: PredictionArrayType['feedbackColors'] = []

  const predictionDataMap = new Map()

  predictions.forEach((pred) => {
    predictionDataMap.set(moment(pred.time).utc().format(), pred)
  })

  const noOfIterations = moment(toDate).diff(fromDate, 'minutes')

  for (let i = 0; i <= noOfIterations; i++) {
    const et = moment(fromDate).utc().add(i, 'minutes').seconds(0).milliseconds(0)
    const etFormatted = et.format()
    eventTimes.push(et.toDate())

    if (predictionDataMap.has(etFormatted)) {
      const val = predictionDataMap.get(etFormatted)

      groundTruths.push(val.gt)
      forecastValues.push(val.fv)
      feedbacks = { ...feedbacks, [moment(val.time).valueOf()]: val.fdb }
      feedbackColors.push(val.anomaly && val.fdb === false ? val.gt : null)

      if (val.ut === null && val.lt === null) {
        upperThresholds.push(0)
        lowerThresholds.push(0)
      } else {
        upperThresholds.push(val.ut)
        lowerThresholds.push(val.lt)
      }

      if (val.anomaly) {
        if (val.sev === 1) {
          minorAnomalies.push(val.gt)
          majorAnomalies.push(null)
          criticalAnomalies.push(null)
        } else if (val.sev === 2) {
          minorAnomalies.push(null)
          majorAnomalies.push(val.gt)
          criticalAnomalies.push(null)
        } else if (val.sev === 3) {
          minorAnomalies.push(null)
          majorAnomalies.push(null)
          criticalAnomalies.push(val.gt)
        }
      } else {
        minorAnomalies.push(null)
        majorAnomalies.push(null)
        criticalAnomalies.push(null)
      }
    } else {
      upperThresholds.push(null)
      lowerThresholds.push(null)
      groundTruths.push(null)
      forecastValues.push(null)
      minorAnomalies.push(null)
      majorAnomalies.push(null)
      criticalAnomalies.push(null)
      feedbackColors.push(null)
    }
  }

  return {
    eventTimes,
    upperThresholds,
    lowerThresholds,
    groundTruths,
    forecastValues,
    minorAnomalies,
    majorAnomalies,
    criticalAnomalies,
    feedbacks,
    feedbackColors,
  }
}

export const getDaySlices = (fromDate: number, toDate: number) => {
  const diff = moment(toDate).diff(fromDate, 'days')

  const daySlices =
    diff > 1
      ? [[moment(toDate).subtract(1, 'day').valueOf(), moment(toDate).valueOf()]]
      : [[moment(fromDate).valueOf(), moment(toDate).valueOf()]]

  for (let i = 1; i < diff; i++) {
    daySlices.push([
      moment(toDate)
        .subtract(i + 1, 'day')
        .valueOf(),
      moment(daySlices[daySlices.length - 1][0]).valueOf(),
    ])
  }

  return daySlices
}

export const getAnomaliesOccurrence = ({
  eventTimes,
  minorAnomalies,
  majorAnomalies,
  criticalAnomalies,
}: PredictionArrayType) => {
  let firstTimetamp = null
  let lastTimetamp = null
  let peakValue = null
  for (let i = 0; i < eventTimes.length; i++) {
    if (minorAnomalies[i] !== null || majorAnomalies[i] !== null || criticalAnomalies[i] !== null) {
      if (lastTimetamp === null) {
        lastTimetamp = eventTimes[i]
      }
      firstTimetamp = eventTimes[i]
      peakValue = _.max([peakValue, minorAnomalies[i], majorAnomalies[i], criticalAnomalies[i]])
    }
  }

  return {
    firstTimetamp: firstTimetamp ? moment(firstTimetamp).add(2, 'minutes').toDate() : null,
    lastTimetamp: lastTimetamp ? moment(lastTimetamp).subtract(2, 'minutes').toDate() : null,
    peakValue: peakValue ? peakValue + 30 : null,
  }
}

export const transformAggregationForDeflection = (
  comparisons: GetAggregationApiResponseTypeData[],
  aggregationLevel: AggregationLevels,
  fromDate: number,
  toDate: number
) => {
  const modifiedDeflectiondata: ComparisonArrayType = {
    y: [],
    labels: [],
    titles: [],
    severity: [],
  }
  const aggregator =
    aggregationLevel === 'week'
      ? { iterations: 14, reduceTimeBy: 'days' }
      : aggregationLevel === 'day'
      ? { iterations: 48, reduceTimeBy: 'hours' }
      : comparisonRangeTimestampsAggregator[aggregationLevel]
  const iterations = moment(toDate).diff(fromDate, aggregator.reduceTimeBy as unitOfTime.Diff) + 1

  const startDate =
    aggregationLevel === AggregationLevels.day
      ? moment(toDate).utc().minutes(0).format()
      : aggregationLevel === AggregationLevels.week
      ? moment(toDate).utc().hours(0).minutes(0).format()
      : moment(toDate).utc().format() // passing startDate as toDate based on agregation levels

  const timestamps = getTimestampsForIterations(startDate, 2 * iterations, aggregator.reduceTimeBy)

  comparisons.forEach((comparison, ci) => {
    const comparisonDataMap = new Map()
    const dt = comparison[QueryEntities.ground_truth]

    comparison.event_time.forEach((et, i) => {
      comparisonDataMap.set(moment(et).utc().format(), dt[i])
    })

    const eventTimestamps = timestamps.slice(ci * iterations, (ci + 1) * iterations)

    const modifiedComparison: number[] = []

    modifiedDeflectiondata.labels = []

    eventTimestamps.forEach((et, i) => {
      const label = moment(et).format(xAxisFormat(Number(moment(et).format('m')))[aggregationLevel])
      modifiedDeflectiondata.labels.push(label)

      modifiedComparison.push(comparisonDataMap.has(et) ? comparisonDataMap.get(et) : null)
    })

    modifiedDeflectiondata.y.push(modifiedComparison)
    modifiedDeflectiondata.titles.push({
      fromTime: eventTimestamps.at(0) || '',
      toTime: eventTimestamps.at(-1) || '',
    })
  })

  return modifiedDeflectiondata
}

export const transformAggregationData = (
  comparisons: GetAggregationApiResponseTypeData[],
  aggregationLevel: AggregationLevels,
  fromDate: number,
  toDate: number
) => {
  const modifiedComparisons: DeviationArrayType = {
    y: [],
    labels: [],
    titles: [],
    severity: [],
  } // passing default values for transforming the api data

  const aggregator =
    aggregationLevel === 'week'
      ? { iterations: 35, reduceTimeBy: 'days' }
      : aggregationLevel === 'day'
      ? { iterations: 168, reduceTimeBy: 'hours' }
      : comparisonRangeTimestampsAggregator[aggregationLevel] // to get reduce time based on agregation level

  const iterations = moment(toDate).diff(fromDate, aggregator.reduceTimeBy as unitOfTime.Diff) + 1 // to get iterations based on difference between fromDate and toDate

  const startDate =
    aggregationLevel === AggregationLevels.day
      ? moment(toDate).utc().add(2, 'hours').minutes(0).format()
      : aggregationLevel === AggregationLevels.week
      ? moment(toDate).utc().add(2, 'days').hours(0).minutes(0).format()
      : moment(toDate).utc().format() // passing startDate as toDate based on agregation levels

  const timestamps = getTimestampsForIterations(startDate, iterations, aggregator.reduceTimeBy) // to get timestamps array by passing toDate and no of iterations in the function

  comparisons.forEach((comparison, ci) => {
    const comparisonDataMap = new Map()
    const dt = comparison[QueryEntities.ground_truth]

    comparison.event_time.forEach((et, i) => {
      comparisonDataMap.set(moment(et).utc().format(), dt[i])
    }) // to map eventTime to comparisonDataMap

    const eventTimestamps = timestamps
      .slice(ci * aggregator.iterations, (ci + 1) * aggregator.iterations)
      .reverse() // to get all eventTimestamps toDate included
    const modifiedComparison: number[] = []

    modifiedComparisons.labels = []

    eventTimestamps.forEach((et: string, i: number) => {
      const label =
        aggregationLevel === 'day'
          ? moment(et).utc().minutes(0).seconds(0).milliseconds(0).toDate()
          : moment(et).format(
              aggregationLevel === 'week'
                ? 'D MMM YYYY'
                : xAxisFormat(Number(moment(et).format('m')))[aggregationLevel] // to format eventTimestamps based on aggregation level
            )
      modifiedComparisons.labels.push(label as any)

      modifiedComparison.push(comparisonDataMap.has(et) ? comparisonDataMap.get(et) : null)
    })

    modifiedComparisons.y.push(modifiedComparison)
    modifiedComparisons.titles.push({
      fromTime: eventTimestamps.at(0) || '',
      toTime: eventTimestamps.at(-1) || '',
    })
  })
  return modifiedComparisons
}

export const transformMetricAverageData = (
  comparison: GetAggregationApiResponseTypeData,
  fromDate: number,
  toDate: number
) => {
  const modifiedComparisons: AggregationArrayType = {
    y: [],
    labels: [],
    titles: [],
    severity: [],
    counts: [],
    average: [],
    totalAnomaliesCount: [],
  } // passing default values for transforming the api data

  const reduceTimeBy = comparisonRangeTimestampsAggregator.week.reduceTimeBy // to get reduce time based on agregation level

  const iterations = moment(toDate).diff(fromDate, reduceTimeBy as unitOfTime.Diff) + 1 // to get iterations based on difference between fromDate and toDate

  // passing startDate as toDate based on agregation levels
  const startDate = moment(toDate).utc().hours(0).minutes(0).format()

  const timestamps = getTimestampsForIterations(startDate, iterations, reduceTimeBy) // to get timestamps array by passing toDate and no of iterations in the function

  const comparisonDataMap = new Map()
  const dt = comparison[QueryEntities.ground_truth]

  comparison.event_time.forEach((et, i) => {
    comparisonDataMap.set(moment(et).utc().format(), [dt[i], i])
  }) // to map eventTime to comparisonDataMap

  const eventTimestamps = timestamps.slice(0, iterations).reverse() // to get all eventTimestamps toDate included

  const modifiedComparison: (number | null)[] = []

  modifiedComparisons.labels = []

  eventTimestamps.forEach((et: string, i: number) => {
    const label = moment(et).format('D MMM YYYY') // to format eventTimestamps based on aggregation level
    modifiedComparisons.labels.push(label)

    const hasTimestamp = comparisonDataMap.has(et)

    if (hasTimestamp) {
      const [val, ogIndex] = comparisonDataMap.get(et)

      modifiedComparison.push(val)

      const index = ogIndex

      modifiedComparisons.average.push({
        upperThresholdAvg: comparison.upper_threshold[index],
        groundTruthAvg: comparison.ground_truth[index],
        lowerThresholdAvg: comparison.lower_threshold[index],
        forecastValueAvg: comparison.forecast_value[index],
      }) // to push avg values of GT,UT,LT,FV according to index

      if (comparison.counts !== undefined) {
        const counts = comparison.counts[index]

        modifiedComparisons.counts.push(counts)

        const countValuesSum = Object.values(counts).reduce((acc, value) => {
          return acc + value
        }, 0) // to get totalAnomalies of a certain timestamp

        modifiedComparisons.totalAnomaliesCount.push(countValuesSum)

        if (counts.critical !== 0) {
          modifiedComparisons.severity.push(3)
        } else if (counts.major !== 0) {
          modifiedComparisons.severity.push(2)
        } else if (counts.minor !== 0) {
          modifiedComparisons.severity.push(1)
        } else {
          modifiedComparisons.severity.push(0)
        } // to determine the marker color according to breach count
      }
    } else {
      modifiedComparison.push(null)
      modifiedComparisons.average.push({
        upperThresholdAvg: 0,
        groundTruthAvg: 0,
        lowerThresholdAvg: 0,
        forecastValueAvg: 0,
      }) // to push avg values of GT,UT,LT,FV according to index
      modifiedComparisons.counts.push({ minor: 0, major: 0, critical: 0 })
      modifiedComparisons.totalAnomaliesCount.push(0)
      modifiedComparisons.severity.push(0)
    }
  })

  modifiedComparisons.y = modifiedComparison
  modifiedComparisons.titles.push({
    fromTime: eventTimestamps.at(0) || '',
    toTime: eventTimestamps.at(-1) || '',
  })

  return modifiedComparisons
}

export const transformCausalGraphData = (metrics: CausationGraphResponseType) => {
  const modifiedMetrics: CausalArrayType = {
    value: [],
    source: [],
    target: [],
    labelValue: [],
  } // passing default values for transforming the api data

  metrics.data[0].graphData.map((item: NodesType) => {
    var queries = modifiedMetrics.labelValue.map((a) => a.queryId)
    if (!queries.includes(item.queryId)) {
      modifiedMetrics.labelValue?.push({
        queryId: item.queryId,
        name: item.applicationName + '--' + item.metricName,
      })
    }
    item.causingList.forEach((causation: ImpactedMetricsType) => {
      {
        if (!queries.includes(causation.queryId))
          modifiedMetrics.labelValue?.push({
            queryId: causation.queryId,
            name: causation.applicationName + '--' + causation.metricName,
          })
      }
      modifiedMetrics.source.push(item.queryId)
      modifiedMetrics.target.push(causation.queryId)
      modifiedMetrics.value.push(causation.percentage)
    })
  })

  return modifiedMetrics
}

export const handleNullValues = (data: (number | null)[]): number[] => {
  return data.map((value: number | null) => (value === null ? 0 : value))
}
