import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const COMPARE_ICON_TEST_ID = 'COMPARE_ICON_TEST_ID'

export const CompareIcon = ({
  testId = COMPARE_ICON_TEST_ID,
  className,
  color = COLORS.PRIMARY,
  onClick,
}: IconProps) => (
  <div data-testid={testId} className={className} onClick={onClick}>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.444 9.33377H16.8885V22.6671H22.444C23.0577 22.6671 23.5551 22.1696 23.5551 21.556V10.4449C23.5551 9.83123 23.0577 9.33377 22.444 9.33377ZM15.7773 8.22266V23.7782H22.444C23.6713 23.7782 24.6662 22.7833 24.6662 21.556V10.4449C24.6662 9.21758 23.6713 8.22266 22.444 8.22266H15.7773Z"
        fill={color}
      />
      <path d="M15.7773 6H16.8885V26H15.7773V6Z" fill="#F59600" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2222 8.22266C8.99492 8.22266 8 9.21758 8 10.4449V21.556C8 22.7833 8.99492 23.7782 10.2222 23.7782H16.8889V8.22266H10.2222ZM12.7114 12.8192C12.5641 12.6167 12.3253 12.6167 12.1779 12.8192L9.77738 16.1191C9.63007 16.3215 9.63007 16.6499 9.77738 16.8524C9.92469 17.0548 10.1635 17.0548 10.3108 16.8524L12.0675 14.4377V20.4451H12.8219V14.4377L14.5785 16.8524C14.7258 17.0548 14.9647 17.0548 15.112 16.8524C15.2593 16.6499 15.2593 16.3215 15.112 16.1191L12.7114 12.8192Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4888 20.2934C20.3415 20.4959 20.1027 20.4959 19.9554 20.2934L17.5548 16.9936C17.4075 16.7911 17.4075 16.4628 17.5548 16.2603C17.7021 16.0578 17.941 16.0578 18.0883 16.2603L19.8449 18.675V12.6675H20.5993V18.675L22.3559 16.2603C22.5033 16.0578 22.7421 16.0578 22.8894 16.2603C23.0367 16.4628 23.0367 16.7911 22.8894 16.9936L20.4888 20.2934Z"
        fill={color}
      />
    </svg>
  </div>
)
