import { display } from '@mui/system'
import { COLORS } from '../../themes/default/colors'
import { BasicCard } from '../components'

export default function TermsOfService() {
  const tableStyle: React.CSSProperties = {
    borderCollapse: 'collapse',
    width: '552px',
  }

  const cellStyle: React.CSSProperties = {
    border: '1px solid white',
    padding: '8px',
    width: '276px',
    backgroundColor: COLORS.SECONDARY,
  }

  return (
    <BasicCard
      contentClasses="overflow-y-scroll text-warm select-none"
      containerClasses="flex relative flex-col w-6/12 py-5 mobile:w-9/12 tablet_p:w-9/12 tablet_p:h-[47rem] tablet_l:w-8/12 tablet_l:h-[40rem] laptop:w-6/12 laptop:h-[46rem] desktop:w-5/12 desktop:h-[47rem]"
    >
      <div className="flex flex-col gap-2 text-center	">
        <p>
          <strong>END USER LICENSE AGREEMENT (“AGREEMENT”)</strong>
        </p>
        <p>BETWEEN</p>
        <p>
          <strong>RAKUTEN INDIA ENTERPRISE PRIVATE LIMITED</strong>
          <strong>(“Licensor / Company”)</strong>
        </p>
        <p>AND</p>
        <p>
          <strong>(“Licensee / Customer / You”)</strong>
        </p>
        <p>
          <strong>STANDARD LEGAL TERMS</strong>
        </p>
        <p>
          In consideration of the mutual agreement, covenants, representations, and warranties set
          forth in the Order Form and this Agreement, and for other good and valuable consideration,
          the receipt and sufficiency of which is acknowledged by the Parties, the Parties hereby
          agree as follows:
        </p>
      </div>
      <ol>
        <li>
          <strong>DEFINITIONS AND INTERPRETATION</strong>
          <ul>
            <li>
              The Company and Licensee shall hereinafter be individually referred to as &ldquo;
              <strong>Party</strong>&rdquo; and jointly as the &ldquo;<strong>Parties</strong>
              &rdquo;.
            </li>
            <li>
              Unless the contrary intention appears and/or the context otherwise requires, in
              addition to the terms defined elsewhere within this Agreement, the definitions listed
              in <u>Schedule I</u> (<em>Definitions</em>) shall apply throughout this Agreement. The
              interpretation and/or construction of this Agreement shall be in accordance with the
              rules of interpretation annexed and marked <u>Schedule II</u> (<em>Interpretation</em>
              ).
            </li>
          </ul>
        </li>
        <li>
          <strong>ENGAGEMENT AND ORDERS</strong>
          <ul>
            <li>
              The&nbsp;Licensee&nbsp;hereby engages the Company to&nbsp;provide the&nbsp;Services
              and the Company hereby accepts the engagement and agrees to&nbsp;provide
              the&nbsp;Services and the Software Products to the&nbsp;Licensee&nbsp;in accordance
              with the terms and conditions contained in this Agreement.
            </li>
            <li>
              Pursuant to the execution of the Order Form (the Standards form a part whereof), the
              Licensor shall provide, the Software Product and Services to the Licensee during the
              relevant License Term and in the Territory in accordance with the terms of this
              Agreement.
            </li>
            <li>
              Parties may agree to specific customization of the Software Products as required by
              the Licensee under the Order Form, subject to mutually agreed terms.
            </li>
            <li>
              On the Effective Date, the Company shall provide the Licensee the Software Product in
              accordance with the Order Form executed simultaneously with this Agreement.
            </li>
          </ul>
        </li>
        <li>
          <strong>USAGE RIGHTS</strong>
          <ul>
            <li>
              Pursuant to the Company&rsquo;s engagement hereunder, subject to compliance by the
              Licensee of the terms and conditions of this Agreement, acceptance of the Order Form
              for a Software Product, and payment of the Fees due, during the applicable License
              Term, the Company shall grant to the Licensee and/or the Authorised Affiliates (
              <em>as specified in the Order Form</em>) upon execution of the Order Form, a license
              on a limited, revocable, non-exclusive, non-transferable (except as provided herein),
              non-assignable and on non-sub licensable basis (except as set out in the Order Form),
              the license to access and use the relevant Software Product, subject to the terms and
              conditions described in the Order Form, and the the Licensing Metric agreed between
              the Parties, and in accordance with the Documentation provided for such Software The
              Software Product&rsquo;s functionalities and capabilities or limitations shall be as
              per the Documentation, including the Company&rsquo;s then-current technical and
              functional documentation for the Software Products which is made available to Licensee
              with the Software Products.
            </li>
            <li>
              The Licensee undertakes to ensure that such access and use by the Authorised
              Affiliates is in compliance with the terms of this Agreement and the relevant
              Documentation and shall promptly notify the Company of any suspected or alleged
              violation. The Licensee shall be solely and entirely responsible for the acts and
              omissions of any Authorised Affiliate, as if they were the acts and omissions of
              Licensee; and agrees to indemnify and hold the Company harmless for any act or
              omission on the part of the Authorised Affiliate. The Licensee will be solely
              authorised to enforce the rights under this Agreement (including with respect to any
              claim for losses, damages or liabilities suffered by the relevant affiliate). Further,
              any claim made by the Licensee on behalf of its Authorised Affiliates will be subject
              to the exclusions and limitation of liability stipulated in the Agreement.
            </li>
            <li>
              To the extent applicable under an Order Form, the Licensee may permit such number of
              Users as agreed under the Order Form to access and use the Software The Licensee
              undertakes to ensure that any access and use by Licensee&rsquo;s Users is in
              compliance with the provisions of this Agreement and the relevant Documentation and
              shall promptly notify the Company of any suspected or alleged violation. Licensee
              shall be solely and entirely responsible for the acts and omissions of any User, as if
              they were the acts and omissions of Licensee. The Licensee agrees to indemnify and
              hold the Company harmless for any act or omission on the part of Licensee&rsquo;s
              Users.
            </li>
            <li>
              Except as set out in the Order Form, Licensee shall not sublicense, license, sell,
              lease, rent or otherwise make the Software Product available to any third party (other
              than Users who are using the Software Product in accordance with Licensee&rsquo;s
              authorized use of the Software Product). The Company shall have the right under this
              Agreement to block a User(s) from accessing the Software Products in cases of: (i)
              breach of the terms of usage of Software Product, as per terms of this Agreement or
              the Documentation, by the Users; or (ii) breach of specific Applicable Law by the
              Users. In case of such breach or violation by a User, the Licensee agrees to extend
              reasonable cooperation to the Company in defence or prosecution of any claim. Such
              cooperation shall include the retention and upon the request of the Company, the
              provision of records and information which are reasonably relevant to such claim and
              making representatives available on a mutually convenient basis to provide additional
              information and explanation of any matters relating to such claim.
            </li>
            <li>
              Notwithstanding anything contained in <u>Clause 3.4</u> (<em>Usage Rights</em>),
              Licensee shall have the right to sub-license the Software Product to Permitted
              Sub-Licensees, only if the same is permitted under the Order Form. Individual users of
              the Permitted Sub-Licensee shall be deemed to be Users under the Agreement. The
              Licensee undertakes to ensure that such access and use by the Permitted Sub-Licensees
              and/or their Users is in compliance with the terms of this Agreement and the relevant
              Documentation and shall promptly notify the Company of any suspected or alleged
              violation. The Licensee shall be solely and entirely responsible for the acts and
              omissions of any Permitted Sub-Licensees and/or their Users, as if they were the acts
              and omissions of Licensee; and agrees to indemnify and hold the Company harmless for
              any act or omission on the part of the Permitted Sub-Licensee and/or its Users. The
              Licensee will be solely authorised to enforce the rights under this Agreement
              (including with respect to any claim for losses, damages or liabilities suffered by
              the relevant Permitted Sub-Licensee and/or its Users). Further, any claim made by the
              Licensee on behalf of its Permitted Sub-Licensee and/or its Users will be subject to
              the exclusions and limitation of liability stipulated in the Agreement.
            </li>
            <li>
              The Company may change or modify the Software Products or the Service at any time and
              in its sole discretion in accordance with <u>Clause 6</u> (
              <em>Company&rsquo;s Rights</em>) over this Agreement.
            </li>
            <li>
              Licensee agrees that its licensing of the Software Product is neither contingent upon
              the delivery of any future functionality or features (except those covered in the
              Order Form) nor dependent upon any oral or written public comments made by the
              Company, including any roadmaps, with respect to future functionality or features. In
              case functionality or features developed by the Company in future become part of the
              Software Products provided by Company to Licensee, such future functionality or
              features shall be provided on such terms, as mutually agreed between the Parties at
              such time.
            </li>
          </ul>
        </li>
        <li>
          <strong>PURPOSE</strong>
          <ul>
            <li>
              [The Licensee shall use the Software Product and Services, solely: (i) for the
              Licensee&rsquo;s lawful internal business activities and operations pertaining to
              capturing of data from IT infrastructure, aggregating it into a single database,
              analyzing the data to detect patterns and trends and presenting actionable insights in
              a human-readable format, and (ii) for the number of Users specified in the Order
              Form]. The Licensee may use the Software Product in its network or other systems to
              interface and interact with other systems and software.
            </li>
            <li>
              To the extent Permitted Sub-Licensees are authorised under the Order Form, the
              Licensee shall use and allow the Permitted Sub-Licensee to use the Software Product
              and Services, solely: (i) for the Permitted Sub-Licensee&rsquo;s lawful internal
              business activities and operations pertaining to capturing of data from IT
              infrastructure, aggregating it into a single database, analyzing the data to detect
              patterns and trends and presenting actionable insights in a human-readable format, and
              (ii) for the number of Users specified in the Order Form]. The Licensee may use and
              allow the Permitted Sub-Licensee to use the Software Product in its network or other
              systems to interface and interact with other systems and software.
            </li>
            <li>
              The Licensee shall not be authorised (except as set out in <u>Clause 4.2</u>) and
              shall not allow the Permitted Sub-Licensee to: (i) use the Software Product and/or
              Services to provide any service to a third party, and/or (ii) provide direct access to
              the Software Product to any third party.
            </li>
          </ul>
        </li>
        <li>
          <strong>THIRD-PARTY COMPONENTS</strong>
          <ul>
            <li>
              The Licensee acknowledges that certain third-party components may be incorporated in
              or with the Software Products ("<strong>Third Party Components</strong>") which are
              subject to various "open source" or "free software" licenses. The Third-Party
              Components in the Software Products are as set out in Schedule IV, which list may be
              supplemented from time to time for any updates or upgrades to the Software The
              Third-Party Components shall be governed by the terms and conditions of the open
              source or free software licenses. The Company makes no warranties of any kind with
              respect to Third Party Components and shall not be liable for any faults or defects
              therein.
            </li>
            <li>
              The Software Products may contain links to external websites or (including embedded
              widgets or other means of access) and/or information provided on such external
              websites by third parties. The Licensee&rsquo;s access and use of such external
              websites shall be subject to the terms and conditions of such external websites. The
              Company shall not be responsible in any manner for the content of any external
              website.
            </li>
            <li>
              The Software Products may be hosted on third party servers, computing services or
              platforms (&ldquo;<strong>Third Party Host</strong>&rdquo;). The Third-Party Host of
              the Software Products is as set out in Schedule V, which list may be modified or
              supplemented from time to time. The Licensee&rsquo;s access and use of such Software
              Products through the Third-Party Hosts shall be subject to the terms and conditions of
              such Third-Party Hosts. The Company makes no warranties of any kind with respect to
              such Third-Party Hosts and shall not be responsible in any manner for any faults or
              defects therein.
            </li>
          </ul>
        </li>
        <li>
          <strong>COMPANY&rsquo;S RIGHTS</strong>
        </li>
      </ol>
      <p>
        The Company shall have the right to vary or discontinue any feature or element of the
        Software Product or remove or discontinue any Third-Party Component comprised in the
        Software Product. [In the event the Company in its sole discretion determines that such
        change detrimentally impacts the functionality of the Software Product, the Company shall
        replace the discontinued feature or Third-Party Component, as deemed suitable by it, or
        refund such portion of the Fee as the Company shall apportion to discontinued feature or
        Third-Party Component.
      </p>
      <ol start={7}>
        <li>
          <strong>RESTRICTIONS</strong>
          <ul>
            <li>
              When using any Software Products, Licensee shall not, and shall ensure that its Users
              do not:
              <ul>
                <li>
                  copy, translate, disassemble, decompile, reverse-engineer or otherwise modify any
                  parts of or create derivative works from Software Products and/or Company
                  Materials;
                </li>
                <li>
                  prevent, impair, attempt to interfere with, disrupt or otherwise adversely affect
                  the operation of the Software Product, Company Materials including the reliability
                  of any program or data; or adversely affect the User experience, including through
                  worms, trojan horses, viruses and other similar things or devices; or attempt to
                  scan or probe, hack, defeat or otherwise compromise any security or technological
                  measures of the Software Products, Company Materials or any systems or networks
                  operated by the Company;
                </li>
                <li>
                  perform any act or omission that deceives or misleads the recipient of any
                  information about the origin of such message, or impersonates any person,
                  infringes the intellectual property rights of any entity or person, circumvents or
                  discloses the user authentication or security of the Software Products or any
                  host, network, or account related thereto, or violates Applicable Law;
                </li>
                <li>
                  access the Software Products for the purpose of building a competitive product or
                  service or copying its features or user interface, benchmarking the performance of
                  the Software Products, or analyzing the Software Products for any competitive or
                  review purposes, or permit access to the Software Products by a direct competitor
                  of the Company;
                </li>
                <li>
                  remove, alter or cancel from view any copyright or other proprietary notices,
                  rights, marks, or legends from the Software Products or any other Company
                  Materials furnished or made available hereunder;
                </li>
                <li>
                  attempt to gain access to any systems or networks that connect thereto except for
                  the express purpose of using the Software Products for their intended use; and
                </li>
                <li>
                  merge all or any part of the Software Products or Company Materials with another
                  software program.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <ol start={8}>
        <li>
          <strong>MAINTENANCE AND SUPPORT</strong>
        </li>
      </ol>
      <p>
        The Company shall provide &ldquo;Premium Support Offering Terms&rdquo; attached to this
        Agreement as Schedule III, provided that the Parties may agree to an increased level of
        support and maintenance in writing. The Company reserves the right to terminate the Premium
        Support Offering Terms in whole or part, at its sole discretion.
      </p>
      <ol start={9}>
        <li>
          <strong>RESPONSIBILITIES AND OBLIGATIONS OF THE LICENSEE</strong>
          <ul>
            <li>
              The Licensee is solely responsible for determining the suitability of the Software
              Products for Licensee's business and complying with any regulations, laws, or
              conventions applicable to Licensee&rsquo;s access to and use of the Software
            </li>
            <li>
              The Licensee shall ensure: (i) adequate functioning of hardware and software with
              respect to the central technology platform owned / operated by Licensee, to support
              the Software Products; and (ii) the integration of such central technology platform
              with the Software
            </li>
            <li>
              The Licensee agrees that the Company is responsible only to the extent of its
              obligations expressly set out in this Agreement and the Order Form executed pursuant
              hereto, to which Company is a party. The Company shall not be bound by the provisions
              of any other agreement or understanding between the Licensee and its Users. No implied
              or fiduciary duties or obligations of Company shall be read into the terms of this
              Agreement. Notwithstanding anything contained in this Agreement, Company shall not be
              liable for the performance of the obligations of the Licensee to its Users or for any
              breach of Applicable Law by the Licensee in providing any services to its Users, and
              the Users shall not have the right to any recourse against the Company for failure by
              the Licensee to fulfil its obligations, under contract or law towards any User(s).
            </li>
            <li>
              The Licensee shall not disclose or use the user ID and password provided by the
              Company for the purpose of receiving delivery of the Software Products to any third
              party and shall notify the Company immediately of any known unauthorized use or
              disclosure of the user ID and password.
            </li>
            <li>
              The Licensee shall provide cooperation and assistance to the Company in the
              Company&rsquo;s efforts to provide Services and support offering hereunder. Such
              cooperation and assistance shall include but not be limited to: (i) the timely
              transmittal and release to the Company of appropriate and accurate documentation and
              information; (ii) making available such personnel and other assistance to the Company
              as may be reasonably necessary for the Company to provide the Software Product; and
              (iii) carrying out in a timely manner all the Licensee responsibilities set forth
              herein. The Licensee agrees that any delay by the Licensee in performing the
              responsibilities and obligations under this <u>Clause 9</u> (
              <em>Responsibilities and Obligations of the Licensee</em>) shall result in a
              day-for-day extension of the Company&rsquo;s dependent obligations.
            </li>
          </ul>
        </li>
        <li>
          <strong>LICENSEE DATA</strong>
          <ul>
            <li>
              Licensee and the Users shall be responsible for entering or input of Licensee Data
              into the Software Products, and for the content of the Licensee Data. The Licensee
              acknowledges that the Company will store, have access to and use all Licensee Data, to
              the extent necessary to provide services through the Software Products, or to drive
              improvements in the Software Licensee agrees that it has obtained relevant consents
              from and made necessary disclosures to the Users. The Licensee has collected and shall
              maintain and handle all Licensee Data in compliance with all applicable privacy and
              data protection laws, rules and regulations. To the extent that the Licensee Data
              contains any personal data belonging to any third parties, the Licensee represents
              that the Licensee has obtained relevant consents and made necessary disclosures to the
              owners of such personal data in respect of collection of the personal data, its use in
              relation to the Software Product and its disclosure to the Company for the purposes of
              this Agreement. Further, Licensee is solely responsible for complying with any
              regulations, laws, or conventions applicable to the Licensee Data and Licensee&rsquo;s
              access to and use of the Software Products.
            </li>
            <li>
              Licensee shall maintain commercially reasonable security standards for its Users in
              respect of use of the Software Products, including without limitation sufficient
              processes and safeguards to ensure that Licensee Data is not made available to any
              unauthorised individual, person or entity. The Company shall be liable only to the
              extent where any unauthorised disclosure or leakage of Licensee Data is solely and
              directly attributable to the Software
            </li>
            <li>
              Licensee grants to the Company a perpetual, transferable, non-exclusive, royalty-free
              and worldwide right to use Licensee Data (i) to provide the Services and to manage or
              provide the Software Products, (ii) to drive any improvements in the Software Products
              and (iii) to create and use derivative and/or aggregate data sets for the purpose of
              testing or developing performance or quality metrics and/or new products or services
              provided such derivative data sets shall not be identifiable as associated with the
              Licensee. Licensee shall be responsible for the security, availability and reliability
              of the central technology platform owned or operated by the Licensee.
            </li>
            <li>
              During the License Term for a Software Product, the Company may maintain data source
              connection with the Licensee&rsquo;s or User&rsquo;s systems or servers to enable
              input or ingestion of Licensee Data without any further consent or permission from the
              Licensee or User.
            </li>
          </ul>
        </li>
        <li>
          <strong>DATA PROCESSING AGREEMENT / SECURITY</strong>
          <ul>
            <li>
              The Company shall implement and maintain appropriate technical and organizational
              measures to protect the personal data processed by the Company as part of the Software
              Product described in the Data Processing Agreement incorporated into this Agreement in
              compliance with applicable data protection law.
            </li>
          </ul>
        </li>
        <li>
          <strong>OWNERSHIP OF INTELLECTUAL PROPERTY</strong>
          <ul>
            <li>
              Subject to the provisions of <u>Clause 5 </u>(<em>Third Party Components</em>), the
              Company owns all right, title and interest in any and all copyrights, trademark
              rights, patent rights and other intellectual property or other rights in the Software
              Product and Documentation, and all Company Materials, and any application programming
              interfaces, improvements, design contributions or derivative works thereto. Except for
              the limited license expressly granted herein, no proprietary right or interest in
              transferred to the Licensee in any Software Product, Documentation or the Company All
              rights not expressly granted to Licensee in this Agreement are reserved solely by
              Company.
            </li>
            <li>
              Any of Licensee&rsquo;s pre-existing designs, data processes, API&rsquo;s, plug-ins
              and Licensee Data (&ldquo;<strong>Licensee Property</strong>&rdquo;) shall be the sole
              and exclusive property of the Licensee. The Company shall not use or access the
              Licensee Property except as strictly necessary to provide the maintenance and support
              services described in <u>Clause 8</u> (<em>Maintenance and Support</em>) or as
              otherwise permitted under this Agreement.
            </li>
            <li>
              Notwithstanding anything to the contrary contained herein, any and all derivative
              works or applications or other intellectual property created by the Licensee using the
              Software Products shall be owned solely and exclusively by the Company. Further, all
              intellectual property rights in any and all models created by the Software Product,
              whether artificial intelligence or otherwise, trained using Licensee Data shall vest
              with the Company and the Licensee shall have no claim in respect thereof.
            </li>
          </ul>
        </li>
        <li>
          <strong>FEES AND TERMS OF PAYMENT</strong>
          <ul>
            <li>
              Unless otherwise agreed under the Order Form, the Licensee shall pay to the Company
              the Fees in advance, as per the billing cycle set out in the Order Form, within 30
              (thirty) days of an invoice being raised by the Company. The Licensee shall notify the
              Company of any dispute relating to an invoice within 5 (five) Business Days from
              receipt of such invoice, failing which the invoice shall be deemed accepted by
              Licensee and the Fee in relation thereto shall be deemed undisputed. Unless otherwise
              agreed between the Parties, undisputed Fees not paid within 30 (thirty) days from the
              due date shall accrue interest at the highest rate permissible by law.
            </li>
            <li>
              The Parties shall revise and review the Fees payable under this Agreement within a
              period of 12 (twelve) months from the Effective Date and on every anniversary of the
              Effective Date thereafter, based on relevant factors determined by the Company,
              including key performance indicators and critical performance indicators of the
              Software Product, and the Services and such other factors as may be determined by the
              Licensor in its sole discretion. All revisions to the Fees as mutually agreed between
              the Parties shall be effective immediately upon execution of the Order Form.
            </li>
            <li>
              The Licensee shall reimburse the Company for out-of-pocket expenses incurred with
              prior approval, while performing obligations under this Agreement, upon submission of
              supporting documentation.
            </li>
            <li>
              All prices, Fees and other charges payable under this Agreement or Order Form, or any
              other instrument or agreement ancillary to or referenced by this Agreement, shall be
              exclusive of applicable taxes including goods and services tax. The Licensee shall be
              responsible for payment of all taxes and any related interest or penalties arising
              from any payments made hereunder. If the Company is required to pay any such taxes,
              interest, or penalties (other than any taxes based on Company&rsquo;s net income), the
              Company will include those taxes, interest and penalties in its invoices to the
              Licensee, which the Licensee shall promptly pay.
            </li>
            <li>
              Except as expressly set forth in this Agreement or the Order Form, all Fees are
              non-refundable. Licensee shall not have a right to withhold any Fees payable to the
              Company under this Agreement or set off any amount against the Fees due to the Company
              for any reason whatsoever.
            </li>
            <li>
              In the event the Licensee&rsquo;s access to the Software Products and Services is
              obtained through an authorized reseller, the Licensee shall pay to such reseller the
              Fees for the Software Products as agreed between the reseller and the Licensee. The
              Licensee shall pay the Fees to the Company directly, upon failure of the reseller to
              pay the Company, if so instructed by the Company and subject to reseller&rsquo;s prior
              written consent.
            </li>
          </ul>
        </li>
      </ol>
      <ol start={14}>
        <li>
          <strong>RESELLER</strong>
        </li>
      </ol>
      <p>
        To the extent the Software Products are availed by the Licensee through an authorised
        reseller, the Licensee agrees and acknowledges that the use of the Software Products and
        provision of Services to the Licensee shall be governed under this Agreement and the
        reseller shall not be authorised to modify this Agreement or make promises and/or
        commitments on behalf of the Company. The Company is not a party to the agreement between
        the Licensee and reseller and shall not be responsible for or bound by the terms of such
        agreement. The Company shall adhere to orders placed by the authorised reseller on behalf of
        the Licensee, unless the relevant Order Form, this Agreement, or the Company&rsquo;s
        agreement with the reseller is terminated, or if the reseller informs the Company that the
        Licensee has opted out of auto-renewal of a license.
      </p>
      <ol start={15}>
        <li>
          <strong>FEEDBACK</strong>
        </li>
      </ol>
      <p>
        The Licensee may provide, or the Company may solicit inputs in connection with the Software
        Products or Services, including, without limitation, comments or suggestions regarding the
        possible creation, modification, correction, improvement, or enhancement thereof
        (collectively &ldquo;<strong>Feedback</strong>&rdquo;). Any Feedback pursuant to this Clause
        shall constitute Confidential Information of the Company under this Agreement. Further, the
        ownership of any and all intellectual property rights which shall vest solely and
        exclusively with the Company at all times. To the extent that the ownership in any Feedback
        is deemed to be with the Licensee for any reason, the Licensee irrevocably grants and
        assigns, free and clear of any claims, all of Licensee&rsquo;s rights and interests in such
        Feedback, exclusively and in perpetuity, throughout the world, to the Company
      </p>
      <ol start={16}>
        <li>
          <strong>TERM AND TERMINATION</strong>
          <ul>
            <li>
              The Agreement shall be valid from the Effective Date and shall continue for the
              License Term of all Software Products or until termination, if terminated earlier in
              accordance with this <u>Clause 16 </u>(<em>Term and Termination</em>) (&ldquo;
              <strong>Term</strong>&rdquo;).
            </li>
            <li>
              A Party may terminate this Agreement or any Order Confirmation upon 30 (thirty) days
              written notice to the other Party of such other Party&rsquo;s material breach of any
              provision of this Agreement unless the breaching Party has cured such breach during
              such 30 (thirty) day period.
            </li>
            <li>
              In the event the Licensee&rsquo;s access to the Software Products and Services is
              obtained through an authorized reseller, the Company shall have the right to terminate
              this Agreement upon failure of the reseller to pay the fees received by the reseller
              from the Licensee.
            </li>
            <li>
              The Licensee shall not terminate this Agreement for any reason for a period of 36
              (thirty-six) months after the Effective Date. Upon completion of this duration, the
              Licensee may terminate this Agreement for any reason by providing 30 (thirty) days
              written notice to the Licensor.
            </li>
            <li>
              In the event Licensee commits an incurable breach of this Agreement, then the Company
              shall be entitled to terminate this Agreement and the Order Form executed hereto with
              immediate effect, without prejudice to Company&rsquo;s rights against Licensee under
              law or equity.
            </li>
            <li>
              Notwithstanding the Company&rsquo;s right to terminate as set forth above, the Company
              may, in its reasonable determination and with prior written notice to the Licensee,
              suspend or temporarily deactivate Licensee&rsquo;s access to the Software Products or
              a portion thereof, if and to the extent the Company determines that the
              Licensee&rsquo;s use has or continued use of the Software Products may result in harm
              to the Software Products (including the security of the systems used to provide the
              Software Products) or other clients of the Company, or the rights of third parties.
            </li>
            <li>
              After the lapse of 15 (fifteen) days commencing from the effective date of termination
              (&ldquo;<strong>Retrieval Period</strong>&rdquo;) and upon payment of pro-rated Fees
              by the Licensee for these additional 15 (fifteen) days, Licensee&rsquo;s access to the
              Software Products will be terminated. Licensee may access the Software Products during
              the Retrieval Period solely for the purpose of retrieving its Licensee Data, except in
              cases where the Company is of the opinion that the Licensee has (i) interfered with or
              disrupted the software or systems used to host the Software Products, or other
              equipment or networks connected to the Software Products, including without limitation
              by way of any software virus or any software program or code designed to interrupt,
              destroy or limit the functionality of any computer resource; (ii) circumvented the
              security of the Software Products or any host, network, or account related thereto; or
              (iii) breached its obligations under <u>Clause 22</u> (<em>Confidentiality</em>) of
              this Agreement. In the above-mentioned instances, the Company will retrieve Licensee
              Data from the Software Products during the Retrieval Period. At the end of the
              Retrieval Period, the Company shall be entitled to permanently delete all Licensee
              Data, following which Licensee shall have no right or claim against the Company for
              any reason whatsoever in connection with the Licensee Data. The Company may continue
              to retain and use any data or information pertaining to the Licensee and its Users in
              an aggregated and/or anonymised form (such that no personal data of the Licensee or
              any User can be deduced), post termination of the Agreement, in accordance with
              Applicable Law. Upon the termination of this Agreement, each Party shall promptly
              return to other Party all papers, written materials, business
              information/documentation and other Confidential Information in its possession in
              connection with this Agreement.
            </li>
            <li>
              Termination of this Agreement shall automatically terminate the Order Form executed
              hereto. Upon such termination of this Agreement, each Party shall be relieved of its
              obligations hereunder. The termination of this Agreement shall not relieve either
              Party of its obligations and liabilities under this Agreement or the Order Form
              accruing up to the date of termination, including the payment of Fees up to the date
              of termination.
            </li>
          </ul>
        </li>
        <li>
          <strong>REPRESENTATIONS AND WARRANTIES</strong>
          <ul>
            <li>
              Each Party hereby represents and warrants that:
              <ul>
                <li>
                  it has the full power, capacity and authority to execute, deliver and perform this
                  Agreement and has taken all necessary action (corporate, statutory or otherwise)
                  to execute and authorize the execution, delivery and performance of this
                  Agreement;
                </li>
                <li>
                  this Agreement has been duly and validly executed and delivered by it and
                  constitutes a legal and binding obligation, enforceable against it in accordance
                  with its terms; and
                </li>
                <li>
                  to the best of its knowledge, there are no legal, quasi-legal, administrative,
                  arbitration, mediation, conciliation or other proceedings, claims, actions,
                  governmental investigations, orders, judgments, or decrees of any nature made,
                  existing, threatened, anticipated or pending against it or any obligation, act,
                  omission or transaction contemplated hereunder.
                </li>
              </ul>
            </li>
            <li>
              The Company represents and warrants that Software Products will function in accordance
              with the specifications set out in the Documentation for a period as set forth in the
              Order Form. For any breach of this warranty, the Company shall promptly repair or
              replace, at the Company&rsquo;s option, any Software Product that fails to conform to
              the warranty set forth herein. The warranty set forth in this <u>Clause 17.2</u> (
              <em>Representations and Warranties</em>) shall not apply to Software Products that
              have (i) been damaged as a result of Licensee&rsquo;s negligence or abuse; or that
              have been modified by the Licensee or any person other than Company or its authorised
              personnel; (ii) altered, damaged, or modified, save for those alterations or
              modifications made by the Company; (iii) defects caused by failure to implement
              reasonable recommendations in respect of or solutions to defects provided by Company;
              (iv) been installed in a hardware or operating system version or network environment
              not supported by Company; (v) defects or errors caused by any fault or error in the
              equipment, programs, applications, or software used in conjunction with the Software
              Product, or otherwise resulting from causes beyond the reasonable control of Company;
              or (vi) been used in a manner that is not in accordance with the Documentation or
              terms of this Agreement.
            </li>
            <li>
              The Company shall be liable only for warranty claims made to the Company in a timely
              manner. Any warranty claim must be made in writing and contain reasonable detail and
              be delivered to the Company no later than 30 (thirty) calendar days following the date
              on which the Licensee first determines that a warranty breach has occurred. The
              Company&rsquo;s sole obligation and Licensee&rsquo;s sole remedy with respect to any
              claimed breach of warranty is for the Company to use commercially reasonable efforts
              to remedy such breach as soon as is reasonably practicable in accordance with{' '}
              <u>Clause 17.2</u> (<em>Representations and</em>
              <em> Warranties</em>).
            </li>
          </ul>
        </li>
        <li>
          <strong>DISCLAIMER</strong>
          <ul>
            <li>
              EXCEPT AS PROVIDED IN <u>CLAUSE 17.2</u> (<em>REPRESENTATIONS AND WARRANTIES</em>
              ), THE COMPANY LICENSES THE SOFTWARE PRODUCT AND PROVIDES SUPPORT SERVICE TO THE
              LICENSEE ON AN "AS IS", &ldquo;WITH ALL FAULTS&rdquo; BASIS. THE WARRANTY IN{' '}
              <u>CLAUSE 17.2</u> (<em>REPRESENTATIONS AND</em>
              <em> WARRANTIES</em>), IS PROVIDED IN LIEU OF ALL OTHER WARRANTIES OR CONDITIONS AND
              THE COMPANY MAKES NO OTHER WARRANTY, CONDITION OR REPRESENTATION OF ANY KIND WHETHER
              EXPRESS OR IMPLIED. THE COMPANY EXPRESSLY DISCLAIMS THE WARRANTY OF NON-INFRINGEMENT
              AND THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
              THE COMPANY DOES NOT REPRESENT OR WARRANT THAT OPERATIONS OF THE SOFTWARE PRODUCTS OR
              THE NETWORK AND THIRD-PARTY SERVICES TO WHICH THE SOFTWARE PRODUCTS ARE CONNECTED WILL
              BE ERROR FREE OR UNINTERRUPTED OR THAT PROGRAMMING ERRORS IN THE SOFTWARE PRODUCTS CAN
              BE FOUND IN ORDER TO BE CORRECTED. FOR CLARIFICATION, THE WARRANTY IN{' '}
              <u>CLAUSE 17.2</u> (<em>REPRESENTATIONS AND</em>
              <em> WARRANTIES</em>) DOES NOT APPLY DURING ANY EVALUATION OR TRIAL PERIOD. EXCEPT FOR
              THE WARRANTY THAT THE COMPANY HAS THE NECESSARY RIGHTS TO PROVIDE THE OPEN SOURCE AND
              THIRD-PARTY COMPONENTS TO THE LICENSEE PURSUANT TO THIS AGREEMENT, THE COMPANY
              DISCLAIMS ALL WARRANTIES WITH RESPECT TO OPEN SOURCE AND THIRD-PARTY COMPONENTS AND
              MATERIAL. ALL OPEN SOURCE AND THIRD-PARTY COMPONENTS AND MATERIAL ARE PROVIDED ON AN
              &ldquo;AS IS&rdquo; BASIS AND THE COMPANY SHALL HAVE NO LIABILITY FOR ANY CLAIMS
              ARISING THEREFROM.
            </li>
          </ul>
        </li>
        <li>
          <strong>INDEMNITY</strong>
          <ul>
            <li>
              <strong>Indemnification by the Company</strong>
              <ul>
                <li>
                  The Company shall defend the Licensee against claims brought against Licensee by
                  any third party alleging that Licensee&rsquo;s or User&rsquo;s use of the Software
                  Products, in accordance with the terms and conditions of this Agreement,
                  constitutes a direct infringement or misappropriation of an intellectual property
                  right (&ldquo;<strong>Third Party IPR Claim</strong>
                  &rdquo;). The Company shall pay damages finally judicially determined (or agreed
                  to be settled in consultation with the Company) against Licensee with respect to
                  such claims. The Parties agree that:
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <ul>
        <li>
          Upon becoming aware of a Third Party IPR Claim, the Licensee shall, within 5 (Five) days,
          issue a written notice to the Company accompanied by reasonable details in relation to the
          Third Party IPR Claim (&ldquo;
          <strong>Third Party IPR Claim Notice</strong>&rdquo;).
        </li>
      </ul>
      <ul>
        <li>
          Within 15 (Fifteen) Business Days of receipt of a Third Party IPR Claim Notice, the
          Company may notify the Licensee of its intention of to defend and control the Third Party
          IPR Claim on behalf of the Licensee. In such a scenario, the Company shall keep the
          Licensee reasonably informed of the status of the matter and proceedings ancillary
          thereto.
        </li>
      </ul>
      <ul>
        <li>
          If the Company does not assume the defence and control of the Third Party IPR Claim, the
          Licensee can defend against such claim, provided that (A) the Licensee shall not take any
          action or concede any issue which may be prejudicial to the interests of the Company
          without the Company&rsquo;s prior consent, (B) the Company will be required to indemnify
          the Licensee to the extent the damages that have been finally judicially determined.
        </li>
      </ul>
      <ul>
        <li>
          The Licensee will not be entitled to recover more than once in respect of the same loss.
        </li>
      </ul>
      <ul>
        <li>
          If any payment is received by the Licensee from a third party in respect of any loss, the
          liability of the Company in respect of such loss shall stand reduced to the extent of the
          payment received by the Licensee.
        </li>
      </ul>
      <ul>
        <li>
          This obligation of the Company shall not apply if the alleged infringement or
          misappropriation results from (a) use of the Software Products in a manner that is not in
          accordance with the Documentation or terms of this Agreement; or (b) is caused solely by
          or attributable directly to any acts or omissions of the Licensee or the Users, including
          any modification or alteration to the Software Products not authorised by the Company or
          use of any third-party software or service which are not mentioned in the Documentation
          provided by the Company.
        </li>
      </ul>
      <ul>
        <li>
          In the event of any claim under this <u>Clause 19.1 (</u>
          <em>Indemnity</em>), Company may, at its option: (a) obtain the right to permit Licensee
          to continue using the Software Product, (b) modify or replace the relevant portion(s) of
          the Software Product with a non-infringing alternative having substantially equivalent
          performance within a reasonable period of time, or (c) terminate this Agreement as to the
          infringing portion of the Software Product and refund to Licensee any prepaid, unused fees
          for such infringing portion of the Software Product hereunder.
        </li>
      </ul>
      <ul>
        <li>
          The indemnification obligations set forth in this Clause are Company&rsquo;s sole and
          exclusive obligations, and Licensee&rsquo;s sole and exclusive remedies, with respect to
          infringement or misappropriation of third-party intellectual property rights of any kind.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Indemnification by the Licensee</strong>
          <ul>
            <li>
              The Licensee shall promptly defend, indemnify and hold harmless the Company, its
              officers, directors, employees and its Affiliates against all losses, damages and
              claims (including third party claims, court costs and reasonable attorney&rsquo;s
              fees), suffered by the Company arising out of or related to: (a) any use of the
              Software Products by the Licensee or the Users in violation of this Agreement or
              Documentation or any Applicable Law or in infringement of the rights of a third party;
              or (b) the collection and storage of Licensee Data, in breach of this Agreement or
              violation of applicable; or (c) Licensee&rsquo;s use of any other software or services
              in a way that violates or misappropriates the rights of a third party.
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          The foregoing shall apply regardless of whether such loss or damage is caused by the
          conduct of Licensee and/or its Users or by the conduct of a third-party using Licensee's
          or its Users&rsquo; access credentials.
        </li>
      </ul>
      <ol start={20}>
        <li>
          <strong>LIMITATION OF LIABILITY</strong>
          <ul>
            <li>
              Notwithstanding anything herein to the contrary, under no circumstances and regardless
              of the nature of any claim, shall the Company or its Affiliates or licensors be liable
              to Licensee or any other person or entity under this Agreement, in respect of the
              Services or Software Product used by the Licensee pursuant to the Order Form, for an
              amount of damages in excess of the Fees paid by Licensee under the Order Form, in the
              12 (Twelve) months period immediately preceding the date of the incident giving rise
              to liability.
            </li>
            <li>
              Parties shall not be liable in any amount, regardless of the nature of claim, for any
              special, incidental, consequential, or indirect damages, loss of goodwill, loss of
              revenues or business profits, loss of data, work stoppage or for exemplary or punitive
              damages, arising out of, or relating to this Agreement whether framed as a breach of
              warranty, in tort, contract, or otherwise even if a Party has been advised of the
              possibility of such damages.
            </li>
            <li>
              The Parties agree that liability limitations under this Clause shall include any
              claims against employees or subcontractors of the Company, or any other persons
              authorized by the Company.
            </li>
          </ul>
        </li>
        <li>
          <strong>AUDIT</strong>
          <ul>
            <li>
              During the License Term of a Software Product and for the longer of (a)&nbsp;a period
              of 4 (four) years from termination or expiration of this Agreement, or (b) as
              otherwise required by Applicable Law, Licensee shall maintain, in accordance with
              generally accepted accounting principles, complete books and records relating to the
              Software Product, this Agreement and the Order Form hereunder, that demonstrate
              compliance with this Agreement and the Order Form (&ldquo;
              <strong>Agreement-related Records</strong>&rdquo;). The Company has the right to audit
              the Licensee&rsquo;s use of the Software Product (including use by the Users) during
              regular business hours and upon reasonable prior notice to the Licensee, either itself
              or through its authorized representatives or agents, and to make copies the
              Agreement-related Records (in whatever form they may be kept) to determine
              Licensee&rsquo;s compliance with this Agreement and the Order Form. The Licensee
              agrees to provide reasonable assistance, cooperation, and access to relevant
              information in the course of any audit at its own cost. If pursuant to the audit, the
              Company determines that the Licensee has exceeded the usage rights in the Software
              Product or otherwise is non-compliant with the terms of this Agreement or the Order
              Form, the Licensee shall immediately cease such excessive / non-compliant use and
              forthwith pay to the Company additional Fee for such excessive usage as determined by
              the Company. This remedy is without prejudice to any other remedies available to the
              Company in law or equity or under this Agreement.
            </li>
          </ul>
        </li>
      </ol>
      <ol start={22}>
        <li>
          <strong>CONFIDENTIALITY</strong>
          <ul>
            <li>
              Confidential Information shall not be used or reproduced in any form except as
              required to accomplish the intent of this Agreement. Any reproduction of any
              Confidential Information of the other Party shall remain the property of the Discloser
              and shall contain any and all confidential or proprietary notices or legends which
              appear on the original. With respect to the Confidential Information of the other,
              each Party (i) shall take all Reasonable Steps (<em>defined below</em>) to keep all
              Confidential Information strictly confidential; and (ii) shall not disclose any
              Confidential Information of the other to any person other than individuals such as
              counsel, directors, officers, employees, agents and representatives whose access is
              necessary to enable it to exercise its rights and/or perform its obligations hereunder
              and who are under obligations of confidentiality substantially similar to those set
              forth herein. As used herein &ldquo;<strong>Reasonable Steps</strong>&rdquo; means
              those steps the Recipient takes to protect its own similar proprietary and
              confidential information, which shall not be less than a reasonable standard of care.
              Confidential Information of either Party disclosed prior to execution of this
              Agreement shall be subject to the protections afforded hereunder. If the Recipient is
              compelled by Applicable Law or legal process to disclose Confidential Information of
              the Discloser, it shall provide the Discloser with prompt prior notice of such
              compelled disclosure (to the extent legally permitted) and reasonable assistance, at
              the Discloser&rsquo;s expense, if the Discloser wishes to contest the disclosure.
            </li>
            <li>
              The above restrictions on the use or disclosure of the Confidential Information shall
              not apply to any Confidential Information that: (i) as evidenced in writing, is
              independently developed by the Recipient without reference to the Discloser&rsquo;s
              Confidential Information and without breaching confidentiality obligations, or is
              lawfully received free of restriction from a third party having the right to furnish
              such Confidential Information; (ii) is or has become generally available to the public
              without breach of this Agreement by the Recipient; (iii) as evidenced in writing, at
              the time of disclosure, was known to the Recipient free of restriction and was not
              unlawfully appropriated; or (iv) the Discloser agrees in writing is free of such
              restrictions.
            </li>
          </ul>
        </li>
        <li>
          <strong>PUBLICITY</strong>
          <ul>
            <li>
              During the License Term and thereafter, the Company shall have the right to use the
              name and brand of the Licensee in any promotional / marketing materials, customer
              lists relating the Company and/or the Software Product or for any other non-commercial
              purposes as the Company may determine in its sole discretion, including case studies
              on the Licensee&rsquo;s use of the Software Product and the Licensee hereby grants to
              the Company a royalty-free license to use the trademarks, trade names, logos, images,
              brands owned and controlled by the Licensee in connection with the Company&rsquo;s
              performance of this Clause.
            </li>
          </ul>
        </li>
        <li>
          <strong>FORCE MAJEURE</strong>
          <ul>
            <li>
              The Company shall not be considered to be in default of performance of its obligations
              under the terms of this Agreement or for indemnification provided for hereunder, if
              such performance is prevented or delayed for any cause beyond the reasonable control
              of the Company, including denial-of-service attacks, hacker attacks, virus or other
              malicious software attacks or infections, failure of the Company&rsquo;s hosting
              provider, war, hostilities, revolution, riots, civil commotion, strikes, lockouts,
              epidemic, pandemic, lockdowns, fire, explosion, act of God, any act of Government,
              espionage, network, power or internet failure or failure of electronic transmission,
              default or failure of/ by any third party or any other cause beyond the control of the
              concerned Party (the &ldquo;
              <strong>Force Majeure Event</strong>&rdquo;). The Company shall inform the Licensee of
              any occurrence, event or incident of which it becomes aware which might adversely
              affect its ability to perform its obligations under this Agreement, including without
              limitation, any Force Majeure Event which could reasonably be expected to adversely
              impact the day-to-day functioning or the business of the The Company shall be excused
              from any further performance of its obligations effected by a Force Majeure Event for
              so long as the Force Majeure Event continues.
            </li>
          </ul>
        </li>
        <li>
          <strong>GOVERNING LAW AND JURISDICTION</strong>
          <ul>
            <li>
              This Agreement shall be governed by, and construed in accordance with, the laws of
              India and the Parties submit to the exclusive jurisdiction of the courts in Bengaluru,
              India.
            </li>
          </ul>
        </li>
        <li>
          <strong>MISCELLANEOUS</strong>
          <ul>
            <li>
              <strong>Export Control</strong>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        The Licensee shall not use, export, import, or transfer any part of the Software Products
        except as authorized by Applicable Law, or the laws of the jurisdiction in which Licensee
        uses or accesses the Software Products. In particular, but without limitation, no part of
        Software Products may be exported or re-exported: (i) into any embargoed countries; or (ii)
        to any party which has been prohibited or restricted; under Applicable Laws. The Licensee
        shall also not use the Software Products for any purpose prohibited by Applicable Laws.
      </p>
      <ul>
        <li>
          <strong>Notices</strong>
          <ul>
            <li>
              Any notice or other communication to be given by one Party to any other Party under,
              or in connection with, this Agreement shall be made in writing and signed by, or on
              behalf of, the Party giving it.
            </li>
            <li>
              <strong>
                <em>Service of a notice shall be effected by one of the following methods</em>
              </strong>
              :
              <ul>
                <li>
                  by hand to the relevant address set out in <u>Clause 26.2.4</u> (
                  <em>Address for Service</em>) and shall be deemed served upon delivery if
                  delivered during a Business Day, or at the start of the next Business Day if
                  delivered at any other time; or
                </li>
                <li>
                  by prepaid first-class post to the relevant address set out in{' '}
                  <u>Clause 26.2.4</u> (<em>Address for Service</em>) and shall be deemed served at
                  the start of the second Business Day after the date of posting; or
                </li>
                <li>
                  by prepaid international airmail to the relevant address set out in{' '}
                  <u>Clause 26.2.4</u> (<em>Address for Service</em>) and shall be deemed served at
                  the start of the fourth Business Day after the date of posting; or
                </li>
                <li>
                  by email, to the relevant email address set out in <u>Clause 26.2.4</u> (
                  <em>Address for Service</em>) and shall be deemed served on the day when the
                  sending of the email is recorded on the sender&rsquo;s computer, unless the sender
                  receives a message from its internet service provider or the recipient&rsquo;s
                  mail server indicating unsuccessful transmission. Any such email should be
                  followed by service of the notice through one of the methods in (a) through (c)
                  above, within 3 (three) Business Days of such email being deemed as served
                  pursuant to this sub-Clause (d); or
                </li>
                <li>
                  by facsimile transmission to the relevant facsimile number set out in{' '}
                  <u>Clause 26.2.4</u> (<em>Address for Service</em>) and shall be deemed served on
                  dispatch, if dispatched during a Business Day or at the start of the next Business
                  Day if dispatched at any other time. Any such facsimile transmission should be
                  followed by service of the notice through one of the methods in (a) through (c)
                  above, within 3 (three) Business Days of such facsimile transmission being deemed
                  as served pursuant to this sub-Clause (e).
                </li>
              </ul>
            </li>
            <li>
              In <u>Clause 26.2.2</u> (<em>Method of Service</em>), &ldquo;during a Business
              Day&rdquo; means any time between 9.30 am and 5.30 pm on a Business Day based on the
              local time where the recipient of the notice is located. References to &ldquo;the
              start of a Business Day&rdquo; and &ldquo;the end of a Business Day&rdquo; shall be
              construed accordingly.
            </li>
            <li>
              <strong>
                <em>Notices shall be addressed as follows</em>
              </strong>
              :
              <ul>
                <li>
                  <em>
                    In case of notice to Licensee &ndash; the notice shall be shared to the email
                    address of the contact person of the Licensee as provided for under the Sales
                    Order Form or shall be sent to the Licensee Billing Address as captured under
                    the Sales Order Form.{' '}
                  </em>
                </li>
                <li>
                  <em>In case of notice to Company</em>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <br></br>
      <table>
        <tbody>
          <tr>
            <td width="156">
              <p>Name :</p>
            </td>
            <td width="295">
              <p>Rakuten India Enterprise Private Limited</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Address :</p>
            </td>
            <td width="295">
              <p>
                Bagamane Pallavi Tower #20, 1st Cross, Raja Ram Mohan Roy Road, Sampangiramanagar,
                Bengaluru, Karnataka &ndash; 560027
              </p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Email address :</p>
            </td>
            <td width="295">
              <p className="text-blue-800">
                <a href="mailto:uthappa.mp@rakuten.com">uthappa.mp@rakuten.com</a> /{' '}
                <a href="mailto:rakuten-india-legal@mail.rakuten.com">
                  rakuten-india-legal@mail.rakuten.com
                </a>
                &nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>To the attention of :</p>
            </td>
            <td width="295">
              <p>Uthappa MP</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <p>
        Either Party may, from time to time, change its address or representative for receipt of
        notices provided for in this Agreement by giving to the other Party not less than 7 (Seven)
        Business Days&rsquo; prior written notice. Until the end of such notice period, service on
        either address shall remain effective.
      </p>
      <ul>
        <li>
          <strong>Waiver</strong>
          <ul>
            <li>
              To the extent permitted by Applicable Law: (a) no claim or right arising out of this
              Agreement or the documents referred to in this Agreement can be discharged by one
              Party, in whole or in part, by a waiver or renunciation of the claim or right unless
              in writing signed by the Party or Parties giving the same; (b) no waiver that may be
              given by a Party will be applicable except in the specific instance for which it is
              given; and (c) no notice to or demand on one Party will be deemed to be a waiver of
              any obligation of such Party or of the right of the Party giving such notice or demand
              to take further action without notice or demand as provided in this Agreement or the
              documents referred to in this Agreement.
            </li>
            <li>
              The rights and remedies of the Parties hereto are cumulative and not alternative.
              Except where a specific period for action or inaction is provided herein, neither the
              failure nor any delay on the part of any Party in exercising any right, power or
              privilege under this Agreement or the documents referred to in this Agreement shall
              operate as a waiver thereof, nor shall any waiver on the part of any Party of any such
              right, power or privilege, nor any single or partial exercise of any such right, power
              or privilege, preclude any other or further exercise thereof or the exercise of any
              other such right, power or privilege. The failure of a Party to exercise any right
              conferred herein within the time required shall cause such right to terminate with
              respect to the transaction or circumstances giving rise to such right, but not to any
              such right arising as a result of any other transactions or circumstances.
            </li>
          </ul>
        </li>
        <li>
          <strong>Assignment </strong>
        </li>
      </ul>
      <p>
        The Licensee shall not be entitled to, and shall not purport to, assign, transfer, charge or
        otherwise deal with all or any of its respective rights and/or obligations under this
        Agreement nor grant, declare, create or dispose of any right or interest in it, in whole or
        in part without the prior written consent of the Company.
      </p>
      <ul>
        <li>
          <strong>Anti-Bribery </strong>
          <ul>
            <li>
              It is the policy of the Company to comply and require parties with whom it contracts
              to comply with anti-bribery, anti-corruption, prevention of money laundering and the
              financing of terrorism, including the (Indian) Prevention of Corruption Act 1988, and
              all other applicable anti-bribery laws (collectively, "
              <strong>Anti-bribery Laws</strong>"). Licensee represents, warrants and covenants
              that: (i) it is aware of the Anti-bribery Laws and will advise all persons and parties
              supervised by it of the requirements of the Anti-bribery Laws; (ii) it has not and
              will not cause any party to be in violation of the Anti-bribery Laws; and (iii) should
              it learn of, or have reasons to know of, any request for payment that is inconsistent
              with the Anti-bribery Laws, it shall immediately notify the Company. Licensee shall
              indemnify, defend, and hold harmless the Company and its representatives for any and
              all liability arising from any violation of the Anti-bribery Laws caused or
              facilitated by the Licensee. In the event Company deems that it has reasonable grounds
              to suspect Licensee has violated the Anti-bribery Laws, Company and its
              representatives shall have the right to review and audit any and all books and
              financial records of the Licensee at any time, and Company shall be entitled partially
              or totally to suspend its performance hereunder until such time it is proven to
              Company&rsquo;s satisfaction that Licensee has not violated the Anti-bribery Laws. In
              the event Company determines, in its sole discretion (whether through an audit or
              otherwise), that Licensee has violated the Anti-bribery Laws, either in connection
              with this Agreement or otherwise, Company may terminate this Agreement immediately
              upon written notice to Licensee. Such suspension or termination of this Agreement
              shall not subject Company to any liability, whether in contract or tort or otherwise,
              to Company or any third party, and Company&rsquo;s rights to indemnification shall
              survive such suspension or termination of this Agreement.
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          The Licensee shall also comply with all business codes of conduct of the Company including
          but not limited to the &ldquo;Code of Business and Ethics&rdquo; available at
          &ldquo;Microsoft Word - DOCS--3331264-v2--Code of Business Ethics and Conduct (2018-06-18)
          Clean.docx (rakuten-med.com)&rdquo; and any other policies that may be introduced by the
          Company from time to time.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Amendments </strong>
        </li>
      </ul>
      <p>
        No variation of this Agreement shall be valid unless it is made by an instrument in writing
        and signed by duly authorised representatives of each of the Parties hereto. The expression
        &ldquo;variation&rdquo; shall include without limitation any variation, amendment,
        supplement, deletion or replacement however effected.
      </p>
      <ul>
        <li>
          <strong>No Partnership </strong>
        </li>
      </ul>
      <p>
        Nothing in this Agreement (or any of the arrangements contemplated herein) shall be deemed
        to constitute a partnership between the Parties, nor, except as may be expressly provided
        herein, constitute any Party as the agent of another Party for any purpose, or entitle any
        Party to commit or bind another Party in any manner.
      </p>
      <ul>
        <li>
          <strong>Reservation of Rights </strong>
        </li>
      </ul>
      <p>
        No forbearance, indulgence or relaxation or inaction by any Party at any time to require
        performance of any of the provisions of the Agreement shall in any way affect, diminish or
        prejudice the right of such Party to require performance of that provision. Any waiver or
        acquiescence by any Party of any breach of any of the provisions of the Agreement shall not
        be construed as a waiver or acquiescence of any right under or arising out of the Agreement
        or of the subsequent breach, or acquiescence to or recognition of rights other than as
        expressly stipulated in the Agreement.
      </p>
      <ul>
        <li>
          <strong>Independent Rights </strong>
        </li>
      </ul>
      <p>
        Each of the rights of the Parties are independent, cumulative and without prejudice to all
        other rights available to them, and the exercise or non-exercise of any such rights shall
        not prejudice or constitute a waiver of any other right of the Party, whether under this
        Agreement or otherwise.
      </p>
      <ul>
        <li>
          <strong>Specific Performance </strong>
        </li>
      </ul>
      <p>
        The Parties agree that damages may not be an adequate remedy and that each Party shall be
        entitled to, in addition to any other right or remedy available to it, an injunction,
        restraining order, right for recovery, suit for specific performance or such other equitable
        relief as a court of competent jurisdiction may deem necessary or appropriate to restrain
        the other Parties from committing any violation or enforce the performance of the covenants,
        representations and obligations contained in this Agreement.
      </p>
      <ul>
        <li>
          <strong>Entire Agreement </strong>
        </li>
      </ul>
      <p>
        This Agreement, together with the Documentation, constitutes the entire agreement of the
        Parties relating to the subject matter hereof and supersedes any and all prior agreements,
        including expression of interest, binding offers, either oral or in writing, between the
        Parties hereto with respect to the subject matter herein. In case of any conflict between
        the Agreement and the Documentation, the terms of this Agreement shall prevail. In case of
        any conflict between the terms of the Standard Terms and the terms of the Order Form, the
        terms of the Order Form shall prevail.
      </p>
      <ul>
        <li>
          <strong>Partial Invalidity </strong>
        </li>
      </ul>
      <p>
        If any provision of this Agreement is, or becomes invalid, illegal or unenforceable under
        Applicable Law, then such provision shall (so far as it is invalid or unenforceable) be
        given no effect and shall be deemed not to be included in this Agreement but without
        invalidating any of the remaining provisions of this Agreement which shall not in any way be
        affected or impaired. The Parties hereto shall then use all reasonable endeavours to replace
        the invalid or unenforceable provisions with a valid and enforceable and mutually
        satisfactory substitute provision, achieving as nearly as possible the intended commercial
        effect of the invalid, illegal or unenforceable provision.
      </p>
      <ul>
        <li>
          <strong>Counterparts </strong>
        </li>
      </ul>
      <p>
        This Agreement may be executed in any number of originals or counterparts, each in the like
        form and all of which when taken together shall constitute one and the same document, and
        any Party may execute this Agreement by signing any one or more of such originals or
        counterparts. The delivery of signed counterparts by facsimile transmission or electronic
        mail in &ldquo;portable document format&rdquo; (&ldquo;
        <strong>.pdf</strong>&rdquo;) shall be as effective as signing and delivering the
        counterpart in person.
      </p>
      <ul>
        <li>
          <strong>Further Assurances </strong>
        </li>
      </ul>
      <p>
        The Parties hereto undertake (so far as they are legally able and permitted to do so) they
        will promptly, or will procure that a relevant person will promptly, perform any and all
        such further acts, execute and deliver any and all such additional documentation, exercise
        any and all voting rights and powers, whether direct or indirect, available to them in
        relation to any person, render any and all such assistance and do all such further things as
        may be necessary, expedient or desirable to ensure the complete and prompt fulfilment,
        observance and performance of the provisions of the Agreement so that full effect is given
        to the provisions of the Agreement.
      </p>
      <ul>
        <li>
          <strong>Costs and Expenses </strong>
        </li>
      </ul>
      <p>
        Each of the Parties shall bear their own legal costs, disbursements charges and all other
        expenses incurred in and about the negotiation, preparation and execution of this Agreement
        and any other document executed in connection with this Agreement, provided however that all
        stamp duty payable in relation to this Agreement and any other document executed in
        connection with this Agreement shall be borne by the Licensee.
      </p>
      <ul>
        <li>
          <strong>Survival </strong>
        </li>
      </ul>
      <p>
        The termination of this Agreement shall in no event terminate or prejudice: (a) any right or
        obligation arising out of or accruing under this Agreement attributable to events or
        circumstances occurring prior to such termination; (b) any provision which by its nature is
        intended to survive termination.
      </p>
      <p>SCHEDULE I | DEFINITIONS</p>
      <ol>
        <li>
          <strong>Definitions</strong>
        </li>
      </ol>
      <p>
        In the Agreement, (i) capitalised terms defined by inclusion in quotations and/or
        parenthesis have the meanings so ascribed; and (ii) the following terms shall have the
        meanings assigned to them herein below:
      </p>
      <p>
        &ldquo;<strong>Affiliate</strong>&rdquo; of either Party means a person or entity, directly
        or indirectly, Controlling, Controlled by, or under common Control with such Party;
      </p>
      <p>
        &ldquo;<strong>Agreement</strong>&rdquo; means this Software as a Service Agreement and all
        schedules, annexures attached to it or incorporated in it by reference and shall include any
        amendments or addendums to this Agreement in writing, executed by the Parties from time to
        time;
      </p>
      <p>
        &ldquo;<strong>Applicable Law</strong>&rdquo; means any law, rule, regulation, ordinance,
        order, code, treaty, judgment, decree, injunction, permit or decision of any central, state
        or local government, authority, agency, court or other body having jurisdiction over the
        matter or person in question, including those prevailing in the relevant jurisdiction, as in
        effect, from time to time;
      </p>
      <p>
        &ldquo;<strong>Bugs</strong>&rdquo; means a failure of a Software Product to perform as
        specified in the applicable product description and/or user's guide and/or installation
        guide due to defective software distribution media or otherwise.
      </p>
      <p>
        &ldquo;<strong>Business Day</strong>&rdquo; means any day of the week except Saturday,
        Sunday or any day on which the banks in India are closed for business;
      </p>
      <p>
        &ldquo;<strong>Confidential Information</strong>&rdquo; means with respect to Licensee, the
        Licensee Data, marketing and business plans of the Licensee and/or Licensee&rsquo;s
        financial information, and with respect to Company: (a) Company Materials, Software
        Products, Documentation including, without limitation, all (i) computer software (both
        object and source codes) and related documentation or specifications; (ii) techniques,
        concepts, methods, processes and designs embodied in or relating to the Software Products;
        (iii) all application program interfaces, system security and system architecture design
        relating to the Software Products; (iv) Company&rsquo;s research and development, product
        offerings, pricing and availability; and (v) the terms and condition of this Agreement and
        the Order Form. In addition to the foregoing, Confidential Information of either Party may
        also include without limitation information which the Discloser protects against
        unrestricted disclosure to others that (i) the Discloser or its representatives designates
        as confidential at the time of disclosure; or (ii) should reasonably be understood to be
        confidential given the nature of the information and the circumstances surrounding its
        disclosure; including, without limitation, information about or concerning any third party
        that is disclosed to the Recipient under this Agreement;
      </p>
      <p>
        &ldquo;<strong>Control</strong>&rdquo; means the legal power or authority of a person or
        entity to direct the management, affairs and policies of another entity, directly or
        indirectly, whether by way of the right to appoint the majority of the board of directors or
        partners of such other entity or by management agreement or otherwise, including by virtue
        of such person&rsquo;s shareholding or management rights or shareholders&rsquo; agreements
        or voting agreements or in any other manner;
      </p>
      <p>
        &ldquo;<strong>Discloser</strong>&rdquo; means the Party disclosing Confidential
        Information;
      </p>
      <p>
        &ldquo;<strong>Documentation</strong>&rdquo; means the Company&rsquo;s then-current
        technical and functional documentation for the Software Products which is made available to
        Licensee with the Software Products, including, but not limited to, configuration workbooks
        or release notes, terms of service and policies, as applicable;
      </p>
      <p>
        &ldquo;<strong>Effective Date</strong>&rdquo; shall mean the date as described in the Order
        Form;
      </p>
      <p>
        &ldquo;<strong>Fees</strong>&rdquo; means the fees payable by Licensee to Company, as
        described in the Order Form;
      </p>
      <p>
        &ldquo;<strong>Licensee Data</strong>&rdquo; means any content, images, documents,
        materials, data including application performance data, personal data, such as user
        credentials, user behaviour, security data and other information and/ or code that Licensee
        or its Users input into the Software Product, enriched data and any intermediate output or
        final output thereof;
      </p>
      <p>
        &ldquo;<strong>Company Materials</strong>&rdquo; means the Software Products, Documentation
        and any materials produced by the Company pursuant to this Agreement;
      </p>
      <p>
        &ldquo;<strong>Permitted Sub-Licensee</strong>&rdquo; means the sub-licensees permitted to
        use and access the Software Products and/or Services, as described in the Order Form.
      </p>
      <p>
        &ldquo;<strong>Purpose</strong>&rdquo; means the purpose for which the Licensee shall use
        the Software Products and Services, as described in the Order Form;
      </p>
      <p>
        &ldquo;<strong>Recipient</strong>&rdquo; means the Party receiving Confidential Information;
      </p>
      <p>
        &ldquo;<strong>Services</strong>&rdquo; means the services to be rendered by Company in
        relation to Software Product as set out in Exhibit A;
      </p>
      <p>
        &ldquo;<strong>Software Product(s)</strong>&rdquo; means software provided by the Company
        the details of which are as set out in the Order Form, including the software, analytics
        solutions, data services and any and all updates, upgrades, and modifications thereto,
        licensed through the Company&rsquo;s online store or other channels;
      </p>
      <p>
        &ldquo;<strong>Upgrade</strong>&rdquo; in respect of a Software Product means an embodiment
        of the Software Product that delivers substantial performance improvements, architectural
        changes, Bug fixes or new features and/or functionality to the Software Product; and
      </p>
      <p>
        &ldquo;<strong>User&rdquo; </strong>means each individual user of the Software Product
        authorised by the Licensee.
      </p>
      <ol start={2}>
        <li>
          <strong>Additional Defined Terms</strong>
        </li>
      </ol>
      <p>
        For purposes of this Agreement, the following terms have the meanings specified in the
        indicated Clauses of this Agreement:
      </p>
      <br></br>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={cellStyle}>DEFINED TERM</th>
            <th style={cellStyle}>CLAUSE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={cellStyle}>.pdf</td>
            <td style={cellStyle}>Clause 26.13</td>
          </tr>
          <tr>
            <td style={cellStyle}>Agreement-related Records</td>
            <td style={cellStyle}>Clause 21</td>
          </tr>
          <tr>
            <td style={cellStyle}>Anti-Bribery Laws</td>
            <td style={cellStyle}>Clause 26.5.1</td>
          </tr>
          <tr>
            <td style={cellStyle}>Company / Licensor</td>
            <td style={cellStyle}>Order Form</td>
          </tr>
          <tr>
            <td style={cellStyle}>Feedback</td>
            <td style={cellStyle}>Clause 15</td>
          </tr>
          <tr>
            <td style={cellStyle}>Force Majeure Event</td>
            <td style={cellStyle}>Clause 24</td>
          </tr>
          <tr>
            <td style={cellStyle}>Licensee</td>
            <td style={cellStyle}>Order Form</td>
          </tr>
          <tr>
            <td style={cellStyle}>Licensee Property</td>
            <td style={cellStyle}>Clause 12.2</td>
          </tr>
          <tr>
            <td style={cellStyle}>Licensing Metric</td>
            <td style={cellStyle}>Order Form</td>
          </tr>
          <tr>
            <td style={cellStyle}>Party / Parties</td>
            <td style={cellStyle}>Clause 1.1</td>
          </tr>
          <tr>
            <td style={cellStyle}>Retrieval Period</td>
            <td style={cellStyle}>Clause 16.7</td>
          </tr>
          <tr>
            <td style={cellStyle}>Reasonable Steps</td>
            <td style={cellStyle}>Clause 22.1</td>
          </tr>
          <tr>
            <td style={cellStyle}>Standard Terms</td>
            <td style={cellStyle}>Order Form</td>
          </tr>
          <tr>
            <td style={cellStyle}>Third Party Components</td>
            <td style={cellStyle}>Clause 5.1</td>
          </tr>
          <tr>
            <td style={cellStyle}>Third Party Host</td>
            <td style={cellStyle}>Clause 5.3</td>
          </tr>
          <tr>
            <td style={cellStyle}>Third Party IPR Claim</td>
            <td style={cellStyle}>Clause 19.1 (i)</td>
          </tr>
          <tr>
            <td style={cellStyle}>Third Party IPR Claim Notice</td>
            <td style={cellStyle}>Clause 19.1 (i)(a)</td>
          </tr>
          <tr>
            <td style={cellStyle}>UAT</td>
            <td style={cellStyle}>Schedule III</td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <h1>SCHEDULE II | INTERPRETATION</h1>
      <ol>
        <li>
          The terms referred to in this Agreement shall, unless defined otherwise or inconsistent
          with the context or meaning thereof, bear the meanings ascribed to them under the relevant
          statute / legislation.
        </li>
        <li>
          Reference to statutory provisions shall be construed as meaning and including references
          also to any amendment or re-enactment (whether before or after the Effective Date) for the
          time being in force and to all statutory instruments or orders made pursuant to such
          statutory provisions.
        </li>
        <li>
          Words denoting the singular shall include the plural and words denoting any gender shall
          include all genders.
        </li>
        <li>
          Headings, subheadings, titles, subtitles to clauses, sub-clauses and paragraphs are for
          information only and shall not form part of the operative provisions of this Agreement or
          the annexures hereto and shall be ignored in construing the same.
        </li>
        <li>
          References to days, months and years are to calendar days, calendar months and calendar
          years, respectively.
        </li>
        <li>
          Unless otherwise specified, time periods within or following which any payment is to be
          made or act is to be done shall be calculated by excluding the day on which the period
          commences and including the day on which the period ends and by extending the period to
          the next Business Day if the last day of such period is not a Business Day; and whenever
          any payment is to be made or action to be taken under this Agreement is required to be
          made or taken on a day other than a Business Day, such payment shall be made or action
          taken on the next Business Day.
        </li>
        <li>
          Words &ldquo;directly or indirectly&rdquo; mean directly or indirectly through one or more
          intermediary persons or through contractual or other legal arrangements, and &ldquo;direct
          or indirect&rdquo; shall have the correlative meanings.
        </li>
        <li>
          Any reference to &ldquo;writing&rdquo; shall include printing, typing, lithography,
          transmissions by facsimile or in electronic form (including e-mail) and other means of
          reproducing words in visible form but excludes text messaging from smartphones.
        </li>
        <li>
          No provisions shall be interpreted in favour of, or against, any Party by reason of the
          extent to which such Party or its counsel participated in the drafting hereof or by reason
          of the extent to which any such provision is inconsistent with any prior draft hereof.
        </li>
        <li>
          If there is any conflict or inconsistency between a term in the body of this Agreement and
          a term in any of the schedules or any other document referred to or otherwise incorporated
          in this Agreement, the term in the body of this Agreement shall take precedence.
        </li>
      </ol>
      <h1>SCHEDULE III | PREMIUM SUPPORT OFFERING TERMS</h1>
      <ol>
        <li>
          <strong>Support Priority Levels</strong>
        </li>
      </ol>
      <br></br>
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={cellStyle}>
              <p>Priority</p>
            </td>
            <td style={cellStyle}>
              <p>Description</p>
            </td>
            <td style={cellStyle}>
              <p>Initial Response Time</p>
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>P1</p>
            </td>
            <td style={cellStyle}>
              <p>
                Indicates a reported incident where Software Product is completely unavailable and
                inaccessible to all Users.
              </p>
            </td>
            <td style={cellStyle}>
              <p>Within 1 (one) hour</p>
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>P2</p>
            </td>
            <td style={cellStyle}>
              <p>
                Software Product is available; however, functionality or performance of the Software
                Product is severely affected.
              </p>
            </td>
            <td style={cellStyle}>
              <p>Within 2 (two) hours</p>
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>P3</p>
            </td>
            <td style={cellStyle}>
              <p>
                Software Product is available and usable with partial, with non-critical loss of
                functionality.
              </p>
            </td>
            <td style={cellStyle}>
              <p>Within 4 (four) hours</p>
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>P4</p>
            </td>
            <td style={cellStyle}>
              <p>General enquiries on the product use, support and documentation.</p>
            </td>
            <td style={cellStyle}>
              <p>Next Business Day</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <ol start={2}>
        <li>
          <strong>Bug Support </strong>
        </li>
      </ol>
      <p>
        In the event a Bug is discovered in a Software Product, the Company shall fix such Bug at no
        additional charge to the Licensee, subject to the severity codes described below and the
        corresponding response and resolution times.
      </p>
      <br></br>
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td style={cellStyle}>
              <p>Severity</p>
            </td>
            <td style={cellStyle}>
              <p>Impact</p>
            </td>
            <td style={cellStyle}>
              <p>Response Time</p>
            </td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>Blocker</p>
            </td>
            <td style={cellStyle}>&nbsp;</td>
            <td style={cellStyle}>&nbsp;</td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>Critical</p>
            </td>
            <td style={cellStyle}>&nbsp;</td>
            <td style={cellStyle}>&nbsp;</td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>Major</p>
            </td>
            <td style={cellStyle}>&nbsp;</td>
            <td style={cellStyle}>&nbsp;</td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>Minor</p>
            </td>
            <td style={cellStyle}>&nbsp;</td>
            <td style={cellStyle}>&nbsp;</td>
          </tr>
          <tr>
            <td style={cellStyle}>
              <p>Low</p>
            </td>
            <td style={cellStyle}>&nbsp;</td>
            <td style={cellStyle}>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <ol start={3}>
        <li>
          <strong>Exclusions</strong>
        </li>
      </ol>
      <p>
        If a problem is found to be caused by one or more of the following excluded factors, then
        the Company is not obligated to provide support. The Company shall notify the Licensee as
        soon as they become aware that a fault may be due to one of the following exclusions:
      </p>
      <ul>
        <li>
          Altered, damaged, or modified Software Product, save for those alterations or
          modifications made by the Company.
        </li>
        <li>Defects or errors caused by incorrect or negligent use of the Software Product.</li>
        <li>
          Defects caused by failure to implement reasonable recommendations in respect of or
          solutions to defects provided by Company.
        </li>
        <li>
          Software Product installed in a hardware or operating system version or network
          environment not supported by Company.
        </li>
        <li>Third party software not licensed through or supported by Company.</li>
        <li>
          Defects or errors caused by any fault or error in the equipment, programs, applications,
          or software used in conjunction with the Software Product, or otherwise resulting from
          causes beyond the reasonable control of Company.
        </li>
      </ul>
      <ol start={4}>
        <li>
          <strong>Upgrades</strong>
        </li>
      </ol>
      <p>
        The Company may provide or install an Upgrade to a Software Product at additional charges
        and subject to the Licensee agreeing to the Upgrade in writing.
      </p>
      <ul>
        <li>
          For each Upgrade, the Licensee will permit down time and necessary assistance in terms of
          hardware and access to the system.
        </li>
        <li>
          After the Upgrade, the Licensee is responsible for carrying out UAT to ensure all features
          of the Upgrade are working. On request, the Company will provide the Licensee with sample
          Software Product specific UAT use cases and scripts, if available. This will not include
          any application specific UAT use cases and scripts.
        </li>
      </ul>
      <ol start={5}>
        <li>
          <strong>Licensee&rsquo;s Support Service Obligations</strong>
        </li>
      </ol>
      <p>
        The Licensee shall provide cooperation and assistance to the Company in efforts to provide
        support. Such cooperation and assistance shall include but not be limited to:
      </p>
      <ul>
        <li>
          The timely transmittal and release to Company of appropriate and accurate documentation
          and information.
        </li>
        <li>
          Remote access to Licensee&rsquo;s environment where the defect can be reproduced and
          traced.
        </li>
      </ul>
      <p>
        If the fault cannot be replicated via the remote access, the Licensee may require
        Company&rsquo;s staff to attend site and shall pay for the time and expense charges involved
        in attending this site or other locations.
      </p>
      <ol start={6}>
        <li>
          <strong>Contact for Support</strong>
        </li>
      </ol>
      <p>
        The Licensee may contact the Company by writing to the following email address:{' '}
        <a className="text-blue-800" href="mailto:info-sixthsense@mail.rakuten.com">
          info-sixthsense@mail.rakuten.com
        </a>
      </p>
      <ol start={7}>
        <li>
          <strong>Standard Hours of Operations</strong>
        </li>
      </ol>
      <ul>
        <li>For P1 Issues: 24 hours on all days of the week.</li>
        <li>
          For P 2-4 issues: Monday to Friday, From 0700 Hours to 1500 Hours (US Eastern Time Zone).
        </li>
      </ul>
      <div className="flex flex-col gap-2 text-center">
        <p>
          <strong> SCHEDULE IV</strong>
        </p>
        <p>
          <strong>PART A | OPEN-SOURCE SOFTWARE</strong>
        </p>
        <p>
          <strong>[List of Open-Source Software] </strong>
        </p>
        <p>
          <strong>PART B | PROPRIETARY THIRD-PARTY COMPONENTS</strong>
        </p>
        <p>
          <strong>[List of proprietary third-party components]</strong>
        </p>
        <p>
          <strong>SCHEDULE V </strong>
        </p>
        <p>
          <strong>THIRD PARTY HOST</strong>
        </p>
        <p>
          <strong>[Details of third-party hosts / servers]</strong>
        </p>
      </div>
    </BasicCard>
  )
}
