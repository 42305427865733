import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { timer } from 'rxjs'
import { useAppDispatch } from '../../hooks'
import { COLORS } from '../../../themes/default/colors'
import { BasicCard, Button, LabelInput } from '../../components'
import { ChevronRight } from '../../components/icons'
import { validationMessages } from '../../consts'
import { validateField } from '../../utils'
import {
  resetRegisterValue,
  useLazyOtpVerificationQuery,
  useLazyResendOtpQuery,
} from '../../store/auth'
import { setAlert } from '../../store/alert'

export default function OtpValidation() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const countdownRef = useRef(30)
  const [resendText, setResendText] = useState(`Resend OTP in ${countdownRef.current} seconds`)
  const [errorMessage, setErrorMessage] = useState('')
  const [otpValidation] = useLazyOtpVerificationQuery()
  const [resendOtp] = useLazyResendOtpQuery()

  const isValidOtp = useMemo(() => validateField(otp, ['required', 'number', 'length:6']), [otp])

  useEffect(() => {
    const timerSubscription$ = timer(1000, 1000).subscribe(() => {
      countdownRef.current -= 1
      if (countdownRef.current <= 0) {
        setResendText('Resend OTP')
      } else {
        setResendText(`Resend OTP in ${countdownRef.current} seconds`)
      }
    })

    return () => timerSubscription$.unsubscribe()
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value)
    setErrorMessage('')
  }

  const handleVerification = () => {
    otpValidation(Number(otp)).then(({ isSuccess, error }) => {
      if (isSuccess) {
        navigate('/')
        dispatch(
          setAlert({
            message: 'Verification Success! Please Login to continue',
            severity: 'success',
          })
        )
        dispatch(resetRegisterValue())
      } else if (error) {
        setErrorMessage(validationMessages.otpValidaton.match)
      }
    })
  }

  const handleResendOtp = () => {
    if (countdownRef.current <= 0) {
      resendOtp(null).then(() => {
        countdownRef.current = 30
      })
    }
  }

  return (
    <div className="flex w-full items-center justify-center">
      <BasicCard
        contentClasses="flex-1"
        containerClasses="flex !p-3 laptop:w-4/12 laptop:h-[20rem] desktop:h-[25rem] desktop:w-3/12 justify-center items-center bg-black"
      >
        <div className="flex w-full flex-col laptop:gap-4 desktop:gap-6">
          <div className="-ml-3 flex cursor-pointer flex-row">
            <ChevronRight
              transform="rotate(180)"
              className="h-10 w-10"
              color={COLORS.PRIMARY}
              onClick={() => navigate(-1)}
            />
            <span className="text-3xl text-warm">OTP Verification </span>
          </div>
          <span className="pb-4 text-justify align-middle text-sm text-warm">
            Check your email for the one-time verification code (OTP). Enter it below to complete
            your registration.
          </span>
          <LabelInput
            name="otp"
            type="number"
            placeholder="Enter the OTP"
            value={otp}
            onChange={handleChange}
            error={isValidOtp !== ''}
            errorMessage={isValidOtp ? validationMessages.otpValidaton[isValidOtp] : ''}
          />
        </div>
        <div className="-mt-4 h-10 w-full text-xs text-red-600">{errorMessage}</div>
        <div className="flex flex-col ">
          <Button
            disabled={isValidOtp !== ''}
            color={'primary'}
            variant="contained"
            buttonTextClass="font-semibold text-base"
            className="h-10 w-full"
            onClick={handleVerification}
          >
            Verify and Proceed{' '}
          </Button>
        </div>

        <div className="mt-2 flex text-warm">
          Didn't receive the OTP?
          <div
            className={`ml-1 cursor-pointer text-primary ${
              countdownRef.current > 0 ? '' : 'underline decoration-primary'
            }`}
            onClick={handleResendOtp}
          >
            {resendText}
          </div>
        </div>
      </BasicCard>
    </div>
  )
}
