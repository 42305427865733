import { PropsWithChildren } from 'react'

interface LoaderProps extends PropsWithChildren {
  testId?: string
  classes?: string
}

export const LOADER_TEST_ID = 'LOADER_TEST_ID'

export default function Loader({ children, classes }: LoaderProps) {
  return (
    <div
      data-testid={LOADER_TEST_ID}
      className={`flex h-full w-full flex-col items-center justify-center text-white ${classes}`}
    >
      <div className="flex w-full items-center justify-center space-x-2">
        {[...Array(5)].map((_, k) => (
          <div
            key={k}
            className="inline-block h-4 w-4 animate-pulse rounded-full bg-primary"
            role="status"
          />
        ))}
      </div>
      {children}
    </div>
  )
}
