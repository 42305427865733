export const LOGIN_ROUTES_NAMES = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  VERIFY: 'VERIFY',
}

export const LOGIN_ROUTES = {
  [LOGIN_ROUTES_NAMES.LOGIN]: '/',
  [LOGIN_ROUTES_NAMES.SIGNUP]: 'signup',
  [LOGIN_ROUTES_NAMES.VERIFY]: 'verify',
}

export const DASHBOARD_ROUTES_NAMES = {
  HOME: 'HOME',
  SOURCES: 'SOURCES',
  SOURCE: 'SOURCE',
  ADD_SOURCE: 'ADD_SOURCE',
  EDIT_SOURCE: 'EDIT_SOURCE',
  SEARCH_SPACE: 'SEARCH_SPACE',
  ALERTS_RULES: 'ALERTS_RULES',
  ALL_SOURCES: 'ALL_SOURCES',
}

export const DASHBOARD_ROUTES = {
  [DASHBOARD_ROUTES_NAMES.HOME]: 'dashboard',
  [DASHBOARD_ROUTES_NAMES.SOURCES]: 'sources',
  [DASHBOARD_ROUTES_NAMES.ADD_SOURCE]: 'sources/add',
  [DASHBOARD_ROUTES_NAMES.ALERTS_RULES]: 'alerts',
  [DASHBOARD_ROUTES_NAMES.EDIT_SOURCE]: 'sources/:sourceId/edit',
  [DASHBOARD_ROUTES_NAMES.SEARCH_SPACE]: 'sources/:sourceId/searchspace',
  [DASHBOARD_ROUTES_NAMES.SOURCE]: 'sources/:sourceId',
  [DASHBOARD_ROUTES_NAMES.ALL_SOURCES]: 'sources',
}

export const SOURCES_ROUTES_NAMES = {
  ANOMALIES: 'ANOMALIES',
  ANOMALIES_VIEW: 'ANOMALIES_VIEW',
  VIEW_MULTIVARIATE: 'VIEW_MULTIVARIATE',
  APP_BEHAVIOUR: 'APP_BEHAVIOUR',
  BEHAVIOUR: 'BEHAVIOUR',
  MULTIVARIATE_BEHAVIOUR: 'MULTIVARIATE_BEHAVIOUR',
}

export const SOURCES_ROUTES = {
  [SOURCES_ROUTES_NAMES.ANOMALIES]: 'anomalies',
  [SOURCES_ROUTES_NAMES.ANOMALIES_VIEW]: 'anomalies/:queryId',
  [SOURCES_ROUTES_NAMES.VIEW_MULTIVARIATE]: 'anomalies/:applicationId/multivariate',
  [SOURCES_ROUTES_NAMES.APP_BEHAVIOUR]: 'anomalies/:applicationId/behaviour',
  [SOURCES_ROUTES_NAMES.BEHAVIOUR]: 'behaviour',
  [SOURCES_ROUTES_NAMES.MULTIVARIATE_BEHAVIOUR]: 'anomalies/:applicationId/multivariate/behaviour',
}

export const ANOMALIES_ROUTES_NAMES = {
  BREACHES: 'BREACHES',
  DEF_DEV: 'DEF_DEV',
}

export const ANOMALIES_ROUTES = {
  [ANOMALIES_ROUTES_NAMES.BREACHES]: 'breaches',
  [ANOMALIES_ROUTES_NAMES.DEF_DEV]: 'divergence',
}

//commented for future use

// export const ALERTS_ROUTES = {
//   WEBHOOK_INTERGRATION: 'alerts',
// }
