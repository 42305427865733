interface SourceCardLoaderProps {
  testId?: string
  numberOfItems?: number
}

export const SOURCE_CARD_LOADER_TEST_ID = 'SOURCE_CARD_LOADER_TEST_ID'

export default function SourceCardLoader({
  testId = SOURCE_CARD_LOADER_TEST_ID,
  numberOfItems,
}: SourceCardLoaderProps) {
  const sourceCard = () => {
    return [...Array(numberOfItems)].map((_, index) => (
      <div
        key={index}
        className="flex h-[23vh] animate-pulse flex-col justify-between rounded-md bg-dark px-3 py-4"
      >
        <div className="flex h-[4vh] flex-col justify-between">
          <div className="flex h-4 w-full flex-row rounded-lg bg-seal_brown"></div>
          <div className="flex h-3 w-40 flex-row rounded-lg bg-seal_brown"></div>
        </div>
        <div className="flex flex-row justify-between px-3 py-2">
          <div className="flex h-10 w-20 flex-row rounded-md bg-seal_brown"></div>
          <div className="flex h-10 w-20 flex-row rounded-md bg-seal_brown"></div>
          <div className="flex h-10 w-20 flex-row rounded-md bg-seal_brown"></div>
        </div>
        <div className="flex h-[4vh] flex-col justify-between">
          <div className="flex h-3 w-40 flex-row rounded-md bg-seal_brown"></div>
          <div className="flex h-3 w-40 flex-row rounded-md bg-seal_brown"></div>
        </div>
      </div>
    ))
  }
  return (
    <div data-testid={testId} className="grid w-[98vw] grid-cols-4 gap-3">
      {sourceCard()}
    </div>
  )
}
