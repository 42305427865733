import { useMemo } from 'react'
import { MetricTypes, QueryType } from '../../store/application'
import { Autocomplete, HelperText, LabelInput, Select } from '../../components'
import QueryValidateInput from './query-validate-input'
import { validateField } from '../../utils'
import { validationMessages } from '../../consts'
import { OptionType } from '../../types'
import { APP_NAME } from '../../consts/version'
import { ApplicationTooltipList } from '../../consts/tooltips'

export const LEFT_SECTION_TEST_ID = 'LEFT_SECTION_TEST_ID'

const metricTypeOptions = Object.keys(MetricTypes).map((k) => ({ label: k, value: k }))

interface AddEditApplicationCardLeftSectionProps {
  sourceName: string
  sourceType: string
  applicationName: string
  metricType: string
  jobs: OptionType[]
  metrics: OptionType[]
  selectedJob: OptionType
  selectedMetric: OptionType
  queries: QueryType[]
  currentQuery: string
  onChange: (type: string, value: string | number | boolean) => void
  onAddQuery: () => void
}
export default function AddEditApplicationCardLeftSection({
  sourceName,
  sourceType,
  applicationName,
  metricType,
  jobs,
  metrics,
  selectedJob,
  selectedMetric,
  queries,
  currentQuery,
  onChange,
  onAddQuery,
}: AddEditApplicationCardLeftSectionProps) {
  const isValidName = useMemo(
    () => validateField(applicationName, ['required', 'entityName']),
    [applicationName]
  )
  return (
    <div className="flex w-6/12 flex-col" data-testid={LEFT_SECTION_TEST_ID}>
      <div className="flex flex-col">
        <div className="text-xl text-white">
          {sourceName} via <span className="capitalize">{sourceType}</span>
        </div>
        <HelperText text={`Add the metrics for ${APP_NAME} to consume, consolidate and process`} />
      </div>
      <div className="mt-2 flex-1 flex-row">
        <LabelInput
          required
          error={isValidName !== ''}
          label="Application name"
          toolTipMessage={<ApplicationTooltipList />}
          toolTipPlacement="right-start"
          showCount={true}
          placeholder="Name the application"
          helperText={'Name your application for which the metrics will be scraped'}
          value={applicationName}
          onChange={(e) => onChange('applicationName', e.target.value)}
          errorMessage={isValidName ? validationMessages.applicationName[isValidName] : ''}
        />
      </div>
      <div className="mt-2 flex flex-col">
        <div className="flex flex-row items-end gap-x-3">
          <div className="flex-1">
            <Select
              labelClasses="text-white text-xs"
              required
              placeholder="Metric type"
              options={metricTypeOptions}
              label="Build a metric query"
              value={metricTypeOptions.find((m) => m.value === metricType)}
              onChange={(option) => onChange('metricType', option.value as string)}
              helperText="select metric type"
            />
          </div>
          <div className="flex-1">
            <Autocomplete
              placeholder="Label value"
              options={jobs}
              value={selectedJob}
              onChange={(value) => onChange('selectedJob', value)}
              helperText="select metric label"
            />
          </div>
          <div className="flex-1">
            <Autocomplete
              placeholder="Metric name"
              options={metrics}
              value={selectedMetric}
              onChange={(value) => onChange('selectedMetric', value)}
              helperText="select metric"
            />
          </div>
        </div>
      </div>
      <div className="mt-2 flex-1 flex-row">
        <QueryValidateInput
          placeholder="Select query or write custom query"
          value={currentQuery}
          onAddQuery={onAddQuery}
          onChange={(v) => onChange('currentQuery', v)}
          queries={queries}
          metricType={metricType}
        />
      </div>
    </div>
  )
}
