import type {} from '@mui/x-date-pickers/themeAugmentation'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { createTheme } from '@mui/material'
import MuiFilledInput from './MuiFilledInput'
import MuiFormControl from './MuiFormControl'
import MuiInputBase from './MuiInputBase'
import MuiInputLabel from './MuiInputLabel'
import MuiFormHelperText from './MuiFormHelperText'
import MuiButton from './MuiButton'
import palette from './palette'
import MuiCard from './MuiCard'
import MuiPaper from './MuiPaper'
import MuiPickersDay from './MuiPickersDay'
import MuiTypography from './MuiTypography'
import MuiSvgIcon from './MuiSvgIcon'
import MuiClockNumber from './MuiClockNumber'
import MuiDateCalendar from './MuiDateCalendar'
import MuiYearCalendar from './MuiYearCalendar'
import MuiPickersArrowSwitcher from './MuiPickersArrowSwitcher'
import MuiDataGrid from './MuiDataGrid'

const defaultTheme = createTheme({
  palette,
  typography: {
    allVariants: {
      fontFamily: 'RakutenSans',
    },
  },
  components: {
    // Name of the component
    MuiDataGrid,
    MuiInputBase,
    MuiFilledInput,
    MuiFormControl,
    MuiInputLabel,
    MuiFormHelperText,
    MuiButton,
    MuiCard,
    MuiPaper,
    MuiPickersDay,
    MuiTypography,
    MuiSvgIcon,
    MuiClockNumber,
    MuiDateCalendar,
    MuiYearCalendar,
    MuiPickersArrowSwitcher,
  },
})

export default defaultTheme
