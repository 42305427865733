import { KeyboardEvent, useMemo, useState } from 'react'
import { COLORS } from '../../../themes/default/colors'
import {
  Card,
  Button,
  HelperText,
  LabelInput,
  Spinner,
  Loader,
  Select,
  Tooltip,
} from '../../components'
import { HttpMethods, HeaderType } from '../../store/add-application'
import HeaderInput from './headers-input'
import { Close, EditOutlined } from '../../components/icons'
import { isFormValid, validateField } from '../../utils'
import { SourceStateType } from '../../store/source'
import { InfoOutlined } from '../../components/icons'
import { validationMessages } from '../../consts'
import { QIntervalTooltipList, QTimeoutTooltipList, EntityTooltipList } from '../../consts/tooltips'

export const httpOptions = Object.keys(HttpMethods).map((k) => ({ label: k, value: k }))

export const ADD_EDIT_SOURCE_CARD_TEST_ID = 'ADD_EDIT_SOURCE_CARD_TEST_ID'

interface AddEditSourceCardProps extends SourceStateType {
  onClose: () => void
  onSave: () => void
  onInputChange: (type: string, value: string, check?: boolean) => void
  onHeaderInputChange: (index: number, type: string, value: string) => void
  onTestConnection: () => void
  testConnectionIsLoading: boolean
}

export default function AddEditSourceCard({
  sourceName,
  url,
  headers,
  httpMethod,
  queryInterval,
  queryTimeout,
  isLoading,
  connectionState,
  onClose,
  onSave,
  onTestConnection,
  onHeaderInputChange,
  onInputChange,
  isFetching,
  testConnectionIsLoading,
  sourceType,
  prometheusPrefix,
}: AddEditSourceCardProps) {
  const [showSourceNameInput, setSourceNameInput] = useState<boolean>(false)

  const disableSave = useMemo(
    () =>
      connectionState.status !== 'SUCCESS' ||
      isLoading ||
      !isFormValid(
        {
          url: url,
          queryInterval: queryInterval,
          queryTimeout: queryTimeout,
          sourceName: sourceName,
          prometheusPrefix: prometheusPrefix,
        },
        {
          url: ['required', 'url'],
          queryInterval: ['required', 'min:15', 'max:86400'],
          queryTimeout: ['required', 'min:1', 'max:5'],
          sourceName: ['required', 'entityName'],
          prometheusPrefix: ['required'],
        }
      ),
    [
      connectionState.status,
      url,
      isLoading,
      queryInterval,
      queryTimeout,
      sourceName,
      prometheusPrefix,
    ]
  )

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSourceNameInput(false)
    }
  }

  const isValidName = useMemo(
    () => validateField(sourceName, ['required', 'entityName']),
    [sourceName]
  )

  const isValidUrl = useMemo(() => validateField(url, ['required', 'url']), [url])

  const isValidInterval = useMemo(
    () => validateField(queryInterval, ['required', 'min:15', 'max:86400']),
    [queryInterval]
  )

  const isValidTimeout = useMemo(
    () => validateField(queryTimeout, ['required', 'min:1', 'max:5']),
    [queryTimeout]
  )

  const isValidprometheusPrefix = useMemo(
    () => validateField(prometheusPrefix, ['required']),
    [prometheusPrefix]
  )

  return (
    <Card
      testId={ADD_EDIT_SOURCE_CARD_TEST_ID}
      className="relative flex flex-col mobile:h-[65vh] mobile:w-9/12 tablet_p:h-[46rem] tablet_p:w-9/12 tablet_l:h-[40rem] tablet_l:w-7/12 laptop:h-[46rem] laptop:w-5/12 desktop:h-[48rem] desktop:w-5/12"
    >
      {!isLoading ? (
        <button
          role="cancelButton"
          className="absolute right-2.5 top-2.5 text-primary"
          onClick={onClose}
        >
          <Close className="w-5 pt-3 " color={COLORS.PRIMARY} />
        </button>
      ) : null}
      {isFetching ? (
        <Loader>
          <HelperText size="base" text={'Fetching data...'} />
        </Loader>
      ) : (
        <>
          <div className="flex-1 flex-col items-center overflow-y-auto px-8 pt-8">
            <div className="flex flex-col">
              {showSourceNameInput && (
                <>
                  <LabelInput
                    testId={`${ADD_EDIT_SOURCE_CARD_TEST_ID}_SOURCE_NAME`}
                    required
                    labelClasses="flex"
                    error={isValidName !== ''}
                    label="Source name"
                    toolTipMessage={<EntityTooltipList entityType="source name" />}
                    toolTipPlacement="right-start"
                    placeholder="Enter Source Name"
                    value={sourceName}
                    onChange={(e) => onInputChange('sourceName', e.target.value)}
                    errorMessage={isValidName ? validationMessages.sourceName[isValidName] : ''}
                    onKeyDown={handleKeyPress}
                  />
                  <HelperText text="Reconfigure the source" />
                </>
              )}
              {!showSourceNameInput && (
                <div className="flex flex-row items-center text-xl text-white">
                  <div className="mr-2 flex max-w-[20rem] truncate">
                    <Tooltip className="border-1 w-auto whitespace-normal" title={sourceName}>
                      <span className=" font-RakutenSansBold text-base text-white">
                        {sourceName}
                      </span>
                    </Tooltip>
                  </div>
                  via <span className="pl-1 capitalize">{sourceType}</span>
                  <EditOutlined
                    testId={`${ADD_EDIT_SOURCE_CARD_TEST_ID}_EDIT_BUTTON`}
                    className="ml-1 h-5 w-5"
                    onClick={() => setSourceNameInput(true)}
                    color={COLORS.PRIMARY}
                  />
                </div>
              )}
            </div>
            <div className="mt-2 flex flex-col">
              <div className="text-l flex text-white">HTTP</div>
              <div className="mt-2 flex-1">
                <LabelInput
                  testId={`${ADD_EDIT_SOURCE_CARD_TEST_ID}_URL`}
                  labelClasses="flex"
                  required
                  label="Url"
                  error={isValidUrl !== ''}
                  placeholder="enter an accessible URL"
                  value={url}
                  onChange={(e) => onInputChange('url', e.target.value)}
                  errorMessage={isValidUrl ? validationMessages.httpUrl[isValidUrl] : ''}
                />
              </div>
              {sourceType === 'cortex' && (
                <LabelInput
                  required
                  label="Prometheus prefix"
                  className="rounded-sm"
                  placeholder="prometheus prefix"
                  value={prometheusPrefix}
                  onChange={(e) => onInputChange('prometheusPrefix', e.target.value)}
                  error={isValidprometheusPrefix !== ''}
                  errorMessage={
                    isValidprometheusPrefix
                      ? validationMessages.PrometheusPrefix[isValidprometheusPrefix]
                      : ''
                  }
                />
              )}
            </div>
            <div className="mt-2 flex flex-col">
              <div className="text-l flex text-white">Method and Auth</div>
              <div className="mt-2 w-1/6 mobile:w-3/12 tablet_p:w-3/12 tablet_l:w-2/12 laptop:w-3/12 desktop:w-2/12">
                <Select
                  testId={`${ADD_EDIT_SOURCE_CARD_TEST_ID}_METHOD`}
                  labelClasses="text-white text-xs"
                  label="HTTP Method"
                  options={httpOptions}
                  value={httpOptions.find((h) => h.value === httpMethod)}
                  onChange={(option) => onInputChange('httpMethod', option.value as string)}
                />
              </div>
              <div className="mt-2">
                {headers.map((header: HeaderType, index: number) => (
                  <HeaderInput
                    key={index}
                    name={header.name}
                    value={header.value}
                    onChange={(name: string, value: string) =>
                      onHeaderInputChange(index, name, value)
                    }
                  />
                ))}
              </div>
            </div>
            <div className="mt-5 flex flex-row">
              <div className="flex-2">
                <Button
                  testId={`${ADD_EDIT_SOURCE_CARD_TEST_ID}_TEST_BUTTON`}
                  fullWidth
                  variant="outlined"
                  onClick={onTestConnection}
                  disabled={isValidUrl !== '' || isValidprometheusPrefix !== ''}
                >
                  Test connection
                </Button>
              </div>
              <div className="flex w-8/12 flex-row items-center text-sm">
                {testConnectionIsLoading && <Spinner className="h-6 w-6" />}
                {connectionState.status !== null && !testConnectionIsLoading && (
                  <>
                    <span
                      className={`mx-2 rounded border px-2 text-base ${
                        connectionState.status === 'SUCCESS'
                          ? 'border-green-900 bg-green-300 text-green-900'
                          : 'border-red-900 bg-red-300 text-red-900'
                      }`}
                    >
                      {connectionState.status}
                    </span>
                    {connectionState.status === 'SUCCESS' ? (
                      <span className="text-primary"> Test connection passed </span>
                    ) : (
                      <span className="text-red-600"> Test connection failed </span>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-center">
              <div className="my-5 w-5/6 bg-primary p-[0.5px]" />
            </div>
            <div className="flex flex-col">
              <div className="text-l flex text-white">Query config</div>
              <div className="mt-2 flex flex-row">
                <div className="flex-1">
                  <LabelInput
                    testId={`${ADD_EDIT_SOURCE_CARD_TEST_ID}_QUERY_INTERVAL`}
                    required
                    type="number"
                    error={isValidInterval !== ''}
                    label="Query interval (seconds)"
                    toolTipMessage={<QIntervalTooltipList />}
                    toolTipPlacement="top"
                    placeholder="Interval in seconds"
                    value={queryInterval}
                    onChange={(e) => onInputChange('queryInterval', e.target.value, true)}
                    errorMessage={
                      isValidInterval ? validationMessages.queryInterval[isValidInterval] : ''
                    }
                    disabled={true}
                  />
                </div>
                <div className="w-10"></div>
                <div className="flex-1">
                  <LabelInput
                    testId={`${ADD_EDIT_SOURCE_CARD_TEST_ID}_QUERY_TIMEOUT`}
                    type="number"
                    required
                    error={isValidTimeout !== ''}
                    label="Query timeout (seconds)"
                    toolTipMessage={<QTimeoutTooltipList />}
                    toolTipPlacement="top"
                    placeholder="time in seconds"
                    value={queryTimeout}
                    onChange={(e) => onInputChange('queryTimeout', e.target.value, true)}
                    errorMessage={
                      isValidTimeout ? validationMessages.queryTimeout[isValidTimeout] : ''
                    }
                  />
                </div>
                {/* <div className="flex-1">// commented for future use
                  <LabelInput
                    inputStyles={{
                      '& .Mui-disabled': { color: 'white !important' },
                      '& input.MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
                        '-webkit-text-fill-color': 'white',
                      },
                    }}
                    type="number"
                    readOnly
                    label="Scrape steps (seconds)"
                    value={scrapeSteps}
                    disabled={true}
                  />
                </div> */}
              </div>
              {/* <div className="mt-2 flex flex-row">
                <div className="flex-1">
                  <LabelInput
                    type="number"
                    error={validateField(queryTimeout, ['required', 'min:1', 'max:5']) !== ''}
                    label={
                      <LabelTooltip
                        title="Connection will be considered timed out if a response is not received in this duration"
                        info="Query timeout (seconds)"
                      />
                    }
                    placeholder="time in seconds"
                    value={queryTimeout}
                    onChange={(e) => onInputChange('queryTimeout', e.target.value, true)}
                    errorMessage={validateField(queryTimeout, ['required', 'min:1', 'max:5'])}
                  />
                </div>
                <div className="w-10"></div>
                <div className="flex-1"></div>
              </div> */}
            </div>
          </div>
          <div className="flex flex-col items-end px-8">
            <div className="flex w-2/12 justify-center">
              <Button
                testId={`${ADD_EDIT_SOURCE_CARD_TEST_ID}_SAVE`}
                fullWidth
                variant="contained"
                disabled={disableSave}
                onClick={onSave}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </Card>
  )
}
