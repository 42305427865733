import { MutableRefObject, useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideClick(ref: MutableRefObject<any>, action: () => void) {
  useEffect(() => {
    /**
     * Call the action if clicked on outside of given ref
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        action()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [action, ref])
}
