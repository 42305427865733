/** This file contains all dashboard level routes */
import { RouteObject } from 'react-router-dom'
import { SOURCES_ROUTES, SOURCES_ROUTES_NAMES } from './route-names'
import Anomalies from '../pages/dashboard/source/anomalies'
import Behaviour from '../pages/dashboard/source/behaviour'
import ViewMultivariateAnalysis from '../pages/dashboard/source/anomalies/view-multivariate-analysis'
import AnomaliesView from '../pages/dashboard/source/anomalies/anomalies-view'
import { anomaliesRoutes } from './anomalies-routes'

export const sourcesRoutes: RouteObject[] = [
  {
    id: SOURCES_ROUTES_NAMES.ANOMALIES,
    path: SOURCES_ROUTES.ANOMALIES,
    element: <Anomalies />,
  },
  {
    id: SOURCES_ROUTES_NAMES.ANOMALIES_VIEW,
    path: SOURCES_ROUTES.ANOMALIES_VIEW,
    element: <AnomaliesView />,
    children: anomaliesRoutes,
  },
  {
    id: SOURCES_ROUTES_NAMES.BEHAVIOUR,
    path: SOURCES_ROUTES.BEHAVIOUR,
    element: <Behaviour />,
  },
  {
    id: SOURCES_ROUTES_NAMES.VIEW_MULTIVARIATE,
    path: SOURCES_ROUTES.VIEW_MULTIVARIATE,
    element: <ViewMultivariateAnalysis />,
  },
  {
    id: SOURCES_ROUTES_NAMES.APP_BEHAVIOUR,
    path: SOURCES_ROUTES.APP_BEHAVIOUR,
    element: <Behaviour />,
  },
  {
    id: SOURCES_ROUTES_NAMES.MULTIVARIATE_BEHAVIOUR,
    path: SOURCES_ROUTES.MULTIVARIATE_BEHAVIOUR,
    element: <Behaviour />,
  },
]
