import _ from 'underscore'
import { QueryType } from './types'

export const mapStringArrayToOptions = (arr: string[]) => arr.map((a) => ({ label: a, value: a }))

export const mapQueriesForBody = (queries: QueryType[]) =>
  queries.map((q) => ({
    id: q.id,
    queryString: q.queryString,
    metricType: q.metricType,
    isDeleted: q.isDeleted,
    unit: q.unit,
  }))

export const mapMetricsToOptions = (metrics: { __name__: string }[]) =>
  _.uniq(metrics, (p) => p.__name__).map((p) => ({
    label: p.__name__,
    value: p.__name__,
  }))
