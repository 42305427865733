import { COLORS } from './colors'

const MuiClockNumber = {
  styleOverrides: {
    root: {
      color: COLORS.WHITE,
    },
  },
}

export default MuiClockNumber
