import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const LINK_ICON_TEST_ID = 'LINK_ICON_TEST_ID'

export const LinkIcon = ({
  testId = LINK_ICON_TEST_ID,
  className,
  color = COLORS.PRIMARY,
  onClick,
}: IconProps) => {
  return (
    <div className={className} data-testid={testId} onClick={onClick}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.5921 16.6969C14.8944 17.1011 15.2801 17.4355 15.723 17.6775C16.166 17.9195 16.6558 18.0634 17.1592 18.0995C17.6626 18.1355 18.1679 18.0629 18.6408 17.8865C19.1137 17.7101 19.5432 17.434 19.9 17.0771L22.0119 14.9652C22.6531 14.3013 23.0079 13.4122 22.9999 12.4893C22.9918 11.5664 22.6217 10.6836 21.9691 10.0309C21.3164 9.37833 20.4336 9.00815 19.5107 9.00013C18.5878 8.99211 17.6987 9.3469 17.0348 9.98807L15.824 11.1919M17.4079 15.289C17.1056 14.8848 16.7199 14.5504 16.277 14.3084C15.834 14.0664 15.3442 13.9225 14.8408 13.8864C14.3374 13.8504 13.8321 13.923 13.3592 14.0994C12.8863 14.2758 12.4568 14.5519 12.1 14.9088L9.98807 17.0208C9.3469 17.6846 8.99211 18.5737 9.00013 19.4966C9.00815 20.4195 9.37833 21.3024 10.0309 21.955C10.6836 22.6076 11.5664 22.9778 12.4893 22.9858C13.4122 22.9938 14.3013 22.639 14.9652 21.9979L16.169 20.7941"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}
