import { useRef, useState } from 'react'
import { ProfileIcon, Usericon } from '../icons'
import { useOutsideClick } from '../../hooks'
import { COLORS } from '../../../themes/default/colors'

export const USER_MENU_TEST_ID = 'USER_MENU_TEST_ID'

export interface MenuType {
  id: string
  title: string | React.ReactNode
  onClick?: () => void
}

export interface UserMenuProps {
  items: MenuType[]
  testId?: string
}

export default function UserMenu({ testId = USER_MENU_TEST_ID, items }: UserMenuProps) {
  const userMenuRef = useRef<HTMLDivElement>(null)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const onUserMenuClick = () => setShowUserMenu(!showUserMenu)

  useOutsideClick(userMenuRef, () => setShowUserMenu(false))

  return (
    <div
      ref={userMenuRef}
      data-testid={testId}
      className="relative flex flex-shrink-0 items-center p-2 "
    >
      <ProfileIcon
        className="w-7 cursor-pointer"
        color={COLORS.PRIMARY}
        onClick={onUserMenuClick}
      />
      <span className="sr-only">User menu</span>
      <div
        className={`${
          !showUserMenu ? 'hidden' : ''
        } absolute right-1.5 top-10 z-30 mt-2 w-52 origin-bottom-left cursor-pointer rounded-sm bg-secondary py-1 shadow-lg focus:outline-none`}
        role="menu"
        aria-orientation="vertical"
        aria-label="user menu"
      >
        {items.map((menu, index) => (
          <div
            key={menu.id}
            className={`block px-3 py-2 text-sm text-white ${index > 0 && 'hover:bg-primary20'} ${
              index < items.length - 1 && 'border-b-[1px] border-warm_grey'
            }`}
            role="menuitem"
            onClick={() => {
              onUserMenuClick()
              menu && menu.onClick && menu.onClick()
            }}
          >
            {menu.title}
          </div>
        ))}
      </div>
    </div>
  )
}
