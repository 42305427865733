import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  changeSourceHeaders,
  changeSourceValues,
  resetState,
  changeConnectionState,
  setSourceValues,
  sourceValuesSelector,
  useAddSourceMutation,
  useLazyGetSourceConnectionQuery,
  useTestConnectionMutation,
  useUpdateSourceMutation,
} from '../../../store/source'
import DefineSourceType from './define-source-type'
import SelectSourceType from './select-source-type'
import SourceForm from './source-form'
import { Breadcrumb, Button } from '../../../components'
import { validateField } from '../../../utils'
import { DASHBOARD_ROUTES } from '../../../routes'
import { useSourceTabsContext } from '../../../providers'

export default function AddEditSource() {
  const navigate = useNavigate()
  const { sourceId = '' } = useParams()
  const dispatch = useAppDispatch()
  const source = useAppSelector(sourceValuesSelector)
  const { currentSourceTab, setSourceDetailsValues, setSourceTabDetailsHeader, removeSourceTab } =
    useSourceTabsContext()
  const [resetError, setResetError] = useState({ sourceName: false })
  function ResetError(label: any) {
    setResetError({ sourceName: false })
  }
  const [
    testSourceUrl,
    {
      data: connectionState = { status: '' },
      isLoading: isTestingConnection,
      reset: resetTestConnectionState,
    },
  ] = useTestConnectionMutation()
  const [addSource, { isLoading: isSubmitting }] = useAddSourceMutation()
  const [updateSource, { isLoading: isUpdating }] = useUpdateSourceMutation()
  const [getConnection] = useLazyGetSourceConnectionQuery()

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      dispatch(resetState())
    })
    return () => {
      window.removeEventListener('beforeunload', () => {
        dispatch(resetState())
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (sourceId !== '') {
      dispatch(setSourceValues(currentSourceTab.details))
      resetTestConnectionState()
      getConnection(sourceId, true)
    }
  }, [sourceId])

  const handleInputChange = (type: string, value: string, check?: boolean) => {
    if (check) {
      Number(value) >= 0 && dispatch(changeSourceValues({ type, value }))
    } else {
      dispatch(changeSourceValues({ type, value }))
    }

    if (type === 'url' || type === 'httpMethod' || type === 'prometheusPrefix') {
      resetTestConnectionState()
    }
    if (sourceId !== '') {
      dispatch(setSourceDetailsValues({ sourceId, type, value }))
    }
  }

  const handleHeaderInputChange = (index: number, type: string, value: string) => {
    dispatch(changeSourceHeaders({ index, type, value }))
    if (sourceId !== '') {
      dispatch(setSourceTabDetailsHeader({ sourceId, index, type, value }))
    }
  }

  const handleTestConnection = async () => {
    const res: any = await testSourceUrl({
      url: source.url,
      sourceType: source.sourceType,
      prometheusPrefix: source.prometheusPrefix,
    })
    dispatch(
      changeConnectionState({
        status: res.data.status,
        message: res.data.message,
        isLoading: false,
      })
    )
  }

  const handleCancelClick = () => {
    dispatch(resetState())
    setResetError({ sourceName: true })
  }

  const handleSourceSelect = (value: string) => {
    handleInputChange('sourceType', source.sourceType === value ? '' : value)
    handleInputChange('url', '')
    handleInputChange('prometheusPrefix', 'prometheus')
    dispatch(
      changeConnectionState({
        status: '',
        message: '',
      })
    )
  }

  const handleCreateClick = async () => {
    try {
      if (sourceId === '') {
        await addSource(source).unwrap()
      } else {
        await updateSource(source).unwrap()
      }
      removeSourceTab(currentSourceTab.id)
      navigate(`/${DASHBOARD_ROUTES.HOME}`, { replace: true })
      dispatch(resetState())
    } catch (e) {}
  }

  const isValidName = useMemo(
    () => validateField(source.sourceName, ['required', 'entityName']),
    [source.sourceName]
  )

  const isValidUrl = validateField(source.url, ['required', 'url'])

  const isValidInterval = useMemo(
    () => validateField(source.queryInterval, ['required', 'min:60', 'max:86400']),
    [source.queryInterval]
  )

  const isValidTimeout = useMemo(
    () => validateField(source.queryTimeout, ['required', 'min:1', 'max:5']),
    [source.queryTimeout]
  )

  const isValidprometheusPrefix = useMemo(
    () => validateField(source.prometheusPrefix, ['required']),
    [source.prometheusPrefix]
  )

  const disableSave = useMemo(
    () =>
      source.connectionState.status !== 'SUCCESS' ||
      isSubmitting ||
      isUpdating ||
      isValidUrl !== '' ||
      isValidInterval !== '' ||
      isValidTimeout !== '' ||
      isValidName !== '' ||
      isValidprometheusPrefix !== '',
    [
      source.connectionState.status,
      isSubmitting,
      isUpdating,
      isValidUrl,
      isValidInterval,
      isValidTimeout,
      isValidName,
      isValidprometheusPrefix,
    ]
  )

  const breadcrumbs = [
    { routeName: 'Home', onClick: () => null },
    { routeName: sourceId === '' ? 'New source' : source.sourceType, onClick: () => null },
  ]

  return (
    <div className="flex w-full flex-col bg-light_grey">
      <Breadcrumb items={breadcrumbs} containerClasses="p-2" />
      <div className="flex h-[95vh] w-full flex-col overflow-y-scroll  px-[25%]">
        <DefineSourceType
          isValidName={isValidName}
          sourceName={source.sourceName}
          onSourceNameChange={(value) => handleInputChange('sourceName', value)}
          isEditModeOn={sourceId !== ''}
          sourceType={source.sourceType}
          clickedCancel={resetError.sourceName}
          resetCancel={ResetError}
        />
        <SelectSourceType selected={source.sourceType} onSelect={handleSourceSelect} />
        <AnimatePresence>
          <SourceForm
            {...source}
            connectionStatus={source.connectionState.status}
            isTestingConnection={isTestingConnection}
            onInputChange={handleInputChange}
            onTestConnection={handleTestConnection}
            onHeaderInputChange={handleHeaderInputChange}
            isValidUrl={isValidUrl}
            isValidInterval={isValidInterval}
            isValidTimeout={isValidTimeout}
            isValidprometheusPrefix={isValidprometheusPrefix}
            disableUrl={sourceId !== ''}
          />
        </AnimatePresence>
        {source.sourceType !== '' ? (
          <div className="flex h-full w-full flex-row items-end justify-between gap-3 laptop:pb-11 desktop:pb-9">
            <Button
              className="w-full !border-2"
              buttonTextClass="font-RakutenSansBold !text-sm whitespace-nowrap"
              variant="outlined"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              className="w-full !border-2"
              buttonTextClass="font-RakutenSansBold !text-sm whitespace-nowrap"
              variant="outlined"
              disabled={disableSave}
              onClick={handleCreateClick}
            >
              {sourceId === '' ? 'Create' : 'Save'}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
