import { useMemo, useState } from 'react'
import DefineSearchSpace from './define-search-space'
import { validateField } from '../../../utils'

export default function SearchSpace() {
  const [searchSpaceName, changeSearchSpaceName] = useState('')
  const isValidName = useMemo(
    () => validateField(searchSpaceName, ['required', 'entityName']),
    [searchSpaceName]
  )
  return (
    <div className="flex w-full flex-col bg-light_grey px-32 py-16">
      <DefineSearchSpace
        searchSpaceName={searchSpaceName}
        onSearchSpaceNameChange={(value) => changeSearchSpaceName(value)}
        isValidName={isValidName}
      />
    </div>
  )
}
