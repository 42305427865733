import { COLORS } from '../../../themes/default/colors'
import { IconProps } from './types'

export const SEARCH_ICON_TEST_ID = 'SEARCH_ICON_TEST_ID'

export const SearchIcon = ({
  testId = SEARCH_ICON_TEST_ID,
  className,
  color = COLORS.PRIMARY,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.372 9.83047C12.2908 9.21656 13.371 8.88889 14.4761 8.88889C15.9579 8.88889 17.379 9.47752 18.4268 10.5253C19.4746 11.5731 20.0633 12.9941 20.0633 14.4759C20.0633 15.5809 19.7356 16.6611 19.1217 17.5799C18.5077 18.4987 17.6351 19.2148 16.6142 19.6376C15.5933 20.0605 14.4699 20.1712 13.3861 19.9556C12.3023 19.74 11.3067 19.2079 10.5253 18.4265C9.74396 17.6452 9.21183 16.6497 8.99624 15.5659C8.78066 14.4821 8.89131 13.3587 9.31419 12.3378C9.73707 11.317 10.4532 10.4444 11.372 9.83047ZM11.9364 18.2767C12.6881 18.779 13.572 19.0471 14.4761 19.0471C15.6885 19.0471 16.8512 18.5655 17.7085 17.7082C18.5658 16.851 19.0474 15.6883 19.0474 14.4759C19.0474 13.5718 18.7793 12.688 18.277 11.9363C17.7747 11.1846 17.0608 10.5987 16.2255 10.2527C15.3902 9.90669 14.471 9.81616 13.5843 9.99254C12.6975 10.1689 11.883 10.6043 11.2437 11.2436C10.6043 11.8829 10.169 12.6974 9.99258 13.5841C9.81619 14.4708 9.90672 15.39 10.2527 16.2252C10.5987 17.0605 11.1846 17.7744 11.9364 18.2767ZM18.7682 19.4846C19.0244 19.264 19.2638 19.0246 19.4844 18.7685L23.111 22.395L22.3948 23.1111L18.7682 19.4846Z"
        fill={color}
      />
    </svg>
  </div>
)
