import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { Close, HomeFilled, PlusFilled } from '../icons'
import { TabNavbarProps } from './types'
import MenuDropdown from '../menu-dropdown'
import { ChevronUp, BellOutlined } from '../icons'
import { COLORS } from '../../../themes/default/colors'
import { alertRoutes } from '../../routes/alert-routes'
import { AlertRulesTypes, SourceType } from '../../store/dashboard'
import { DASHBOARD_ROUTES } from '../../routes'

export const TAB_NAVBAR_TEST_ID = 'TAB_NAVBAR_TEST_ID' // ID to use for testing purposes only

export default function TabNavbar({
  showRightSection,
  currentPath,
  brandLogo,
  brandName,
  brandSubName,
  rightSection,
  tabs,
  homePath,
  plusIconPath,
  handleAlerts,
  onCloseClick,
  onSwapSource,
}: TabNavbarProps) {
  const routes = tabs.slice(5, tabs.length)?.map((item) => ({
    label: item.name.toString(),
    action: () => onSwapSource(item.id), // Wrap in a function
    id: item.id,
    closeItem: () => onCloseClick(item.id),
  }))

  const alertRules: AlertRulesTypes = {
    id: 'alerts',
    name: 'Alerts',
  }
  return (
    <nav data-testid={TAB_NAVBAR_TEST_ID} className="flex w-full flex-row bg-black  text-white">
      <div className="flex w-full justify-between">
        <div className="desktop:[400px] flex flex-row items-center px-2 py-1 laptop:w-[270px]">
          <div className="px-2">{brandLogo}</div>
          <div className="flex flex-col pl-1 pr-2">
            <span className="text-xs">{brandSubName}</span>
            <span className="w-max text-sm font-semibold">{brandName}</span>
          </div>
        </div>
        {showRightSection && (
          <div className="items-between flex w-full grow flex-row justify-between">
            <div className="flex grow flex-row items-center">
              <NavLink
                end
                to={homePath}
                className={({ isActive }) =>
                  `max-w-20 flex h-full cursor-pointer items-center justify-center border-l-[1px] border-x-secondary text-primary ${
                    isActive && 'bg-primary bg-opacity-50'
                  }`
                }
              >
                <HomeFilled className="m-2 w-6" pathClasses="fill-primary" />
              </NavLink>
              {tabs.slice(0, 5).map(({ id, name, path, isActive }, i) => (
                <NavLink
                  key={id}
                  to={path}
                  className={() =>
                    `max-w-20 flex h-full cursor-pointer items-center justify-center border-l-[1px] border-x-secondary px-3 text-sm text-primary ${
                      isActive && 'bg-primary bg-opacity-30'
                    } overflow-hidden whitespace-nowrap`
                  }
                  title={name?.toString().length > 25 ? name?.toString() : undefined} // Set title attribute only if characters > 25
                >
                  {name?.toString().length > 25 ? name?.toString().substring(0, 25) + '...' : name}
                  <Close
                    className="ml-5 w-3"
                    pathClasses="fill-primary"
                    onClick={(e) => {
                      e.preventDefault()
                      onCloseClick(id)
                    }}
                  />
                </NavLink>
              ))}
            </div>

            {/* <NavLink
                end
                to={plusIconPath}
                className={({ isActive }) =>
                  `max-w-20 flex h-full cursor-pointer items-center justify-center border-l-[1px] border-x-secondary px-2 text-primary ${
                    isActive && 'bg-primary bg-opacity-50'
                  }`
                }
              >
                <PlusFilled className="w-5" pathClasses="fill-primary" />
              </NavLink> */}
            {/* To be implemented after prod 1.0   */}

            {tabs.length > 5 && (
              <div>
                <MenuDropdown
                  menuTextClasses="mt-2.5"
                  itemsClasses="z-20 absolute  transform border-none rounded-md w-28 block truncate rounded-sm laptop:w-52 desktop:w-68 !bg-dark2"
                  menuText={() => (
                    <div className="flex flex-row items-center justify-center gap-1 text-sm text-primary">
                      More{' '}
                    </div>
                  )}
                  menuItems={routes}
                />
              </div>
            )}
          </div>
        )}

        {showRightSection && (
          <div className="w-34 flex grow flex-row items-center justify-end gap-2 pl-5 hover:cursor-pointer">
            <div
              className={`flex h-full w-full flex-row items-center px-3 py-2 ${
                currentPath === 'alerts' && 'bg-primary bg-opacity-50'
              }`}
            >
              <div className="flex flex-col items-center gap-y-1">
                <BellOutlined
                  color="orange"
                  className="h-5 w-5 "
                  onClick={() => {
                    handleAlerts(alertRules)
                  }}
                />
              </div>
            </div>
            {rightSection}
          </div>
        )}
      </div>
    </nav>
  )
}
