import { HelperText } from '../../components'
import { APP_NAME } from '../../consts/version'

export default function MetricQueryCardTitle({
  sourceName,
  sourceType,
}: {
  sourceName: string
  sourceType: string
}) {
  return (
    <>
      <div className="text-xl text-white">
        {sourceName} via <span className="capitalize">{sourceType}</span>
      </div>
      <HelperText text={` Add the metrics for ${APP_NAME} to consume, consolidate and process`} />
    </>
  )
}
