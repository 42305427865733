import { IconProps } from './types'

export const ARROW_DOWN_TEST_ID = 'ARROW_DOWN_TEST_ID'

export const ArrowDown = ({
  testId = ARROW_DOWN_TEST_ID,
  className,
  color,
  strokeWidth = '0',
  strokeColor = color,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill={color}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    >
      <path d="m28 18-1.41-1.41L17 26.17V2h-2v24.17l-9.59-9.58L4 18l12 12 12-12z" />
    </svg>
  </div>
)
