import { IconProps } from './types'

export const CARET_ICON_TEST_ID = 'CARET_ICON_TEST_ID'

export const Caret = ({ testId = CARET_ICON_TEST_ID, className, transform, color }: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg" transform={transform} fill={color}>
      <path d="M6.00001 0L12 8H-3.05176e-05L6.00001 0Z" />
    </svg>
  </div>
)
