import { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { Alert, TabNavbar, UserMenu, Toast } from '../../app/components'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { userAuthSubject$ } from '../../app/observables'
import { authSelector } from '../../app/store/auth'
import { DASHBOARD_ROUTES, SOURCES_ROUTES } from '../../app/routes'
import { alertSelector, setAlert } from '../../app/store/alert'
import { LogoutIcon, ProfileIcon, RakutenLogo } from '../../app/components/icons'
import { deleteSelectedBreach } from '../../app/store/anomalies'
import { splitApi } from '../../app/store/api'
import { APP_NAME } from '../../app/consts/version'
import { COLORS } from '../../themes/default/colors'
import { useSourceTabsContext } from '../providers'
import { TabType } from '../components/types'
import { resetState as resetSourceState } from '../store/source'
import { AlertRulesTypes, SourceType } from '../store/dashboard'
import { SourceTabType } from '../providers'
import { changeCurrentSource } from '../store/dashboard'
import { changeCurrentSourceId, setShowEditApplicationModal } from '../store/anomaly-detection'
import { resetApplicationState } from '../store/application'

export const APP_TEST_ID = 'APP_TEST_ID'

export default function Pages() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const user = useAppSelector(authSelector)
  const alert = useAppSelector(alertSelector)
  const { sourceTabs, removeSourceTab, swapSourceTab, addSourceTab } = useSourceTabsContext()
  const allowedRoutes = useMemo(
    () => [DASHBOARD_ROUTES.HOME, DASHBOARD_ROUTES.ALERTS_RULES, DASHBOARD_ROUTES.ALL_SOURCES],
    []
  )
  const [showToast, setShowToast] = useState('')
  const handleLogout = () => {
    userAuthSubject$.next(false)
    dispatch(splitApi.util.resetApiState())
  }

  const onAlertsIconClick = (source: AlertRulesTypes) => {
    navigate(`/${DASHBOARD_ROUTES.ALERTS_RULES}`)
  }

  const handleAlertClose = () => {
    dispatch(setAlert({ message: '' }))
  }

  const handleCloseClick = (id: string) => {
    dispatch(resetApplicationState()) && dispatch(setShowEditApplicationModal(false))
    removeSourceTab(id)
    if (id === 'add') {
      dispatch(resetSourceState())
    }
    dispatch(deleteSelectedBreach(id))
    navigate(`/${DASHBOARD_ROUTES.HOME}`)
  }

  useEffect(() => {
    const sIdFromPathname = pathname.split('/')[3]
    const sIdfromSTabs = sourceTabs.some((tab) => tab.id === sIdFromPathname)
    if (pathname.includes('dashboard/sources')) {
      if (!sIdfromSTabs) {
        navigate(`/${DASHBOARD_ROUTES.HOME}`)
      }
    }
  }, [pathname])

  const handleSwapTabs = (sourceId: string) => {
    swapSourceTab(sourceId)
    dispatch(changeCurrentSourceId(sourceId))
    navigate(`/dashboard/${DASHBOARD_ROUTES.SOURCES}/${sourceId}/${SOURCES_ROUTES.ANOMALIES}`)
  }
  const userMenu = useMemo(
    () => [
      {
        id: 'user_info',
        title: (
          <div className="flex h-14 flex-row items-center justify-start pb-1.5">
            <div className="mr-4 flex flex-col">
              <ProfileIcon className="w-9 hover:fill-primary" color={COLORS.PRIMARY} />
              <span className="sr-only">User menu</span>
            </div>
            <div className="flex flex-col justify-between">
              <div className="flex flex-row capitalize text-primary">{user.name.split('@', 1)}</div>
              <div className="flex flex-row text-primary">{user.orgName}</div>
              <div className="flex flex-row text-warm_grey">{user.tenantName}</div>
            </div>
          </div>
        ),
      },
      {
        id: 'sign_out',
        title: (
          <div className="flex flex-row items-center justify-start">
            <span>
              <LogoutIcon className="mr-1 h-7 w-7" />
            </span>
            Logout
          </div>
        ),
        onClick: handleLogout,
      },
    ],
    [user]
  )

  return (
    <div
      className="flex h-screen overflow-hidden bg-dark2 font-RakutenSans antialiased"
      data-testid={APP_TEST_ID}
    >
      <Toast
        vertical="bottom"
        horizontal="center"
        open={showToast.length > 0}
        message={showToast}
      />
      <div className="relative flex flex-1 flex-col">
        {/* Navbar area */}
        <TabNavbar
          showRightSection={allowedRoutes.includes(pathname.split('/')[1])}
          currentPath={pathname.split('/')[1]}
          brandLogo={<RakutenLogo className="w-16" />}
          brandName={APP_NAME}
          brandSubName="SixthSense"
          tabs={sourceTabs as unknown as TabType[]}
          homePath={`/${DASHBOARD_ROUTES.HOME}`}
          onCloseClick={handleCloseClick}
          onSwapSource={handleSwapTabs}
          plusIconPath={`/${DASHBOARD_ROUTES.SOURCES}`}
          rightSection={<UserMenu items={userMenu} />}
          handleAlerts={onAlertsIconClick}
        />
        <div className="flex h-0 flex-1 flex-row !overflow-auto">
          <div className="flex flex-1 flex-col">
            {/* Content area */}
            <main className="max-w-9xl flex w-full flex-grow">
              {alert.message.length > 0 && (
                <Alert
                  onClose={handleAlertClose}
                  severity={alert.severity}
                  message={alert.message}
                  className="mt-10"
                />
              )}
              <AnimatePresence>
                <Outlet />
              </AnimatePresence>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}
