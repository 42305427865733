import { IconProps } from './types'
import { COLORS } from '../../../themes/default/colors'

export const EYE_FILLED_TEST_ID = 'EYE_FILLED_TEST_ID'
export const EYE_BAN_FILLED_TEST_ID = 'EYE_BAN_FILLED_TEST_ID'

export const EyeFilled = ({
  testId = EYE_FILLED_TEST_ID,
  className,
  color = COLORS.WARM,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={color}
        path="evenodd"
        d="M16,6C6,6,2,16,2,16S6,26,16,26,30,16,30,16,26,6,16,6Zm0,14a4,4,0,1,1,4-4A4,4,0,0,1,16,20Z"
      />
    </svg>
  </div>
)

export const EyeBanFilled = ({
  testId = EYE_BAN_FILLED_TEST_ID,
  className,
  color = COLORS.WARM,
}: IconProps) => (
  <div data-testid={testId} className={className}>
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        d="M24.5 23.09A18.93 18.93 0 0 0 30 16S26 6 16 6a13.62 13.62 0 0 0-6.8 1.78l-6-6-1.4 1.43 5.7 5.7A18.93 18.93 0 0 0 2 16s4 10 14 10a13.62 13.62 0 0 0 6.8-1.78l6 6 1.41-1.41ZM16 20a4 4 0 0 1-4-4 4 4 0 0 1 .57-2L18 19.43a3.86 3.86 0 0 1-2 .57Zm3.43-2L14 12.57a4 4 0 0 1 2-.57 4 4 0 0 1 4 4 3.86 3.86 0 0 1-.57 2Z"
        fill={color}
      />
    </svg>
  </div>
)
