import moment from 'moment'
import { AbsoluteDateType } from './types'

const timeUnits: Array<keyof AbsoluteDateType> = ['hour', 'minutes', 'seconds']

export const checkIfDateisCurrent = (aDate: number) => {
  return moment(aDate).format('YYYY-MM-DD HH:mm') === moment().format('YYYY-MM-DD HH:mm')
}

export const isValidRange = (
  fromDate: AbsoluteDateType,
  toDate: AbsoluteDateType,
  maxAllowedDays: number
) => {
  const daas = new Date(toDate.date)
  if (moment(fromDate.date).format('YYYY-MM-DD') === moment(toDate.date).format('YYYY-MM-DD')) {
    const fromTimeInSeconds = fromDate.hour * 3600 + fromDate.minutes * 60 + fromDate.seconds
    const toTimeInSeconds = toDate.hour * 3600 + toDate.minutes * 60 + toDate.seconds
    if (fromTimeInSeconds > toTimeInSeconds) {
      return false
    }
  }

  if (moment(fromDate.date).add(maxAllowedDays, 'days').isSame(toDate.date)) {
    if (toDate.hour > fromDate.hour) return false
    if (fromDate.hour == toDate.hour) {
      if (toDate.minutes > fromDate.minutes) return false
    }
  }

  if (daas.getDate() === moment().date().valueOf()) {
    if (toDate.hour > moment().hours().valueOf()) {
      return false
    }
    if (
      toDate.hour == moment().hours().valueOf() &&
      toDate.minutes.valueOf() > moment().minutes().valueOf()
    ) {
      return false
    }
    if (
      toDate.hour == moment().hours().valueOf() &&
      toDate.minutes == moment().minutes().valueOf() &&
      toDate.seconds.valueOf() > moment().seconds().valueOf()
    ) {
      return false
    }
  }

  return timeUnits.reduce(
    (acc, curr) =>
      curr == 'hour'
        ? fromDate[curr] >= 0 &&
          fromDate[curr] < 24 &&
          toDate[curr] >= 0 &&
          toDate[curr] < 24 &&
          acc
        : fromDate[curr] >= 0 &&
          fromDate[curr] <= 59 &&
          toDate[curr] >= 0 &&
          toDate[curr] <= 59 &&
          acc,

    true
  )
}

export function getDeviationTimeFormat(type: string, level: string, deviationTime: any) {
  if (type === 'deviation') {
    if (level === 'week') {
      return moment(deviationTime).format('D MMM YYYY')
    } else if (level === 'day') {
      return moment(deviationTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    }
  }
  if ((type = 'deflection')) {
    if (level === 'week') {
      return moment(deviationTime).utc().format('ddd')
    } else if (level === 'day') {
      return moment(deviationTime).utc().format('HH:30')
    }
  }
  return ''
}
