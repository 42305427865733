import {
  Autocomplete,
  BasicCard,
  Button,
  HelperText,
  LabelInput,
  Select,
  Switch,
  Tooltip,
} from '../../components'
import MetricQueryCardTitle from './metric-query-card-title'
import MetricQueryList from './metric-query-list'
import QueryValidateInput from './query-validate-input'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  MetricTypes,
  QueryType,
  addApplication,
  changeCurrentQuery,
  changeInputValues,
  changeUnitOfQuery,
  deleteQuery,
  httpInputsSelector,
  resetApplicationId,
  resetState,
  validateQueryOfJobMetric,
} from '../../store/add-application'
import { useEffect, useMemo } from 'react'
import { AddApplicationCardProps } from './types'
import { Close, InfoOutlined } from '../../components/icons'
import { COLORS } from '../../../themes/default/colors'
import { dashboardStateSelector } from '../../store/dashboard'
import { isFormValid, onlyIntegerKeyDown, validateField } from '../../utils'
import { validationMessages } from '../../consts'
import { useGetSourceLabelsQuery, useGetSourceMetricValuesQuery } from '../../store/application'
import { ApplicationTooltipList } from '../../consts/tooltips'

export const ADD_APPLICATION_CARD_TEST = 'ADD_APPLICATION_CARD_TEST'

const metricTypeOptions = Object.keys(MetricTypes).map((k) => ({ label: k, value: k }))

export const isValidQueries = (queries: QueryType[]) =>
  queries.reduce((acc, curr) => acc && curr.status === 'success' && curr.unit !== '', true)

export default function AddApplicationCard({
  onCloseClick,
  onAddApplicationAction,
  onCancelClick,
}: AddApplicationCardProps) {
  const dispatch = useAppDispatch()
  const httpInputs = useAppSelector(httpInputsSelector)
  const { currentSource } = useAppSelector(dashboardStateSelector)
  const { data: sourcelabelOptions = [] } = useGetSourceLabelsQuery({ sourceId: currentSource.id })

  const { data: sourceValueOptions = [] } = useGetSourceMetricValuesQuery(
    {
      sourceId: currentSource.id,
      label: httpInputs.selectedJob.value,
    },
    { skip: !httpInputs.selectedJob.value }
  )

  useEffect(() => {
    if (httpInputs.applicationId !== '') {
      onAddApplicationAction()
      dispatch(resetApplicationId())
    }
  }, [httpInputs.applicationId])

  useEffect(() => {
    dispatch(
      changeCurrentQuery(
        `${httpInputs.selectedMetric.value}{job='${httpInputs.selectedJob.value}'}`
      )
    )
    return () => {}
  }, [httpInputs.selectedJob.value, httpInputs.selectedMetric.value])

  const handleInputChange = (type: string, value: string | number | boolean) => {
    dispatch(changeInputValues({ type, value }))
  }

  const handleValidateQuery = () => {
    dispatch(
      validateQueryOfJobMetric({
        sourceId: currentSource.id,
        query: httpInputs.currentQuery,
        queryIndex: httpInputs.queries.length,
        metricType: httpInputs.metricType,
      })
    )
  }

  const handleDeleteQuery = (index: number) => {
    dispatch(deleteQuery(index))
  }

  const handleQueryUnitChange = (index: number, unit: string) => {
    dispatch(changeUnitOfQuery({ index, unit }))
  }

  const handleAddApplication = () => {
    dispatch(
      addApplication({
        ...httpInputs,
        sourceId: currentSource.id as string,
      })
    )
  }

  const handleQueryChange = (value: string) => {
    dispatch(changeCurrentQuery(value))
  }

  const isValidName = useMemo(
    () => validateField(httpInputs.applicationName, ['required', 'entityName']),
    [httpInputs.applicationName]
  )

  const isValidHistoryDays = useMemo(
    () => validateField(httpInputs.historyDays, ['required', 'min:1', 'max:10']),
    [httpInputs.historyDays]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      dispatch(resetState())
    })
    return () => {
      window.removeEventListener('beforeunload', () => {
        dispatch(resetState())
      })
    }
  }, [dispatch])

  return (
    <BasicCard
      testId={`${ADD_APPLICATION_CARD_TEST}_CARD`}
      containerClasses="flex relative flex-col w-6/12 py-5 mobile:w-9/12 tablet_p:w-9/12 tablet_p:h-[47rem] tablet_l:w-8/12 tablet_l:h-[40rem] laptop:w-6/12 laptop:h-[46rem] desktop:w-5/12 desktop:h-[47rem]"
      contentClasses="flex flex-col !px-10 tablet_l:overflow-y-scroll"
    >
      {onCloseClick !== undefined && !httpInputs.isLoading ? (
        <button className="absolute right-2.5 top-2.5 text-primary" onClick={onCloseClick}>
          <Close className="w-10 p-2 pt-3 " color={COLORS.PRIMARY} />
        </button>
      ) : null}

      <div className="flex flex-col">
        <MetricQueryCardTitle
          sourceName={currentSource.sourceName}
          sourceType={currentSource.sourceType}
        />
      </div>
      <div className="mt-2 flex flex-row">
        <div className="flex-1">
          <LabelInput
            testId={`${ADD_APPLICATION_CARD_TEST}_APP_NAME`}
            required
            label="Application name"
            toolTipMessage={<ApplicationTooltipList />}
            toolTipPlacement="right-start"
            error={isValidName !== ''}
            placeholder="Name the application"
            helperText={'Name your application for which the metrics will be scraped'}
            value={httpInputs.applicationName}
            onChange={(e) => handleInputChange('applicationName', e.target.value)}
            errorMessage={isValidName ? validationMessages.applicationName[isValidName] : ''}
          />
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="mt-2 flex flex-col">
        <div className="flex flex-row items-end gap-x-3">
          <div className="flex-1">
            <Select
              testId={`${ADD_APPLICATION_CARD_TEST}_METRIC_TYPE`}
              required
              placeholder="Metric type"
              labelClasses="text-xs"
              options={metricTypeOptions}
              label="Build a metric query"
              value={metricTypeOptions.find((m) => m.value === httpInputs.metricType)}
              onChange={(option) => handleInputChange('metricType', option.value as string)}
            />
          </div>

          <div className="flex-1">
            <Autocomplete
              testId={`${ADD_APPLICATION_CARD_TEST}_LABEL_VALUE`}
              placeholder="Label value"
              options={sourcelabelOptions}
              value={httpInputs.selectedJob}
              onChange={(value) => handleInputChange('selectedJob', value)}
            />
          </div>

          <div className="flex-1">
            <Autocomplete
              testId={`${ADD_APPLICATION_CARD_TEST}_METRIC_NAME`}
              placeholder="Metric name"
              options={sourceValueOptions}
              value={httpInputs.selectedMetric}
              onChange={(value) => handleInputChange('selectedMetric', value)}
            />
          </div>
        </div>
        <HelperText
          text="Select job and metrics to aggregate metrics grouped by job"
          color="primary"
        />
      </div>
      <div className="mt-2 flex flex-row">
        <QueryValidateInput
          testId={`${ADD_APPLICATION_CARD_TEST}_QUERY`}
          placeholder="Write custom query"
          value={httpInputs.currentQuery}
          onAddQuery={handleValidateQuery}
          onChange={(e) => handleQueryChange(e.target.value)}
          queries={httpInputs.queries}
          metricType={httpInputs.metricType}
        />
      </div>
      <div className="mt-2 flex flex-col">
        <div className="mb-2 text-sm text-white">
          Selected metric queries ({httpInputs.queries.length})
        </div>
        <MetricQueryList
          queries={httpInputs.queries}
          onDelete={handleDeleteQuery}
          onUnitChange={handleQueryUnitChange}
        />
      </div>
      <div className="mt-2 flex h-16 flex-col">
        <div className="flex-rown flex h-8 items-center text-white">
          <span className="mr-2">Scrape historical data</span>
          <Switch
            testId={`${ADD_APPLICATION_CARD_TEST}_SWITCH`}
            checked={httpInputs.includeHistory}
            onChange={() => handleInputChange('includeHistory', !httpInputs.includeHistory)}
          />
          {httpInputs.includeHistory && (
            <>
              <span className="mx-2">from past</span>{' '}
              <LabelInput
                testId={`${ADD_APPLICATION_CARD_TEST}_HISTORY_DAYS`}
                type="text"
                error={isValidHistoryDays !== ''}
                onKeyDown={onlyIntegerKeyDown}
                inputStyles={{ padding: 0 }}
                className="mt-4 w-10 p-0"
                value={httpInputs.historyDays}
                onChange={(e) => handleInputChange('historyDays', e.target.value)}
              />
              <span className="mx-2">days</span>
            </>
          )}
          <Tooltip
            placement="top"
            title={
              !httpInputs.includeHistory
                ? 'History of metric behaviour is useful in learning seasonality and will decrease learning time for better accuracy'
                : 'Days allowed minimum 1 day, maximum 10 days'
            }
          >
            <span>
              <InfoOutlined className="ml-1.5 mt-0.5 h-4 w-4" color={COLORS.PRIMARY} />
            </span>
          </Tooltip>
        </div>
        <div className="flex h-4 items-center pt-0.5 text-xs text-red-500">
          {httpInputs.includeHistory ? validationMessages.historyDays[isValidHistoryDays] : ''}
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <Button
          testId={`${ADD_APPLICATION_CARD_TEST}_CANCEL`}
          className="w-40"
          variant="outlined"
          onClick={onCancelClick}
        >
          Cancel
        </Button>
        <Button
          testId={`${ADD_APPLICATION_CARD_TEST}_ADD_APP_BUTTON`}
          className="w-40"
          variant="contained"
          disabled={
            httpInputs.isLoading ||
            httpInputs.applicationName === '' ||
            httpInputs.queries.length === 0 ||
            !isValidQueries(httpInputs.queries) ||
            !isFormValid(
              { applicationName: httpInputs.applicationName, historyDays: httpInputs.historyDays },
              {
                applicationName: ['required', 'entityName'],
                historyDays: ['required', 'min:1', 'max:10'],
              }
            )
          }
          onClick={handleAddApplication}
        >
          Add application
        </Button>
      </div>
    </BasicCard>
  )
}
