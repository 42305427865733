import { COLORS } from './colors'

const MuiSvgIcon = {
  styleOverrides: {
    root: {
      color: COLORS.PRIMARY,
      '&.MuiSelect-icon': {
        color: COLORS.PRIMARY,
      },
      '&.MuiPickersCalendarHeader-switchViewIcon': {
        fontSize: '1.4rem',
      },
    },
  },
}

export default MuiSvgIcon
