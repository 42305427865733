import { CardProps } from './types'

export const CARD_TEST_ID = 'CARD_TEST_ID'

export default function Card({ testId = CARD_TEST_ID, children, className, onClick }: CardProps) {
  return (
    <div
      data-testid={testId}
      onClick={onClick}
      className={`flex w-full border border-dark94 bg-dark94 p-8 shadow ${className}`}
    >
      {children}
    </div>
  )
}
