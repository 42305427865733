import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export { useAuth, AuthProvider } from './useAuth'
export { useLocalStorage } from './useLocalStorage'
export { useOutsideClick } from './useOutsideClick'
export { useMousePosition } from './useMousePosition'
