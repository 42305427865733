import { COLORS } from './colors'

const MuiFormHelperText = {
  styleOverrides: {
    root: {
      color: COLORS.WARM_GREY,
    },
  },
}

export default MuiFormHelperText
