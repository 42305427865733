import { createAsyncThunk } from '@reduxjs/toolkit'
import { getApplicationQueries, validateQuery } from './services'

export const validateQueryOfJobMetric = createAsyncThunk(
  'application/validateQueryOfJobMetric',
  async ({
    sourceId,
    query,
    queryIndex,
    metricType,
  }: {
    sourceId: string
    query: string
    queryIndex: number
    metricType: string
  }) => {
    const response = await validateQuery(sourceId, query)
    return { value: query, queryIndex, metricType, ...response }
  }
)

export const getQueries = createAsyncThunk('application/queries', async (appId: string) => {
  const response = await getApplicationQueries(appId)
  return response.queries
})
