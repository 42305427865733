import { motion } from 'framer-motion'
import { Button, LabelInput, Select, Spinner } from '../../../components'
import HeaderInput from '../../../partials/add-edit-source-card/headers-input'
import { SourceStateType } from '../../../store/source'
import { httpOptions } from '../../add-source/add-source-card'
import { onlyIntegerKeyDown } from '../../../utils'
import { accordianTransition, validationMessages } from '../../../consts'
import { QIntervalTooltipList, QTimeoutTooltipList } from '../../../consts/tooltips'
import { ReactNode, useEffect, useState } from 'react'

export interface IPrometheusForm extends SourceStateType {
  onInputChange: (type: string, value: string, check?: boolean) => void
  onHeaderInputChange: (index: number, type: string, value: string) => void
  onTestConnection: () => void
  connectionStatus: string | null | number
  isTestingConnection: boolean
  isValidUrl: string
  isValidInterval: string
  isValidTimeout: string
  disableUrl: boolean
  prometheusPrefix: string
  isValidprometheusPrefix: string
}

export default function PrometheusForm({
  url,
  headers,
  httpMethod,
  queryInterval,
  queryTimeout,
  connectionStatus,
  isTestingConnection,
  isValidUrl,
  isValidInterval,
  isValidTimeout,
  onInputChange,
  onHeaderInputChange,
  onTestConnection,
  disableUrl,
  sourceId,
  prometheusPrefix,
  sourceType,
  isValidprometheusPrefix,
}: IPrometheusForm) {
  const [resetErrors, setResetErrors] = useState({
    'Http URL': false,
    'Query timeout (seconds)': false,
    'Query interval (seconds)': false,
  })

  useEffect(() => {
    setResetErrors({
      'Http URL': true,
      'Query timeout (seconds)': true,
      'Query interval (seconds)': true,
    })
  }, [sourceType])

  function ResetErrors(label: any) {
    setResetErrors((prevResetErrors) => ({
      ...prevResetErrors,
      [label]: false,
    }))
  }

  return (
    <motion.div {...accordianTransition} className="flex w-full flex-col py-10">
      <div className="flex w-full flex-row gap-1">
        <div className="flex flex-row items-center laptop:h-[4.3rem] laptop:w-32 laptop:min-w-[7rem]  desktop:w-28">
          <Select
            required
            label="Http method"
            buttonClasses="!h-10 bg-dark2"
            labelClasses="text-sm text-white"
            options={httpOptions}
            value={httpOptions.find((h) => h.value === httpMethod)}
            onChange={(option) => onInputChange('httpMethod', option.value as string)}
          />
        </div>
        <div className="flex flex-row">
          <LabelInput
            label="Http URL"
            className={`desktop: rounded-sm  ${
              sourceType === 'cortex'
                ? 'laptop:w-[27rem] desktop:w-[37rem]'
                : 'laptop:w-[39rem] desktop:w-[53rem]'
            }`}
            required
            error={isValidUrl !== ''}
            placeholder="Enter an accessible URL"
            value={url}
            onChange={(e) => onInputChange('url', e.target.value)}
            errorMessage={
              isValidUrl
                ? !resetErrors['Http URL']
                  ? validationMessages.httpUrl[isValidUrl]
                  : ''
                : validationMessages.httpUrl[isValidUrl]
            }
            disabled={disableUrl}
            resetCancel={ResetErrors}
            clickedCancel={resetErrors['Http URL']}
          />
        </div>

        {sourceType === 'cortex' && (
          <LabelInput
            label="Prometheus prefix"
            className={`rounded-sm ${
              sourceType === 'cortex'
                ? 'laptop:w-[12rem] desktop:w-[16rem]'
                : 'laptop:w-[39rem] desktop:w-[53rem]'
            }`}
            placeholder="Prometheus prefix"
            value={prometheusPrefix}
            onChange={(e) => onInputChange('prometheusPrefix', e.target.value)}
            error={isValidprometheusPrefix !== ''}
            errorMessage={
              isValidprometheusPrefix
                ? validationMessages.PrometheusPrefix[isValidprometheusPrefix]
                : ''
            }
          />
        )}
      </div>
      <div className="flex flex-col gap-y-4">
        {headers.map((header, index: number) => (
          <HeaderInput
            key={sourceId ? `${sourceId}-${index}` : index}
            name={header.name}
            value={header.value}
            onChange={(name, value) => onHeaderInputChange(index, name, value)}
            className="!w-3"
          />
        ))}
        <div className="flex flex-row">
          <Button
            className="!border-2"
            styles={{ height: '2.5rem', width: '12rem' }}
            buttonTextClass="font-bold text-[14px] whitespace-nowrap"
            fullWidth
            variant="outlined"
            onClick={onTestConnection}
            disabled={isValidUrl !== '' || isValidprometheusPrefix !== ''}
          >
            Test connection
          </Button>
          <div className="flex flex-row items-center justify-between px-3">
            {isTestingConnection && <Spinner className="h-6 w-6" />}

            {connectionStatus !== '' && !isTestingConnection && (
              <>
                <span
                  className={`mx-2 rounded border px-2 text-base ${
                    connectionStatus === 'SUCCESS'
                      ? 'border-green-900 bg-green-300 text-green-900'
                      : 'border-red-900 bg-red-300 text-red-900'
                  }`}
                >
                  {connectionStatus}
                </span>
                {connectionStatus === 'SUCCESS' ? (
                  <span className="text-primary"> Test connection passed </span>
                ) : (
                  <span className="text-red-600"> Test connection failed </span>
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <LabelInput
            className="laptop:w-[23.2rem] desktop:w-[30rem]"
            required
            type="number"
            error={isValidInterval !== ''}
            onKeyDown={onlyIntegerKeyDown}
            label="Query interval (seconds)"
            toolTipMessage={<QIntervalTooltipList />}
            toolTipPlacement="right-start"
            placeholder="Interval in seconds"
            value={queryInterval}
            onChange={(e) => onInputChange('queryInterval', e.target.value, true)}
            errorMessage={
              isValidInterval
                ? !resetErrors['Query interval (seconds)']
                  ? validationMessages.queryInterval[isValidInterval]
                  : ''
                : validationMessages.queryInterval[isValidInterval]
            }
            resetCancel={ResetErrors}
            clickedCancel={resetErrors['Query interval (seconds)']}
          />
          <LabelInput
            className="laptop:w-[23.2rem] desktop:w-[30rem]"
            required
            type="number"
            error={isValidTimeout !== ''}
            onKeyDown={onlyIntegerKeyDown}
            label="Query timeout (seconds)"
            toolTipMessage={<QTimeoutTooltipList />}
            toolTipPlacement="right-start"
            placeholder="Time in seconds"
            value={queryTimeout}
            onChange={(e) => onInputChange('queryTimeout', e.target.value, true)}
            errorMessage={
              isValidTimeout
                ? !resetErrors['Query timeout (seconds)']
                  ? validationMessages.queryTimeout[isValidTimeout]
                  : ''
                : validationMessages.queryTimeout[isValidTimeout]
            }
            resetCancel={ResetErrors}
            clickedCancel={resetErrors['Query timeout (seconds)']}
          />
        </div>
      </div>
    </motion.div>
  )
}
